import { EntityType } from '@/store/types/basicTypes';
import { ProccessStep } from '@/store/types/caseType';

export const caseType2Mock = {
  id: 'case-type-2',
  label: 'Case type 2',
  indicators: ['indicator-2'],
  modules: ['module-2'],
  triggeredModules: [],
  isSessionOnly: false,
  entityType: EntityType.CaseType,
  processStep: ProccessStep.Intake,
};

export const caseType1Mock = {
  id: 'case-type-1',
  label: 'Case type 1',
  indicators: ['indicator-1'],
  modules: ['module-1'],
  triggeredModules: [],
  isSessionOnly: false,
  entityType: EntityType.CaseType,
  processStep: ProccessStep.Intake,
};

export const caseTypesMock = {
  entities: {
    [caseType1Mock.id]: caseType1Mock,
    [caseType2Mock.id]: caseType2Mock,
  },
  ids: [caseType1Mock.id, caseType2Mock.id],
  suggested: [],
  selected: [caseType1Mock.id, caseType2Mock.id],
};

import { EntityType } from '@/store/types/basicTypes';
import {
  datePickerQuestionMock,
  dateRangeQuestionMock,
  multiSelectQuestionMock,
  numericalQuestionMock,
  radioQuestionMock,
  textFieldQuestionMock,
} from './questionsMock';
import { tip1Mock } from './tipsMock';
import { module1Mock, module2Mock } from './modulesMock';

export const displayOrderMock = {
  [module1Mock.id]: [
    {
      id: radioQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
    {
      id: tip1Mock.id,
      componentType: EntityType.Tip,
      parentId: radioQuestionMock.id,
    },
    {
      id: tip1Mock.id,
      componentType: EntityType.Tip,
      parentId: radioQuestionMock.id,
    },
    {
      id: multiSelectQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
    {
      id: tip1Mock.id,
      componentType: EntityType.Tip,
      parentId: radioQuestionMock.id,
    },
    {
      id: textFieldQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
    {
      id: dateRangeQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
    {
      id: datePickerQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
  ],
  [module2Mock.id]: [
    {
      id: numericalQuestionMock.id,
      componentType: EntityType.Question,
      parentId: '',
    },
  ],
};

import { EntityType } from '@/store/types/basicTypes';
import { CaseType, ProccessStep } from '@/store/types/caseType';

const createCaseTypeTransform = (data: any): CaseType => {
  const {
    createCaseType: { id, label, modules = [], indicators = [] },
  } = data;
  return {
    id,
    label,
    modules,
    indicators,
    isSessionOnly: true,
    entityType: EntityType.CaseType,
    processStep: ProccessStep.Intake,
  };
};

export default createCaseTypeTransform;

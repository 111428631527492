import { styled } from '@mui/system';

export const InfoBarContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.primary.lightest};
  width: 100%;
`;

export const SelectedCaseType = styled('div')`
  font-size: 1.2em;
  font-weight: 600;
  display: inline-block;
  // Add this back once data below it is back in.
  // margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const Spacer = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  display: inline-block;
`;

export const InfoSection = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(5)};
  display: inline-block;
  font-size: 13px;
`;
export const InfoHeader = styled('div')`
  font-size: 0.7em;
`;

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ButtonContainer = styled('div')`
  margin-left: auto;
`;

export const PlaceholderText = styled('div')`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const SessionInfoContainer = styled('div')`
  background: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(1)};
  border-left: 3px solid ${({ theme }) => theme.palette.primary.main};
`;

import client from '@/api/client';
// @ts-ignore
import { GetInitialData } from '@/api/gql/queries.gql';
import { isUsingFixtures as IS_USING_FIXTURES } from '@/constants/env';
import { RawInitialData } from '../types/initialData';
import { fetchUser } from '../auth/fetchUser';
import { getMockApiInitialData } from '@/__mocks__/api/mockApiInitialData';
import initialTestState from '@/__mocks__/testState/initialTestState';
import featureFlags from '@/constants/featureFlags';
import { checkAndSetFeatureFlag } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

export const getInitialData = async ({
  projectId,
}: {
  projectId: string;
}): Promise<{
  data?: RawInitialData;
  apiKey?: string;
  error?: any;
}> => {
  await checkAndSetFeatureFlag(
    featureFlags.isUsingNewQuestionnaireAPI,
    'IS_USING_QUESTIONNAIRE_API'
  );

  await checkAndSetFeatureFlag(
    featureFlags.isUsingStaticModules,
    'IS_USING_STATIC_MODULES'
  );

  const fetchData = async () => {
    if (IS_USING_FIXTURES) {
      return { data: getMockApiInitialData(initialTestState) };
    }

    const { data } = await client.query({
      query: GetInitialData,
      variables: {
        caseId: projectId,
      },
    });

    const getUserDetails = await fetchUser();

    return {
      data: {
        ...data,
        getUserDetails,
      },
    };
  };

  try {
    return await fetchData();
  } catch (error: any) {
    return { error };
  }
};

export default getInitialData;

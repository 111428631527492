'use-client';
import { useContext } from 'react';
import { Box } from '@mui/material';
import { ProfileMenu } from '../ProfileMenu';
import { NetworkMessages } from '../NetworkMessages';
import { OnlineStatusContext } from '@/context/onlineStatusContext';

const GenericHeader = (): JSX.Element => {
  const { isOnline, wasOffline } = useContext(OnlineStatusContext);

  return (
    <Box data-testid='main-header'>
      <Box display='flex' justifyContent='flex-end'>
        <ProfileMenu />
      </Box>
      {!isOnline && NetworkMessages.Offline()}
      {wasOffline && isOnline && NetworkMessages.Reconnect()}
    </Box>
  );
};

export default GenericHeader;

import {
  selectModuleGroupsAsArray,
  selectSelectedModules,
} from '@/store/slices/modules';
import { useAppSelector } from './storeHooks';
import { selectCaseType } from '@/store/slices/caseTypes';
import useCaseClosure from './useCaseClosure';

const useGetModulesWithGroups = () => {
  const { isCaseClosureRoute, caseClosureId } = useCaseClosure();
  const selectedModuleIds = useAppSelector(selectSelectedModules);
  const moduleGroups = useAppSelector(selectModuleGroupsAsArray);
  const caseClosureModules =
    useAppSelector((state) => selectCaseType(state, caseClosureId!))?.modules ||
    [];

  const modulesToEvaluate = isCaseClosureRoute
    ? caseClosureModules
    : selectedModuleIds.filter((id) => !caseClosureModules.includes(id));

  const modulesWithGroups = modulesToEvaluate.filter((id) =>
    moduleGroups.some((group) => group.moduleIds.includes(id))
  );

  return modulesWithGroups;
};

export default useGetModulesWithGroups;

import { ContributorStatus, UserRole } from '@/store/types/admin';

export const userRolesData = {
  [UserRole.TA]: {
    label: 'Technical Analyst',
    abreviation: 'TA',
  },
  [UserRole.DFIR]: {
    label: 'DFIR',
    abreviation: 'DFIR',
  },
  [UserRole.Legal]: {
    label: 'Legal',
    abreviation: 'LEGAL',
  },
};

export const contributorStatusData = {
  [ContributorStatus.Active]: {
    label: 'Active',
  },
  [ContributorStatus.Invited]: {
    label: 'Invited',
  },
  [ContributorStatus.Suspended]: {
    label: 'Inactive',
  },
};

import { emptyPagination } from '@/api/admin/types';
import { Questionnaire } from './questionnaire';

export type ProjectStatus = 'CREATED' | 'ACTIVE' | 'CLOSED';
export type IncidentRole = 'PrimaryContact' | 'Contributor' | null;

export const defaultFiltersObject: Filters = {
  pagination: {
    ...emptyPagination,
    limit: 10,
  },
  filters: [],
  sort: { column: '', direction: '' },
  searches: [],
};

export interface PrimaryContactUser {
  id: string;
  fullName: string;
  email: string;
}

export interface MandatoryQuestionCompletionStatus {
  visible: number;
  completedVisible: number;
}

export interface Pagination {
  offset: number;
  limit: number;
  total: number;
  next: boolean;
  previous: boolean;
}

export interface Incident {
  id: string;
  title: string;
  status: ProjectStatus;
  caseTypes: string[];
  mandatoryQuestionCompletionStatus: MandatoryQuestionCompletionStatus;
  createdAt: string; // startDate
  closedAt: string | null; // endDate
  incidentManagerOrganizationName: string | null;
  incidentManagerPrimaryContactName: string;
  incidentOwnerOrganizationName: string;
  incidentOwnerPrimaryContactName: string;
  role: IncidentRole;
  victimOrg: string;
  // This is only applicable for GET /incidents/:id
  description?: string;
  contributors?: string[];
  intake?: {
    status: string;
    startDate: string;
    endDate: string;
    caseType: string[];
  };
  closure?: {
    status: string;
    startDate: string;
    endDate: string;
    completionRate: number;
  };
  completionRate: number;
}

export interface Contributor {
  id: string;
  email: string;
  name: string;
  organization: string;
  status: ContributorStatus;
  roles: UserRole[];
  completionRate: number;
}

export enum ContributorStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Suspended = 'SUSPENDED',
}

export enum UserRole {
  'TA',
  'DFIR',
  'Legal',
}

export interface SearchProps {
  column: string;
  value: string;
}

export interface Filters {
  searches: SearchProps[];
  filters: { column: string; values: string[] }[];
  sort: {
    column: string;
    direction: 'asc' | 'desc' | '';
  };
  pagination: {
    offset: number;
    limit: number;
    total: number;
    hasNext: boolean;
    hasPrev: boolean;
    page?: number;
    pages?: number;
  };
}

export interface AdminSlice {
  questionnaires: {
    ids: string[];
    entities: {
      [key: string]: Questionnaire;
    };
  };
  incidents: {
    ids: string[];
    entities: {
      [key: string]: Incident;
    };
    filters: Filters;
    isLoading: boolean;
  };
  contributors: {
    ids: string[];
    entities: {
      [key: string]: Contributor;
    };
    filters: Filters;
    isLoading: boolean;
  };
}

import * as React from 'react';
import { Snackbar as MUISnackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  removeSnackbarNotification,
  selectSnackbarNotification,
} from '@/store/slices/ui';

const Snackbar = () => {
  const { message, type, action, disableAutoHide } = useAppSelector(
    selectSnackbarNotification
  );
  const dispatch = useAppDispatch();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removeSnackbarNotification());
  };

  return (
    <MUISnackbar
      open={!!message}
      autoHideDuration={disableAutoHide ? null : 6000}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={type}
        sx={{ width: '100%' }}
        variant='filled'
        action={action}
      >
        {message}
      </MuiAlert>
    </MUISnackbar>
  );
};

export default Snackbar;

import { AppThunk } from '@/store/store';
import { removeEntitiesFromQuestionDisplayOrder } from '@/store/slices/questionDisplayOrder';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import archiveTip from '@/api/mutations/editor/archiveTip';
import { deleteTip } from '@/store/slices/tips';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveTipsThunk =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const searchFilter = getState().editor.entitySearchFilter;
    const isSessionOnly = getState().tips.entities[id].isSessionOnly;
    !isSessionOnly ? await archiveTip(id) : await resetEditorSessionChange(id);
    dispatch(setIsDirty(true));
    dispatch(
      setEntitySearchFilter({
        ...searchFilter,
        entityIds: searchFilter.entityIds?.filter((qId) => qId !== id),
      })
    );

    dispatch(removeEntitiesFromQuestionDisplayOrder(id));
    dispatch(deleteTip(id));
  };

export default archiveTipsThunk;

import { EntityType } from '@/store/types/basicTypes';
import { advice1Mock } from './adviceMock';

export const tip1Mock = {
  id: 'tip-1',
  label: '',
  text: 'Tip 1',
  advice: [advice1Mock.id],
  entityType: EntityType.Tip,
};

export const tipsMock = {
  entities: {
    [tip1Mock.id]: tip1Mock,
  },
  ids: [tip1Mock.id],
  aliasMap: {},
};

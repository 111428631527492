import { ModuleGroup } from '../types/module';

export const getOrderedModuleGroupIds = (moduleGroups: {
  entities: { [key: string]: ModuleGroup };
  ids: string[];
}): string[] => {
  return moduleGroups.ids.sort((a, b) => {
    const groupA = moduleGroups.entities[a];
    const groupB = moduleGroups.entities[b];

    // @ts-ignore
    return groupA.priorityIndex - groupB.priorityIndex;
  });
};

export default getOrderedModuleGroupIds;

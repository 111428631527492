import { Switch, styled } from '@mui/material';

const SwitchWithText = styled(Switch)(({ theme, checked }) => ({
  width: 70,
  padding: 8,

  '& .MuiSwitch-switchBase': {
    padding: 9,
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
  },
  '.Mui-checked': {
    transform: 'translateX(32px)',
  },
  '& .MuiSwitch-track': {
    width: 70,
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      content: '"Show"',
      fontWeight: '700',
      fontSize: '10px',
      left: 15,
      color: theme.palette.common.white,
      display: checked ? 'block' : 'none',
    },
    '&:after': {
      content: '"Hide"',
      fontWeight: '700',
      fontSize: '10px',
      right: 20,
      color: theme.palette.common.white,
      display: checked ? 'none' : 'block',
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    background: '#fff',
  },
}));

export default SwitchWithText;

import { ErrorCodes } from '@/constants/errors';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectHasAuthenticated,
  setHasInitialised,
} from '@/store/slices/config';
import initAppData from '@/store/thunks/initAppData';
import { createError } from '@/utils/errorHandler';
import {
  getIsAdminRoute,
  getRootPathRequiresAuth,
  getRootPathRequiresCaseId,
  getRootPathRequiresQuestionnaireId,
} from '@/utils/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const StoreInitialiser = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isReady } = router;
  const caseId = router.query.caseId as string;
  const questionnaireId = router.query.id as string;
  const sessionId = router.query.sessionId as string;
  const hasAuthenticated = useAppSelector(selectHasAuthenticated);
  const routeRequiresAuth = getRootPathRequiresAuth(router);
  const routeRequiresCaseId = getRootPathRequiresCaseId(router);
  const routeRequiresQuestionnaireId =
    getRootPathRequiresQuestionnaireId(router);
  const isAdminRoute = getIsAdminRoute(router);
  const shouldFetchQuestionnaireData =
    isReady &&
    hasAuthenticated &&
    routeRequiresAuth &&
    (routeRequiresCaseId || routeRequiresQuestionnaireId);

  useEffect(() => {
    if (isAdminRoute) {
      dispatch(setHasInitialised(true));
      return;
    }

    const initialiseData = async () =>
      dispatch(initAppData({ caseId: caseId || questionnaireId, sessionId }));
    if (shouldFetchQuestionnaireData) {
      if (caseId || questionnaireId) {
        initialiseData();
        return;
      } else {
        createError({ code: ErrorCodes.NoCaseId, displaySnackbar: true });
        router.push('/error');
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldFetchQuestionnaireData,
    dispatch,
    caseId,
    questionnaireId,
    sessionId,
  ]);

  return null;
};

export default StoreInitialiser;

import {
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Paper,
  PaperProps,
} from '@mui/material';
import { StyledDialogue } from './styled';
import Draggable from 'react-draggable';
import { useCallback } from 'react';

const PaperComponent = ({
  props,
  draggableId,
}: {
  props: PaperProps;
  draggableId?: string;
}) => {
  return (
    <Draggable
      handle={'#draggable-dialog-title'}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

interface EditorDialogProps extends DialogProps {
  draggableId?: string;
}

interface EditorDialogTitleProps extends DialogTitleProps {
  draggableId?: string;
}

const EditorDialog = ({ children, ...props }: EditorDialogProps) => {
  const MemoizedPaperComponent = useCallback(
    (paperProps: PaperProps) =>
      PaperComponent({
        props: paperProps,
        draggableId: 'draggable-dialog-title',
      }),
    []
  );
  return (
    <StyledDialogue {...props} PaperComponent={MemoizedPaperComponent}>
      {children}
    </StyledDialogue>
  );
};

export const EditorDialogTitle = ({
  children,
  ...props
}: EditorDialogTitleProps) => {
  return (
    <DialogTitle
      sx={{ textAlign: 'right' }}
      style={{ cursor: 'move' }}
      id={'draggable-dialog-title'}
      {...props}
    >
      {children}
    </DialogTitle>
  );
};

export default EditorDialog;

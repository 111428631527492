import { LicenseInfo } from '@mui/x-license';
import React, { useEffect, useMemo } from 'react';
import type { AppProps } from 'next/app';
import '@/styles/globals.css';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import client, { queueLink as ql } from '@/api/client';
import { ThemeProvider, lightTheme } from '@s-rm/react-ui-lib';
import ErrorBoundary from '@/components/ErrorBoundary';
import Layout from '@/components/Layout';
import { Provider } from 'react-redux';
import { store } from '@/store';
import {
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
  createTheme,
} from '@mui/material';
import StoreInitialiser from '@/components/StoreInitialiser';
import { RawInitialData } from '@/api/types/initialData';
import useAuthenticate from '@/hooks/useAuthenticate';
import { ErrorCodes } from '@/constants/errors';
import Snackbar from '@/components/Snackbar/Snackbar';
import { RefreshDialogOverlay } from '@/components/RefreshDialogOverlay';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { useNetworkStatus } from '@/hooks/useNetworkStatus';
import { useBeforeUnloadEvent } from '@/hooks/useBeforeUnloadEvent';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { FeatureFlagProvider } from '@/components/FeatureFlags/Provider';
import { useRouter } from 'next/router';
import { getIsAdminRoute } from '@/utils/router';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUIX_LICENSE_KEY as string);

interface CustomAppProps extends AppProps {
  initialData: RawInitialData;
  caseId: string;
  requiresJWT: boolean;
  error?: ErrorCodes;
  apiKey?: string;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = CustomAppProps & {
  Component: NextPageWithLayout;
};

const getTheme = ({ isAdminRoute }: { isAdminRoute: boolean }) =>
  createTheme({
    ...lightTheme,
    ...(isAdminRoute
      ? {
          typography: {
            h1: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 200,
              fontSize: '2.5rem',
            },
            h2: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 500,
              fontSize: '1.7rem',
            },
            h3: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 300,
              fontSize: '1.3rem',
            },
            h4: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 700,
              fontSize: '2.5rem',
            },
            h5: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 700,
              fontSize: '2.5rem',
            },
            h6: {
              fontFamily: 'Soleil, Roboto, Arial, sans-serif',
              fontWeight: 700,
              fontSize: '2.5rem',
            },
          },
        }
      : {}),
    palette: {
      ...lightTheme.palette,
      ...(isAdminRoute
        ? {}
        : {
            primary: {
              main: '#526988',
              light: '#DAE6F5',
              lightest: '#edf2f6',
            },
          }),
    },
  });

const CustomApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useAuthenticate();
  const router = useRouter();
  const isAdminRoute = getIsAdminRoute(router);
  const { isOnline, wasOffline } = useNetworkStatus();
  useBeforeUnloadEvent((event) => {
    event.returnValue = true;
  });
  const theme = getTheme({ isAdminRoute });

  useEffect(() => {
    isOnline ? ql.open() : ql.close();
  }, [isOnline]);

  const contextValue = useMemo(
    () => ({ isOnline, wasOffline }),
    [isOnline, wasOffline]
  );

  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  return (
    <ApolloProvider client={client as ApolloClient<NormalizedCacheObject>}>
      <Provider store={store}>
        <StoreInitialiser />
        <MUIThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary>
              <FeatureFlagProvider
                endpoint={process.env.NEXT_PUBLIC_FEATURE_FLAGS_URL || ''}
              >
                <RefreshDialogOverlay />
                <Snackbar />
                <OnlineStatusContext.Provider value={contextValue}>
                  {getLayout(<Component {...pageProps} />)}
                </OnlineStatusContext.Provider>
              </FeatureFlagProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </MUIThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default CustomApp;

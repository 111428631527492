import { useAppDispatch, useAppSelector } from '@/hooks';
import PrintableSummaryContent from './PrintableSummaryContent';
import {
  selectPrintableSummaryStatus,
  setPrintableSummaryStatus,
} from '@/store/slices/ui';
import Dialog from '@mui/material/Dialog';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Button } from '@s-rm/react-ui-lib';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import copyToClipboard from './utils/copyToClipboard';
import CopyButton from './CopyButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogueTitleContainer,
  DialogTitleWrapped,
  DialogContentWrapped,
  DialogActionsWrapped,
} from './styled';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { useTabsHandlerAndIndex } from '@/hooks/useTabsHandlerAndIndex';
import { SummaryTabs } from './types';

const PrintableSummary = () => {
  const { isOpen, isAutoCopy } = useAppSelector(selectPrintableSummaryStatus);
  const dispatch = useAppDispatch();
  const printAreaRef = useRef(null);

  const handleCloseDialogue = () => {
    dispatch(setPrintableSummaryStatus({ isOpen: false, isAutoCopy: false }));
  };

  const handlePrint = useReactToPrint({
    content: () =>
      currentTabIndex === SummaryTabs.Advice
        ? (printAreaRef?.current as unknown as HTMLDivElement)?.querySelector?.(
            '#printable-summary-advice'
          )
        : (printAreaRef?.current as unknown as HTMLDivElement)?.querySelector?.(
            '#printable-summary'
          ),
  });

  const handleOnCopyEntireSummary = () => {
    copyToClipboard('printable-summary');
    dispatch(updateSnackbarNotification({ message: 'Copied to clipboard' }));
  };

  const handleOnCopyAdvice = () => {
    copyToClipboard('printable-summary-advice');
    dispatch(updateSnackbarNotification({ message: 'Copied to clipboard' }));
  };

  const { currentTabIndex, handleOnTabIndexChange } = useTabsHandlerAndIndex();

  const renderDialogueTitle = () =>
    currentTabIndex === SummaryTabs.Advice
      ? `ADVICE PREVIEW`
      : currentTabIndex === SummaryTabs.Summary && `SUMMARY PREVIEW`;

  if (!isOpen) return null;
  return isAutoCopy ? (
    <PrintableSummaryContent isAutoCopy />
  ) : (
    <Dialog open={isOpen} maxWidth='lg' fullWidth={true}>
      <DialogTitleWrapped>
        <DialogueTitleContainer>
          {renderDialogueTitle() || ''}
          <Button>
            <CloseIcon onClick={handleCloseDialogue} />
          </Button>
        </DialogueTitleContainer>
      </DialogTitleWrapped>
      <DialogContentWrapped>
        <div ref={printAreaRef}>
          <PrintableSummaryContent
            currentTabIndex={currentTabIndex}
            handleOnTabIndexChange={handleOnTabIndexChange}
          />
        </div>
      </DialogContentWrapped>
      <DialogActionsWrapped>
        <Button onClick={handleCloseDialogue} variant='contained'>
          Close
        </Button>
        {currentTabIndex === SummaryTabs.Advice && (
          <>
            <CopyButton onClick={handleOnCopyAdvice}>Copy Advice</CopyButton>
            <Button
              startIcon={<LocalPrintshopOutlinedIcon />}
              variant='outlined'
              onClick={handlePrint}
            >
              Print
            </Button>
          </>
        )}

        {currentTabIndex === SummaryTabs.Summary && (
          <>
            <CopyButton onClick={handleOnCopyEntireSummary}>
              Copy Summary
            </CopyButton>
            <Button
              startIcon={<LocalPrintshopOutlinedIcon />}
              variant='outlined'
              onClick={handlePrint}
            >
              Print
            </Button>
          </>
        )}
      </DialogActionsWrapped>
    </Dialog>
  );
};

export default PrintableSummary;

import dynamic from 'next/dynamic';
import { CircularProgress } from '@mui/material';
import { EditorWrapper } from './styled';

// Prevents the module loading and crashing during SSR since the DOM is not available, load on client side instead.
export const QuillNoSSRWrapper = dynamic(import('react-quill'), {
  ssr: false,
  loading: () => <CircularProgress />,
});

// An object specifying which modules are enabled, and their configuration.
export const defaultModules = {
  toolbar: [
    ['bold', 'italic', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, 'underline'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

// An array of formats to be enabled during editing
export const defaultFormats = [
  'italic',
  'bold',
  'strike',
  'list',
  'bullet',
  'underline',
];

interface TextEditorProps {
  modules?: unknown;
  value: string;
  onChange: any;
  id?: string;
  formats?: string[];
}

const TextEditor = ({
  modules,
  value,
  onChange,
  id,
  formats,
}: TextEditorProps) => (
  <EditorWrapper>
    <QuillNoSSRWrapper
      id={id}
      theme='snow'
      modules={modules || defaultModules}
      formats={formats || defaultFormats}
      value={value}
      onChange={(content, delta, source) => {
        source === 'user' && onChange(content);
      }}
    />
  </EditorWrapper>
);

export default TextEditor;

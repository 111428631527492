import { Module, ModuleGroup } from '@/store/types/module';

const getNonOrphanedModules = ({
  modules,
  moduleGroups,
}: {
  modules: { [key: string]: Module };
  moduleGroups: { [key: string]: ModuleGroup };
}) => {
  return Object.values(modules)
    .filter((m) => {
      return Object.values(moduleGroups).some((moduleGroup) =>
        moduleGroup.moduleIds.includes(m.id)
      );
    })
    .map((m) => m.id);
};

export default getNonOrphanedModules;

import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: '6586cc0ac5857446aa03f695dbbe2931',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NEXT_PUBLIC_ENV,
    enabledReleaseStages: ['production', 'staging'],
  });
}

// @ts-ignore - Bugsnag is not typed
const BugsnagBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const ErrorBoundary = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  if (BugsnagBoundary) {
    return <BugsnagBoundary>{children}</BugsnagBoundary>;
  }
  return <>{children}</>;
};

export default ErrorBoundary;

import {
  AdaptedEntities,
  TransformedInitialData,
} from '@/api/types/initialData';
import { getFlattenedEntity } from '../utils/getFlattenedEntity';

export const flattenStateEntities = ({
  questions,
  caseTypes,
  indicators,
  modules,
  moduleGroups,
  tips,
  advice,
  integrations,
}: AdaptedEntities) => ({
  questions: getFlattenedEntity(
    questions
  ) as TransformedInitialData['questions'],
  caseTypes: getFlattenedEntity(
    caseTypes
  ) as TransformedInitialData['caseTypes'],
  indicators: getFlattenedEntity(
    indicators
  ) as TransformedInitialData['indicators'],
  modules: getFlattenedEntity(modules) as TransformedInitialData['modules'],
  moduleGroups: getFlattenedEntity(
    moduleGroups
  ) as TransformedInitialData['moduleGroups'],
  tips: getFlattenedEntity(tips) as TransformedInitialData['tips'],
  advice: getFlattenedEntity(advice) as TransformedInitialData['advice'],
  integrations: getFlattenedEntity(
    integrations
  ) as TransformedInitialData['integrations'],
});

export default flattenStateEntities;

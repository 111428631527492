import React from 'react';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Typography } from '@mui/material';
import { StyledAlert } from './styled';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

export const NetworkMessages = {
  Offline: () => (
    <StyledAlert
      icon={false}
      severity='info'
      sx={{ background: '#EBEBEB' }}
      data-testid='offline-alert-bar'
    >
      <CloudOffIcon sx={{ mr: '8px', color: '#333' }} />
      <Typography variant='body1' align='center' sx={{ color: '#333' }}>
        You are currently offline. Do not reload or close the page otherwise you
        may lose any unsaved changes.
      </Typography>
    </StyledAlert>
  ),
  Reconnect: () => (
    <StyledAlert
      icon={false}
      severity='success'
      data-testid='reconnected-alert-bar'
    >
      <CloudDoneIcon sx={{ mr: 1 }} />
      <Typography variant='body1' align='center'>
        You have been reconnected, all unsaved changes are being saved!
      </Typography>
    </StyledAlert>
  ),
};

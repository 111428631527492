import Fuse from 'fuse.js';

const fuzzySearch = ({
  keys,
  threshold,
  entities,
  searchTerm,
}: {
  keys: string[];
  threshold?: number;
  searchTerm: string;
  entities: Record<string, any>[];
}): string[] => {
  const sanitizedEntities = entities.map((entity) => ({
    ...entity,
    [keys[0]]: entity[keys[0]]?.replace(/[^\w\s]/gi, ''),
  }));

  const fuse = new Fuse(sanitizedEntities, {
    keys,
    threshold: threshold || 0.3,
    distance: 1000,
  });

  return fuse.search(searchTerm).map((match) => match.item.id);
};

export default fuzzySearch;

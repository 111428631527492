import { updateQuestion } from '@/store/slices/questions';
import { AppThunk } from '@/store/store';
import { removeModulesFromQuestionDisplayOrder } from '@/store/slices/questionDisplayOrder';
import { updateCaseType } from '@/store/slices/caseTypes';
import { deleteModule } from '@/store/slices/modules';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import archiveModule from '@/api/mutations/editor/archiveModule';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveModuleThunk =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const questions = getState().questions.entities;
    const caseTypes = getState().caseTypes.entities;
    const searchFilter = getState().editor.entitySearchFilter;
    const isSessionOnly = getState().modules.entities[id].isSessionOnly;

    !isSessionOnly
      ? await archiveModule(id)
      : await resetEditorSessionChange(id);

    dispatch(setIsDirty(true));

    dispatch(
      setEntitySearchFilter({
        ...searchFilter,
        entityIds: searchFilter.entityIds?.filter((qId) => qId !== id),
      })
    );

    Object.values(questions).forEach((q) => {
      if (q.triggers.some((t) => t.componentId === id)) {
        dispatch(
          updateQuestion({
            id: q.id,
            updates: {
              triggers: questions[q.id].triggers.filter(
                (t) => t.componentId !== id
              ),
            },
          })
        );
      }
    });

    Object.values(caseTypes).forEach((c) => {
      if (c.modules.includes(id)) {
        dispatch(
          updateCaseType({
            id: c.id,
            updates: {
              modules: caseTypes[c.id].modules.filter((mId) => mId !== id),
              triggeredModules: caseTypes[c.id].modules.filter(
                (mId) => mId !== id
              ),
            },
          })
        );
      }
    });

    dispatch(removeModulesFromQuestionDisplayOrder([id]));
    dispatch(deleteModule(id));
  };

export default archiveModuleThunk;

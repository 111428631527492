import { Button } from '@s-rm/react-ui-lib';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

export const ActiveStepIndicator = styled.span`
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #fe6001;
  content: '';
  border-radius: 5px;
`;

export const StepButton = styled(Button)<{
  activeStep: number;
  isEditMode?: boolean;
}>`
  height: 40px;
  text-transform: none;
  color: ${({ isEditMode }) => (isEditMode ? '#3A3D40' : '#FFF')};
  font-weight: 100;
  margin: 5px 13px;
  &.Mui-disabled {
    color: ${({ isEditMode }) => (isEditMode ? '#3A3D40' : '#FFF')};
  }
  &:hover {
    background: none;
  }
`;

export const DividerWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const StepIcon = styled.span`
  margin-right: 8px;
`;

export const ChevronRight = styled(ChevronRightIcon)<{
  isEditMode?: boolean;
}>`
  color: ${({ isEditMode }) => (isEditMode ? '#3A3D40' : '#FFF')};
`;

export const VerticalRule = styled(HorizontalRuleIcon)<{
  isEditMode?: boolean;
}>`
  transform: rotate(90deg);
  color: ${({ isEditMode }) => (isEditMode ? '#3A3D40' : '#FFF')};
`;

import { FilterOptions } from '@/store/types/editor';
import { setEntitySearchFilter } from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import fuzzySearch from '@/store/utils/fuzzySearch';
import { getCaseTypeRelations } from '@/components/utils/getCaseTypeRelations';

const filterCaseTypesThunk =
  (filterOptions: FilterOptions): AppThunk =>
  (dispatch, getState) => {
    dispatch(setEntitySearchFilter(filterOptions));
    const caseTypes = getState().caseTypes.entities;
    const modules = getState().modules.entities;
    const indicators = getState().indicators.entities;
    const { searchTerm, filterBy } = getState().editor.entitySearchFilter;

    if (!searchTerm) {
      dispatch(
        setEntitySearchFilter({
          entityIds: convertEntitiesToArray(caseTypes).map((q) => q.id),
        })
      );
      return;
    }

    switch (filterBy) {
      case EntityType.Indicator:
        dispatch(
          setEntitySearchFilter({
            entityIds: getCaseTypeRelations({
              caseTypes,
              indicatorIds: fuzzySearch({
                keys: ['label'],
                entities: convertEntitiesToArray(indicators),
                searchTerm,
              }).map((indicatorId) => indicatorId),
              moduleId: '',
            }).map(({ caseType: id }) => id),
          })
        );
        break;
      case EntityType.Module:
        dispatch(
          setEntitySearchFilter({
            entityIds: fuzzySearch({
              keys: ['label'],
              entities: convertEntitiesToArray(modules),
              searchTerm,
            })
              .map((moduleId) => moduleId)
              .flatMap((mId) =>
                getCaseTypeRelations({
                  caseTypes,
                  moduleId: mId,
                  indicatorIds: [],
                }).map(({ caseType: id }) => id)
              ),
          })
        );
        break;
      case EntityType.CaseType:
        dispatch(
          setEntitySearchFilter({
            entityIds: fuzzySearch({
              keys: ['label'],
              entities: convertEntitiesToArray(caseTypes),
              searchTerm: searchTerm,
            }),
          })
        );
        break;

      default:
        break;
    }
  };

export default filterCaseTypesThunk;

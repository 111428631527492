import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetAdvice } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { Advice } from '@/store/types/advice';

export const getAdvice = async ({
  adviceId,
}: {
  adviceId: string;
}): Promise<{ data: Advice }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetAdvice,
    variables: {
      id: adviceId,
    },
  });

  // @ts-ignore
  return { data: getFormattedEntity(data.getAdvice) as Advice, errors };
};

export default getAdvice;

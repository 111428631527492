import {
  removeSelectedModules,
  removeTriggeredModules,
} from '../slices/modules';
import { AppThunk } from '../store';
import { ModuleId } from '../types/basicTypes';
import getModuleRelatedCaseTypes from '../utils/getModuleRelatedCaseTypes';

const removeTriggeredModulesThunk =
  ({
    moduleIdToTrigger,
    triggeringModuleId,
  }: {
    moduleIdToTrigger: ModuleId;
    triggeringModuleId?: ModuleId;
  }): AppThunk =>
  (dispatch, getState) => {
    const caseTypes = getState().caseTypes.entities;
    const relatedCaseTypeIds = getModuleRelatedCaseTypes({
      caseTypes,
      moduleId: triggeringModuleId as string,
    });
    const triggeredModulesPayload = relatedCaseTypeIds.map((caseTypeId) => ({
      moduleId: moduleIdToTrigger,
      relatedCaseTypeId: caseTypeId,
    }));

    dispatch(removeTriggeredModules(triggeredModulesPayload));
    dispatch(removeSelectedModules({ moduleIds: [moduleIdToTrigger] }));
  };

export default removeTriggeredModulesThunk;

import { styled } from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';

export const FilterButton = styled(Button)`
  padding-left: 15px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 600;
  border-right: 1px solid ${({ theme }) => theme.palette.grey[200]};
  text-transform: none;
  justify-content: flex-start;
`;

import { CaseType } from '@/store/types/caseType';
import getCaseClosureCaseType from '@/store/utils/getCaseClosureCaseType';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

export const getSelectedCaseTypesToSave = (
  caseTypes: { [key: string]: CaseType },
  store: ToolkitStore
) => {
  const caseClosureId = getCaseClosureCaseType(caseTypes);
  return store
    .getState()
    .caseTypes.selected.filter((id: string) => id !== caseClosureId);
};

import clearQuestionnaire from '@/api/mutations/clearQuestionnaire';
import {
  setHasQuestionnaireBeenCleared,
  setSnackbarNotification,
} from '../slices/ui';
import { AppThunk } from '../store';
import { SnackbarTypes } from '../types/ui';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';

export const clearQuestionnaireThunk =
  (id?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setHasQuestionnaireBeenCleared(true));
      await clearQuestionnaire();
      dispatch(
        setSnackbarNotification({
          message: `The case has been cleared.`,
          type: SnackbarTypes.Success,
          disableAutoHide: false,
        })
      );
    } catch (error) {
      createError({
        code: ErrorCodes.NetworkError,
        displaySnackbar: true,
      });
    } finally {
      dispatch(setHasQuestionnaireBeenCleared(false));
    }
  };

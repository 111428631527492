import DialogContent from '@mui/material/DialogContent';
import { Button, LoadingButton } from '@s-rm/react-ui-lib';

import {
  DialogTitle,
  Typography,
  Dialog,
  DialogActions,
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogueTitleContainer } from '../styled';

const ConfirmDeleteSessionModal = ({
  isOpen,
  onConfirm,
  onClose,
  isDeleting,
}: {
  isOpen: boolean;
  isDeleting: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Delete draft version
          </Typography>
          <Button>
            <CloseIcon onClick={onClose} />
          </Button>
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent>
        <Alert severity='warning'>
          <AlertTitle>
            <Typography fontWeight={700}>Warning</Typography>
          </AlertTitle>
          <Typography>
            By continuing with this action all changes made in this draft
            version will be deleted
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Box py={2} px={2}>
          <LoadingButton
            loading={isDeleting}
            variant='contained'
            onClick={onConfirm}
          >
            Yes, delete
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteSessionModal;

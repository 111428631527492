import {
  Box,
  InputAdornment,
  Menu,
  MenuItem,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { TextField } from '@s-rm/react-ui-lib';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { FilterButton } from './styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useDebouncedInputChange } from '@/hooks/useDebounceInputChange';

const SearchInput = ({
  value: initialValue,
  onChange,
  onChangeFilterOption,
  filterOptions,
  selectedFilterOption,
  onDebounce,
  shouldDebounce,
  ...rest
}: {
  value: string;
  onChange: (value: string) => void;
  onChangeFilterOption?: (value: string) => void;
  filterOptions?: {
    label: string;
    value: string;
  }[];
  selectedFilterOption?: string;
  onDebounce?: (props: { isDebouncing: boolean }) => void;
  shouldDebounce?: boolean;
} & TextFieldProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { handleChange: handleDebounce, value } = useDebouncedInputChange({
    initialValue,
    cb: (debouncedValue) => onChange(debouncedValue),
    onDebounce,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeFilterOption = (optionValue: string) => {
    onChangeFilterOption?.(optionValue);
    handleClose();
  };

  return (
    <TextField
      defaultValue={value}
      onChange={(e) => {
        if (shouldDebounce) {
          handleDebounce(e.target.value);
        } else {
          onChange(e.target.value);
        }
      }}
      size='small'
      data-testid='module-select-search'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        ),
        startAdornment: filterOptions ? (
          <Box mr={2} ml='-14px'>
            <FilterButton
              onClick={handleClick}
              startIcon={<FilterAltOutlinedIcon />}
              endIcon={<ExpandMoreIcon />}
            >
              <Typography textAlign='left' noWrap minWidth={100}>
                {selectedFilterOption}
              </Typography>
            </FilterButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {filterOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleChangeFilterOption(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        ) : null,
      }}
      {...rest}
    />
  );
};

export default SearchInput;

import client from '@/api/client';
//@ts-ignore
import { CreateEditorSession } from '@/api/gql/editor/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const createSession = async (): Promise<{
  data: any;
  errors: any;
}> => {
  // @ts-ignore
  const { data, errors } = await client.mutate({
    mutation: CreateEditorSession,
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateQuestion,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
    return { data: {}, errors };
  }

  return { data: { id: data.createEditorSession.id }, errors };
};

export default createSession;

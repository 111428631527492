import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectHasTriggeredIntegrationDialogOpen,
  setHasTriggeredIntegrationDialogOpen,
} from '@/store/slices/ui';
import { IntegrationName } from '@/store/types/integrations';
import triggerJiraIntegrationThunk from '@/store/thunks/triggerJiraIntegrationThunk';
import triggerS1IntegrationThunk from '@/store/thunks/triggerS1IntegrationThunk';

const IntegrationExistsDialog = () => {
  const dispatch = useAppDispatch();
  const { isOpen, integrationName } = useAppSelector(
    selectHasTriggeredIntegrationDialogOpen
  );

  const closeIntegrationDialog = () =>
    dispatch(
      setHasTriggeredIntegrationDialogOpen({
        isOpen: false,
        integrationName: '',
      })
    );

  const handleClose = () => closeIntegrationDialog();

  const handleConfirm = () => {
    switch (integrationName) {
      case IntegrationName.JIRA:
        dispatch(triggerJiraIntegrationThunk());
        closeIntegrationDialog();
        break;
      case IntegrationName.S1:
        dispatch(triggerS1IntegrationThunk());
        closeIntegrationDialog();
        break;
      default:
        break;
    }
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title='Integration exists'
      confirmText='Proceed'
      cancelText='Dismiss'
      onConfirm={handleConfirm}
      onClose={handleClose}
      testId='integration-exists-confirmation-modal'
    >
      <Alert
        severity='warning'
        data-testid={`${integrationName?.toLowerCase()}-integration-exists`}
      >
        <AlertTitle>Please note:</AlertTitle>
        <Typography>
          The <b>{integrationName}</b> integration already exists, do you want
          to re-create it?
        </Typography>
      </Alert>
    </ConfirmationModal>
  );
};

export default IntegrationExistsDialog;

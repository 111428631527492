export const getMockApiInitialData = (mockState: any) => {
  const state = {
    getCase: {
      projectId: mockState.case.id,
      answers: Object.keys(mockState.questions.values).map((key) => ({
        answer: Array.isArray(mockState.questions.values[key])
          ? JSON.stringify(mockState.questions.values[key])
          : mockState.questions.values[key],
        questionId: key,
        __typename: 'Answer',
      })),
      project: mockState.case.project,
      client: mockState.case.client,
      selectedCaseTypeIds: mockState.caseTypes.selected,
      selectedIndicatorIds: mockState.indicators.selected,
      selectedModules: mockState.modules.manuallySelected.map((id: string) => ({
        moduleId: id,
      })),
      dismissedIndicatorIds: [],
      description: mockState.case.description,
      __typename: 'Case',
    },
    getDescription: {
      id: 'description-id',
      text: mockState.case.description,
      hasSuggestedIndicators: !mockState.case.descriptionPreSuggestedIndicators,
      suggestedIndicators: {
        indicatorIds: mockState.indicators.suggested,
        __typename: 'SuggestedIndicators',
      },
      __typename: 'Description',
    },
    getAllQuestions: mockState.questions.ids.map((id: string) => ({
      id: id,
      control: mockState.questions.entities[id].control,
      placeholder: mockState.questions.entities[id].placeholder,
      guidance: mockState.questions.entities[id].guidance,
      label: mockState.questions.entities[id].label,
      version: mockState.questions.entities[id].version,
      triggers: mockState.questions.entities[id].triggers.map(
        (t: {
          componentType: string;
          componentId: string;
          action: string;
          value: string;
        }) => ({
          componentType: t.componentType,
          componentId: t.componentId,
          action: t.action,
          triggeringValue: t.value,
          __typename: 'QuestionTrigger',
        })
      ),
      options: mockState.questions.entities[id].options.map(
        (o: { label: string; value: string }) => ({
          label: o.label,
          value: o.value,
          __typename: 'QuestionOptions',
        })
      ),
      validation: {
        ...mockState.questions.entities[id].validation,
        required: mockState.questions.entities[id].validation.isRequired,
      },
      __typename: 'Question',
    })),
    getAllCaseTypes: mockState.caseTypes.ids.map((id: string) => ({
      id: id,
      label: mockState.caseTypes.entities[id].label,
      indicatorIds: mockState.caseTypes.entities[id].indicators,
      moduleIds: mockState.caseTypes.entities[id].modules,
      __typename: 'CaseType',
    })),
    getAllTips: mockState.tips.ids.map((id: string) => ({
      id: id,
      label: mockState.tips.entities[id].label,
      text: mockState.tips.entities[id].text,
      linkedAdviceIds: mockState.tips.entities[id].advice,
      __typename: 'Tip',
    })),
    getAllIndicators: mockState.indicators.ids.map((id: string) => ({
      id: id,
      label: mockState.indicators.entities[id].label,
      __typename: 'Indicator',
    })),
    getAllModules: mockState.modules.ids.map((id: string) => ({
      id: id,
      label: mockState.modules.entities[id].label,
      description: mockState.modules.entities[id].description,
      rootQuestionIds: mockState.modules.entities[id].questions,
      __typename: 'Module',
    })),
    getAllAdvice: mockState.advice.ids.map((id: string) => ({
      id: id,
      text: mockState.advice.entities[id].text,
      label: mockState.advice.entities[id].label,
      __typename: 'Advice',
    })),
    getAllModuleGroups: mockState.modules.groups.ids.map((id: string) => ({
      id: id,
      label: mockState.modules.groups.entities[id].label,
      moduleIds: mockState.modules.groups.entities[id].moduleIds,
      priorityIndex: mockState.modules.groups.entities[id].priorityIndex,
      __typename: 'ModuleGroup',
    })),
  };
  return state;
};
export default getMockApiInitialData;

import { EntityType } from '@/store/types/basicTypes';

const filterOptionsData = {
  [EntityType.Question]: {
    label: 'Questions',
    value: EntityType.Question,
  },
  [EntityType.Module]: {
    label: 'Modules',
    value: EntityType.Module,
  },
  [EntityType.Tip]: {
    label: 'Tips',
    value: EntityType.Tip,
  },
  [EntityType.ModuleGroup]: {
    label: 'Module groups',
    value: EntityType.ModuleGroup,
  },
  [EntityType.CaseType]: {
    label: 'Case types',
    value: EntityType.CaseType,
  },
  [EntityType.Indicator]: {
    label: 'Indicators',
    value: EntityType.Indicator,
  },
  [EntityType.Advice]: {
    label: 'Advice',
    value: EntityType.Advice,
  },
};

export const getFilterOptions = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Module:
      return [
        filterOptionsData[EntityType.CaseType],
        filterOptionsData[EntityType.ModuleGroup],
        filterOptionsData[EntityType.Module],
        filterOptionsData[EntityType.Question],
      ];
    case EntityType.Question:
      return [
        filterOptionsData[EntityType.Module],
        filterOptionsData[EntityType.Question],
        filterOptionsData[EntityType.Tip],
      ];
    case EntityType.Tip:
      return [
        filterOptionsData[EntityType.Tip],
        filterOptionsData[EntityType.Question],
        filterOptionsData[EntityType.Advice],
      ];
    case EntityType.CaseType:
      return [
        filterOptionsData[EntityType.CaseType],
        filterOptionsData[EntityType.Module],
        filterOptionsData[EntityType.Indicator],
      ];
    case EntityType.ModuleGroup:
      return [
        filterOptionsData[EntityType.Module],
        filterOptionsData[EntityType.ModuleGroup],
      ];
    case EntityType.Advice:
      return [
        filterOptionsData[EntityType.Advice],
        filterOptionsData[EntityType.Tip],
      ];
  }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';
import { RootState } from '../store';
import { IndicatorToCaseTypesMap } from '../types/indicator';
import { IndicatorId } from '../types/basicTypes';

export const indicatorSlice = createSlice({
  name: 'indicators',
  initialState: initialState.indicators,
  reducers: {
    setIndicators: (
      state,
      action: PayloadAction<TransformedInitialData['indicators']>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
    setIndicatorToCaseTypesMap: (
      state,
      action: PayloadAction<IndicatorToCaseTypesMap>
    ) => {
      state.indicatorCaseTypesMap = action.payload;
    },
    addSelectedIndicators: (state, action: PayloadAction<string[]>) => {
      state.selected = Array.from(
        new Set([...state.selected, ...action.payload])
      );
    },
    removeSelectedIndicators: (state, action: PayloadAction<string[]>) => {
      state.selected = state.selected.filter(
        (id) => !action.payload.includes(id)
      );
    },
    addDismissedIndicators: (state, action: PayloadAction<string[]>) => {
      state.dismissed = Array.from(
        new Set([...state.dismissed, ...action.payload])
      );
    },
    removeDismissedIndicators: (state, action: PayloadAction<string[]>) => {
      state.dismissed = state.dismissed.filter(
        (id) => !action.payload.includes(id)
      );
    },
    setSuggestedIndicators: (state, action: PayloadAction<string[]>) => {
      state.suggested = action.payload;
    },

    removeIndicatorFromCaseTypesMap: (
      state,
      action: PayloadAction<{ indicatorId: string; caseTypeId: string }>
    ) => {
      state.indicatorCaseTypesMap[action.payload.indicatorId] =
        state.indicatorCaseTypesMap[action.payload.indicatorId].filter(
          (id) => id !== action.payload.caseTypeId
        );
    },
  },
});

export const {
  setIndicators,
  addSelectedIndicators,
  removeSelectedIndicators,
  addDismissedIndicators,
  removeDismissedIndicators,
  setIndicatorToCaseTypesMap,
  setSuggestedIndicators,
  removeIndicatorFromCaseTypesMap,
} = indicatorSlice.actions;

export const selectIndicatorIds = (state: RootState) => state.indicators.ids;
export const selectIndicatorEntities = (state: RootState) =>
  state.indicators.entities;
export const selectIndicatorEntitiesAsArray = (state: RootState) =>
  state.indicators.ids.map((id) => state.indicators.entities[id]);
export const selectSelectedIndicators = (state: RootState) =>
  state.indicators.selected;
export const selectSuggestedIndicators = (state: RootState) =>
  state.indicators.suggested;
export const selectIndicator = (state: RootState, id: IndicatorId) =>
  state.indicators.entities[id];
export const selectDismissedIndicators = (state: RootState) =>
  state.indicators.dismissed;
export const selectAllIndicatorsAsArray = (state: RootState) =>
  state.indicators.ids.map((id) => state.caseTypes.entities[id]);
export const selectAvailableIndicators = (state: RootState) =>
  state.indicators.ids.filter(
    (id) =>
      !state.indicators.selected.includes(id) &&
      !state.indicators.dismissed.includes(id)
  );
export const selectIndicatorToCaseTypesMap = (state: RootState) =>
  state.indicators.indicatorCaseTypesMap;

export default indicatorSlice.reducer;

import { EntityType } from '@/store/types/basicTypes';
import { ControlTypes, Question } from '@/store/types/question';
import { getuuid } from '@/utils/helpers';

const createQuestionTransform = (data: any): Question => {
  const {
    createQuestion: { id },
  } = data;
  return {
    id: id,
    control: ControlTypes.Radio,
    entityType: EntityType.Question,
    placeholder: '',
    guidance: '',
    triggers: [],
    options: [
      { label: '', value: getuuid(), id: getuuid() },
      { label: '', value: getuuid(), id: getuuid() },
    ],
    validation: {
      pattern: '',
      isRequired: false,
      maxLength: 150,
      minLength: 1,
      maxValue: 0,
      minValue: 0,
      longText: false,
    },
    label: '',
    version: 1,
    isSessionOnly: true,
    integrationTags: [],
  };
};

export default createQuestionTransform;

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';

import incidentsReducers from './incidents';
import contributorsReducers from './contributors';
import questionnairesReducers from './questionnaires';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: initialState.admin,
  reducers: {
    ...questionnairesReducers,
    ...incidentsReducers,
    ...contributorsReducers,
  },
});

export const {
  setQuestionnaires,
  setIncidents,
  setIncidentsSearchTerm,
  setIsLoadingIncidents,
  setIncidentsFilters,
  updateIncidentsFilters,
  resetIncidentsFilters,
  setContributors,
  updateContributorFilters,
  resetContributorsFilters,
  setIsLoadingContributors,
  updateIncident,
} = adminSlice.actions;

// Questionnaire selectors
export const selectQuestionnaireEntities = (state: RootState) =>
  state.admin.questionnaires.entities;
export const selectQuestionnaireEntitiesAsArray = (state: RootState) =>
  state.admin.questionnaires.ids.map(
    (id) => state.admin.questionnaires.entities[id]
  );
export const selectQuestionnaireIds = (state: RootState) =>
  state.admin.questionnaires.ids;

// Incident selectors
export const selectIncidentEntityById = (state: RootState, id: string) =>
  state.admin.incidents.entities[id];

export const selectIncidentsEntities = (state: RootState) =>
  state.admin.incidents.entities;

export const selectIncidentsAsArray = (state: RootState) =>
  state.admin.incidents.ids.map((id) => state.admin.incidents.entities[id]);

export const selectIncidentsFilters = (state: RootState) =>
  state.admin.incidents.filters;

export const selectIsLoadingIncidents = (state: RootState) =>
  state.admin.incidents.isLoading;

// Contributor selectors
export const selectContributorEntities = (state: RootState) =>
  state.admin.contributors.entities;
export const selectContributorsEntitiesAsArray = (state: RootState) =>
  state.admin.contributors.ids.map(
    (id) => state.admin.contributors.entities[id]
  );
export const selectContributorIds = (state: RootState) =>
  state.admin.contributors.ids;
export const selectContributorsFilters = (state: RootState) =>
  state.admin.contributors.filters;
export const selectIsLoadingContributors = (state: RootState) =>
  state.admin.contributors.isLoading;

export default adminSlice.reducer;

import { styled } from '@mui/material';

export const EditorWrapper = styled('div')`
  height: 100%;
  .quill {
    height: 100%;
  }
  .ql-toolbar {
    background-color: white;
    border-radius: 5px 5px 0 0;
  }
  .ql-container {
    font-size: 16px;
    background-color: white;
    border-radius: 0 0 5px 5px;
    height: 100%;
  }
`;

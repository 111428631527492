import { FilterOptions } from '@/store/types/editor';
import { setEntitySearchFilter } from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import fuzzySearch from '@/store/utils/fuzzySearch';
import { getAdviceIdsRelatedToTips } from './utils/helpers';

const filterAdviceThunk =
  (filterOptions: FilterOptions): AppThunk =>
  (dispatch, getState) => {
    dispatch(setEntitySearchFilter(filterOptions));
    const tips = getState().tips.entities;
    const advice = getState().advice.entities;

    const { searchTerm, filterBy } = getState().editor.entitySearchFilter;

    if (!searchTerm) {
      dispatch(
        setEntitySearchFilter({
          entityIds: convertEntitiesToArray(advice).map((a) => a.id),
        })
      );
      return;
    }
    if (filterBy === EntityType.Advice) {
      dispatch(
        setEntitySearchFilter({
          entityIds: fuzzySearch({
            keys: ['text'],
            entities: convertEntitiesToArray(advice),
            searchTerm,
          }),
        })
      );
      return;
    }
    if (filterBy === EntityType.Tip) {
      const searchResults = fuzzySearch({
        keys: ['text'],
        entities: convertEntitiesToArray(tips),
        searchTerm,
      });
      dispatch(
        setEntitySearchFilter({
          entityIds: getAdviceIdsRelatedToTips({
            tipsId: searchResults,
            tips,
          }),
        })
      );
    }
  };

export default filterAdviceThunk;

import { sanitizeHtml } from '@/utils/helpers';
import { Box, BoxProps } from '@mui/material';
import { makeHtmlFromMarkdown } from '@s-rm/react-ui-lib';

export const HTMLRender = ({
  content,
  ...rest
}: {
  content: string;
  rest?: BoxProps;
}) => {
  return (
    <Box
      sx={{
        // Because our HTML editor automatically wraps everything in a paragraph, remove it.
        '& p:first-of-type': {
          marginTop: 0,
          marginBottom: 0,
        },
        ...(rest as BoxProps)?.sx,
      }}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(makeHtmlFromMarkdown(content)),
      }}
      {...rest}
    />
  );
};

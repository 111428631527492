import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';
import { RootState } from '../store';
import { ModuleGroupId, ModuleId } from '../types/basicTypes';
import { Module, ModuleGroup, ModuleSelectedByTrigger } from '../types/module';
import getOrderedSelectedModules from '../utils/getOrderedSelectedModules';
import getOrderedModuleGroupIds from '../utils/getOrderedModuleGroupIds';

export const moduleSlice = createSlice({
  name: 'modules',
  initialState: initialState.modules,
  reducers: {
    setModules: (
      state,
      action: PayloadAction<TransformedInitialData['modules']>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
    deleteModule: (state, action: PayloadAction<ModuleId>) => {
      state.groups.ids.forEach((groupId) => {
        state.groups.entities[groupId].moduleIds = state.groups.entities[
          groupId
        ].moduleIds.filter((id) => id !== action.payload);
      });
      state.selected = state.selected.filter((id) => id !== action.payload);
      state.manuallySelected = state.manuallySelected.filter(
        (id) => id !== action.payload
      );
      state.selectedByTrigger = state.selectedByTrigger.filter(
        (m) => m.moduleId !== action.payload
      );
      delete state.entities[action.payload];
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    deleteModuleGroup: (state, action: PayloadAction<ModuleGroupId>) => {
      delete state.groups.entities[action.payload];
      state.groups.ids = state.groups.ids.filter((id) => id !== action.payload);
    },
    setModuleGroups: (
      state,
      action: PayloadAction<TransformedInitialData['moduleGroups']>
    ) => {
      state.groups = {
        entities: action.payload.entities,
        ids: getOrderedModuleGroupIds(action.payload),
      };
    },
    addSelectedModules: (
      state,
      action: PayloadAction<{
        moduleIds: ModuleId[];
        triggeredFromModuleId?: ModuleId;
      }>
    ) => {
      const orderedModules = getOrderedSelectedModules({
        selectedIds: Array.from(
          new Set([...state.selected, ...action.payload.moduleIds])
        ),
        entities: state.entities,
      });

      state.selected = orderedModules;
    },
    removeSelectedModules: (
      state,
      action: PayloadAction<{ moduleIds: ModuleId[]; isTriggered?: boolean }>
    ) => {
      const { moduleIds, isTriggered } = action.payload;
      const modulesToKeep = state.manuallySelected;
      state.selected = [
        ...state.selected.filter((id) => !moduleIds?.includes(id)),
        ...modulesToKeep,
      ];
    },
    addTriggeredModules: (
      state,
      action: PayloadAction<ModuleSelectedByTrigger[]>
    ) => {
      state.selectedByTrigger = Array.from(
        new Set([...state.selectedByTrigger, ...action.payload])
      );
    },
    removeTriggeredModules: (
      state,
      action: PayloadAction<ModuleSelectedByTrigger[]>
    ) => {
      state.selectedByTrigger = state.selectedByTrigger.filter(
        ({ moduleId, relatedCaseTypeId }) =>
          !action.payload.find(
            (m) =>
              m.moduleId === moduleId &&
              m.relatedCaseTypeId === relatedCaseTypeId
          )
      );
    },
    addManuallySelectedModules: (state, action: PayloadAction<ModuleId[]>) => {
      state.manuallySelected = Array.from(
        new Set([...state.manuallySelected, ...action.payload])
      );
    },
    removeManuallySelectedModules: (state, action: PayloadAction<string[]>) => {
      state.manuallySelected = state.manuallySelected.filter(
        (id) => !action.payload.includes(id)
      );
    },
    updateModule: (
      state,
      action: PayloadAction<{ id: ModuleId; updates: Partial<Module> }>
    ) => {
      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        ...action.payload.updates,
      };
    },
    updateModuleGroup: (
      state,
      action: PayloadAction<{
        id: ModuleGroupId;
        updates: Partial<ModuleGroup>;
      }>
    ) => {
      state.groups.entities[action.payload.id] = {
        ...state.groups.entities[action.payload.id],
        ...action.payload.updates,
      };
    },
    addModule: (state, action: PayloadAction<Module>) => {
      state.entities[action.payload.id] = action.payload;
      state.ids.push(action.payload.id);
    },
    addModuleGroup: (state, action: PayloadAction<ModuleGroup>) => {
      state.groups.entities[action.payload.id] = action.payload;
      state.groups.ids.push(action.payload.id);
    },
    setStaticModuleIds: (state, action: PayloadAction<ModuleId[]>) => {
      state.staticModuleIds = action.payload;
    },
  },
});

export const {
  addModule,
  addModuleGroup,
  setModules,
  setModuleGroups,
  addSelectedModules,
  removeSelectedModules,
  addManuallySelectedModules,
  removeManuallySelectedModules,
  removeTriggeredModules,
  addTriggeredModules,
  updateModule,
  updateModuleGroup,
  deleteModule,
  deleteModuleGroup,
  setStaticModuleIds,
} = moduleSlice.actions;

export const selectActiveModuleIds = (state: RootState) =>
  state.modules.selected;
export const selectAllModuleIds = (state: RootState) => state.modules.ids;
export const selectModuleEntities = (state: RootState) =>
  state.modules.entities;
export const selectModuleGroupEntities = (state: RootState) =>
  state.modules.groups.entities;
export const selectModule = (state: RootState, id: string) =>
  state.modules.entities[id];
export const selectModuleGroup = (state: RootState, id: string) =>
  state.modules.groups.entities[id];
export const selectModuleGroups = (state: RootState) => state.modules.groups;
export const selectModuleGroupsAsArray = (state: RootState) =>
  state.modules.groups.ids.map((id) => state.modules.groups.entities[id]);
export const selectSelectedModules = (state: RootState) =>
  state.modules.selected;
export const selectManuallySelectedModules = (state: RootState) =>
  state.modules.manuallySelected;
export const selectTriggeredModuleIds = (state: RootState) =>
  state.modules.selectedByTrigger;
export const selectAllModulesAsArray = (state: RootState) =>
  state.modules.ids.map((id) => state.modules.entities[id]);
export const selectModuleRoles = (state: RootState, id: string) =>
  state.modules.entities[id]?.roles;

export const selectStaticModuleIds = (state: RootState) =>
  state.modules.staticModuleIds;

export default moduleSlice.reducer;

import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetTip } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { Tip } from '@/store/types/tip';

export const getTip = async ({
  tipId,
}: {
  tipId: string;
}): Promise<{ data: Tip }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetTip,
    variables: {
      id: tipId,
    },
  });
  // @ts-ignore
  return { data: getFormattedEntity(data.getTip) as Tip, errors };
};

export default getTip;

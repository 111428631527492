export const openingAdviceEmail = (name?: string): string => `
Dear all,

Thank you for speaking to us earlier.

This message sets out our initial advice based on the information you have provided. Please review the points below, and carry out these steps as soon as you can.

If you have questions or require support with any of these points, please do not hesitate to get in touch and we will be happy to help.

Kind regards,
${!name ? 'S-RM' : name}

`;

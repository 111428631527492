import { QuestionId } from '@/store/types/basicTypes';
import { updateQuestion } from '@/store/slices/questions';
import { AppThunk } from '@/store/store';
import { Question } from '@/store/types/question';
import editQuestion from '@/api/mutations/editor/editQuestion';
import { debounce } from 'lodash';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';
import { getNonAliasTriggers } from './utils/helpers';

const debouncedEditQuestion = debounce((id) => {
  editQuestion(id);
}, 1000);

const updateQuestionThunk =
  ({
    id,
    updates,
    shouldDebounce,
    lastUpdatedKey,
  }: {
    id: QuestionId;
    updates: Partial<Question>;
    shouldDebounce?: boolean;
    lastUpdatedKey?: any;
  }): AppThunk =>
  (dispatch, getState) => {
    dispatch(setIsDirty(true));
    const question = getState().questions.entities[id];
    const tipAliasMap = getState().tips.aliasMap;

    // If using the editor, we need to convert any alias trigger ids to their
    // original counterpart ids. Aliases are only generated and stored locally.
    const nonAliasTriggers = getNonAliasTriggers({
      triggers: updates.triggers || question.triggers,
      tipAliasMap,
    });

    const formattedUpdates = {
      ...updates,
      triggers: nonAliasTriggers,
    };

    dispatch(updateQuestion({ id, updates: formattedUpdates }));
    dispatch(
      updateEntityModifications({
        entityType: 'questions',
        entityId: id,
        fields: Object.keys({
          ...formattedUpdates,
          ...(lastUpdatedKey ? { [lastUpdatedKey]: lastUpdatedKey } : {}),
        }),
      })
    );

    if (shouldDebounce) {
      debouncedEditQuestion(id);
    } else {
      editQuestion(id);
    }
  };

export default updateQuestionThunk;

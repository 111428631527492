import { Module } from '@/store/types/module';
import { Question } from '@/store/types/question';

const getQuestionsWithNoModule = ({
  questions,
  modules,
}: {
  questions: Question[];
  modules: Module[];
}) =>
  questions
    .filter((question) => {
      return !modules.some((m) => m.questions.includes(question.id));
    })
    .map((q) => q.id);

export default getQuestionsWithNoModule;

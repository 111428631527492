import {
  Box,
  Container,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectOutdatedEntities,
  selectQuestionInEdit,
  selectSingleEntityModifications,
} from '@/store/slices/editor';
import questionTypeOptions from '../../data';
import TextEditor from '@/components/TextEditor';
import { selectQuestion } from '@/store/slices/questions';
import updateQuestionThunk from '@/store/thunks/editor/updateQuestion';
import { ControlTypes } from '@/store/types/question';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import { Label, LabelBadge, LabelContainer } from '../../styled';
import { getQuestionHasOptions, getuuid } from '@/utils/helpers';
import MultiChoiceOptions from './MultiChoiceOptions';
import { QuestionId } from '@/store/types/basicTypes';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NumbersIcon from '@mui/icons-material/Numbers';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Checkbox, TextField } from '@s-rm/react-ui-lib';
import { selectIntegrationEntitiesAsArray } from '@/store/slices/integrations';
import { MenuProps } from './MenuProps';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id, isNew } = useAppSelector(selectQuestionInEdit);
  const integrationEntities = useAppSelector(selectIntegrationEntitiesAsArray);
  const questionInEdit = id as QuestionId;
  const question = useAppSelector((state) =>
    selectQuestion(state, questionInEdit)
  );

  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'questions', id!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).questions?.[id!] || [];

  const handleUpdateLabel = (val: string) => {
    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: { label: val },
      })
    );
  };
  const handleUpdateGuidance = (val: string) => {
    /* istanbul ignore next */
    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: { guidance: val },
        shouldDebounce: true,
      })
    );
  };
  const handleUpdateQuestionType = (e: SelectChangeEvent<ControlTypes>) => {
    const newType = e.target.value as ControlTypes;
    const isMultipleChoice = getQuestionHasOptions(newType);

    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: {
          control: newType,
          options: isMultipleChoice
            ? [
                { label: '', value: getuuid(), id: getuuid() },
                { label: '', value: getuuid(), id: getuuid() },
              ]
            : [],
          triggers: [],
        },
      })
    );
  };

  const handleUpdateValidation = (isChecked: boolean) => {
    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: {
          validation: {
            ...question.validation,
            isRequired: isChecked,
          },
        },
        lastUpdatedKey: 'isRequired',
      })
    );
  };

  const handleUpdateIsLongText = (isChecked: boolean) => {
    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: {
          validation: {
            ...question.validation,
            longText: isChecked,
          },
        },
        lastUpdatedKey: 'longText',
      })
    );
  };

  const handleUpdateIntegrations = (
    event: SelectChangeEvent<string[] | string>
  ) => {
    const {
      target: { value },
    } = event;
    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: { integrationTags: value as string[] },
      })
    );
  };

  const renderConfig = () => {
    switch (question.control) {
      case ControlTypes.Radio:
        return <MultiChoiceOptions />;
      case ControlTypes.MultiSelect:
        return <MultiChoiceOptions />;
      case ControlTypes.MultiSelectSearch:
        return <MultiChoiceOptions />;
      case ControlTypes.Textarea:
        return null;
      case ControlTypes.Datepicker:
        return null;
      case ControlTypes.Daterange:
        return null;
      case ControlTypes.Numeric:
        return null;
      case ControlTypes.Currency:
        return null;
      case ControlTypes.Timepicker:
        return null;
    }
  };

  return (
    <>
      <Container>
        <Box mt={2} mb={3} display='flex' alignItems='center' gap={1}>
          <Label for='required'>Required</Label>
          {modifications.includes('isRequired') && !isNew && (
            <Tooltip title='This field contains changes'>
              <LabelBadge variant='modified'>Modified</LabelBadge>
            </Tooltip>
          )}
          {outDatedFields?.includes('isRequired') && !isNew && (
            <Tooltip title='This field is out of date with the latest published draft'>
              <LabelBadge variant='outdated'>Outdated</LabelBadge>
            </Tooltip>
          )}

          <Checkbox
            onChange={(e) => handleUpdateValidation(e.target.checked)}
            id='required'
            checked={question.validation.isRequired}
            role='checkbox'
            data-testid='update-required-checkbox'
          />

          {question.control === ControlTypes.Textarea && (
            <>
              <Label for='long-text-field'>Long text</Label>
              {modifications?.includes('longText') && !isNew && (
                <Tooltip title='This field contains changes'>
                  <LabelBadge variant='modified'>Modified</LabelBadge>
                </Tooltip>
              )}
              {outDatedFields?.includes('longText') && !isNew && (
                <Tooltip title='This field is out of date with the latest published draft'>
                  <LabelBadge variant='outdated'>Outdated</LabelBadge>
                </Tooltip>
              )}
              <Checkbox
                onChange={(e) => handleUpdateIsLongText(e.target.checked)}
                id='long-text-field'
                role='checkbox'
                data-testid='update-long-text-checkbox'
                checked={question.validation.longText}
              />
            </>
          )}
        </Box>
        <Box mt={2} mb={3}>
          <LabelContainer>
            <Label>Question Text</Label>
            {modifications.includes('label') && !isNew && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('label') && !isNew && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextField
            size='small'
            placeholder={!question.label ? 'Enter question content' : ''}
            value={question.label}
            onChange={(e) => handleUpdateLabel(e.target.value)}
            data-testid='question-label'
          />
        </Box>

        <Box mt={2} mb={3} height={100}>
          <LabelContainer>
            <Label>Question Guidance</Label>
            {modifications.includes('guidance') && !isNew && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('guidance') && !isNew && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>

          <TextEditor
            onChange={handleUpdateGuidance}
            value={question.guidance}
          />
        </Box>
        <Box mt={12} maxWidth={400}>
          <LabelContainer>
            <Label>Integrations</Label>
            {modifications.includes('integrationTags') && !isNew && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('integrationTags') && !isNew && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <Select
            fullWidth
            size='small'
            multiple
            value={
              integrationEntities
                ?.filter(({ name }) => question?.integrationTags.includes(name))
                .map(({ name }) => name) || []
            }
            onChange={handleUpdateIntegrations}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            data-testid={'integrations-multiple-checkbox'}
          >
            {integrationEntities?.map(({ name }) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={question?.integrationTags.indexOf(name) > -1}
                  data-testid={`menu-item-integrations-${name.toLowerCase()}`}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <LabelContainer sx={{ mt: 3 }}>
            <Label>Question Type</Label>
            {modifications.includes('control') && !isNew && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('control') && !isNew && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <Select
            value={question.control}
            fullWidth
            size='small'
            onChange={handleUpdateQuestionType}
            data-testid={'question-type-select'}
            renderValue={(selected) => (
              <Box display='flex' gap={1.5} alignItems='center'>
                {selected === ControlTypes.Radio && (
                  <RadioButtonCheckedOutlinedIcon />
                )}
                {selected === ControlTypes.Textarea && <SubjectOutlinedIcon />}
                {selected === ControlTypes.MultiSelect && (
                  <CheckBoxOutlinedIcon />
                )}
                {selected === ControlTypes.MultiSelectSearch && (
                  <ManageSearchIcon />
                )}
                {selected === ControlTypes.Numeric && <NumbersIcon />}
                {selected === ControlTypes.Datepicker && <CalendarMonthIcon />}
                {selected === ControlTypes.Daterange && <DateRangeIcon />}
                {selected === ControlTypes.Currency && <CurrencyPoundIcon />}
                {selected === ControlTypes.Timepicker && <AccessTimeIcon />}

                {questionTypeOptions.find((o) => o.value === selected)?.label}
              </Box>
            )}
          >
            {questionTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemIcon
                  data-testid={`question-type-${option.value.toLowerCase()}`}
                >
                  {option.value === ControlTypes.Radio && (
                    <RadioButtonCheckedOutlinedIcon />
                  )}
                  {option.value === ControlTypes.Textarea && (
                    <SubjectOutlinedIcon />
                  )}
                  {option.value === ControlTypes.Numeric && <NumbersIcon />}
                  {option.value === ControlTypes.MultiSelect && (
                    <CheckBoxOutlinedIcon />
                  )}
                  {option.value === ControlTypes.MultiSelectSearch && (
                    <ManageSearchIcon />
                  )}
                  {option.value === ControlTypes.Datepicker && (
                    <CalendarMonthIcon />
                  )}
                  {option.value === ControlTypes.Daterange && <DateRangeIcon />}
                  {option.value === ControlTypes.Currency && (
                    <CurrencyPoundIcon />
                  )}
                  {option.value === ControlTypes.Timepicker && (
                    <AccessTimeIcon />
                  )}
                </ListItemIcon>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box mt={3} mb={3} maxWidth={400}>
          <Divider />
        </Box>
        {getQuestionHasOptions(question.control) && (
          <Box display='flex' gap={1}>
            {modifications?.includes('options') && !isNew && (
              <LabelContainer>
                <Tooltip title='This field contains changes'>
                  <LabelBadge variant='modified'>Modified</LabelBadge>
                </Tooltip>
              </LabelContainer>
            )}
            {outDatedFields?.includes('options') && !isNew && (
              <LabelContainer>
                <Tooltip title='This field is out of date with the latest published draft'>
                  <LabelBadge variant='outdated'>Outdated</LabelBadge>
                </Tooltip>
              </LabelContainer>
            )}
          </Box>
        )}
      </Container>

      <>{renderConfig()}</>
    </>
  );
};

export default ModalContent;

import client from '@/api/client';
//@ts-ignore
import { CreateModuleGroup } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import createModuleGroupTransform from '@/api/transforms/editor/createModuleGroupTransform';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const createModuleGroup = async () => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: CreateModuleGroup,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        label: '',
        moduleIds: [],
        priorityIndex: 1,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateModuleGroup,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: createModuleGroupTransform(data) };
};

export default createModuleGroup;

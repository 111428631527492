import { IndicatorId } from '@/store/types/basicTypes';
import { CaseType } from '@/store/types/caseType';
import { IndicatorToCaseTypesMap } from '@/store/types/indicator';

export const getIndicatorToCaseTypesMap = (caseTypes: CaseType[]) => {
  const map: IndicatorToCaseTypesMap = {};

  caseTypes.forEach((caseType) => {
    caseType.indicators.forEach((indicatorId: IndicatorId) => {
      map[indicatorId] = Array.from(
        new Set([...(map[indicatorId] || []), caseType.id])
      );
    });
  });

  return map;
};

import { AppThunk } from '../store';
import { IntegrationName, IntegrationNames } from '../types/integrations';
import getCase from '@/api/queries/getCase';
import { setTriggeredIntegrations } from '../slices/questionnaire';
import { setHasTriggeredIntegrationDialogOpen } from '../slices/ui';
import triggerS1IntegrationThunk from './triggerS1IntegrationThunk';
import triggerJiraIntegrationThunk from './triggerJiraIntegrationThunk';

const triggerIntegrationThunk =
  ({ integrationName }: { integrationName: IntegrationNames }): AppThunk =>
  async (dispatch, getState) => {
    const projectId = getState().questionnaire?.id;
    const {
      data: { triggeredIntegrations },
    } = await getCase({ projectId });
    dispatch(setTriggeredIntegrations(triggeredIntegrations ?? []));
    const integrationExists =
      getState().questionnaire.triggeredIntegrations.includes(integrationName);

    if (integrationExists) {
      dispatch(
        setHasTriggeredIntegrationDialogOpen({ isOpen: true, integrationName })
      );
      return;
    }

    switch (integrationName) {
      case IntegrationName.JIRA:
        dispatch(triggerJiraIntegrationThunk());
        break;
      case IntegrationName.S1:
        dispatch(triggerS1IntegrationThunk());
        break;
      default:
        break;
    }
  };

export default triggerIntegrationThunk;

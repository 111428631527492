import { useAppSelector } from '@/hooks';
import {
  ModuleContainer,
  ModuleSummary,
  AdviceText,
  ModuleSummaryHeader,
  AdviceBreak,
  ModuleHeaderPreview,
} from './styled';
import { selectModule } from '@/store/slices/modules';

import { Collapse } from '@mui/material';
import { useState } from 'react';
import {
  selectQuestionDisplayOrder,
  selectQuestionsVisibility,
} from '@/store/slices/questions';
import { selectAllAdviceEntities } from '@/store/slices/advice';
import { selectAllTipEntities, selectTipAliasMap } from '@/store/slices/tips';
import { Advice } from '@/store/types/advice';
import removeOrderFromModuleLabel from '../utils/removeOrderFromModuleLabel';
import { HTMLRender } from '../HTMLContent/HTMLRender';
import getUniqueTips from './utils/getUniqueTips';
interface SummaryModuleProps {
  moduleId: string;
  isExpanded?: boolean;
  getAsPrintView?: boolean;
}

const SummaryAdvice = ({
  moduleId,
  isExpanded,
  getAsPrintView,
}: SummaryModuleProps) => {
  const moduleEntity = useAppSelector((state) => selectModule(state, moduleId));
  const questionDisplayOrder = useAppSelector(selectQuestionDisplayOrder);
  const renderStructure = questionDisplayOrder[moduleId];
  const questionVisibility = useAppSelector(selectQuestionsVisibility);
  const tipAliasMap = useAppSelector(selectTipAliasMap);
  const allAdvice = useAppSelector(selectAllAdviceEntities);
  const allTips = useAppSelector(selectAllTipEntities);
  const visibleTipsInModule = getUniqueTips({
    renderStructure,
    questionVisibility,
    tipAliasMap,
  });

  const advice = visibleTipsInModule.reduce((acc: Advice[], tip) => {
    // There may be a case where the tip being referenced does not exist if
    // it has been deleted on the backend or referenced wrongly - so we default to an empty array.
    const adviceIds = allTips[tip.id]?.advice || [];

    const adviceEntities = adviceIds.map((id) => allAdvice[id]);
    return [...acc, ...adviceEntities];
  }, []);

  // This is to prevent the accordion from animating on first render
  const { id, label } = moduleEntity;
  const [hasInitialsedExpand, setHasInitialisedExpand] = useState(false);

  if (!advice.length) {
    return null;
  }

  if (getAsPrintView) {
    return (
      <>
        <ModuleHeaderPreview>
          {removeOrderFromModuleLabel(label)}
        </ModuleHeaderPreview>
        <ul>
          {advice.map((entity, index) => (
            <li key={entity.id}>
              <HTMLRender content={entity.text} />
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <ModuleContainer key={id} data-testid='summary-advice-container'>
      <ModuleSummary>
        <ModuleSummaryHeader>
          {removeOrderFromModuleLabel(label)}
        </ModuleSummaryHeader>
        <Collapse
          in={isExpanded}
          timeout={hasInitialsedExpand ? 'auto' : 0}
          addEndListener={() => setHasInitialisedExpand(true)}
        >
          {advice.map((entity, index) => (
            <>
              <AdviceText
                data-testid={`advice-${entity.id}`}
                key={entity.id}
                content={entity.text}
              />
              {index !== advice.length - 1 && <AdviceBreak />}
            </>
          ))}
        </Collapse>
      </ModuleSummary>
    </ModuleContainer>
  );
};

export default SummaryAdvice;

import {
  selectCaseType,
  selectCaseTypeEntities,
  selectSelectedCaseTypeIds,
} from '@/store/slices/caseTypes';
import { useAppDispatch, useAppSelector } from './storeHooks';
import getCaseClosureCaseType from '@/store/utils/getCaseClosureCaseType';
import { useRouter } from 'next/router';
import { getIsCaseClosureRoute } from '@/utils/router';
import { useEffect } from 'react';
import loadCaseClosureThunk from '@/store/thunks/loadCaseClosure';
import removeCaseClosureThunk from '@/store/thunks/removeCaseClosure';
import { selectCaseClosureId, setCaseClosureId } from '@/store/slices/ui';
import { selectHasInitialised } from '@/store/slices/config';

const useCaseClosure = ({ shouldHandleSelection = false } = {}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isCaseClosureRoute = getIsCaseClosureRoute(router);
  const caseTypes = useAppSelector(selectCaseTypeEntities);
  const selectedCaseTypes = useAppSelector(selectSelectedCaseTypeIds);
  const hasInitialised = useAppSelector(selectHasInitialised);
  const caseClosureId = useAppSelector(selectCaseClosureId);
  const caseClosureModules =
    useAppSelector((state) => selectCaseType(state, caseClosureId!))?.modules ||
    [];

  // We only want to handle unselecting case closure if we have navigated from
  // the case closure route as opposed to on load.
  // This is defined by if case closure id is SELECTED (have been on the route),
  // and we are not currently on the case closure route.
  const shouldUnselectCaseClosure =
    !!caseClosureId &&
    !isCaseClosureRoute &&
    selectedCaseTypes.includes(caseClosureId);

  if (hasInitialised && !caseClosureId) {
    dispatch(setCaseClosureId(getCaseClosureCaseType(caseTypes)));
  }

  useEffect(() => {
    if (!shouldHandleSelection || !caseClosureId) {
      return;
    }

    // Navigated to case closure route
    if (isCaseClosureRoute) {
      dispatch(loadCaseClosureThunk());
      return;
    }

    // Navigated away from case closure route
    if (shouldUnselectCaseClosure) {
      dispatch(removeCaseClosureThunk());
      return;
    }
  }, [
    isCaseClosureRoute,
    dispatch,
    shouldHandleSelection,
    caseClosureId,
    shouldUnselectCaseClosure,
  ]);

  return {
    isCaseClosureRoute,
    caseClosureId,
    caseClosureModules,
  };
};

export default useCaseClosure;

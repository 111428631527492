import { useAppSelector } from '@/hooks';
import {
  ModuleContainer,
  ModuleHeaderPreview,
  ModuleSummary,
  ModuleSummaryHeader,
  QuestionAnswerSection,
} from './styled';
import { selectModule } from '@/store/slices/modules';
import { Collapse } from '@mui/material';
import { useState } from 'react';
import {
  selectQuestionDisplayOrder,
  selectQuestionValues,
  selectQuestionsVisibility,
} from '@/store/slices/questions';
import SummaryQuestionAnswer from './SummaryQuestionAnswer';
import removeOrderFromModuleLabel from '../utils/removeOrderFromModuleLabel';

interface SummaryModuleProps {
  moduleId: string;
  isExpanded?: boolean;
  getAsPrintView?: boolean;
  hideUnanswered?: boolean;
}

const SummaryQuestions = ({
  moduleId,
  isExpanded,
  getAsPrintView,
  hideUnanswered,
}: SummaryModuleProps) => {
  const questionDisplayOrder = useAppSelector(selectQuestionDisplayOrder);
  const renderStructure = questionDisplayOrder[moduleId];
  const moduleEntity = useAppSelector((state) => selectModule(state, moduleId));
  const answers = useAppSelector(selectQuestionValues);
  const { id, label } = moduleEntity;

  const questionsVisibility = useAppSelector(selectQuestionsVisibility);
  const visibleQuestions = renderStructure.filter(
    ({ id, componentType }) =>
      questionsVisibility[id] && componentType === 'QUESTION' && answers[id]
  );

  // This is to prevent the accordion from animating on first render
  const [hasInitialsedExpand, setHasInitialsedExpand] = useState(false);

  if (getAsPrintView) {
    if (hideUnanswered && !visibleQuestions.length) {
      return null;
    }
    return (
      <>
        <h2>
          <u>{removeOrderFromModuleLabel(label)}</u>
        </h2>
        {visibleQuestions.length ? (
          visibleQuestions.map(({ id }) => (
            <SummaryQuestionAnswer getAsPrintView key={id} questionId={id} />
          ))
        ) : (
          <ul>
            <li>No questions answered</li>
          </ul>
        )}
      </>
    );
  }

  return (
    <ModuleContainer key={id}>
      <ModuleSummary>
        <ModuleSummaryHeader>
          {removeOrderFromModuleLabel(label)}
        </ModuleSummaryHeader>
      </ModuleSummary>
      <Collapse
        in={isExpanded}
        timeout={hasInitialsedExpand ? 'auto' : 0}
        addEndListener={() => setHasInitialsedExpand(true)}
      >
        <QuestionAnswerSection>
          {visibleQuestions.map(({ id }) => (
            <SummaryQuestionAnswer
              key={id}
              questionId={id}
              data-testid={`summary-question-${id}`}
            />
          ))}
        </QuestionAnswerSection>
      </Collapse>
    </ModuleContainer>
  );
};

export default SummaryQuestions;

import { FlattenedEntities } from '@/api/transforms/utils/getFlattenedEntity';
import { AdminSlice } from '@/store/types/admin';
import { Questionnaire } from '@/store/types/questionnaire';
import { PayloadAction } from '@reduxjs/toolkit';

const questionnairesReducers = {
  setQuestionnaires: (
    state: AdminSlice,
    action: PayloadAction<FlattenedEntities<Questionnaire>>
  ) => {
    state.questionnaires.entities = action.payload.entities;
    state.questionnaires.ids = action.payload.ids;
  },
};

export default questionnairesReducers;

import { AppThunk } from '@/store/store';
import {
  setActiveEntitySearchList,
  setIsDirty,
  setTipInEdit,
} from '@/store/slices/editor';

import createTip from '@/api/mutations/editor/createTip';
import { addTip } from '@/store/slices/tips';

const createTipThunk = (): AppThunk => async (dispatch) => {
  const { data: newTip } = await createTip();
  dispatch(setIsDirty(true));
  dispatch(addTip({ ...newTip, isSessionOnly: true }));
  dispatch(setTipInEdit({ id: newTip.id, isNew: true }));
  dispatch(setActiveEntitySearchList(null));
};

export default createTipThunk;

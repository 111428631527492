import client from '@/api/client';
//@ts-ignore
import { CreateSentinelOneSite } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';

const createSentinelOneSite = async () => {
  const { data, errors } = await client.mutate({
    mutation: CreateSentinelOneSite,
    variables: {
      projectId: store.getState().questionnaire.projectId,
    },
  });

  if (errors) {
    const message = errors[0]?.message || '';
    if (message.includes('400')) {
      createError({
        code: ErrorCodes.S1SiteExists,
        error: new Error(JSON.stringify(errors)),
        displaySnackbar: true,
      });
    } else {
      createError({
        code: ErrorCodes.TriggerIntegration,
        error: new Error(JSON.stringify(errors)),
        displaySnackbar: true,
      });
    }
  }

  return { sentinelOneSiteCreated: data?.createSentinelOneSite ?? null };
};

export default createSentinelOneSite;

import { QuestionTrigger } from '@/store/types/question';

// Looks through list of question and tip triggers, and adds the new trigger
// to the end of the last trigger of the same componentType.
const addTriggerToLastOfType = (
  list: QuestionTrigger[],
  newTrigger: QuestionTrigger
) => {
  // find the index of the last item of the same componentType
  const lastIndex = list
    .slice()
    .reverse()
    .findIndex((item) => item.componentType === newTrigger.componentType);

  // it's -1 when the item is not found, otherwise adjust for reversed indexing
  const insertAt = lastIndex === -1 ? list.length : list.length - lastIndex;

  const updatedList = [
    ...list.slice(0, insertAt),
    newTrigger,
    ...list.slice(insertAt),
  ];

  return updatedList;
};

export default addTriggerToLastOfType;

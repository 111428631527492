import client from '@/api/client';
//@ts-ignore
import { CloseEditorSession } from '@/api/gql/editor/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const closeSession = async (
  editorSessionId: string
): Promise<{
  data: any;
  errors: any;
}> => {
  // @ts-ignore
  const { data, errors } = await client.mutate({
    mutation: CloseEditorSession,
    variables: {
      editorSessionId,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CloseSession,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: { success: !errors }, errors };
};

export default closeSession;

import { Routes } from '@/constants/routes';
import { closureRoute, navPaths } from '@/data/navPaths';
import {
  getRootPathFromRouter,
  replaceRootPathFromPathname,
} from '@/utils/router';
import { useRouter } from 'next/router';
import { StepperNav } from './StepperNav';
import {
  selectActivePath,
  selectIsEditMode,
  setActivePath as setPathActiveAction,
} from '@/store/slices/ui';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { useFeatureFlags } from '../FeatureFlags/Provider';
import featureFlags from '@/constants/featureFlags';

const NavBar = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const activePath = useAppSelector(selectActivePath);
  const isEditMode = useAppSelector(selectIsEditMode);

  const { isFeatureFlagOn } = useFeatureFlags();

  const handleChangeRoute = (rootPath: Routes) => {
    router.push(
      replaceRootPathFromPathname({ pathname: router.asPath, rootPath })
    );
  };

  useEffect(() => {
    dispatch(setPathActiveAction(getRootPathFromRouter(router)));
  }, [dispatch, router]);

  return (
    <StepperNav
      isEditMode={isEditMode}
      onChange={(rootPath) => handleChangeRoute(rootPath as Routes)}
      paths={[
        ...navPaths,
        ...(isFeatureFlagOn(featureFlags.shouldRemoveClosureLink)
          ? []
          : [closureRoute]),
      ]}
      activePath={activePath}
    />
  );
};
export default NavBar;

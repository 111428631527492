import { useAppSelector } from '@/hooks';
import { selectActiveParentTreeView } from '@/store/slices/editor';
import { selectAllModulesAsArray } from '@/store/slices/modules';
import { selectQuestionEntitiesAsArray } from '@/store/slices/questions';
import { selectAllTipsAsArray } from '@/store/slices/tips';
import { Module } from '@/store/types/module';
import { ComponentReferenceObject } from '@/store/types/question';
import getNestedEntityStructure from '@/store/utils/getNestedEntityStructure';
import {
  getEntityText,
  getEntityTypeAsText,
  removeAllTags,
} from '@/utils/helpers';
import { Box, Typography } from '@mui/material';
import getDisplayStructure from '../utils/getDisplayStructure';
import getModulesContainingChild from '../utils/getModulesContainingChild';
import EmptyItemsPlaceholder from '@/components/EmptyItemsPlaceholder';
import { EntityType } from '@/store/types/basicTypes';
import getQuestionsWithNoModule from '../utils/getQuestionsWithNoModule';
import { selectAllAdviceAsArray } from '@/store/slices/advice';

const AdviceTreeViewContent = () => {
  const { entityId } = useAppSelector(selectActiveParentTreeView);
  const modules = useAppSelector(selectAllModulesAsArray);
  const tips = useAppSelector(selectAllTipsAsArray);
  const questions = useAppSelector(selectQuestionEntitiesAsArray);

  const advice = useAppSelector(selectAllAdviceAsArray);

  const linkedTipIds = tips
    .filter((tip) => tip.advice.includes(entityId as string))
    .map(({ id }) => id);

  const modulesWithPlaceholder = [
    ...modules,
    {
      id: 'placeholder-module',
      label: '-',
      description: '',
      questions: getQuestionsWithNoModule({ questions, modules }),
      entityType: EntityType.Module,
    } as Module,
  ];

  const structure = getNestedEntityStructure({
    modules: modulesWithPlaceholder,
    questions,
    tips,
  });

  const displayStructures = linkedTipIds.map((tipId) =>
    getDisplayStructure({
      moduleIds: getModulesContainingChild(structure, tipId as string),
      structure,
      childId: tipId as string,
    })
  );

  const displayStructuresWithAdvice = displayStructures.map(
    (displayStructure) => {
      return displayStructure.map((moduleRef) => ({
        ...moduleRef,
        children: [
          ...moduleRef.children,
          { id: entityId!, componentType: EntityType.Advice, parentId: '' },
        ],
      }));
    }
  );

  if (!displayStructures.length) {
    return (
      <Box height='200px'>
        <EmptyItemsPlaceholder text='No parent items found' />
      </Box>
    );
  }

  return (
    <>
      {displayStructuresWithAdvice.map((displayStructure, i) => {
        return (
          <div key={i}>
            {displayStructure.map(
              (moduleRef: {
                id: string;
                children: ComponentReferenceObject[];
              }) => {
                const moduleEntity = modulesWithPlaceholder.find(
                  (module) => module.id === moduleRef.id
                ) as Module;
                return (
                  <div key={moduleEntity.id}>
                    <Typography
                      mb={0.5}
                      color='grey.600'
                      fontSize='.8rem'
                      variant='h5'
                    >
                      Module
                    </Typography>
                    <Typography mb={2}>{moduleEntity.label}</Typography>
                    <Box ml={2}>
                      {moduleRef.children.map((child, index) => {
                        const childEntity = [
                          ...questions,
                          ...tips,
                          ...advice,
                        ].find((item) => item.id === child.id);
                        const isLast = index === moduleRef.children.length - 1;
                        console.log({ child });
                        return (
                          <div
                            style={{ marginLeft: `${index * 20}px` }}
                            key={child.id}
                          >
                            <Typography
                              mb={0.5}
                              color='grey.600'
                              fontSize='.8rem'
                            >
                              {getEntityTypeAsText(child.componentType)}
                            </Typography>
                            <Typography
                              sx={{
                                color: isLast ? 'primary.main' : 'inherit',
                                fontWeight: isLast ? 700 : 400,
                              }}
                              mb={2}
                            >
                              {removeAllTags(getEntityText(childEntity))}
                            </Typography>
                          </div>
                        );
                      })}
                    </Box>
                  </div>
                );
              }
            )}
          </div>
        );
      })}
    </>
  );
};

export default AdviceTreeViewContent;

import { Badge, Dialog, InputLabel, styled } from '@mui/material';

export const DialogueTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledDialogue = styled(Dialog)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

export const Label = styled(InputLabel)<{ for?: string }>`
  font-weight: 600;
  font-size: 0.9rem;
  // margin-bottom: ${({ theme }) => theme.spacing(1)};
  .MuiInputLabel-asterisk {
    color: ${({ theme }) => theme.palette.error.main};
  }
  line-height: 1.2;
  cursor: pointer;
`;

export const LabelContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const LabelBadge = styled('div')<{ variant?: 'modified' | 'outdated' }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme, variant }) =>
    variant === 'modified' ? theme.palette.secondary.main : '#bf66a9'};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  border-radius: 15px;
  padding: ${({ theme }) => theme.spacing(0.3, 0.5)};
  line-height: 1;
  font-size: 0.7rem;
`;

// export const SecondaryBadge = styled(Badge)`
//   .MuiBadge-badge {
//     margin-right: -13px;
//     top: -17.5px;
//     background-color: #bf66a9;
//     color: ${({ theme }) => theme.palette.secondary.contrastText};
//   }
// `;

export const SecondaryBadge = styled(Badge)`
  .MuiBadge-badge {
    margin-right: -45px;
    background-color: #bf66a9;
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
`;

export const ModifiedBadge = styled(Badge)`
  .MuiBadge-badge {
    margin-right: -20px;
    top: -17.5px;
  }
`;

import { EntityType } from '@/store/types/basicTypes';
import { Tip } from '@/store/types/tip';

const createTipTransform = (data: any): Tip => {
  const {
    createTip: { id },
  } = data;
  return {
    entityType: EntityType.Tip,
    id: id,
    label: '',
    text: '',
    advice: [],
    isSessionOnly: true,
  };
};

export default createTipTransform;

import { ReactNode } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

export const SortableItem = ({
  children,
  index,
}: {
  children: ReactNode;
  index: number;
}) => {
  const Item = SortableElement(() => (
    <div style={{ position: 'relative', zIndex: 9999 }}>{children}</div>
  ));

  return <Item index={index} />;
};

export const SortableList = ({
  children,
  useDragHandle,
  onSortEnd,
}: {
  useDragHandle?: boolean;
  onSortEnd: (props: { oldIndex: number; newIndex: number }) => void;
  children: ReactNode;
}) => {
  const List = SortableContainer(() => <div>{children}</div>);
  return <List onSortEnd={onSortEnd} useDragHandle={useDragHandle} />;
};

export const DragHandle = ({ children }: { children: ReactNode }) => {
  const Handle = SortableHandle(() => children);

  return <Handle />;
};

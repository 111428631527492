import { getIsUsingApiKey, getRefreshToken } from '@/utils/auth';
import { apiUrl } from '@/constants/env';
// Can be used to get a token by using either a code, or a refresh token.
const fetchNewToken = async (code?: string) => {
  const url = `${apiUrl}/auth/${code ? 'token' : 'refresh'}`;
  const args = {
    method: 'POST',
    body: JSON.stringify(
      code ? { code } : { refresh_token: getRefreshToken() }
    ),
  };

  const response = await fetch(url, args);
  const res = await response.json();

  const token = res.access_token;

  if (!token.length) {
    throw new Error();
  }

  return {
    jwt: res.access_token as string,
    refreshToken: res.refresh_token as string,
    exp: res.expires_in as number,
  };
};

export default fetchNewToken;

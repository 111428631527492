import { getIsMultipleChoiceValue } from '@/utils/helpers';
import {
  MultipleChoiceValue,
  QuestionAnswerValue,
  QuestionTrigger,
} from '../types/question';

const validateTriggers = ({
  triggers,
  value,
}: {
  triggers: QuestionTrigger[];
  value: QuestionAnswerValue;
}): string[] => {
  if (getIsMultipleChoiceValue(value)) {
    return triggers
      .filter((trigger) =>
        (value as MultipleChoiceValue).some(
          (selection) => selection === trigger.value
        )
      )
      .map((t) => t.componentId);
  }
  return triggers
    .filter((trigger) => trigger.value === value)
    .map((t) => t.componentId);
};

export default validateTriggers;

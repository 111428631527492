import { selectRefreshDialogStatus } from '@/store/slices/ui';
import Overlay from '../Overlay';
import { useAppSelector } from '@/hooks';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

export const RefreshDialogOverlay = (): JSX.Element => {
  const { isOpen } = useAppSelector(selectRefreshDialogStatus);
  const handleClickReAthenticate = () => window.location.reload();
  return (
    <>
      <Overlay open={isOpen}>
        <Dialog open={isOpen} style={{ zIndex: '10000' }}>
          <DialogTitle>Your login session has expired.</DialogTitle>
          <DialogContent>
            To continue, simply click the button below to reauthenticate your
            session.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickReAthenticate} variant='contained'>
              Re-authenticate
            </Button>
          </DialogActions>
        </Dialog>
      </Overlay>
    </>
  );
};

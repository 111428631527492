import { ModuleId } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import { debounce } from 'lodash';
import { ModuleGroup } from '@/store/types/module';
import { updateModuleGroup } from '@/store/slices/modules';
import editModuleGroup from '@/api/mutations/editor/editModuleGroup';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';

const debouncedEditModuleGroup = debounce((id) => {
  editModuleGroup(id);
}, 1000);

const updateModuleGroupThunk =
  ({
    id,
    updates,
    shouldDebounce,
  }: {
    id: ModuleId;
    updates: Partial<ModuleGroup>;
    shouldDebounce?: boolean;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setIsDirty(true));
    dispatch(updateModuleGroup({ id, updates }));
    dispatch(
      updateEntityModifications({
        entityType: 'moduleGroups',
        entityId: id,
        fields: Object.keys(updates),
      })
    );
    if (shouldDebounce) {
      debouncedEditModuleGroup(id);
    } else {
      editModuleGroup(id);
    }
  };

export default updateModuleGroupThunk;

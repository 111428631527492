import {
  Question,
  QuestionAnswerValue,
  QuestionOptions,
} from '@/store/types/question';

interface GetRadioOptionsProps {
  question: Question;
  questionValues: { [key: string]: QuestionAnswerValue };
}

const getRadioOptions = ({ question, questionValues }: GetRadioOptionsProps) =>
  [question.options.find((o) => o.value === questionValues[question.id])].map(
    (o) => ({
      ...o,
      radioProps: { readOnly: true },
      labelProps: { labelPlacement: 'start' },
    })
  ) as QuestionOptions[];

export default getRadioOptions;

import { EntityType } from '@/store/types/basicTypes';
import { QuestionTrigger } from '@/store/types/question';
import { Module } from '@/store/types/module';

export const filterTriggersByEntityType = (
  triggers: QuestionTrigger[],
  entityType: EntityType
) => triggers.filter((trigger) => trigger.componentType === entityType);

export const getTriggersByOptionValue = (
  triggers: QuestionTrigger[],
  optionValue: string
) => {
  return triggers.filter((trigger) => trigger.value === optionValue);
};

export const reorderList = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const filterEntitiesByIds = <T>(
  entities: { [key: string]: T },
  targetIds: string[]
) => {
  return Object.entries(entities)
    .filter(([key, value]) => targetIds?.includes(key))
    .map(([key, value]) => value);
};

export const findModuleById = (modules: Module[], targetId: string) =>
  modules.find((module: Module) => module.id === targetId);

import { EntityType } from '@/store/types/basicTypes';
import { ControlTypes, Question } from '@/store/types/question';
import { getuuid } from '@/utils/helpers';

/**
 * The `createTipAliases` function is aimed at optimizing the reuse of tip across multiple question triggers.
 * The concept is simple: instead of assigning the same tip ID to multiple triggers (which could lead to redundancy)
 * we create unique "aliases" or shadow copies for each occurrence. This way, we make sure that we can reuse the same
 * tip without unnecessary duplication.
 *
 * Each alias points to the original tip. When a trigger's alias is activated, the tip component would lookup the alias
 * in the map, retrieve the original tip ID, and use its data to display the tip.
 */
const createTipAliases = ({ questions }: { questions: Question[] }) => {
  const aliasMap: { [aliasId: string]: string } = {};

  const allTipTriggers = questions.reduce((acc: string[], question) => {
    return [
      ...acc,
      ...question.triggers
        .filter((t) => t.componentType === EntityType.Tip)
        .map((t) => t.componentId),
    ];
  }, [] as string[]);

  const duplicatedTriggeredTips = allTipTriggers.filter(
    (value, index, self) => {
      return self.indexOf(value) !== index && self.lastIndexOf(value) === index;
    }
  );

  // Replace the duplicated tip triggers with a unique alias. This way, we are effectively pointing to the original tip
  // but via a unique identifier - allowing us to display it multiple times, as needed
  questions.forEach((question) => {
    let multiSelectTipAlias: string | null = null;
    const isMultiSelect = question.control === ControlTypes.MultiSelect;

    question.triggers.forEach((trigger) => {
      // Check if trigger's componentId is in the duplication list
      if (duplicatedTriggeredTips.includes(trigger.componentId)) {
        // If our triggering question is a multiselect, we only need to create
        // one alias for the same tip. This is because multiselects only display
        // the same tip once, regardless of how many options are selected.
        if (isMultiSelect) {
          if (!multiSelectTipAlias) {
            multiSelectTipAlias = getuuid();
          }
          // Assign the alias to the original tip id
          aliasMap[multiSelectTipAlias] = trigger.componentId;
          // Replace the trigger componentId with the alias
          trigger.componentId = multiSelectTipAlias;
          return;
        }

        // If we are not dealing with a multiselect, we need to create a unique
        // alias for each trigger of the same tip.
        const newId = getuuid();
        aliasMap[newId] = trigger.componentId;
        trigger.componentId = newId;
      }
    });
  });

  return aliasMap;
};

export default createTipAliases;

import client from '@/api/client';
//@ts-ignore
import { GetEditorSessionList } from '@/api/gql/editor/queries.gql';
import getSessionListTransform from '@/api/transforms/editor/getSessionListTransfrom';
import { isUsingFixtures } from '@/constants/env';
import { EditorSession } from '@/store/types/editor';

export const getSessionList = async (): Promise<{
  data: {
    entities: {
      [key: string]: EditorSession;
    };
    ids: string[];
  };
  errors: any;
}> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: getSessionListTransform({ listEditorSessions: [] }) };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetEditorSessionList,
  });

  return { data: getSessionListTransform(data), errors };
};

export default getSessionList;

import {
  styled,
  ListItemButton as MUIListItemButton,
  ListItem as MUIListItem,
} from '@mui/material';

export const SelectionHeader = styled('div')`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const SelectionTitle = styled('div')`
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ScrollContainer = styled('div')`
  height: 400px;
  overflow-y: auto;
`;

export const ListItemButton = styled(MUIListItemButton)`
  padding-left: 30px;
`;

export const ListItem = styled(MUIListItem)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

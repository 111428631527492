import {
  Box,
  Checkbox,
  Container,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectModuleInEdit,
  selectOutdatedEntities,
  selectSingleEntityModifications,
  setActiveParentTreeView,
} from '@/store/slices/editor';
import TextEditor from '@/components/TextEditor';
import { Label, LabelBadge, LabelContainer } from '../../styled';
import { EntityType, QuestionId } from '@/store/types/basicTypes';
import { selectModule, selectModuleRoles } from '@/store/slices/modules';
import EntitySelectMenu from '../../EntitySelectMenu';
import { Button, TextField } from '@s-rm/react-ui-lib';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import updateModuleThunk from '@/store/thunks/editor/updateModule';
import { selectAllQuestionEntites } from '@/store/slices/questions';
import { QuestionItemWrapper } from './styled';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { reorderList } from '../../utils/helpers';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {
  DragHandle,
  SortableItem,
  SortableList,
} from '@/components/DragDropList/DragDropList';
import { MenuProps } from './MenuProps';
import { useState } from 'react';
import { ModuleRoles, ModuleRoleLabels, Roles } from '@/store/types/module';
import { getRoleOptions } from '../../utils/getRoleOptions';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id: moduleInEditId, isNew } = useAppSelector(selectModuleInEdit);
  const moduleEntity = useAppSelector((state) =>
    selectModule(state, moduleInEditId!)
  );
  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'modules', moduleInEditId!)
  );
  const moduleRoles = useAppSelector((state) =>
    selectModuleRoles(state, moduleInEditId!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).modules?.[moduleInEditId!] || [];
  const questionEntities = useAppSelector(selectAllQuestionEntites);

  const handleClickParentView = (entityId: string) => {
    dispatch(
      setActiveParentTreeView({
        entityId,
        entityType: EntityType.Question,
      })
    );
  };

  const handleUpdateLabel = (val: string) => {
    dispatch(
      updateModuleThunk({
        id: moduleEntity.id,
        updates: { label: val },
      })
    );
  };
  const handleUpdateDescription = (val: string) => {
    /* istanbul ignore next */
    dispatch(
      updateModuleThunk({
        id: moduleEntity.id,
        updates: { description: val },
        shouldDebounce: true,
      })
    );
  };
  const handleSelectQuestion = (questionId: QuestionId) => {
    dispatch(
      updateModuleThunk({
        id: moduleEntity.id,
        updates: {
          questions: moduleEntity.questions.includes(questionId)
            ? moduleEntity.questions.filter((id) => id !== questionId)
            : [...moduleEntity.questions, questionId],
        },
      })
    );
  };

  const handleDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;

    dispatch(
      updateModuleThunk({
        id: moduleEntity.id,
        updates: {
          questions: reorderList(moduleEntity.questions, oldIndex, newIndex),
        },
      })
    );
  };

  const [assignedModuleRoles, setAssignedModuleRoles] = useState<ModuleRoles[]>(
    moduleRoles! ?? []
  );

  const handleUpdateModuleRole = (
    event: SelectChangeEvent<string[] | string>
  ) => {
    const {
      target: { value },
    } = event;
    setAssignedModuleRoles(value as ModuleRoles[]);
    dispatch(
      updateModuleThunk({
        id: moduleEntity.id,
        updates: {
          roles: value as ModuleRoles[],
        },
      })
    );
  };

  return (
    <Box minHeight={500} mb={5}>
      <Container>
        <Box mt={2} mb={3}>
          <LabelContainer>
            <Label htmlFor='my-input'>Module Text</Label>
            {modifications.includes('label') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('label') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextField
            data-testid='module-label-textfield'
            size='small'
            placeholder={!moduleEntity.label ? 'Enter module text' : ''}
            value={moduleEntity.label}
            onChange={(event) => handleUpdateLabel(event.target.value)}
          />
        </Box>
        <Box mt={2} mb={12} height={100}>
          <LabelContainer>
            <Label htmlFor='my-input'>Module description</Label>
            {modifications.includes('description') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('description') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextEditor
            data-testid='module-description-textfield'
            onChange={handleUpdateDescription}
            value={moduleEntity.description}
          />
        </Box>

        <Box mt={2} mb={12}>
          <LabelContainer>
            <Label htmlFor='module-roles'>Module roles</Label>
            {modifications.includes('roles') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('roles') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <Select
            fullWidth={true}
            size='small'
            multiple
            onChange={handleUpdateModuleRole}
            value={assignedModuleRoles}
            renderValue={(selected: ModuleRoles[]) =>
              selected.map((role) => ModuleRoleLabels[role]).join(', ')
            }
            MenuProps={MenuProps}
            data-testid={'module-roles-multiple-checkbox'}
          >
            {getRoleOptions.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                <Checkbox checked={assignedModuleRoles.indexOf(value) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box
          mb={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center' gap={2}>
            <Typography>Root Questions</Typography>
            {modifications.includes('questions') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('questions') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </Box>
          <EntitySelectMenu
            componentType={EntityType.Question}
            onSelect={handleSelectQuestion}
            Trigger={
              <Button
                startIcon={<AddOutlinedIcon />}
                data-testid='btn-add-root-questions-module'
              >
                Add Root Questions
              </Button>
            }
            selectedEntityIds={moduleEntity.questions}
          />
        </Box>
        <Divider />
        <Stack
          direction='column'
          gap={2}
          mt={2}
          data-testid='selected-questions-stack'
        >
          {!moduleEntity.questions.length && (
            <Typography color='grey.500'>No selected questions yet</Typography>
          )}
          <SortableList onSortEnd={handleDragEnd} useDragHandle>
            {moduleEntity.questions.map((questionId: QuestionId, index) => {
              const question = questionEntities[questionId];
              return (
                <SortableItem key={questionId} index={index}>
                  <Box py={1} display='flex' alignItems='center' gap={1}>
                    <DragHandle>
                      <Box>
                        <DragIndicatorIcon />
                      </Box>
                    </DragHandle>
                    <Box width='100%'>
                      <QuestionItemWrapper key={questionId}>
                        <Typography noWrap width={700} mr={1}>
                          {question.label}
                        </Typography>
                        <IconButton
                          onClick={() => handleClickParentView(questionId)}
                        >
                          <AccountTreeOutlinedIcon
                            data-testid={`view-question-parents-module`}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSelectQuestion(questionId)}
                          size='small'
                        >
                          <DeleteOutlineOutlinedIcon
                            data-testid={`delete-question-module`}
                          />
                        </IconButton>
                      </QuestionItemWrapper>
                    </Box>
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
        </Stack>
      </Container>
    </Box>
  );
};

export default ModalContent;

import { AppThunk } from '@/store/store';
import { EntityType } from '@/store/types/basicTypes';
import archiveCaseTypeThunk from './archiveCaseType';
import archiveTipsThunk from './archiveTip';
import archiveQuestionThunk from './archiveQuestion';
import archiveModuleGroupThunk from './archiveModuleGroup';
import archiveModuleThunk from './archiveModule';
import archiveAdviceThunk from './archiveAdvice';

const resetEditorSessionOrArchive =
  (id: string, entityType: EntityType): AppThunk =>
  async (dispatch) => {
    switch (entityType) {
      case EntityType.CaseType:
        dispatch(archiveCaseTypeThunk(id));
        break;
      case EntityType.Advice:
        dispatch(archiveAdviceThunk(id));
        break;
      case EntityType.Module:
        dispatch(archiveModuleThunk(id));
        break;
      case EntityType.ModuleGroup:
        dispatch(archiveModuleGroupThunk(id));
        break;
      case EntityType.Question:
        dispatch(archiveQuestionThunk(id));
        break;
      case EntityType.Tip:
        dispatch(archiveTipsThunk(id));
        break;
      default:
        break;
    }
  };

export default resetEditorSessionOrArchive;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';
import { ComponentReferenceObject } from '../types/question';
import { ModuleId } from '../types/basicTypes';
import { omit } from 'lodash';

export const questionDisplayOrder = createSlice({
  name: 'questionDisplayOrder',
  initialState: initialState.questionDisplayOrder,
  reducers: {
    addModuleToQuestionDisplayOrder: (
      state,
      action: PayloadAction<{ [key: ModuleId]: ComponentReferenceObject[] }>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    removeModulesFromQuestionDisplayOrder: (
      state,
      action: PayloadAction<ModuleId[]>
    ) => {
      return omit(state, action.payload) || {};
    },
    removeEntitiesFromQuestionDisplayOrder: (
      state,
      action: PayloadAction<string>
    ) => {
      const newState = { ...state };
      Object.keys(newState).forEach((key) => {
        newState[key] = newState[key].filter(
          (component) => component.id !== action.payload
        );
      });
      return newState;
    },
  },
});

export const {
  addModuleToQuestionDisplayOrder,
  removeModulesFromQuestionDisplayOrder,
  removeEntitiesFromQuestionDisplayOrder,
} = questionDisplayOrder.actions;

export const selectQuestionDisplayOrder = (state: RootState) =>
  state.questionDisplayOrder;
export const selectDisplayOrderByModule = (
  state: RootState,
  moduleId: ModuleId
) => state.questionDisplayOrder[moduleId];

export default questionDisplayOrder.reducer;

import {
  AdviceId,
  EntityType,
  ModuleGroupId,
  ModuleId,
  QuestionId,
  TipId,
} from '@/store/types/basicTypes';
import { CaseType } from '@/store/types/caseType';
import { Module, ModuleGroup } from '@/store/types/module';
import { Question } from '@/store/types/question';
import { QuestionDisplayOrderSlice } from '@/store/types/questionDisplayOrder';
import { Tip } from '@/store/types/tip';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';

// We use the pre-defined question display order to determine which questions
// are related to each module that we have searched for.
export const getQuestionsRelatedToModules = (
  moduleIds: ModuleId[],
  questionDisplayOrder: QuestionDisplayOrderSlice
) => {
  return moduleIds.reduce<ModuleId[]>((acc, moduleId) => {
    const foundModule = questionDisplayOrder[moduleId];
    return [
      ...acc,
      ...(foundModule
        ? foundModule
            .filter((q) => q.componentType === EntityType.Question)
            .map((q) => q.id)
        : []),
    ];
  }, []);
};

// Find any question triggers that are related to the tips that we have searched
export const getQuestionsRelatedToTips = (
  tipIds: TipId[],
  questions: {
    [key: string]: Question;
  }
) => {
  return convertEntitiesToArray(questions)
    .filter((q) => q.triggers.some((t) => tipIds.includes(t.componentId)))
    .map((q) => q.id);
};

export const getTipsRelatedToQuestions = (
  questionIds: QuestionId[],
  questions: {
    [key: string]: Question;
  }
) => {
  return convertEntitiesToArray(questions).reduce((acc, question) => {
    if (questionIds.includes(question.id)) {
      return [
        ...acc,
        ...question.triggers
          .filter((t) => t.componentType === EntityType.Tip)
          .map((t) => t.componentId),
      ];
    }
    return acc;
  }, [] as string[]);
};
export const getModulesRelatedToQuestionsOrTips = (
  questionIds: QuestionId[],
  questionDisplayOrder: QuestionDisplayOrderSlice
): ModuleId[] => {
  return Object.keys(questionDisplayOrder).filter((moduleId) => {
    const currentModule = questionDisplayOrder[moduleId];
    return currentModule.some((q) => questionIds.includes(q.id));
  });
};

export const getModulesRelatedToQuestions = ({
  questionIds,
  modules,
}: {
  questionIds: ModuleId[];
  modules: {
    [key: string]: Module;
  };
}): ModuleId[] =>
  convertEntitiesToArray(modules).reduce((acc, m) => {
    if (m.questions.some((q) => questionIds.includes(q))) {
      return [...acc, m.id];
    }
    return acc;
  }, [] as string[]);

export const getModuleGroupsByModuleIds = ({
  moduleGroups,
  moduleIds,
}: {
  moduleGroups: {
    [key: string]: ModuleGroup;
  };
  moduleIds: ModuleId[];
}): ModuleGroupId[] =>
  Object.keys(moduleGroups).filter((groupId) =>
    moduleGroups[groupId].moduleIds.some((m) => moduleIds.includes(m))
  );

export const getNonAliasTriggers = ({
  triggers,
  tipAliasMap,
}: {
  triggers: Question['triggers'];
  tipAliasMap: {
    [key: string]: string;
  };
}) => {
  return triggers.map((t) => {
    if (t.componentType === EntityType.Tip) {
      return {
        ...t,
        componentId: tipAliasMap[t.componentId] || t.componentId,
      };
    }
    return t;
  });
};

export const getAdviceIdsRelatedToTips = ({
  tipsId,
  tips,
}: {
  tipsId: TipId[];
  tips: {
    [key: string]: Tip;
  };
}): AdviceId[] =>
  tipsId.reduce<AdviceId[]>((acc, tipId) => {
    return tipId in tips ? [...acc, ...tips[tipId].advice] : acc;
  }, []);

export const getModulesByModuleGroupIds = ({
  moduleGroupsIds,
  moduleGroups,
}: {
  moduleGroupsIds: ModuleGroupId[];
  moduleGroups: {
    [key: string]: ModuleGroup;
  };
}): ModuleId[] =>
  moduleGroupsIds.reduce<ModuleId[]>((acc, groupId) => {
    return groupId in moduleGroups
      ? [...acc, ...moduleGroups[groupId].moduleIds]
      : acc;
  }, []);

export const getModulesByCaseTypeIds = ({
  caseTypeIds,
  caseTypes,
}: {
  caseTypeIds: string[];
  caseTypes: { [key: string]: CaseType };
}) =>
  convertEntitiesToArray(caseTypes).reduce<ModuleId[]>((acc, caseType) => {
    return caseTypeIds.includes(caseType.id)
      ? [...acc, ...caseType.modules]
      : acc;
  }, []);

export const getTipsRelatedToAdviceIds = ({
  adviceIds,
  tips,
}: {
  adviceIds: AdviceId[];
  tips: {
    [key: string]: Tip;
  };
}) =>
  convertEntitiesToArray(tips).reduce<TipId[]>((acc, tip) => {
    return tip.advice.some((a) => adviceIds.includes(a))
      ? [...acc, tip.id]
      : acc;
  }, []);

import saveData from '@/api/utils/saveData';
import {
  removeSelectedCaseTypes,
  removeSuggestedCaseTypes,
} from '../slices/caseTypes';
import {
  removeSelectedModules,
  removeTriggeredModules,
} from '../slices/modules';
import { removeModulesFromQuestionDisplayOrder } from '../slices/questionDisplayOrder';
import { AppThunk } from '../store';
import convertEntitiesToArray from '../utils/convertEntitiesToArray';
import getModulesByCaseTypes from '../utils/getModulesByCaseTypes';
import SaveDataTypes from '@/api/types/saveDataTypes';
import getSuggestedCaseTypesToRemove from '../utils/getSuggestedCaseTypesToRemove';
import getTriggeredModulesToRemove from '../utils/getTriggeredModulesToRemove';

const removeSelectedCaseTypesThunk =
  (caseTypeIds: string[]): AppThunk =>
  (dispatch, getState) => {
    const allTriggeredModules = getState().modules.selectedByTrigger;
    const caseTypes = getState().caseTypes.entities;
    const selectedCaseTypeIds = getState().caseTypes.selected;
    const caseTypesArray = convertEntitiesToArray(caseTypes);
    const defaultModules = getModulesByCaseTypes({
      caseTypes: caseTypesArray,
      selectedCaseTypes: caseTypeIds,
    });
    const selectedIndicators = getState().indicators.selected;
    const indicatorCaseTypeMap = getState().indicators.indicatorCaseTypesMap;

    const isModuleInOtherSelectedCaseType = (moduleId: string) => {
      return selectedCaseTypeIds.some((caseTypeId) => {
        const caseType = caseTypes[caseTypeId];

        return (
          caseType.modules.includes(moduleId) &&
          !caseTypeIds.includes(caseTypeId)
        );
      });
    };

    const suggestedCaseTypesToRemove = getSuggestedCaseTypesToRemove({
      caseTypeIds,
      selectedIndicators,
      indicatorCaseTypeMap,
    });
    const triggeredModulesToRemove = getTriggeredModulesToRemove({
      caseTypesToRemove: caseTypeIds,
      allTriggeredModules,
    });
    const allSelectedModulesToRemove = [
      ...defaultModules.filter((m) => !isModuleInOtherSelectedCaseType(m)),
      ...triggeredModulesToRemove.map((m) => m.moduleId),
    ];

    dispatch(removeSelectedCaseTypes(caseTypeIds));
    dispatch(removeSuggestedCaseTypes(suggestedCaseTypesToRemove));
    dispatch(
      removeSelectedModules({
        moduleIds: allSelectedModulesToRemove,
      })
    );
    dispatch(removeTriggeredModules(triggeredModulesToRemove));
    dispatch(removeModulesFromQuestionDisplayOrder(allSelectedModulesToRemove));
    dispatch(
      removeSelectedModules({
        moduleIds: allSelectedModulesToRemove,
      })
    );
    dispatch(removeTriggeredModules(triggeredModulesToRemove));
    dispatch(removeModulesFromQuestionDisplayOrder(allSelectedModulesToRemove));

    saveData({
      type: SaveDataTypes.Case,
    });
  };

export default removeSelectedCaseTypesThunk;

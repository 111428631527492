import { EntityType } from '@/store/types/basicTypes';
import {
  ControlTypes,
  QuestionTrigger,
  QuestionTriggerActions,
} from '@/store/types/question';
import { getuuid } from '@/utils/helpers';
import { tip1Mock } from './tipsMock';

export const radioQuestionMock = {
  id: 'radio-question',
  entityType: EntityType.Question,
  control: ControlTypes.Radio,
  placeholder: '',
  guidance: 'The guidance for radio question',
  label: 'Radio question',
  triggers: [
    {
      id: 'trigger-1',
      action: QuestionTriggerActions.Show,
      value: '1',
      componentId: tip1Mock.id,
      componentType: EntityType.Tip,
    },
  ],
  options: [
    {
      label: 'Yes',
      value: '1',
      id: 'radio-question-option-1',
    },
    {
      label: 'No',
      value: '2',
      id: 'radio-question-option-2',
    },
  ],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
    longText: false,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const multiSelectQuestionMock = {
  id: 'multi-select-question',
  entityType: EntityType.Question,
  control: ControlTypes.MultiSelect,
  placeholder: '',
  guidance: 'The guidance for multi select question',
  label: 'Multi select question',
  triggers: [],
  options: [
    {
      label: 'Option 1',
      value: '1',
      id: 'multiselect-question-option-1',
    },
    {
      label: 'Option 2',
      value: '2',
      id: 'multiselect-question-option-2',
    },
  ],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const textFieldQuestionMock = {
  id: 'text-field-questions',
  entityType: EntityType.Question,
  control: ControlTypes.Textarea,
  placeholder: 'The placeholder',
  guidance: 'The guidance for text field question',
  label: 'Text field question',
  triggers: [],
  options: [],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const dateRangeQuestionMock = {
  id: 'date-range-question',
  control: ControlTypes.Daterange,
  placeholder: '',
  guidance: 'The guidance for date range question',
  label: 'Date range question',
  triggers: [],
  options: [],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const datePickerQuestionMock = {
  id: 'date-picker-question',
  control: ControlTypes.Datepicker,
  placeholder: '',
  guidance: 'The guidance for date picker question',
  label: 'Date picker question',
  triggers: [],
  options: [],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const numericalQuestionMock = {
  id: 'numerical-question',
  control: ControlTypes.Numeric,
  placeholder: '',
  guidance: 'The guidance for numerical question',
  label: 'Numerical question',
  version: 1,
  triggers: [],
  options: [],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  integrationTags: ['S1'],
};

export const questionMock = {
  id: '',
  entityType: EntityType.Question,
  control: ControlTypes.Textarea,
  placeholder: '',
  guidance: '',
  label: '',
  triggers: [],
  options: [],
  validation: {
    pattern: 'string',
    isRequired: true,
    maxLength: 5,
    minLength: 2,
    maxValue: 10,
    minValue: 2,
  },
  version: 1,
  integrationTags: ['S1'],
};

export const dateRangeValueMock = '2024-02-07T00:00:00Z,2024-02-10T00:00:00Z';
export const datePickerValueMock = '2024-02-07T00:00:00Z';

export const getTriggerMock = ({
  componentId,
  componentType,
  value,
}: {
  componentId: string;
  componentType: EntityType;
  value: string;
}): QuestionTrigger => ({
  id: getuuid(),
  action: QuestionTriggerActions.Show,
  value,
  componentId,
  componentType,
});

export const questionsMock = {
  entities: {
    [radioQuestionMock.id]: radioQuestionMock,
    [multiSelectQuestionMock.id]: multiSelectQuestionMock,
    [textFieldQuestionMock.id]: textFieldQuestionMock,
    [dateRangeQuestionMock.id]: dateRangeQuestionMock,
    [datePickerQuestionMock.id]: datePickerQuestionMock,
    [numericalQuestionMock.id]: numericalQuestionMock,
  },
  questionDisplayOrder: {},
  questionsVisibility: {
    [radioQuestionMock.id]: true,
    [tip1Mock.id]: true,
    [multiSelectQuestionMock.id]: true,
    [textFieldQuestionMock.id]: true,
    [dateRangeQuestionMock.id]: true,
    [datePickerQuestionMock.id]: true,
    [numericalQuestionMock.id]: true,
  },
  values: {
    [radioQuestionMock.id]: radioQuestionMock.options[0].value,
  },
  ids: [
    radioQuestionMock.id,
    multiSelectQuestionMock.id,
    textFieldQuestionMock.id,
    dateRangeQuestionMock.id,
    datePickerQuestionMock.id,
    numericalQuestionMock.id,
  ],
  versions: {},
  suggestedValues: {},
};

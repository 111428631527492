export const caseMock = {
  id: 'be429790-886c-4112-8795-f39cd9cc094e',
  description: 'The description of the case',
  descriptionPreSuggestedIndicators: 'The description of the case',
  client: {
    name: 'Test client',
  },
  project: {
    name: 'DemoProject',
  },
  triggeredIntegrations: ['Jira', 'SentinelOne'],
  isActive: true,
  diagnosis: {
    enabled: true,
  },
  notes: {
    enabled: false,
  },
  addModules: {
    enabled: false,
  },
  staticModuleIds: [],
};

import { TriggerConfigActions, TriggerConfigWrapper } from '../styled';
import { Box, IconButton, Typography } from '@mui/material';
import TextSwitch from '@/components/Switch';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {
  QuestionTrigger,
  QuestionTriggerActions,
} from '@/store/types/question';
import { useAppDispatch, useAppSelector } from '@/hooks';
import updateQuestionThunk from '@/store/thunks/editor/updateQuestion';
import {
  selectQuestionInEdit,
  setActiveParentTreeView,
} from '@/store/slices/editor';
import {
  selectAllQuestionEntites,
  selectQuestion,
} from '@/store/slices/questions';
import { EntityType } from '@/store/types/basicTypes';

import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { selectModuleEntities } from '@/store/slices/modules';
import { selectAllTipEntities, selectTipAliasMap } from '@/store/slices/tips';
import { getEntityText, removeAllTags } from '@/utils/helpers';

const TriggerItem = ({
  trigger: originalTrigger,
}: {
  trigger: QuestionTrigger;
}) => {
  const questions = useAppSelector(selectAllQuestionEntites);
  const modules = useAppSelector(selectModuleEntities);
  const tips = useAppSelector(selectAllTipEntities);
  const tipAliasMap = useAppSelector(selectTipAliasMap);
  const isTip = originalTrigger.componentType === EntityType.Tip;
  const trigger = isTip
    ? {
        ...originalTrigger,
        componentId:
          tips[originalTrigger.componentId]?.id ??
          tips[tipAliasMap[originalTrigger.componentId]]?.id,
      }
    : originalTrigger;

  const getTriggeredEntityText = (id: string, componentType: EntityType) => {
    switch (componentType) {
      case EntityType.Module:
        return getEntityText(modules[id]);
      case EntityType.Question:
        return getEntityText(questions[id]);
      case EntityType.Tip:
        return getEntityText(tips[id]);
    }
  };

  const entityType = trigger.componentType as EntityType;
  const dispatch = useAppDispatch();
  const questionInEdit = useAppSelector(selectQuestionInEdit);
  const questionInEditTriggers = useAppSelector((state) =>
    selectQuestion(state, questionInEdit.id as string)
  ).triggers;

  const handleDeleteTrigger = (triggerId: string) => {
    dispatch(
      updateQuestionThunk({
        id: questionInEdit.id as string,
        updates: {
          triggers: questionInEditTriggers.filter((trigger) => {
            return trigger.id !== triggerId;
          }),
        },
      })
    );
  };

  const handleClickParentView = (entityId: string) => {
    dispatch(
      setActiveParentTreeView({
        entityId,
        entityType,
      })
    );
  };

  const handleSwitchAction = (triggerId: string) => {
    dispatch(
      updateQuestionThunk({
        id: questionInEdit.id as string,
        updates: {
          triggers: questionInEditTriggers.map((trigger) =>
            trigger.id === triggerId
              ? {
                  ...trigger,
                  action:
                    trigger.action === QuestionTriggerActions.Show
                      ? QuestionTriggerActions.Hide
                      : QuestionTriggerActions.Show,
                }
              : trigger
          ),
        },
      })
    );
  };

  return (
    <TriggerConfigWrapper
      data-testid={`${entityType.toLocaleLowerCase()}-trigger-${
        trigger.componentId
      }`}
      key={trigger.id}
    >
      <Box>
        {entityType === EntityType.Question && <QuizOutlinedIcon />}
        {entityType === EntityType.Tip && <TipsAndUpdatesOutlinedIcon />}
      </Box>
      <Box flex={8}>
        <Typography maxWidth={500} noWrap>
          {removeAllTags(
            getTriggeredEntityText(
              trigger.componentId,
              trigger.componentType as EntityType
            )!
          )}
        </Typography>
      </Box>
      <Box flex={4} display='flex'>
        <TriggerConfigActions>
          <TextSwitch
            data-testid={`${entityType.toLocaleLowerCase()}-trigger-switch-btn`}
            checked={trigger.action === QuestionTriggerActions.Show}
            onClick={() => handleSwitchAction(trigger.id)}
          />
          <IconButton
            data-testid={`${entityType.toLocaleLowerCase()}-trigger-parent-view-btn`}
            onClick={() => handleClickParentView(trigger.componentId)}
          >
            <AccountTreeOutlinedIcon />
          </IconButton>

          <IconButton
            onClick={() => handleDeleteTrigger(trigger.id)}
            data-testid={`${entityType.toLocaleLowerCase()}-delete-trigger-btn`}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </TriggerConfigActions>
      </Box>
    </TriggerConfigWrapper>
  );
};

export default TriggerItem;

import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetEditorSession } from '@/api/gql/editor/queries.gql';
import { RawInitialData } from '@/api/types/initialData';
import { isUsingFixtures } from '@/constants/env';

export const getSession = async ({
  sessionId,
}: {
  sessionId: string;
}): Promise<{ data: RawInitialData }> => {
  if (isUsingFixtures) {
    return { data: fetchEditorSessionFixture as any };
  }

  const { data } = await client.query({
    query: GetEditorSession,
    variables: {
      id: sessionId,
    },
  });

  return { data };
};

export default getSession;

import { Box, Typography } from '@mui/material';
import {
  ButtonContainer,
  InfoHeader,
  InfoSection,
  SelectedCaseType,
} from './styled';

import EditMenu from '../Editor/EditorMenu';
import { Button } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectSessionInEdit,
  setIsConfrimUseEditorModalOpen,
  setIsSessionModalOpen,
} from '@/store/slices/editor';
import { getPossessiveName } from '@/utils/helpers';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import ConfirmMergeSessionModal from '../Editor/ConfirmMergeSessionModal';
import { useState } from 'react';
import mergeSession from '@/api/mutations/editor/mergeSession';
import { useRouter } from 'next/router';
import { getRootPathFromRouter } from '@/utils/router';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GlobalQuestionCounter from '../RequiredQuestionsCounter/GlobalQuestionCounter';

const EditModeContent = () => {
  const dispatch = useAppDispatch();
  const sessionInEdit = useAppSelector(selectSessionInEdit);
  const [showPublishWarning, setShowPublishWarning] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const router = useRouter();

  const {
    query: { caseId },
  } = router;
  const handleOpenDrafts = () => {
    dispatch(setIsSessionModalOpen(true));
  };
  const draftedByName =
    sessionInEdit?.lastUpdatedBy || sessionInEdit?.createdBy || '';
  const handlePublishChanges = async () => {
    try {
      setIsPublishing(true);
      const { errors } = await mergeSession(sessionInEdit?.id as string);
      if (errors) {
        throw new Error(errors[0].message);
      }
      const {
        query: { caseId },
      } = router;
      window.location.href = `/${getRootPathFromRouter(router)}/${caseId}/edit`;
    } catch (e) {
      setIsPublishing(false);
    }
  };

  const handleQuitEditor = () => {
    dispatch(setIsConfrimUseEditorModalOpen(true));
  };

  return (
    <>
      <EditMenu />
      <ConfirmMergeSessionModal
        onConfirm={handlePublishChanges}
        onClose={() => setShowPublishWarning(false)}
        isOpen={showPublishWarning}
        isPublishing={isPublishing}
      />
      {sessionInEdit?.id && (
        <div>
          <Typography mb={1}>
            {getPossessiveName(draftedByName)} edit
          </Typography>

          <div>
            <InfoSection>
              <InfoHeader>
                <b>Created by:</b> {sessionInEdit.createdBy || '-'} <b>on</b>{' '}
                {sessionInEdit.createdAt}
              </InfoHeader>
              <InfoHeader>
                <b>Last updated by:</b> {sessionInEdit.lastUpdatedBy || '-'}{' '}
                <b>on</b> {sessionInEdit.lastUpdatedAt}
              </InfoHeader>
            </InfoSection>
          </div>
        </div>
      )}
      <ButtonContainer>
        <Box display='flex' gap={2}>
          <GlobalQuestionCounter />
          <Button
            onClick={handleOpenDrafts}
            variant='contained'
            endIcon={<FolderOpenOutlinedIcon />}
          >
            Drafts
          </Button>
          <Button
            onClick={() => setShowPublishWarning(true)}
            variant='contained'
            endIcon={<PublishOutlinedIcon />}
          >
            Publish changes
          </Button>
          <Button
            onClick={handleQuitEditor}
            endIcon={<ExitToAppOutlinedIcon />}
            sx={{ background: 'white', marginLeft: '20px' }}
          >
            Quit Editor
          </Button>
        </Box>
      </ButtonContainer>
    </>
  );
};

export default EditModeContent;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectQuestion } from '@/store/slices/questions';
import { ControlTypes } from '@/store/types/question';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import { ListActions } from '../styled';
import {
  Badge,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectOutdatedEntities,
  selectSingleEntityModifications,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setQuestionInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { EntityType, QuestionId } from '@/store/types/basicTypes';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@s-rm/react-ui-lib';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NumbersIcon from '@mui/icons-material/Numbers';
import { SecondaryBadge } from '../../styled';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const QuestionListItem = ({
  id,
  onDelete,
}: {
  id: QuestionId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();
  const entityModifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'questions', id)
  );
  const isOutdated = !!useAppSelector(selectOutdatedEntities).questions[id];

  const { label, control } = useAppSelector((state) =>
    selectQuestion(state, id)
  ) || {
    label: '',
    control: '',
  };

  const handleClickQuestion = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setQuestionInEdit({ id }));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType: EntityType.Question,
      })
    );
  };

  const renderIcon = () => {
    switch (control) {
      case ControlTypes.Radio:
        return <RadioButtonCheckedOutlinedIcon />;
      case ControlTypes.Textarea ?? ControlTypes.Numeric:
        return <SubjectOutlinedIcon />;
      case ControlTypes.Numeric:
        return <NumbersIcon />;
      case ControlTypes.MultiSelect:
        return <CheckBoxOutlinedIcon />;
      case ControlTypes.MultiSelectSearch:
        return <ManageSearchIcon />;
      case ControlTypes.Datepicker:
        return <CalendarMonthIcon />;
      case ControlTypes.Daterange:
        return <DateRangeIcon />;
      case ControlTypes.Currency:
        return <CurrencyPoundIcon />;
      case ControlTypes.Timepicker:
        return <AccessTimeIcon />;
      default:
        return null;
    }
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button
            square
            onClick={handleClickParentView}
            data-testid='btn-question-parent-tree'
          >
            <AccountTreeOutlinedIcon />
          </Button>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-question-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickQuestion}
      >
        <ListItemIcon>
          <Tooltip title='Current draft contains changes to this question'>
            <Badge
              color='secondary'
              badgeContent={entityModifications.length}
            />
          </Tooltip>
          <Tooltip title='This question is out of date with the latest published draft'>
            <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
          </Tooltip>
          {renderIcon()}
        </ListItemIcon>
        <ListItemText>
          <Typography noWrap>{removeAllTags(label)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default QuestionListItem;

import { AppThunk } from '@/store/store';
import {
  removeCaseType,
  removeSelectedCaseTypes,
  removeSuggestedCaseTypes,
} from '@/store/slices/caseTypes';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import archiveCaseType from '@/api/mutations/editor/archiveCaseType';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveCaseTypeThunk =
  (targetId: string): AppThunk =>
  async (dispatch, getState) => {
    const isSessionOnly = getState().caseTypes.entities[targetId].isSessionOnly;
    const searchFilter = getState().editor.entitySearchFilter;

    !isSessionOnly
      ? await archiveCaseType(targetId)
      : await resetEditorSessionChange(targetId);

    dispatch(setIsDirty(true));
    dispatch(
      setEntitySearchFilter({
        ...searchFilter,
        entityIds: searchFilter?.entityIds?.filter(
          (caseTypeId) => caseTypeId !== targetId
        ),
      })
    );

    dispatch(removeSuggestedCaseTypes([targetId]));
    dispatch(removeSelectedCaseTypes([targetId]));
    dispatch(removeCaseType({ targetId }));
  };

export default archiveCaseTypeThunk;

import { FunctionComponent, useState, ReactNode } from 'react';
import { Popover as MUIPopover } from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';

interface PopoverProps {
  children: ReactNode;
  buttonText?: string;
  width?: number | string;
  height?: number | string;
  onClose?: () => void;
  onOpen?: () => void;
  Trigger?: ReactNode;
  'data-testid'?: string;
}

export const Popover: FunctionComponent<PopoverProps> = ({
  children,
  buttonText,
  width,
  height,
  onClose,
  onOpen,
  Trigger,
  'data-testid': testId,
}) => {
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {Trigger && (
        <div tabIndex={0} onClick={handleClick}>
          {Trigger}
        </div>
      )}
      {!Trigger && (
        <Button
          data-testid={testId || 'popover-trigger-button'}
          variant='outlined'
          onClick={handleClick}
          active={open}
        >
          {buttonText}
        </Button>
      )}
      <MUIPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: width, height: height },
        }}
      >
        {children}
      </MUIPopover>
    </div>
  );
};

export default Popover;

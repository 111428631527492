import { updateQuestionsVisibility } from '../slices/questions';
import { addModuleToQuestionDisplayOrder } from '../slices/questionDisplayOrder';
import { AppThunk } from '../store';
import { EntityType, ModuleId } from '../types/basicTypes';
import { ComponentReferenceObject } from '../types/question';
import { buildStructure } from '../utils/buildNestedStructure';
import convertEntitiesToArray from '../utils/convertEntitiesToArray';

/**
 * This thunk will be called when a new module has been added and we
 * need to generate the question display order for it's associated questions.
 */
const initModuleOnQuestionDisplayOrderThunk =
  (moduleIds: ModuleId[]): AppThunk =>
  (dispatch, getState) => {
    moduleIds.forEach((moduleId) => {
      const { entities } = getState().modules;
      const rootQuestions = entities?.[moduleId]?.questions;
      const { questions, tips } = getState();
      const allEntities = [
        ...convertEntitiesToArray(questions.entities),
        ...convertEntitiesToArray(tips.entities),
      ];

      // If there is a case where no corresponding module id to module exists
      if (!rootQuestions) {
        return;
      }

      let result: ComponentReferenceObject[] = [];
      rootQuestions.forEach((questionId) => {
        result.push(
          ...buildStructure(allEntities, {
            id: questionId,
            componentType: EntityType.Question,
            parentId: '',
          })
        );
      });
      // The first question of each module should be visible by default
      dispatch(
        updateQuestionsVisibility(
          rootQuestions.reduce(
            (acc, id) => ({
              ...acc,
              [id]: true,
            }),
            {}
          )
        )
      );

      dispatch(
        addModuleToQuestionDisplayOrder({
          [moduleId]: result,
        })
      );
    });
  };

export default initModuleOnQuestionDisplayOrderThunk;

import { styled } from '@mui/material';

export const RadioGroupWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const AiIndicator = styled('div')`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  width: 20px;
  height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  top: 1.1em;
  font-weight: bold;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  margin: 0;
  position: relative;
`;

export const FormControlLabelWrapper = styled('div')`
  display: inline-flex;
`;

import { FilterOptions } from '@/store/types/editor';
import { setEntitySearchFilter } from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import {
  getModulesByCaseTypeIds,
  getModulesByModuleGroupIds,
  getModulesRelatedToQuestions,
} from './utils/helpers';
import fuzzySearch from '@/store/utils/fuzzySearch';

const filterModulesThunk =
  (filterOptions: FilterOptions): AppThunk =>
  (dispatch, getState) => {
    dispatch(setEntitySearchFilter(filterOptions));
    const modules = getState().modules.entities;
    const questions = getState().questions.entities;
    const { searchTerm, filterBy } = getState().editor.entitySearchFilter;

    const caseTypes = getState().caseTypes.entities;
    const moduleGroups = getState().modules.groups.entities;

    if (!searchTerm) {
      dispatch(
        setEntitySearchFilter({
          entityIds: convertEntitiesToArray(modules).map((q) => q.id),
        })
      );
      return;
    }

    if (filterBy === EntityType.Module) {
      dispatch(
        setEntitySearchFilter({
          entityIds: fuzzySearch({
            keys: ['label'],
            entities: convertEntitiesToArray(modules),
            searchTerm,
          }),
        })
      );
      return;
    }

    if (filterBy === EntityType.Question) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(questions),
        searchTerm,
      });
      dispatch(
        setEntitySearchFilter({
          entityIds: getModulesRelatedToQuestions({
            questionIds: searchResults,
            modules,
          }),
        })
      );
      return;
    }

    if (filterBy === EntityType.CaseType) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(caseTypes),
        searchTerm,
      });

      dispatch(
        setEntitySearchFilter({
          entityIds: getModulesByCaseTypeIds({
            caseTypeIds: searchResults,
            caseTypes,
          }),
        })
      );
      return;
    }

    if (filterBy === EntityType.ModuleGroup) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(moduleGroups),
        searchTerm,
      });
      dispatch(
        setEntitySearchFilter({
          entityIds: getModulesByModuleGroupIds({
            moduleGroupsIds: searchResults,
            moduleGroups,
          }),
        })
      );
      return;
    }
  };

export default filterModulesThunk;

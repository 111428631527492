import { Box, Container, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectAdviceInEdit,
  selectOutdatedEntities,
  selectSingleEntityModifications,
} from '@/store/slices/editor';
import TextEditor from '@/components/TextEditor';

import { Label, LabelBadge, LabelContainer } from '../../styled';
import { selectAdvice } from '@/store/slices/advice';
import updateAdviceThunk from '@/store/thunks/editor/updateAdvice';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id: adviceInEditId, isNew } = useAppSelector(selectAdviceInEdit);
  const adviceEntity = useAppSelector((state) =>
    selectAdvice(state, adviceInEditId!)
  );
  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'advice', adviceInEditId!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).advice?.[adviceInEditId!] || [];
  const handleUpdateText = (val: string) => {
    /* istanbul ignore next */
    dispatch(
      updateAdviceThunk({
        id: adviceEntity.id,
        updates: { text: val },
        shouldDebounce: true,
      })
    );
  };

  return (
    <Box minHeight={500} mb={5}>
      <Container>
        <Box mt={2} mb={12} height={100}>
          <LabelContainer>
            <Label htmlFor='my-input'>Advice text</Label>
            {modifications.includes('text') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified' data-testid='modified-badge'>
                  Modified
                </LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('text') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated' data-testid='outdated-badge'>
                  Outdated
                </LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextEditor onChange={handleUpdateText} value={adviceEntity?.text} />
        </Box>
      </Container>
    </Box>
  );
};

export default ModalContent;

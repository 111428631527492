import client from '@/api/client';
//@ts-ignore
import { UpdateCaseType } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { CaseTypeId } from '@/store/types/basicTypes';
import { ProccessStep } from '@/store/types/caseType';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editCaseType = async (caseTypeId: CaseTypeId) => {
  const caseTypeEntity = store.getState().caseTypes.entities[caseTypeId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;
  const { data, errors } = await client.mutate({
    mutation: UpdateCaseType,
    variables: {
      caseTypeId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        label: caseTypeEntity.label,
        indicatorIds: caseTypeEntity.indicators,
        moduleIds: caseTypeEntity.modules,
        processStep: caseTypeEntity.processStep || ProccessStep.Intake,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditCaseType,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editCaseType;

import { useAppSelector } from '@/hooks';
import { selectQuestion } from '@/store/slices/questions';
import { QuestionAnswerSectionContainer, QuestionLabel } from './styled';
import { ControlTypes } from '@/store/types/question';

import AnswerControl from './SummaryAnswerControl';
import { HTMLRender } from '../HTMLContent/HTMLRender';

const SummaryQuestionAnswer = ({
  questionId,
  getAsPrintView,
}: {
  questionId: string;
  getAsPrintView?: boolean;
}) => {
  const question = useAppSelector((state) => selectQuestion(state, questionId));
  if (getAsPrintView) {
    return (
      <>
        <HTMLRender content={`<p>${question.label}</p>`} />
        <ul>
          <li>
            <AnswerControl question={question} getAsPrintView />
          </li>
        </ul>
      </>
    );
  }

  return (
    <QuestionAnswerSectionContainer
      column={
        question.control === ControlTypes.Textarea ||
        question.control === ControlTypes.Daterange
      }
      data-testid={`summary-question-${questionId}`}
    >
      <QuestionLabel content={question.label} />
      <AnswerControl question={question} />
    </QuestionAnswerSectionContainer>
  );
};

export default SummaryQuestionAnswer;

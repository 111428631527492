import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';
import CloseIcon from '@mui/icons-material/Close';
import { DialogueTitleContainer } from './styled';

const ConfirmationModal = ({
  isOpen,
  children,
  title,
  onConfirm,
  onClose,
  confirmText,
  cancelText,
  testId,
  sxStyles,
  showClearButton = false,
  hideCancelButton = false,
  onClear,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  title?: string;
  onConfirm: () => void;
  onClose: () => void;
  confirmText?: string;
  cancelText?: string;
  testId?: string;
  sxStyles?: any;
  showClearButton?: boolean;
  hideCancelButton?: boolean;
  onClear?: () => void;
}) => {
  return (
    <Dialog
      data-testid={testId ?? 'confirmation-modal'}
      data-testvalue={isOpen ? 'open' : 'closed'}
      open={isOpen}
      maxWidth='sm'
      fullWidth
      sx={sxStyles ? { ...sxStyles } : {}}
    >
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            {title ?? ''}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={onClose}
            data-testid={`${testId ?? 'confirmation-modal'}-close-btn`}
          >
            <CloseIcon />
          </IconButton>
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Box py={2} px={2} display='flex' gap={2}>
          {showClearButton && (
            <Button
              data-testid={`${testId ?? 'confirmation-modal'}-clear-btn`}
              onClick={onClear}
              color='error'
              sx={{ border: '1px solid #d32f2f' }}
            >
              {'Clear'}
            </Button>
          )}

          {!hideCancelButton && (
            <Button
              data-testid={`${testId ?? 'confirmation-modal'}-cancel-btn`}
              variant='outlined'
              onClick={onClose}
            >
              {cancelText ?? 'Cancel'}
            </Button>
          )}
          <Button
            data-testid={`${testId ?? 'confirmation-modal'}-confirm-btn`}
            variant='contained'
            onClick={onConfirm}
          >
            {confirmText ?? 'Confirm'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;

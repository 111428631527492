import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';
import { RootState } from '../store';
import { AdviceId } from '../types/basicTypes';
import { Advice } from '../types/advice';

export const adviceSlice = createSlice({
  name: 'advice',
  initialState: initialState.advice,
  reducers: {
    setAdvice: (
      state,
      action: PayloadAction<TransformedInitialData['advice']>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
    deleteAdvice: (state, action: PayloadAction<string>) => {
      delete state.entities[action.payload];
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    addAdvice: (state, action: PayloadAction<Advice>) => {
      state.entities[action.payload.id] = action.payload;
      state.ids.push(action.payload.id);
    },
    updateAdvice: (
      state,
      action: PayloadAction<{ id: AdviceId; updates: Partial<Advice> }>
    ) => {
      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        ...action.payload.updates,
      };
    },
  },
});

export const { setAdvice, deleteAdvice, addAdvice, updateAdvice } =
  adviceSlice.actions;

export const selectAllAdviceEntities = (state: RootState) =>
  state.advice.entities;

export const selectAdvice = (state: RootState, id: string) =>
  state.advice.entities[id];
export const selectAllAdviceAsArray = (state: RootState) =>
  state.advice.ids.map((id) => state.advice.entities[id]);
export default adviceSlice.reducer;

import { AppThunk } from '../store';
import getCaseClosureCaseType from '../utils/getCaseClosureCaseType';
import addSelectedCaseTypesThunk from './addSelectedCaseTypes';

const loadCaseClosureThunk = (): AppThunk => (dispatch, getState) => {
  const caseTypes = getState().caseTypes.entities;
  const caseClosureId = getCaseClosureCaseType(caseTypes);

  dispatch(
    addSelectedCaseTypesThunk({
      caseTypeIds: [caseClosureId],
      disableUpdateAPI: true,
    })
  );
};

export default loadCaseClosureThunk;

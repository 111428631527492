import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';

import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectEntitySearchFilter,
  setActiveEntitySearchList,
} from '@/store/slices/editor';

import { DialogueTitleContainer } from '../../styled';
import { EntityType, TipId } from '@/store/types/basicTypes';
import { useEffect } from 'react';
import ModalContent from '../ModalContent';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import filterAdviceThunk from '@/store/thunks/editor/filterAdvice';
import archiveAdviceThunk from '@/store/thunks/editor/archiveAdvice';
import createAdviceThunk from '@/store/thunks/editor/createAdvice';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import resetEditorSessionOrArchive from '@/store/thunks/editor/resetEditorSessionOrArchive';

const AdviceSearchModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { searchTerm, filterBy } = useAppSelector(selectEntitySearchFilter);

  const handleTermChange = (updatedSearchTerm: string) => {
    dispatch(
      filterAdviceThunk({
        searchTerm: updatedSearchTerm,
      })
    );
  };

  const handleClose = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(refreshDisplayThunk());
  };

  const handleChangeFilterBy = (updatedFilterBy: EntityType) =>
    dispatch(
      filterAdviceThunk({
        filterBy: updatedFilterBy,
      })
    );

  const handleCreateAdvice = () => {
    dispatch(createAdviceThunk());
  };

  const handleDeleteAdvice = (id: TipId) => {
    dispatch(resetEditorSessionOrArchive(id, filterBy!));
    handleTermChange(searchTerm!);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(filterAdviceThunk({ filterBy: EntityType.Advice }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <EditorDialog open={isOpen} maxWidth='lg' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Typography
            variant='h6'
            textTransform='uppercase'
            fontWeight={700}
            data-testid='add-or-edit-advice-title'
          >
            Add or edit advice
          </Typography>
          <Button onClick={handleClose} data-testid='btn-close-advice-modal'>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent>
        {isOpen && (
          <ModalContent
            onCreate={handleCreateAdvice}
            searchTerm={searchTerm!}
            onChangeTerm={handleTermChange}
            onChangeFilterBy={handleChangeFilterBy}
            onDelete={handleDeleteAdvice}
          />
        )}
      </DialogContent>
    </EditorDialog>
  );
};

export default AdviceSearchModal;

import { getEnvironmentData } from '@/utils/helpers';
import { useAppSelector } from './storeHooks';
import { selectCurrentUser } from '@/store/slices/config';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { isDevelopment } from '@/constants/env';

const useAnalytics = () => {
  const user = useAppSelector(selectCurrentUser);
  // @ts-ignore
  const emailAddress = user?.emailAddress || '';

  const init = useCallback(() => {
    // @ts-ignore
    const _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      const u = `//${process.env.NEXT_PUBLIC_ANALYTICS_URL}/`;
      _paq.push(['setTrackerUrl', `${u}matomo.php`]);
      _paq.push(['setSiteId', getEnvironmentData().analyticsSiteId]);
      _paq.push(['setUserId', emailAddress || 'anonymous']);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.src = `${u}matomo.js`;
      s.parentNode?.insertBefore(g, s);
    })();
  }, [emailAddress]);

  useEffect(() => {
    !isDevelopment && emailAddress && init();
  }, [emailAddress, init]);
};

export default useAnalytics;

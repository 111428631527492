import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsFetchingSessions,
  selectSessionInEdit,
  selectSessionsList,
} from '@/store/slices/editor';
import fetchSessionListThunk from '@/store/thunks/editor/fetchSessionList';
import { Badge, Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  SessionListContainer,
  SessionListHeaders,
  SessionListItem,
} from './styled';
import EmptyItemsPlaceholder from '@/components/EmptyItemsPlaceholder';

const ModalContent = ({
  onSelectSession,
  selectedSession,
  isLoading,
}: {
  onSelectSession: (id: string | null) => void;
  selectedSession: string | null;
  isLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const sessionsList = useAppSelector(selectSessionsList);
  const isFetchingSessions = useAppSelector(selectIsFetchingSessions);
  const activeSession = useAppSelector(selectSessionInEdit);
  const showSessionList =
    !isFetchingSessions && !isLoading && sessionsList.length > 0;
  const showEmptyItems =
    !isFetchingSessions && sessionsList.length === 0 && !isLoading;
  const showLoader = isFetchingSessions || isLoading;

  const handleClickSession = (id: string) => {
    if (selectedSession === id) {
      onSelectSession(null);
      return;
    }
    onSelectSession(id);
  };

  useEffect(() => {
    dispatch(fetchSessionListThunk());
  }, [dispatch]);

  return (
    <>
      <SessionListHeaders>
        <Typography flex={3} fontSize='0.8rem'>
          Created by
        </Typography>
        <Typography flex={3} fontSize='0.8rem'>
          Created on
        </Typography>
        <Typography flex={3} fontSize='0.8rem'>
          Last updated
        </Typography>
        <Typography flex={3} fontSize='0.8rem'>
          Last updated by
        </Typography>
      </SessionListHeaders>
      <SessionListContainer data-testid='session-list-container'>
        {showEmptyItems && (
          <EmptyItemsPlaceholder text='Please create a draft' />
        )}
        {showLoader && (
          <Box
            height='100%'
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Box>
        )}
        {showSessionList &&
          sessionsList.map((session) => {
            const isCurrentSession = session.id === activeSession?.id;
            return (
              <>
                <Badge
                  data-testid={`session-list-item-${session.id}`}
                  color='secondary'
                  badgeContent='Current draft'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  invisible={!isCurrentSession}
                  sx={{
                    '.MuiBadge-badge': {
                      marginLeft: '30px',
                    },
                  }}
                  overlap='rectangular'
                >
                  <div style={{ width: '100%' }}>
                    <SessionListItem
                      tabIndex={0}
                      onClick={() => handleClickSession(session.id)}
                      selected={selectedSession === session.id}
                      isCurrentSession={isCurrentSession}
                      key={session.id}
                    >
                      <Box data-testid='session-created-by' flex={3}>
                        {session.createdBy || '-'}
                      </Box>
                      <Box data-testid='session-created-at' flex={3}>
                        {session.createdAt}
                      </Box>
                      <Box flex={3}>{session.lastUpdatedAt}</Box>
                      <Box flex={3}>{session.lastUpdatedBy || '-'}</Box>
                    </SessionListItem>
                  </div>
                </Badge>
              </>
            );
          })}
      </SessionListContainer>
    </>
  );
};

export default ModalContent;

import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { ChipVariant } from '@s-rm/react-ui-lib';
import { ContributorStatus } from '@/store/types/admin';

export const getIsValid = (
  formValues: Record<string, any>,
  requiredFields: string[]
) => {
  return requiredFields.every(
    (field) => !!formValues[field]?.toString().trim()
  );
};

export const getPercentageChipData = (percentage: number) => {
  if (percentage >= 90) {
    return {
      colorVariant: ChipVariant.Success,
      icon: CheckCircleOutlineOutlinedIcon,
    };
  } else if (percentage <= 40) {
    return {
      colorVariant: ChipVariant.Error,
      icon: HourglassEmptyOutlinedIcon,
    };
  } else {
    return {
      colorVariant: ChipVariant.Warning,
      icon: HourglassEmptyOutlinedIcon,
    };
  }
};

export const getContributorStatusChipVariant = (status: string) => {
  switch (status) {
    case ContributorStatus.Active:
      return ChipVariant.Success;
    case ContributorStatus.Suspended:
      return ChipVariant.Error;
    case ContributorStatus.Invited:
      return ChipVariant.Neutral;
    default:
      return ChipVariant.Neutral;
  }
};

export const getIncidentStatusChipVariant = (status: string) => {
  switch (status) {
    case 'Active':
      return ChipVariant.Success;
    case 'Created':
      return ChipVariant.Primary;
    default:
      return ChipVariant.Warning;
  }
};

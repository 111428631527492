import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Box, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectActiveParentTreeView,
  selectCaseTypeInEdit,
  setActiveParentTreeView,
} from '@/store/slices/editor';

import { DialogueTitleContainer, StyledDialogue } from '../styled';
import AdviceTreeViewContent from './ModalContent/AdviceTreeViewContent';
import QuestionOrTipTreeViewContent from './ModalContent/QuestionOrTipTreeViewContent';
import { EntityType } from '@/store/types/basicTypes';
import ModuleToCaseTypeTreeViewContent from './ModalContent/ModuleToCaseTypeTreeViewContent';
import IndicatorTreeViewContent from './ModalContent/IndicatorTreeViewContent';
import { pluraliseString } from '@/utils/helpers';
import EditorDialog, { EditorDialogTitle } from '../EditorDialog/EditorDialog';

const ParentTreeViewModal = ({ isOpen }: { isOpen: boolean }) => {
  const { entityType } = useAppSelector(selectActiveParentTreeView);
  const caseTypeInEdit = useAppSelector(selectCaseTypeInEdit);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setActiveParentTreeView({ entityId: null, entityType: null }));
  };

  const ModalContent = () => {
    switch (entityType) {
      case EntityType.Advice:
        return <AdviceTreeViewContent />;
      case EntityType.Tip:
        return <QuestionOrTipTreeViewContent />;
      case EntityType.Question:
        return <QuestionOrTipTreeViewContent />;
      case EntityType.Module:
        return <ModuleToCaseTypeTreeViewContent />;
      case EntityType.Indicator:
        return <IndicatorTreeViewContent />;
      default:
        return <></>;
    }
  };

  return (
    <EditorDialog open={isOpen} maxWidth='sm' fullWidth>
      <EditorDialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Linked {pluraliseString(entityType as string)}
          </Typography>
          <Button onClick={handleClose} data-testid='btn-close-tree-modal'>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent>
        {isOpen && (
          <Box data-testid='parent-tree-modal-content'>
            <ModalContent />
          </Box>
        )}
      </DialogContent>
    </EditorDialog>
  );
};

export default ParentTreeViewModal;

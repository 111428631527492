import { useRouter } from 'next/router';
import { SidebarNavigation } from '@s-rm/react-ui-lib';
import { StyledListSubheader } from './styled';
import { IconButton } from '@mui/material';
import logo from '../../assets/img/polus-response.png';
import { sidebarNavigationData } from './data';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import Image from 'next/image';
import { getRootPathFromRouter } from '@/utils/router';

const Sidebar = () => {
  const router = useRouter();
  const path = getRootPathFromRouter(router);
  const handleClick = (to: string) => {
    router.push(to);
  };

  return (
    <SidebarNavigation
      currentPath={`/${path}`}
      navigationData={sidebarNavigationData}
      onClick={handleClick}
      containerSx={{
        backgroundColor: 'grey.50',
      }}
      subheader={
        <StyledListSubheader>
          <IconButton>
            <AppsOutlinedIcon />
          </IconButton>
          <Image src={logo} alt='asm-logo' height={20} />
        </StyledListSubheader>
      }
    />
  );
};

export default Sidebar;

import { CaseTypeId, EntityType, ModuleId, QuestionId } from './basicTypes';

export const Roles = [
  'DFIR', // Digital Forensics and Incident Response
  'LEGAL',
  'TA_ENGAGEMENT_SPECIALIST', // Threat actor engagement specialist
  'CLAIMS_TEAM',
] as const;

export type ModuleRoles = (typeof Roles)[number];

export const ModuleRoleLabels: { [key in ModuleRoles]: string } = {
  DFIR: 'Digital Forensics & Incident Response',
  LEGAL: 'Legal',
  TA_ENGAGEMENT_SPECIALIST: 'TA Engagement Specialist',
  CLAIMS_TEAM: 'Claims Team',
};

export interface Module {
  id: ModuleId;
  label: string;
  description: string;
  questions: QuestionId[];
  entityType: EntityType.Module;
  isSessionOnly: boolean;
  roles: ModuleRoles[];
  text?: string;
}

export interface ModuleGroup {
  id: string;
  label: string;
  moduleIds: ModuleId[];
  priorityIndex: string;
  isSessionOnly: boolean;
}

export interface ModuleSlice {
  entities: {
    [key: string]: Module;
  };
  ids: ModuleId[];
  selected: ModuleId[];
  manuallySelected: ModuleId[];
  selectedByTrigger: ModuleSelectedByTrigger[];
  groups: {
    entities: {
      [key: string]: ModuleGroup;
    };
    ids: string[];
  };
  staticModuleIds: ModuleId[];
}

export interface ModuleSelectedByTrigger {
  relatedCaseTypeId: CaseTypeId;
  moduleId: ModuleId;
}

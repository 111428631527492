import { styled } from '@mui/material';

export const SessionListContainer = styled('div')`
  height: 500px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const SessionListItem = styled('div')<{
  selected: boolean;
  isCurrentSession: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
  background: ${({ isCurrentSession }) =>
    isCurrentSession ? '#f7f7f7' : '#f6faff'};
  transition: ${({ theme }) => theme.transitions.create('background')};
  cursor: pointer;
  &:hover {
    ${({ selected }) => !selected && `background:  #e8f4ff`}
  }

  ${({ selected, theme }) =>
    selected && `background: ${theme.palette.primary.main};`}
  ${({ selected, theme }) =>
    selected && `color: ${theme.palette.primary.contrastText};`}
`;

export const SessionListHeaders = styled('div')`
  display: flex;
  align-items: center;

  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(2)};
`;

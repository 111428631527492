import { EditorSession } from '@/store/types/editor';
import { getformattedTimestamp } from '@/utils/helpers';

interface GetSessionListTransformParams {
  listEditorSessions: {
    id: string;
    createdAt: string;
    lastUpdatedAt: string;
    lastUpdatedBy: {
      Name: string;
    } | null;
    createdBy: {
      Name: string;
    } | null;
  }[];
}

const getSessionListTransform = (
  data: GetSessionListTransformParams
): {
  entities: {
    [key: string]: EditorSession;
  };
  ids: string[];
} => {
  return {
    entities: data.listEditorSessions.reduce((acc, session) => {
      const { id, createdAt, lastUpdatedAt, lastUpdatedBy, createdBy } =
        session;
      return {
        ...acc,
        [id]: {
          id,
          lastUpdatedAt: getformattedTimestamp(lastUpdatedAt),
          createdAt: getformattedTimestamp(createdAt),
          lastUpdatedBy: lastUpdatedBy?.Name,
          createdBy: createdBy?.Name,
        },
      };
    }, {}),
    ids: data.listEditorSessions.map((session) => session.id),
  };
};

export default getSessionListTransform;

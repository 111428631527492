import { AppThunk } from '@/store/store';
import {
  setActiveEntitySearchList,
  setCaseTypeInEdit,
  setIsDirty,
} from '@/store/slices/editor';
import createCaseType from '@/api/mutations/editor/createCaseType';
import { addCaseType } from '@/store/slices/caseTypes';
import { createError } from '@/utils/errorHandler';
import { ErrorCodes } from '@/constants/errors';

const createCaseTypeThunk = (): AppThunk => async (dispatch) => {
  const { data } = await createCaseType();
  dispatch(setIsDirty(true));
  dispatch(setCaseTypeInEdit({ id: data.id, isNew: true }));
  dispatch(
    addCaseType({
      ...data,
      isSessionOnly: true,
    })
  );
  dispatch(setActiveEntitySearchList(null));
};

export default createCaseTypeThunk;

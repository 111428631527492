import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';
import getTip from '@/api/queries/getTip';
import {
  removeEntityFromSession,
  removeEntityModifications,
  removeOutdatedEntities,
} from '@/store/slices/editor';
import { updateTip } from '@/store/slices/tips';
import { AppThunk } from '@/store/store';
import { TipId } from '@/store/types/basicTypes';

const resetTipThunk =
  (tipId: TipId): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await getTip({ tipId });
      dispatch(updateTip({ id: tipId, updates: data }));
      resetEditorSessionChange(tipId);
      dispatch(
        removeEntityFromSession({
          entityType: 'tips',
          entityId: tipId,
        })
      );
      dispatch(
        removeEntityModifications({
          entityType: 'tips',
          entityId: tipId,
        })
      );
      dispatch(
        removeOutdatedEntities({
          entityType: 'tips',
          entityId: tipId,
        })
      );
    } catch (e) {
      // createError({ error: e, code: ErrorCodes.ArchiveAdvice });
      return;
    }
  };

export default resetTipThunk;

import { SessionData } from '@/api/types/initialData';

const getSessionEntities = (sessionData: {
  fetchEditorSession: SessionData;
}) => {
  if (!sessionData) return null;
  const { edited } = sessionData.fetchEditorSession;
  return {
    caseTypes:
      edited.caseTypes?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    modules:
      edited.modules?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    questions:
      edited.questions?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    tips:
      edited.tips?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    advice:
      edited.advice?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    indicators:
      edited.indicators?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
    moduleGroups:
      edited.moduleGroups?.map(
        (entity) => entity.to ?? { id: entity.id, archived: true }
      ) || [],
  };
};

export default getSessionEntities;

import { Advice } from './advice';
import { CaseType } from './caseType';
import { Indicator } from './indicator';
import { Module } from './module';
import { Question } from './question';
import { Tip } from './tip';

export type ModuleId = string;
export type ModuleGroupId = string;
export type QuestionId = string;
export type TipId = string;
export type AdviceId = string;
export type CaseTypeId = string;
export type IndicatorId = string;
export type StateEntities =
  | CaseType
  | Module
  | Question
  | Tip
  | Indicator
  | Advice;
export interface EntityStore {
  [key: string]: StateEntities;
}

export enum EntityType {
  Question = 'QUESTION',
  Tip = 'TIP',
  Advice = 'ADVICE',
  Module = 'MODULE',
  CaseType = 'CASE_TYPE',
  ModuleGroup = 'MODULE_GROUP',
  Indicator = 'INDICATOR',
}

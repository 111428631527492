import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsConfirmingReopeningQuestionnaire,
  setIsConfirmingReopenQuestionnaire,
} from '@/store/slices/ui';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';

const ReopenQuestionnaireModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsConfirmingReopeningQuestionnaire);
  const handleClose = () => dispatch(setIsConfirmingReopenQuestionnaire(false));

  const handleReopenCase = () => {
    dispatch(setIsConfirmingReopenQuestionnaire(false));
    dispatch(
      userActionInterceptor({ actions: [], shouldContinueIntake: true })
    );
    dispatch(
      updateSnackbarNotification({
        message: 'The intake has been re-opened',
        type: SnackbarTypes.Success,
      })
    );
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title='Re-open intake'
      confirmText='Yes, continue'
      onConfirm={handleReopenCase}
      onClose={handleClose}
      testId='reopen-intake-confirmation-modal'
    >
      <Alert severity='warning' data-testid='reopoen-intake-warning'>
        <AlertTitle>Warning</AlertTitle>
        <Typography>You are about to re-open the intake</Typography>
      </Alert>
    </ConfirmationModal>
  );
};

export default ReopenQuestionnaireModal;

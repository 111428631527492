import { styled } from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HTMLRender } from '../HTMLContent/HTMLRender';

export const SummaryContentWrapper = styled('div')`
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const DescriptionWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const DescriptionBox = styled(HTMLRender)`
  height: 150px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  overflow-y: auto;
`;

export const SectionTitle = styled('h4')`
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

export const SectionBox = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const SectionTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ModuleContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ModuleSummary = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ModuleSummaryHeader = styled('div')`
  font-size: 1.2rem;
  font-weight: 600;
  border-left: 4px solid ${({ theme }) => theme.palette.secondary.main};
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const AdviceText = styled(HTMLRender)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const AdviceBreak = styled('div')`
  width: 95%;
  margin: ${({ theme }) => theme.spacing(2)} auto;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const AnswerTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AnswerTitle = styled('div')`
  font-weight: 600;
`;

export const AdviceTitle = styled('div')`
  font-weight: 600;
`;

export const ToggleButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main} !important;
  font-weight: 600 !important;
  text-transform: none !important;
`;

export const ExpandIcon = styled(ExpandMoreIcon)<{ isExpanded: boolean }>`
  transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : '0deg')});
`;

export const SingleModuleToggleButton = styled(ToggleButton)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const QuestionAnswerSection = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[100]};
`;

export const QuestionAnswerSectionContainer = styled('div')<{
  column: boolean;
}>`
  padding: ${({ theme }) => theme.spacing(1.5)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  display: flex;
  justify-content: space-between;
  align-items: ${({ column }) => (column ? 'start' : 'center')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

export const TextFieldWrapper = styled(HTMLRender)`
  display: flex;
  align-items: center;
`;

export const QuestionLabel = styled(HTMLRender)`
  font-weight: 600;
  flex: 4;
`;

export const RadiosWrapper = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const ModuleHeaderPreview = styled('h4')`
  font-size: 1rem;
  font-family: 'Roboto';
`;

import client from '@/api/client';
//@ts-ignore
import { CreateTip } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import createTipTransform from '@/api/transforms/editor/createTipTransform';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const createTip = async () => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: CreateTip,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        text: '',
        label: '',
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateTip,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: createTipTransform(data) };
};

export default createTip;

export const incident1Mock = {
  ID: '1',
  Label: 'AMN Drunen - Project Alpha 1',
  CreatedAt: '2024-01-01T00:00:00Z',
  ClosedAt: '2024-09-01T00:00:00Z',
  ManagerOrgId: 'Bundesamt für Bevölkerungsschutz und Katastrophenhilfe',
  ManagerOrgPrimaryContactId: 'John Doe',
  Status: 'ACTIVE',
  InsurerId: 'Institut für Europäische Sicherheits',
  InsurerPrimaryContactId: 'Jane Smith',
  Role: 'PrimaryContact',
  CaseTypes: ['Intake', 'Closure'],
  CompletionRate: '100%',
};

export const incident2Mock = {
  ID: '2',
  Label: 'AMN Drunen - Project Alpha 2',
  CreatedAt: '2024-01-01T00:00:00Z',
  ClosedAt: '2024-09-01T00:00:00Z',
  ManagerOrgId: 'Bundesamt für Bevölkerungsschutz und Katastrophenhilfe',
  ManagerOrgPrimaryContactId: 'John Doe',
  Status: 'ACTIVE',
  InsurerId: 'Institut für Europäische Sicherheits',
  InsurerPrimaryContactId: 'Bob White',
  Role: 'PrimaryContact',
  CaseTypes: ['Intake', 'Closure'],
  CompletionRate: '100%',
};

export const incident3Mock = {
  id: '3',
  label: 'AMN Drunen - Project Alpha 3',
  createdAt: '2024-01-01T00:00:00Z',
  closedAt: '2024-09-01T00:00:00Z',
  managerOrgId: 'Bundesamt für Bevölkerungsschutz und Katastrophenhilfe',
  managerOrgPrimaryContactId: 'John Doe',
  status: 'ACTIVE',
  insurerId: 'Institut für Europäische Sicherheits',
  insurerPrimaryContactId: 'Bob White',
  role: 'PrimaryContact',
  caseTypes: ['Intake', 'Closure'],
  completionRate: '100%',
};

export const incident4Mock = {
  id: '4',
  title: 'AMN Drunen - Project Alpha 4',
  createdAt: '2024-01-01T00:00:00Z',
  closedAt: '2024-09-01T00:00:00Z',
  managerOrgId: 'Bundesamt für Bevölkerungsschutz und Katastrophenhilfe',
  managerOrgPrimaryContactId: 'John Doe',
  status: 'ACTIVE',
  insurerId: 'Institut für Europäische Sicherheits',
  insurerPrimaryContactId: 'Bob White',
  role: 'PrimaryContact',
  caseTypes: ['Intake', 'Closure'],
  completionRate: '100%',
};

export const projectsMock = {
  ids: [incident3Mock.id],
  entities: { [incident3Mock.id]: incident3Mock },
  filters: {
    pagination: {
      offset: 0,
      limit: 10,
      total: 12,
      hasNext: true,
      hasPrev: false,
      page: 0,
      pages: 1,
    },
    filters: [],
    sort: {
      column: '',
      direction: '',
    },
    searches: [],
  },
  isLoading: false,
};

import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectQuestionInEdit } from '@/store/slices/editor';
import { selectQuestion } from '@/store/slices/questions';
import { Box } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button, TextField } from '@s-rm/react-ui-lib';
import { RadioOptionInputWrapper, RadioOptionLineItem } from './styled';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import updateQuestionThunk from '@/store/thunks/editor/updateQuestion';
import QuestionTriggerMenu from '../../AddTriggerMenu/AddTriggerMenu';
import { EntityType, QuestionId } from '@/store/types/basicTypes';
import TriggerConfig from './EditTriggers';
import { Label } from '../../styled';
import { getuuid } from '@/utils/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { reorderList } from '../../utils/helpers';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { ControlTypes } from '@/store/types/question';

const MultiChoiceOptions = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectQuestionInEdit);
  const questionInEdit = id as QuestionId;
  const question = useAppSelector((state) =>
    selectQuestion(state, questionInEdit)
  );

  const handleAddOption = () => {
    dispatch(
      updateQuestionThunk({
        id: questionInEdit,
        updates: {
          options: [
            ...question.options,
            { label: '', value: getuuid(), id: getuuid() },
          ],
        },
      })
    );
  };

  const handleDeleteOption = (optionValue: string) => {
    dispatch(
      updateQuestionThunk({
        id: questionInEdit,
        updates: {
          options: question.options.filter((o) => optionValue !== o.value),
        },
      })
    );
  };

  const handleChangeOption = (optionValue: string, label: string) => {
    dispatch(
      updateQuestionThunk({
        id: questionInEdit,
        updates: {
          options: question.options.map((option) => {
            if (option.value === optionValue) {
              return { ...option, label };
            }
            return option;
          }),
        },
        shouldDebounce: true,
      })
    );
  };

  const handleDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    dispatch(
      updateQuestionThunk({
        id: questionInEdit,
        updates: {
          options: reorderList(
            question.options,
            result.source.index,
            result.destination.index
          ),
        },
      })
    );
  };

  const getOptionIcon = () => {
    switch (question.control) {
      case ControlTypes.Radio:
        return <RadioButtonCheckedOutlinedIcon />;
      case ControlTypes.MultiSelectSearch:
        return <CheckBoxOutlinedIcon />;
      case ControlTypes.MultiSelect:
        return <CheckBoxOutlinedIcon />;
    }
  };

  const DragItemClone = ({ option }: { option: any; index: number }) => {
    return (
      <RadioOptionLineItem>
        <div>
          <DragIndicatorIcon />
        </div>
        <div>
          <Label required htmlFor='my-input'>
            Option Label
          </Label>
          <RadioOptionInputWrapper>
            {getOptionIcon()}
            <Box ml={1} mr={1} width='100%'>
              <TextField fullWidth size='small' value={option.label} />
            </Box>
            <Button square>
              <DeleteOutlineOutlinedIcon />
            </Button>
          </RadioOptionInputWrapper>
          <Box mt={2} display='flex'>
            <QuestionTriggerMenu
              triggerComponentType={EntityType.Module}
              triggerValue={option.value}
            />
            <QuestionTriggerMenu
              triggerComponentType={EntityType.Question}
              triggerValue={option.value}
            />
            <QuestionTriggerMenu
              triggerComponentType={EntityType.Tip}
              triggerValue={option.value}
            />
          </Box>
          <TriggerConfig questionId={questionInEdit} optionId={option.id} />
        </div>
      </RadioOptionLineItem>
    );
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId='droppable'
          renderClone={(provided, snapshot, rubric) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <DragItemClone
                index={rubric.source.index}
                option={question.options[rubric.source.index]}
              />
            </div>
          )}
        >
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {question.options.map((option, index) => {
                return (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(draggableProvided) => {
                      return (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            padding: '10px 0 10px 0',
                          }}
                        >
                          <RadioOptionLineItem>
                            <div {...draggableProvided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </div>
                            <div>
                              <Label required htmlFor='my-input'>
                                Option Label
                              </Label>
                              <RadioOptionInputWrapper>
                                {getOptionIcon()}
                                <Box ml={1} mr={1} width='100%'>
                                  <TextField
                                    fullWidth
                                    size='small'
                                    value={option.label}
                                    onChange={(e) =>
                                      handleChangeOption(
                                        option.value,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Box>
                                <Button
                                  data-testid={`btn-delete-radio-option-${index}`}
                                  square
                                  onClick={() =>
                                    handleDeleteOption(option.value)
                                  }
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </Button>
                              </RadioOptionInputWrapper>
                              <Box mt={2} display='flex'>
                                <QuestionTriggerMenu
                                  triggerComponentType={EntityType.Module}
                                  triggerValue={option.value}
                                />
                                <QuestionTriggerMenu
                                  triggerComponentType={EntityType.Question}
                                  triggerValue={option.value}
                                />
                                <QuestionTriggerMenu
                                  triggerComponentType={EntityType.Tip}
                                  triggerValue={option.value}
                                />
                              </Box>
                              <TriggerConfig
                                questionId={questionInEdit}
                                optionId={option.id}
                              />
                            </div>
                          </RadioOptionLineItem>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Box m={3}>
        <Button onClick={handleAddOption} startIcon={<AddOutlinedIcon />}>
          Add option
        </Button>
      </Box>
    </>
  );
};

export default MultiChoiceOptions;

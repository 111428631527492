import { addSelectedModules } from '../slices/modules';
import { AppThunk } from '../store';
import initModuleOnQuestionDisplayOrderThunk from './initModuleOnQuestionDisplayOrder';
import runTriggersThunk from './runTriggers';
import { ModuleId } from '../types/basicTypes';

const addSelectedModulesThunk =
  (moduleIds: ModuleId[]): AppThunk =>
  (dispatch) => {
    dispatch(addSelectedModules({ moduleIds }));
    dispatch(initModuleOnQuestionDisplayOrderThunk(moduleIds));
    dispatch(runTriggersThunk(moduleIds));
  };

export default addSelectedModulesThunk;

import { Badge, Box, Menu, MenuItem, Tooltip, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import StyledFAB from './styled';
import { useState } from 'react';
import { EntityType } from '@/store/types/basicTypes';
import {
  selectEntityModifications,
  selectOutdatedEntities,
  setActiveEntitySearchList,
} from '@/store/slices/editor';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { ModifiedBadge, SecondaryBadge } from '../styled';

const EditMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const entityModifications = useAppSelector(selectEntityModifications);
  const outdatedEntities = useAppSelector(selectOutdatedEntities);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = (entityType: EntityType) => {
    dispatch(setActiveEntitySearchList(entityType));
    handleClose();
  };

  const getNumberOfModifications = (entityType: string) => {
    return Object.values(entityModifications?.[entityType] || []).filter(
      (entityMods) => entityMods.length
    ).length;
  };

  const getNumberOfOutdatedEntities = (entityType: string) => {
    return Object.values(outdatedEntities?.[entityType] || []).filter(
      (outdatedFields) => outdatedFields.length
    ).length;
  };

  const getAnyModifications = (entityTypes: string[]) => {
    return entityTypes.some((entityType) => {
      const entityModsArr = entityModifications?.[entityType] || [];
      return Object.values(entityModsArr).some(
        (entityMods) => entityMods.length > 0
      );
    });
  };

  const getAnyOutdatedEntities = (entityTypes: string[]) => {
    return entityTypes.some((entityType) => {
      const outdatedFieldsArr = outdatedEntities?.[entityType] || [];
      return Object.values(outdatedFieldsArr).some(
        (outdatedFields) => outdatedFields.length > 0
      );
    });
  };

  const entityTypes = [
    'advice',
    'caseTypes',
    'moduleGroups',
    'modules',
    'questions',
    'tips',
  ];

  return (
    <>
      <StyledFAB
        onClick={handleClick}
        color='primary'
        aria-label='Edit components'
        data-testid='btn-editor-entity-menu'
      >
        <Box mt='-50px' mr='-40px' position='absolute' display='flex' gap='1px'>
          {getAnyModifications(entityTypes) && (
            <Box
              width='12px'
              height='12px'
              borderRadius='100%'
              bgcolor='secondary.main'
            />
          )}
          {getAnyOutdatedEntities(entityTypes) && (
            <Box
              width='12px'
              height='12px'
              borderRadius='100%'
              bgcolor='#bf66a9'
            />
          )}
        </Box>
        <EditIcon />
      </StyledFAB>
      <Menu
        sx={{ transform: 'translateY(-10px)' }}
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          //@ts-ignore
          'data-testid': 'editor-menu-paper',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          style={{ minWidth: 300 }}
          onClick={() => handleClickEdit(EntityType.CaseType)}
        >
          Case Types
          <Tooltip title='Current draft contains changes to this case type'>
            <Badge
              badgeContent={getNumberOfModifications('caseTypes')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This case type is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('caseTypes') ? 'outdated' : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(EntityType.ModuleGroup)}>
          Module groups
          <Tooltip title='Current draft contains changes to this module group'>
            <Badge
              badgeContent={getNumberOfModifications('moduleGroups')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This module group is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('moduleGroups')
                  ? 'outdated'
                  : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(EntityType.Module)}>
          Modules
          <Tooltip title='Current draft contains changes to this module'>
            <Badge
              badgeContent={getNumberOfModifications('modules')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This module is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('modules') ? 'outdated' : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(EntityType.Question)}>
          Questions
          <Tooltip title='Current draft contains changes to this question'>
            <Badge
              badgeContent={getNumberOfModifications('questions')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This tip is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('questions') ? 'outdated' : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(EntityType.Tip)}>
          Tips
          <Tooltip title='Current draft contains changes to this tip'>
            <Badge
              badgeContent={getNumberOfModifications('tips')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This tip is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('tips') ? 'outdated' : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(EntityType.Advice)}>
          Advice
          <Tooltip title='Current draft contains changes to this advice'>
            <Badge
              badgeContent={getNumberOfModifications('advice')}
              color='secondary'
            >
              &nbsp;
            </Badge>
          </Tooltip>
          <Tooltip title='This advice is out of date with the latest published draft'>
            <SecondaryBadge
              badgeContent={
                !!getNumberOfOutdatedEntities('advice') ? 'outdated' : null
              }
            >
              &nbsp;
            </SecondaryBadge>
          </Tooltip>
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditMenu;

import { styled } from '@mui/material';

export const RadioOptionLineItem = styled('div')`
  background: ${({ theme }) => theme.palette.grey[200]};
  padding: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  display: flex;

  & > div:nth-child(1) {
    flex: 0.6;
  }

  & > div:nth-child(2) {
    flex: 11.4;
  }
`;

export const RadioOptionInputWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)};

  svg {
    fill: ${({ theme }) => theme.palette.grey[600]};
  }
`;

export const TriggerConfigWrapper = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.2)};
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const TriggerConfigActions = styled('div')`
  margin-left: auto;
  svg {
    fill: ${({ theme }) => theme.palette.grey[600]};
  }
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

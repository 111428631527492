import { Filters } from '../types/admin';
import { getDistinctEntries } from './getDistinctEntries';

export interface UpdateFilterPayload {
  pagination?: Partial<Filters['pagination']>;
  filters?: Filters['filters'];
  sort?: Partial<Filters['sort']>;
  searches?: Filters['searches'];
  override?: boolean;
}

export const updateFilters = (
  existingFilters: Filters,
  newFilters: UpdateFilterPayload
): Filters => {
  const {
    pagination = {},
    filters,
    sort = {},
    searches = [],
    override = false,
  } = newFilters;

  const updatedPagination = {
    ...existingFilters.pagination,
    ...pagination,
  };

  // Reset pagination offset if limit changes
  if (
    pagination.limit !== undefined &&
    pagination.limit !== existingFilters.pagination.limit
  ) {
    updatedPagination.offset = 0;
  }

  // Reset pagination offset if searches are updated
  if (searches.length) {
    updatedPagination.offset = 0;
  }

  const updatedFilters = filters
    ? getDistinctEntries([...existingFilters.filters, ...filters], 'column')
    : existingFilters.filters;

  const updatedSearches = override
    ? searches
    : [...existingFilters.searches, ...searches];

  return {
    pagination: updatedPagination,
    filters: override && !!filters ? filters : updatedFilters,
    sort: {
      ...existingFilters.sort,
      ...sort,
    },
    searches: updatedSearches,
  };
};

import { ControlTypes, Question } from '@/store/types/question';
import Radios from '../Question/questionControls/Radio/Radio';
import { RadiosWrapper, TextFieldWrapper } from './styled';
import { useAppSelector } from '@/hooks';
import { selectQuestionValues } from '@/store/slices/questions';
import getRadioOptions from './utils/getRadioOptions';
import { format } from 'date-fns';
import MultiSelect from '../Question/questionControls/MultiSelect/MultiSelect';
import { getFormattedDate } from '@/utils/helpers';
import { Typography } from '@mui/material';

interface AnswerControlProps {
  question: Question;
  getAsPrintView?: boolean;
}

const AnswerControl = ({ question, getAsPrintView }: AnswerControlProps) => {
  const questionValues = useAppSelector(selectQuestionValues);
  const radioOptions = getRadioOptions({ question, questionValues });

  const isTextArea = question.control === ControlTypes.Textarea;
  const isRadio = question.control === ControlTypes.Radio;
  const isNumeric = question.control === ControlTypes.Numeric;
  const isDateRange = question.control === ControlTypes.Daterange;
  const isMultiSelect = question.control === ControlTypes.MultiSelect;
  const isMultiSelectSearch =
    question.control === ControlTypes.MultiSelectSearch;
  const isDate = question.control === ControlTypes.Datepicker;

  if (getAsPrintView) {
    if (isTextArea || isNumeric) {
      return <>{questionValues[question.id]}</>;
    }
    if (isRadio) {
      return (
        <>
          {radioOptions.find((o) => o.value === questionValues[question.id])
            ?.label ?? ''}
        </>
      );
    }
  }

  if (isTextArea || isNumeric) {
    return <TextFieldWrapper content={questionValues[question.id] as string} />;
  }

  if (isDateRange) {
    const dateRange = questionValues[question.id];
    const [from, to] = (dateRange as string).split(',') || '';
    if (!from || !to) return null;
    return (
      <TextFieldWrapper
        content={`<b>FROM</b>: ${format(
          new Date(from),
          'dd/MM/yyyy'
        )} - <b>TO:</b> ${format(new Date(to), 'dd/MM/yyyy')}`}
      />
    );
  }

  if (isRadio) {
    return (
      <RadiosWrapper>
        <Radios
          onChange={() => {}}
          questionId={`${question.id}-summary`}
          data-testid={`${question.id}-summary`}
          options={radioOptions}
          controlledValue={questionValues[question.id] as string}
          hideSuggestedValue={true}
        />
      </RadiosWrapper>
    );
  }

  if (isMultiSelect || isMultiSelectSearch) {
    return (
      <MultiSelect
        onChange={() => {}}
        questionId={`${question.id}-summary`}
        data-testid={`${question.id}-summary`}
        options={question.options.filter((o) =>
          questionValues[question.id].includes(o.value)
        )}
        controlledValue={questionValues[question.id] as string}
        hideSuggestedValue={true}
      />
    );
  }

  if (isDate) {
    return (
      <Typography>
        {getFormattedDate(questionValues[question.id] as string)}
      </Typography>
    );
  }

  return null;
};

export default AnswerControl;

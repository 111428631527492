import styled from 'styled-components';

export const StyledTruncatedTextField = styled.div<{
  truncate?: boolean;
  noWrap?: boolean;
}>`
  white-space: ${({ truncate, noWrap }) =>
    truncate || noWrap ? 'nowrap' : 'normal'};
  overflow: ${({ truncate }) => (truncate ? 'hidden' : 'visible')};
  text-overflow: ${({ truncate }) => (truncate ? 'ellipsis' : 'clip')};
  display: inline-block;
  max-width: 100%;
  flex-shrink: 1;
`;

export const getDistinctEntries = <T extends Record<string, string | string[]>>(
  array: T[],
  key?: keyof T
): T[] => {
  if (!array.length) return [];
  const objKey = key ?? (Object.keys(array[0])?.[0] as keyof T) ?? '';
  return Object.values(
    array.reduce((acc: Record<string, T>, item: T) => {
      acc[item[objKey] as string] = item;
      return acc;
    }, {})
  );
};

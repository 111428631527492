export const integration1Mock = {
  id: 'integration-1',
  name: 'S1',
  description: 'This is the integration for S1',
};

export const integration2Mock = {
  id: 'integration-2',
  name: 'S2',
  description: 'This is the integration for S2',
};

export const integrationsMock = {
  entities: {
    [integration1Mock.id]: integration1Mock,
    [integration2Mock.id]: integration2Mock,
  },
  ids: [integration1Mock.id, integration2Mock.id],
};

import { Advice } from '@/store/types/advice';
import { EntityType } from '@/store/types/basicTypes';

const createAdviceTransform = (data: any): Advice => {
  const {
    createAdvice: { id },
  } = data;
  return {
    entityType: EntityType.Advice,
    id: id,
    label: '',
    text: '',
    isSessionOnly: true,
  };
};

export default createAdviceTransform;

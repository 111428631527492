'use client';

import { StyledTruncatedTextField } from './styled';

interface TruncatedTextFieldProps {
  value: string;
  style?: React.CSSProperties;
  truncate?: boolean;
  noWrap?: boolean;
}

const TruncatedTextField: React.FC<TruncatedTextFieldProps> = ({
  value,
  style,
  truncate = false,
  noWrap = false,
}) => {
  return (
    //@ts-ignore
    <StyledTruncatedTextField
      style={style}
      truncate={truncate}
      noWrap={noWrap}
      title={value}
    >
      {value}
    </StyledTruncatedTextField>
  );
};

export default TruncatedTextField;

import { Dialog, styled } from '@mui/material';

export const SidebarWrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  padding: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const TopSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
`;

export const Item = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1)}; 0;
`;

export const DialogWrapper = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));

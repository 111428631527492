import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Box, CircularProgress, ListItemText } from '@mui/material';
import {
  ListContainer,
  ListItem,
  ListItemButton,
  SelectionHeader,
  SelectionTitle,
} from './styled';
import { Button, Checkbox } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectQuestion,
  selectQuestionEntitiesAsArray,
} from '@/store/slices/questions';
import SearchInput from '@/components/SearchInput';
import Popover from '@/components/Popover';
import { EntityType, QuestionId } from '@/store/types/basicTypes';
import {
  selectQuestionInEdit,
  setActiveParentTreeView,
} from '@/store/slices/editor';
import updateQuestionThunk from '@/store/thunks/editor/updateQuestion';
import { Question, QuestionTriggerActions } from '@/store/types/question';
import { getuuid, removeAllTags } from '@/utils/helpers';
import TextSwitch from '@/components/Switch';
import { selectAllModulesAsArray } from '@/store/slices/modules';
import { selectAllTipsAsArray } from '@/store/slices/tips';
import getTypeSpecificData from './utils/getTypeSpecificData';
import useSearch from '@/hooks/useSearch';
import { Module } from '@/store/types/module';
import { Tip } from '@/store/types/tip';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useState } from 'react';
import addTriggerToLastOfType from './utils/addTriggerToLastOfType';
import isEntityAlreadyLinked from './utils/isEntityAlreadyLinked';

interface QuestionTriggerMenuProps {
  triggerValue: string;
  triggerComponentType: EntityType;
}

const QuestionTriggerMenu = ({
  triggerValue,
  triggerComponentType,
}: QuestionTriggerMenuProps) => {
  const questions = useAppSelector(selectQuestionEntitiesAsArray);
  const modules = useAppSelector(selectAllModulesAsArray);
  const tips = useAppSelector(selectAllTipsAsArray);
  const { id } = useAppSelector(selectQuestionInEdit);
  const questionIdInEdit = id as QuestionId;
  const questionInEdit = useAppSelector((state) =>
    selectQuestion(state, questionIdInEdit)
  );
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const { triggerableEntities, buttonText, titleText, searchKey } =
    getTypeSpecificData({
      triggerComponentType,
      modules,
      tips,
      questions,
      questionInEdit: id!,
    });

  const { searchResults, setSearchTerm, searchTerm } = useSearch<
    Question | Module | Tip
  >({
    searchableEntities: triggerableEntities,
    searchKeys: [searchKey],
    onSearchEnd: () => {
      setIsLoading(false);
    },
  });

  const handleChangeSelection = ({
    id,
    isSelected,
  }: {
    id: QuestionId;
    isSelected: boolean;
  }) => {
    const updatedTriggers = addTriggerToLastOfType(questionInEdit.triggers, {
      componentId: id,
      componentType: triggerComponentType,
      action: QuestionTriggerActions.Show,
      value: triggerValue,
      id: getuuid(),
    });
    dispatch(
      updateQuestionThunk({
        id: questionIdInEdit,
        updates: {
          triggers: isSelected
            ? questionInEdit.triggers.filter((t) => t.componentId !== id)
            : updatedTriggers,
        },
      })
    );
  };

  const updateTriggerAction = ({
    triggerId,
    action,
  }: {
    triggerId: string;
    action: QuestionTriggerActions;
  }) => {
    dispatch(
      updateQuestionThunk({
        id: questionIdInEdit,
        updates: {
          triggers: questionInEdit.triggers.map((t) =>
            t.id === triggerId ? { ...t, action } : t
          ),
        },
      })
    );
  };

  const handleClickParentView = ({
    id,
    entityType,
  }: {
    id: string;
    entityType: EntityType;
  }) => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType,
      })
    );
  };

  const handleClose = () => {
    setSearchTerm('');
    setIsLoading(true);
  };

  const handleOpen = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  };

  const handleDebounce = ({ isDebouncing }: { isDebouncing: boolean }) => {
    isDebouncing && setIsLoading(true);
  };

  return (
    <Popover
      onClose={handleClose}
      onOpen={handleOpen}
      Trigger={
        <Button
          startIcon={<AddOutlinedIcon />}
          sx={{ fontWeight: 600, marginRight: '10px' }}
        >
          {buttonText}
        </Button>
      }
      width={900}
    >
      <SelectionHeader>
        <SelectionTitle>{titleText}</SelectionTitle>
        <SearchInput
          value={searchTerm}
          // @ts-ignore
          onChange={(val: string) => setSearchTerm(val)}
          shouldDebounce
          onDebounce={handleDebounce}
        />
      </SelectionHeader>
      <ListContainer data-testid={`trigger-entity-list`}>
        {isLoading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <CircularProgress />
          </Box>
        ) : (
          <FixedSizeList
            height={400}
            width='100%'
            itemSize={50}
            itemCount={searchResults.length}
            overscanCount={10}
          >
            {({ index, style }: ListChildComponentProps) => {
              //@ts-ignore
              const { id, label, text, entityType } = searchResults[index];

              const foundTrigger = questionInEdit.triggers.find(
                (t) => t.componentId === id && t.value === triggerValue
              ) as Question['triggers'][0];
              const isSelected = !!foundTrigger;

              let isQuestionAlreadyLinked =
                entityType === EntityType.Question ||
                entityType === EntityType.Module
                  ? isEntityAlreadyLinked({
                      id,
                      questionInEdit,
                      questions,
                      modules,
                    })
                  : false;

              return (
                <ListItem
                  style={style}
                  data-testid={`select-module-${id}`}
                  aria-selected={isSelected}
                  key={id}
                  secondaryAction={
                    <Box display='flex' alignItems='center' gap={2}>
                      <TextSwitch
                        disabled={!isSelected}
                        checked={
                          foundTrigger?.action === QuestionTriggerActions.Show
                        }
                        onClick={() =>
                          updateTriggerAction({
                            triggerId: foundTrigger.id,
                            action:
                              foundTrigger?.action ===
                              QuestionTriggerActions.Show
                                ? QuestionTriggerActions.Hide
                                : QuestionTriggerActions.Show,
                          })
                        }
                      />
                      <Button
                        square
                        onClick={() =>
                          handleClickParentView({ id, entityType })
                        }
                      >
                        <AccountTreeOutlinedIcon />
                      </Button>
                    </Box>
                  }
                  disablePadding
                >
                  <Box ml={2}>
                    <Checkbox
                      disabled={isQuestionAlreadyLinked}
                      checked={isSelected}
                      onClick={() =>
                        handleChangeSelection({
                          id,
                          isSelected,
                        })
                      }
                    />
                  </Box>
                  <ListItemButton
                    onClick={() =>
                      handleChangeSelection({
                        id,
                        isSelected,
                      })
                    }
                    style={{
                      marginRight: 90,
                    }}
                    disabled={isQuestionAlreadyLinked}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      primary={removeAllTags(label || text!)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }}
          </FixedSizeList>
        )}
      </ListContainer>
    </Popover>
  );
};

export default QuestionTriggerMenu;

import { styled } from '@mui/material';

export const ListContainer = styled('div')`
  height: 500px;
  position: relative;
`;

export const ListItemContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const LoadingOverlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ListActions = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  svg {
    color: ${({ theme }) => theme.palette.grey[600]};
  }
`;

import { ComponentReferenceObject } from '@/store/types/question';

const getEntityParents = (
  entities: ComponentReferenceObject[],
  childId: string
): ComponentReferenceObject[] => {
  const entity = entities.find((item) => item.id === childId);
  if (!entity) return [];
  if (!entity.parentId) return [entity];

  return [entity, ...getEntityParents(entities, entity.parentId)];
};

export default getEntityParents;

import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectEntitySearchFilter,
  setActiveEntitySearchList,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../../styled';
import { EntityType, TipId } from '@/store/types/basicTypes';
import { useEffect } from 'react';
import ModalContent from '../ModalContent';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import filterTipsThunk from '@/store/thunks/editor/filterTips';
import createTipThunk from '@/store/thunks/editor/createTip';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import resetEditorSessionOrArchive from '@/store/thunks/editor/resetEditorSessionOrArchive';

const TipSearchModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { searchTerm, filterBy } = useAppSelector(selectEntitySearchFilter);

  const handleTermChange = (updatedSearchTerm: string) => {
    dispatch(
      filterTipsThunk({
        searchTerm: updatedSearchTerm,
      })
    );
  };

  const handleClose = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(refreshDisplayThunk());
  };

  const handleChangeFilterBy = (updatedFilterBy: EntityType) =>
    dispatch(
      filterTipsThunk({
        filterBy: updatedFilterBy,
      })
    );

  const handleCreateModule = () => {
    dispatch(createTipThunk());
  };

  const handleDeleteTip = (id: TipId) => {
    dispatch(resetEditorSessionOrArchive(id, filterBy!));
    handleTermChange(searchTerm!);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(filterTipsThunk({ filterBy: EntityType.Tip }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <EditorDialog open={isOpen} maxWidth='lg' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Add or edit tips
          </Typography>
          <Button onClick={handleClose} data-testid='btn-close-tip-modal'>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent>
        {isOpen && (
          <ModalContent
            onCreate={handleCreateModule}
            searchTerm={searchTerm!}
            onChangeTerm={handleTermChange}
            onChangeFilterBy={handleChangeFilterBy}
            onDelete={handleDeleteTip}
          />
        )}
      </DialogContent>
    </EditorDialog>
  );
};

export default TipSearchModal;

import { AppThunk } from '@/store/store';
import {
  setActiveEntitySearchList,
  setIsDirty,
  setModuleInEdit,
} from '@/store/slices/editor';
import createModule from '@/api/mutations/editor/createModule';
import { addModule } from '@/store/slices/modules';

const createModuleThunk = (): AppThunk => async (dispatch) => {
  const { data: newModule } = await createModule();
  dispatch(setIsDirty(true));
  dispatch(addModule({ ...newModule, isSessionOnly: true }));
  dispatch(setModuleInEdit({ id: newModule.id, isNew: true }));
  dispatch(setActiveEntitySearchList(null));
};

export default createModuleThunk;

import { useAppSelector } from '@/hooks';
import { selectActiveParentTreeView } from '@/store/slices/editor';
import {
  selectModuleEntities,
  selectModuleGroupEntities,
} from '@/store/slices/modules';
import { Box, Typography } from '@mui/material';
import EmptyItemsPlaceholder from '@/components/EmptyItemsPlaceholder';
import { selectCaseTypeEntities } from '@/store/slices/caseTypes';
import { getCaseTypeRelations } from '@/components/utils/getCaseTypeRelations';

const ModuleToCaseTypeTreeViewContent = () => {
  const { entityId } = useAppSelector(selectActiveParentTreeView);
  const moduleEntities = useAppSelector(selectModuleEntities);
  const caseTypeEntities = useAppSelector(selectCaseTypeEntities);
  const moduleGroupEntities = useAppSelector(selectModuleGroupEntities);

  const caseTypeRelations = getCaseTypeRelations({
    caseTypes: caseTypeEntities,
    indicatorIds: [],
    moduleId: entityId!,
    moduleGroups: moduleGroupEntities,
  });

  if (!caseTypeRelations.length) {
    return (
      <Box height='200px'>
        <EmptyItemsPlaceholder text='No parent items found' />
      </Box>
    );
  }
  return (
    <>
      {caseTypeRelations.map((relation) => (
        <div key={relation.caseType}>
          <Typography mb={0.5} color='grey.600' fontSize='.8rem' variant='h5'>
            Case type
          </Typography>
          <Typography mb={2}>
            {caseTypeEntities[relation.caseType].label}
          </Typography>
          {relation.moduleGroup && (
            <Box ml={2}>
              <div style={{ marginLeft: '20px' }}>
                <Typography mb={0.5} color='grey.600' fontSize='.8rem'>
                  Module group
                </Typography>
                <Typography mb={2}>
                  {moduleGroupEntities[relation.moduleGroup].label}
                </Typography>
              </div>
            </Box>
          )}
          <Box ml={4}>
            <div style={{ marginLeft: '20px' }}>
              <Typography mb={0.5} color='grey.600' fontSize='.8rem'>
                Module
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
                mb={2}
              >
                {moduleEntities[relation.module].label}
              </Typography>
            </div>
          </Box>
        </div>
      ))}
    </>
  );
};

export default ModuleToCaseTypeTreeViewContent;

import {
  removeSnackbarNotification,
  setSnackbarNotification,
} from '../slices/ui';
import { AppThunk } from '../store';
import { SnackbarTypes } from '../types/ui';

/**
 * We use a thunk + timeout between removing & setting notification because we want to
 * visually show the snackbar notification being removed before adding a new
 * one. when it just swaps content it doesn't look very good.
 */
export const updateSnackbarNotification =
  (notification: {
    message: React.ReactNode;
    action?: React.ReactNode;
    type?: SnackbarTypes;
  }): AppThunk =>
  (dispatch, getState) => {
    const hasExistingNotification = getState().ui.snackbarNotification.message;
    if (!hasExistingNotification) {
      dispatch(setSnackbarNotification(notification));
      return;
    }

    dispatch(removeSnackbarNotification());
    setTimeout(() => {
      dispatch(setSnackbarNotification(notification));
    }, 150);
  };

export default updateSnackbarNotification;

import { setDescription } from '../slices/questionnaire';
import { AppThunk } from '../store';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';
import { emptySuggestedAnswersStatus } from '../slices/ui';

const updateDescriptionThunk =
  (description: string): AppThunk =>
  (dispatch) => {
    dispatch(setDescription(description));
    dispatch(emptySuggestedAnswersStatus());
    saveData({
      type: SaveDataTypes.Case,
    });
  };

export default updateDescriptionThunk;

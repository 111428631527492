import { DialogActions, DialogContent, styled } from '@mui/material';
import { DialogTitle } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const DialogueTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AdviceEmailOpeningHeader = styled('p')`
  font-weight: 100;
`;

export const DialogTitleWrapped = styled(DialogTitle)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  background: ${({ theme }) => theme.palette.grey[100]};
  & > div {
    margin: 0 ${({ theme }) => theme.spacing(2)};
  }
`;

export const TabStyled = styled(Tab)`
  align-items: baseline;
  padding: 0 ${({ theme }) => theme.spacing(1)} 0 0;
  min-width: 55px;
  &:last-child {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const TabsStyled = styled(Tabs)`
  .MuiTabs-indicator {
    height: 2px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  margin: 0 ${({ theme }) => theme.spacing(2)} 0;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    background: ${({ theme }) => theme.palette.background.paper};
    position: fixed;
    width: 1105px;
    margin: 0 ${({ theme }) => theme.spacing(2)} 0;
  }
`;

export const PrintableSummaryWrapper = styled('div')`
  & hr {
    border: none;
    height: 1px;
    background: ${({ theme }) => theme.palette.grey[200]};
    color: ${({ theme }) => theme.palette.grey[200]};
  }
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const SummaryHeader = styled('h4')`
  font-family: 'Soleil';
  font-size: 1.125rem;
`;

export const PrintableSummaryContentDiv = styled('div')`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(3)};
  }
`;

export const DialogContentWrapped = styled(DialogContent)`
  margin-top: ${({ theme }) => `calc(${theme.spacing(1)} - 4px)`};
`;

export const DialogActionsWrapped = styled(DialogActions)`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(5)};
`;

import { useAppSelector } from './storeHooks';
import { selectProjectId } from '@/store/slices/questionnaire';
import { isIntegration, isProduction, isStaging } from '@/constants/env';

const useGetPortalUrl = () => {
  const projectId = useAppSelector(selectProjectId);

  if (isIntegration) {
    return `https://portal.s-rm-ci-integration.s-rminform.com/project/${projectId}`;
  }
  if (isStaging) {
    return `https://portal.s-rm-ci-staging.s-rminform.com/project/${projectId}`;
  }
  if (isProduction) {
    return `https://portal.s-rminform.com/project/${projectId}`;
  }

  return '';
};

export default useGetPortalUrl;

import { createContext } from 'react';

export interface ContextProps {
  isOnline: boolean;
  wasOffline: boolean;
}

export const OnlineStatusContext = createContext<ContextProps>({
  isOnline: true,
  wasOffline: false,
});

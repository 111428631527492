import { Box, TableContainer } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';

import {
  resetIncidentsFilters,
  selectIsLoadingIncidents,
  selectContributorsEntitiesAsArray,
  updateContributorFilters,
  selectContributorsFilters,
  selectIsLoadingContributors,
} from '@/store/slices/admin/admin';
import { defaultFiltersObject } from '@/store/types/admin';
import { Button, Table as TableMUI } from '@s-rm/react-ui-lib';
import { useEffect } from 'react';
import { setIsOpenCreateIncidentDialog } from '@/store/slices/ui';
import {
  getContributorsTableHeader,
  getContributorsTableRows,
} from './utils/getContributorsTableData';
import fetchContributorsThunk from '@/store/thunks/admin/fetchContributorsThunk';

const ContributorsTable = () => {
  const dispatch = useAppDispatch();
  const contributors = useAppSelector(selectContributorsEntitiesAsArray) || [];
  const isLoading = useAppSelector(selectIsLoadingContributors);

  const { filters, pagination, sort } = useAppSelector(
    selectContributorsFilters
  );

  useEffect(() => {
    dispatch(
      updateContributorFilters({
        filters: [],
      })
    );
    dispatch(fetchContributorsThunk());
    return () => {
      dispatch(resetIncidentsFilters());
    };
  }, [dispatch]);

  return (
    <TableContainer>
      <TableMUI
        componentProps={{
          tableProps: {
            //@ts-ignore
            'data-testid': 'contributors-table',
          },
        }}
        stickyHeader={true}
        isLoading={isLoading}
        extendedHeader={
          <Box display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => dispatch(setIsOpenCreateIncidentDialog(true))}
            >
              Add contributor
            </Button>
          </Box>
        }
        header={getContributorsTableHeader()}
        rows={getContributorsTableRows({
          contributors,
        })}
        pagination={{
          paginationData: {
            total: pagination.total,
            offset: pagination.offset / pagination.limit + 1,
            limit: pagination.limit,
          },
          onChange: (newPagination) => {
            dispatch(
              updateContributorFilters({
                pagination: {
                  offset: newPagination.offset ?? pagination.offset,
                  limit: newPagination.limit ?? pagination.limit,
                },
              })
            );
            dispatch(fetchContributorsThunk());
          },
        }}
        filters={{
          values: filters ?? [],
          onChange: (column, selectedValues) => {
            dispatch(
              updateContributorFilters({
                filters: [{ column, values: selectedValues }],
                pagination: defaultFiltersObject.pagination,
              })
            );
            dispatch(fetchContributorsThunk());
          },
        }}
        sort={{
          column: sort.column,
          direction: sort.direction,
          onChange: (column, direction) => {
            dispatch(
              updateContributorFilters({
                sort: { column, direction },
              })
            );
            dispatch(fetchContributorsThunk());
          },
        }}
      />
    </TableContainer>
  );
};

export default ContributorsTable;

import { styled } from '@mui/material';

export const LayoutWrapper = styled('div')`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled('div')`
  margin: ${({ theme }) => theme.spacing(5)} 0;
  flex: 1;
  overflow: hidden;
`;

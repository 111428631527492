export const configMock = {
  hasInitialised: true,
  hasAuthenticated: true,
  isCaseValid: true,
  errors: [],
  featureFlags: {},
  user: {
    fullName: 'Person one',
    firstName: 'Person',
    lastName: 'One',
    emailAddress: 'person@person.com',
    telephone: '',
  },
};

import { Module } from '@/store/types/module';
import { Question } from '@/store/types/question';

export interface IsEntityAlreadyLinked {
  id: string;
  questions: Question[];
  modules: Module[];
  questionInEdit: Question;
}

const isEntityAlreadyLinked = ({
  id,
  questions,
  modules,
  questionInEdit,
}: IsEntityAlreadyLinked) => {
  const isTriggeredFromQuestionInEdit = questionInEdit.triggers.some(
    (t) => t.componentId === id
  );

  const isAlreadyTriggered = questions.some((q) => {
    const foundTrigger = q.triggers.find((t) => t.componentId === id);

    if (foundTrigger && isTriggeredFromQuestionInEdit) {
      return false;
    }

    // It is triggered from another question - we will allow it to be flagged as
    // already triggered.
    return !!foundTrigger;
  });

  // We also do not want to allow re-use of questions if they are root questions,
  // regardless of whether they are already triggered or not.
  const isRootQuestion = modules.some((m) => m.questions.includes(id));

  return isAlreadyTriggered || isRootQuestion;
};

export default isEntityAlreadyLinked;

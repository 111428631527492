import { QuestionId } from '@/store/types/basicTypes';
import { Question, ControlTypes } from '@/store/types/question';

const formatAnswers = (
  answers: { answer: string; questionId: QuestionId }[]
): { [key: string]: string } => {
  return answers.reduce(
    (acc, answer) => ({
      ...acc,
      [answer.questionId]: answer.answer,
    }),
    {}
  );
};

export const formatMultiSelectAnswers = ({
  answers,
  questions,
}: {
  questions: { [key: string]: Question };
  answers: { [key: string]: string };
}) => {
  return Object.keys(answers).reduce((acc, questionId) => {
    const answer = answers[questionId];
    const question = questions[questionId];
    return {
      ...acc,
      [questionId]:
        question.control === ControlTypes.MultiSelect
          ? answer.split(',').filter(Boolean)
          : answer,
    };
  }, {});
};

export default formatAnswers;

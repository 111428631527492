import { ModuleId } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import { debounce } from 'lodash';
import { updateAdvice } from '@/store/slices/advice';
import { Advice } from '@/store/types/advice';
import editAdvice from '@/api/mutations/editor/editAdvice';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';

const debouncedEditAdvice = debounce((id) => {
  editAdvice(id);
}, 1000);

const updateAdviceThunk =
  ({
    id,
    updates,
    shouldDebounce,
  }: {
    id: ModuleId;
    updates: Partial<Advice>;
    shouldDebounce?: boolean;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setIsDirty(true));
    dispatch(updateAdvice({ id, updates }));
    dispatch(
      updateEntityModifications({
        entityType: 'advice',
        entityId: id,
        fields: Object.keys(updates),
      })
    );
    if (shouldDebounce) {
      debouncedEditAdvice(id);
    } else {
      editAdvice(id);
    }
  };

export default updateAdviceThunk;

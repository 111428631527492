import { AppThunk } from '@/store/store';
import { setIsFetchingSessions, setSessions } from '@/store/slices/editor';
import getSessionList from '@/api/queries/editor/getSessionList';

const fetchSessionListThunk = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsFetchingSessions(true));
  const { data: sessions } = await getSessionList();
  dispatch(setSessions(sessions));

  setTimeout(() => {
    // Simulate a delay in the response to make it look nicer
    dispatch(setIsFetchingSessions(false));
  }, 500);
};

export default fetchSessionListThunk;

import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import useRequiredQuestionsCounter from '../RequiredQuestionsCounter/hooks/useRequiredQuestionsCounter';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsConfirmingCaseClosure,
  setIsConfirmingCaseClosure,
} from '@/store/slices/ui';
import submitCase from '@/store/thunks/submitCase';
import useGetModulesWithGroups from '@/hooks/useGetModulesWithGroups';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';

const CaseClosureModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsConfirmingCaseClosure);
  const moduleIds = useGetModulesWithGroups();

  const { total, answered, isComplete } =
    useRequiredQuestionsCounter(moduleIds).combinedCounters;
  const handleClose = () => {
    dispatch(setIsConfirmingCaseClosure(false));
  };

  const handleConfirm = () => {
    dispatch(setIsConfirmingCaseClosure(false));
    dispatch(submitCase());
    dispatch(
      updateSnackbarNotification({
        message: 'The case has been closed successfully',
        type: SnackbarTypes.Success,
      })
    );
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title='Close case'
      confirmText='Yes, continue'
      onConfirm={handleConfirm}
      onClose={handleClose}
      testId='case-closure-confirmation-modal'
    >
      <Typography mb={2}>Are you sure you want to close this case?</Typography>
      {!isComplete && (
        <Alert severity='warning' data-testid='completed-questions-warning'>
          <AlertTitle>Warning</AlertTitle>
          <Typography>
            You have completed{' '}
            <span data-testid='confirm-total-answered'>{answered}</span> out of{' '}
            <span data-testid='confirm-total'>{total}</span> required questions.
          </Typography>
        </Alert>
      )}
    </ConfirmationModal>
  );
};

export default CaseClosureModal;

import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectQuestionInEdit,
  selectSingleEntityModifications,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setQuestionInEdit,
  toggleResetAndSyncEntityModal,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../../styled';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { EntityType, QuestionId } from '@/store/types/basicTypes';
import ModalContent from './ModalContent';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import { selectQuestion } from '@/store/slices/questions';
import ResetButton from '../ResetButton';

const EditQuestionModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { id, isNew } = useAppSelector(selectQuestionInEdit);
  const isSessionOnly = useAppSelector((state) =>
    selectQuestion(state, id!)
  )?.isSessionOnly;
  const questionInEdit = id as QuestionId;
  const modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'questions', id!)
  );
  const handleClose = () => {
    dispatch(setQuestionInEdit({ id: null }));
    dispatch(refreshDisplayThunk());
  };

  const handleBack = () => {
    dispatch(setQuestionInEdit({ id: null }));
    dispatch(setActiveEntitySearchList(EntityType.Question));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: questionInEdit,
        entityType: EntityType.Question,
      })
    );
  };

  const handleReset = () => {
    dispatch(
      toggleResetAndSyncEntityModal({
        isOpen: true,
        entityId: id!,
        entityType: EntityType.Question,
      })
    );
  };

  return (
    <EditorDialog open={isOpen} maxWidth='md' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Button
            onClick={handleBack}
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            data-testid='btn-edit-question-back'
          >
            Back
          </Button>

          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            {`${isNew ? 'Add' : 'Edit'}`} question
          </Typography>
          <Box display='flex' gap={2}>
            {!isSessionOnly && !!modifications.length && (
              <ResetButton
                onClick={handleReset}
                testId='btn-reset-sync-question'
              />
            )}
            <Button
              square
              onClick={handleClickParentView}
              data-testid='btn-open-parent-view-question'
            >
              <AccountTreeOutlinedIcon />
            </Button>
            <Button onClick={handleClose} data-testid='btn-close-edit-question'>
              <CloseIcon />
            </Button>
          </Box>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {isOpen && <ModalContent />}
      </DialogContent>
    </EditorDialog>
  );
};

export default EditQuestionModal;

import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetQuestion } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { Question } from '@/store/types/question';

export const getQuestion = async ({
  questionId,
}: {
  questionId: string;
}): Promise<{ data: Question }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }
  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetQuestion,
    variables: {
      id: questionId,
    },
  });
  // @ts-ignore
  return { data: getFormattedEntity(data.getQuestion) as Question, errors };
};

export default getQuestion;

import { AppThunk } from '@/store/store';
import {
  setActiveEntitySearchList,
  setIsDirty,
  setModuleGroupInEdit,
} from '@/store/slices/editor';
import { addModuleGroup } from '@/store/slices/modules';
import createModuleGroup from '@/api/mutations/editor/createModuleGroup';

const createModuleGroupThunk = (): AppThunk => async (dispatch) => {
  const { data: newModuleGroup } = await createModuleGroup();
  dispatch(setIsDirty(true));
  dispatch(addModuleGroup({ ...newModuleGroup, isSessionOnly: true }));
  dispatch(setModuleGroupInEdit({ id: newModuleGroup.id, isNew: true }));
  dispatch(setActiveEntitySearchList(null));
};

export default createModuleGroupThunk;

const fetchEditorSessionFixture = {
  fetchEditorSession: {
    id: '895794aa-ec0f-42d0-9903-183fe666e104',
    version: 3,
    createdBy: null,
    createdAt: '2023-11-29T13:16:06.283840123Z',
    lastUpdatedBy: null,
    lastUpdatedAt: '2023-11-29T13:24:53.721210284Z',
    edited: {
      caseTypes: null,
      modules: null,
      questions: null,
      tips: null,
      advice: null,
      indicators: null,
      moduleGroups: null,
      __typename: 'EditedDocuments',
    },
    __typename: 'EditorSession',
  },
};

export default fetchEditorSessionFixture;

import { useEffect } from 'react';
import { useNetworkStatus } from './useNetworkStatus';
export const useBeforeUnloadEvent = (
  handler: (event: BeforeUnloadEvent) => void
) => {
  const { isOnline } = useNetworkStatus();
  useEffect(() => {
    if (!isOnline) {
      window.addEventListener('beforeunload', handler);
    }

    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [handler, isOnline]);

  return null;
};

import { getApiToken, getIsUsingApiKey } from '@/utils/auth';
import { FeatureFlag } from './types';
import axios from 'axios';

const getClient = () => {
  const shouldUseApiKey = getIsUsingApiKey();
  const token = getApiToken();
  return axios.create({
    headers: {
      ...(shouldUseApiKey && { 'x-api-key': token }),
      ...(!shouldUseApiKey && {
        authorization: `Bearer ${token}`,
      }),
    },
  });
};

export const getFlags = async (endpoint: string) => {
  try {
    const { data } = await getClient()(endpoint);
    return Promise.resolve({ flags: data ?? [] });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const createFlag = async (flag: FeatureFlag, endpoint: string) => {
  try {
    await getClient().post(endpoint, flag);
    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const deleteFlag = async (name: string, endpoint: string) => {
  try {
    await getClient().delete(`${endpoint}/${name}`);

    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

export const updateFlagStatus = async (
  name: string,
  status: boolean,
  endpoint: string
) => {
  try {
    await getClient().post(`${endpoint}/${name}`, { active: status });

    return Promise.resolve({ success: true });
  } catch (error) {
    return Promise.resolve({ error });
  }
};

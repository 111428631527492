import { CaseTypeId, IndicatorId } from './basicTypes';

export enum IndicatorActions {
  Select = 'SELECT',
  Deselect = 'DESELECT',
  Dismiss = 'DISMISS',
  Revive = 'REVIVE',
}

export interface Indicator {
  id: IndicatorId;
  label: string;
  entityType?: string;
  isSessionOnly: boolean;
  text?: string;
}

export interface IndicatorSlice {
  entities: {
    [key: string]: Indicator;
  };
  ids: IndicatorId[];
  selected: IndicatorId[];
  dismissed: IndicatorId[];
  suggested: IndicatorId[];
  indicatorCaseTypesMap: IndicatorToCaseTypesMap;
}

export interface IndicatorToCaseTypesMap {
  [key: IndicatorId]: CaseTypeId[];
}

import { EntityType } from '../types/basicTypes';
import { Module } from '../types/module';
import { ComponentReferenceObject, Question } from '../types/question';
import { Tip } from '../types/tip';
import { buildStructure } from './buildNestedStructure';

interface getNestedEntityStructure {
  modules: Module[];
  questions: Question[];
  tips: Tip[];
}

const getNestedEntityStructure = ({
  modules,
  tips,
  questions,
}: getNestedEntityStructure) => {
  const structure: { [key: string]: ComponentReferenceObject[] } = {};

  modules.forEach((module) => {
    let result: ComponentReferenceObject[] = [];
    module.questions.forEach((questionId) => {
      result.push(
        ...buildStructure([...questions, ...tips], {
          id: questionId,
          componentType: EntityType.Question,
          parentId: '',
        })
      );
      structure[module.id as keyof typeof structure] = result;
    });
  });

  return structure;
};

export default getNestedEntityStructure;

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  ActiveStepIndicator,
  ChevronRight,
  DividerWrapper,
  StepButton,
  VerticalRule,
} from './styled';

interface StepperNavProps {
  paths: { value: string; label: string; icon?: JSX.Element }[];
  activePath: string;
  onChange: (rootPath: string) => void;
  isEditMode?: boolean;
}

export const StepperNav: React.FC<StepperNavProps> = ({
  paths,
  activePath,
  onChange,
  isEditMode = false,
}) => {
  const [activeStep, setActiveStep] = useState(
    paths.findIndex((path) => path.value === activePath)
  );

  const handleChangeRoute = (rootPath: string) => {
    setActiveStep(paths.findIndex((path) => path.value === rootPath));
    onChange(rootPath);
  };

  useEffect(() => {
    setActiveStep(paths.findIndex((path) => path.value === activePath));
  }, [activePath, paths]);

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      {paths.map((path, index) => (
        <>
          <StepButton
            onClick={() => handleChangeRoute(path.value)}
            disabled={activeStep === index}
            activeStep={activeStep}
            startIcon={path.icon}
            isEditMode={isEditMode}
          >
            {path.label}
            {activeStep === index && <ActiveStepIndicator />}
          </StepButton>
          {index < paths.length - 1 && (
            <DividerWrapper>
              {index !== 2 ? (
                <ChevronRight isEditMode={isEditMode} />
              ) : (
                <VerticalRule isEditMode={isEditMode} />
              )}
            </DividerWrapper>
          )}
        </>
      ))}
    </Box>
  );
};

import { ModuleGroup } from '@/store/types/module';

const createModuleGroupTransform = (data: any): ModuleGroup => {
  const {
    createModuleGroup: { id },
  } = data;
  return {
    id: id,
    label: '',
    moduleIds: [],
    priorityIndex: '1',
    isSessionOnly: true,
  };
};

export default createModuleGroupTransform;

import { styled } from '@mui/material';

export const Counter = styled('div')<{ isComplete: boolean }>`
  background: ${({ isComplete, theme }) =>
    isComplete ? theme.palette.grey[100] : '#ff000e0d'};
  border-radius: 4px;
  color: ${({ isComplete, theme }) =>
    isComplete ? theme.palette.primary.main : '#d3302f'};
  font-weight: 600;
  padding: 4px 8px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  svg rect {
    fill: ${({ isComplete, theme }) =>
      isComplete ? theme.palette.primary.main : '#d3302f'};
  }
`;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectQuestion } from '@/store/slices/questions';
import { EntityType } from '@/store/types/basicTypes';
import { QuestionOptions } from '@/store/types/question';
import { getTriggersByOptionValue, reorderList } from '../../../utils/helpers';
import { Box, Divider, Typography } from '@mui/material';
import { Accordion } from '@s-rm/react-ui-lib';

import TriggerItem from './TriggerItem';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import updateQuestionThunk from '@/store/thunks/editor/updateQuestion';
import {
  DragHandle,
  SortableItem,
  SortableList,
} from '@/components/DragDropList/DragDropList';

const EditTriggers = ({
  questionId,
  optionId,
}: {
  questionId: string;
  optionId: string;
}) => {
  const dispatch = useAppDispatch();

  const question = useAppSelector((state) => selectQuestion(state, questionId));
  const option = question.options.find(
    (o) => o.id === optionId
  ) as QuestionOptions;
  const triggers = getTriggersByOptionValue(question.triggers, option.value);

  const moduleTriggers = triggers.filter(
    (t) => t.componentType === EntityType.Module
  );

  const questionsAndTipsTriggers = triggers.filter(
    (t) => t.componentType !== EntityType.Module
  );

  /**
   * Because we are sorting only a slice of the actual triggers list (related to current option), we are
   * first re-ordering this slice and then determine the new position of the sorted item
   * in the larger main question triggers list.
   */
  const handleDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const draggedItemId = questionsAndTipsTriggers[oldIndex].id;
    const newOrderLocalToOption = reorderList(
      questionsAndTipsTriggers,
      oldIndex,
      newIndex
    );

    // Find out where the dragged item is in the main question triggers list.
    const oldIndexInTriggersList = question.triggers.findIndex(
      (t) => draggedItemId === t.id
    );

    // Now we need to find out where the dragged item will be moved to in the main question triggers list.
    // By locating it's new neighbors in the local list, we can use their ids to find the new
    // position in the main triggers list.
    const newIndexInTriggersList = question.triggers.findIndex(
      (t) =>
        t.id === newOrderLocalToOption[newIndex - 1]?.id ||
        newOrderLocalToOption[newIndex + 1]?.id
    );

    dispatch(
      updateQuestionThunk({
        id: question.id,
        updates: {
          triggers: [
            ...moduleTriggers,
            ...reorderList(
              question.triggers,
              oldIndexInTriggersList,
              newIndexInTriggersList
            ),
          ],
        },
      })
    );
  };

  return (
    <>
      <Box mt={1} mb={1}>
        <Divider />
      </Box>
      {!!moduleTriggers.length && (
        <Accordion
          // @ts-ignore
          noBackground
          noShadow
          noSeperator
          expandIconPosition='LEFT'
          items={[
            {
              id: 'modules',
              title: (
                <Typography
                  fontWeight={700}
                  data-testid='triggered-modules-title'
                >
                  Triggered Modules
                </Typography>
              ),
              content: (
                <Box
                  width='100%'
                  display='flex'
                  flexDirection='column'
                  gap='15px'
                >
                  {moduleTriggers.map((trigger) => (
                    <TriggerItem key={trigger.id} trigger={trigger} />
                  ))}
                </Box>
              ),
            },
          ]}
        />
      )}
      {!!questionsAndTipsTriggers.length && (
        <Accordion
          // @ts-ignore
          noBackground
          noShadow
          noSeperator
          expandIconPosition='LEFT'
          items={[
            {
              id: 'questionsAndTips',
              title: (
                <Typography
                  fontWeight={700}
                  data-testid='triggered-questions-tips-title'
                >
                  Triggered Questions & Tips
                </Typography>
              ),
              content: (
                <SortableList onSortEnd={handleDragEnd} useDragHandle>
                  {questionsAndTipsTriggers.map((trigger, index) => (
                    <SortableItem key={trigger.id} index={index}>
                      <Box display='flex' alignItems='center' gap={1}>
                        <DragHandle>
                          <Box display='flex'>
                            <DragIndicatorIcon />
                          </Box>
                        </DragHandle>
                        <Box width='100%' py={1}>
                          <TriggerItem trigger={trigger} />
                        </Box>
                      </Box>
                    </SortableItem>
                  ))}
                </SortableList>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default EditTriggers;

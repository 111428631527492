import { InitialDataResponseMap } from '@/api/types/initialData';
import { ErrorCodes } from '@/constants/errors';
import Cookie from 'js-cookie';
import { isApiKeyCompatibleEnv } from '@/constants/env';

export const convertExpirySecondsToDateObject = (seconds: number) => {
  const currentDate = new Date();
  return new Date(currentDate.getTime() + seconds * 1000);
};

export const getApiToken = () =>
  getIsUsingApiKey() ? localStorage.getItem('apiKey') : Cookie.get('jwt');

export const setToken = ({
  jwt,
  refreshToken,
  exp,
}: {
  jwt: string;
  exp: number;
  refreshToken: string;
  apiKey?: string;
}) => {
  Cookie.set('jwt', jwt, { expires: convertExpirySecondsToDateObject(exp) });
  Cookie.set('refreshToken', refreshToken);
};

export const getTokenFromCookie = () => Cookie.get('jwt');

export const getRefreshToken = () => Cookie.get('refreshToken');

export const validateInitialData = (initialData: any) => {
  if (!initialData) {
    return ErrorCodes.InitialDataFetch;
  }

  // If the GQL response contains an error object.
  if (initialData.error) {
    return ErrorCodes.InitialDataGQL;
  }

  // An issue has been encountered fetching the case (incorrect Id provided in url).
  if (!initialData?.[InitialDataResponseMap.Case]) {
    return ErrorCodes.NoCaseId;
  }
};

export const getIsUsingApiKey = () =>
  !!(
    isApiKeyCompatibleEnv &&
    typeof Storage !== 'undefined' &&
    localStorage.getItem('apiKey')
  );

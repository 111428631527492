import { ComponentReferenceObject } from '@/store/types/question';
/**
 * Returns a list of unique tips from the renderStructure.
 * We also need to map any tip aliases to their original tip id's.
 * If multiple aliases are visible, make sure we only display x1 of the original id.
 */
const getUniqueTips = ({
  renderStructure,
  tipAliasMap,
  questionVisibility,
}: {
  renderStructure: ComponentReferenceObject[];
  tipAliasMap: { [aliasId: string]: string };
  questionVisibility: { [questionId: string]: boolean };
}): ComponentReferenceObject[] => {
  const tipsList = renderStructure
    .filter((q: ComponentReferenceObject) => q.componentType === 'TIP')
    .filter((t) => questionVisibility[t.id])
    .map((t) => ({
      ...t,
      id: tipAliasMap[t.id] || t.id,
    }));

  // Use Set to remove duplicate tips
  const uniqueTipsList = Array.from(new Set(tipsList.map((tip) => tip.id))).map(
    (id) => tipsList.find((tip) => tip.id === id)
  );

  return uniqueTipsList as ComponentReferenceObject[];
};

export default getUniqueTips;

const copyToClipboard = (id: string) => {
  // Create a temporary element to hold the HTML content
  const el = document.getElementById(id) as HTMLDivElement;
  let tempElement = document.createElement('div');
  const styles = {
    fontSize: '13.33px',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    position: 'absolute',
    left: '-9999px',
    headerFontSize: '14.67px',
    headerFontWeight: 'bold',
  };

  const clonedEl = el.cloneNode(true) as HTMLDivElement;
  tempElement.innerHTML = clonedEl.innerHTML;

  // Set the contentEditable attribute to make it editable
  tempElement.setAttribute('contentEditable', 'true');

  // Remove it from view
  tempElement.style.position = styles.position;
  tempElement.style.left = styles.left;

  // Set inline CSS styling for font family and size on the temp element
  tempElement.style.fontFamily = styles.fontFamily;
  tempElement.style.fontSize = styles.fontSize;
  tempElement.style.fontWeight = styles.fontWeight;

  const headerElements = tempElement.querySelectorAll('h4');
  headerElements.forEach((heading) => {
    heading.style.fontFamily = styles.fontFamily;
    heading.style.fontSize = styles.fontSize;
    heading.style.fontWeight = styles.headerFontWeight;
    heading.style.fontStyle = styles.fontStyle;
  });

  // Append the temporary element to the document
  document.body.appendChild(tempElement);

  // Select the content of the temporary element
  let range = document.createRange();
  range.selectNodeContents(tempElement);
  let selection = window.getSelection() as Selection;
  selection.removeAllRanges();
  selection.addRange(range);

  // Copy the selected content to the clipboard
  document.execCommand('copy');

  // Remove the temporary element from the document
  document.body.removeChild(tempElement);
};

export default copyToClipboard;

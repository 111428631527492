import { Fab, styled } from '@mui/material';

export const StyledFAB = styled(Fab)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 117px;
  margin-right: -15px;
`;

export default StyledFAB;

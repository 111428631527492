import { Module } from '../types/module';

/**
 * This method at present is a temporary measure until we have implemented a
 * more strategic way to order / group modules.
 *
 * We are ordering modules by the number in the label, which is the order in
 * which they appear in the UI. Then removing the number from the label in the UI.
 *
 * The number in the label is prefixed and suffixed by an exclamation mark (!).
 */
const getOrderedSelectedModules = ({
  selectedIds,
  entities,
}: {
  selectedIds: string[];
  entities: {
    [key: string]: Module;
  };
}) => {
  return selectedIds.sort((a, b) => {
    const labelA = entities[a]?.label;
    const labelB = entities[b]?.label;

    const numberA = parseFloat(
      labelA?.substring(labelA.indexOf('!') + 1, labelA.lastIndexOf('!'))
    );
    const numberB = parseFloat(
      labelB?.substring(labelB.indexOf('!') + 1, labelB.lastIndexOf('!'))
    );

    return numberA - numberB;
  });
};

export default getOrderedSelectedModules;

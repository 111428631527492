import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectSelectedModules } from '@/store/slices/modules';
import { selectQuestionnaireDesription } from '@/store/slices/questionnaire';
import SummaryAdvice from '../SummaryContent/SummaryAdvice';
import SummaryQuestions from '../SummaryContent/SummaryQuestions';
import { useEffect } from 'react';
import copyToClipboard from './utils/copyToClipboard';
import { setPrintableSummaryStatus } from '@/store/slices/ui';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { HTMLRender } from '../HTMLContent/HTMLRender';
import {
  PrintableSummaryWrapper,
  PrintableSummaryContentDiv,
  SummaryHeader,
  TabStyled,
  TabsStyled,
} from './styled';
import Box from '@mui/material/Box';
import { openingAdviceEmail } from './templates/openingAdviceEmail';
import { SummaryTabs } from './types';
import { TabPanel } from '@/components/TabPanel/TabPanel';
import { summaryTabLabels } from './data';
import { selectCurrentUser } from '@/store/slices/config';
import { User } from '@/api/types/user';

const PrintableSummaryContent = ({
  isAutoCopy,
  currentTabIndex,
  handleOnTabIndexChange,
}: {
  isAutoCopy?: boolean;
  currentTabIndex?: number;
  handleOnTabIndexChange?: (
    event: React.SyntheticEvent,
    newTabIndexValue: number
  ) => void;
}) => {
  const description = useAppSelector(selectQuestionnaireDesription);
  const selectedModules = useAppSelector(selectSelectedModules);
  const user = useAppSelector(selectCurrentUser) as User;
  const firstName = user.firstName;
  const dispatch = useAppDispatch();

  const Advice = selectedModules.map((selectedModule) => (
    <SummaryAdvice
      key={selectedModule}
      moduleId={selectedModule}
      getAsPrintView
    />
  ));
  const Questions = selectedModules.map((selectedModule) => (
    <SummaryQuestions
      key={selectedModule}
      moduleId={selectedModule}
      getAsPrintView
    />
  ));

  const containsAdvice = Advice.some((advice) => advice);
  const hasSelectedModules = selectedModules.length > 0;

  useEffect(() => {
    // If we have invoked this component by clicking the "Copy to clipboard" button in the Summary component,
    // we display the component off-screen - copy to clipboard - and then close the component.
    if (isAutoCopy) {
      copyToClipboard('printable-summary');
      dispatch(setPrintableSummaryStatus({ isOpen: false, isAutoCopy: false }));
      dispatch(
        updateSnackbarNotification({
          message: 'Copied to clipboard',
        })
      );
    }
  }, [isAutoCopy, dispatch]);

  return (
    <div
      style={{
        ...(isAutoCopy ? { position: 'absolute', left: '-9999px' } : {}),
      }}
    >
      <Box>
        <TabsStyled value={currentTabIndex} onChange={handleOnTabIndexChange}>
          {summaryTabLabels.map((tabLabel) => (
            <TabStyled
              key={tabLabel.toString()}
              label={tabLabel}
              disableRipple={true}
              data-testid={`${tabLabel.toLowerCase()}-summary-tab-button`}
            />
          ))}
        </TabsStyled>
      </Box>
      <TabPanel currentIndex={currentTabIndex!} tabIndex={SummaryTabs.Advice}>
        {containsAdvice && hasSelectedModules && (
          <PrintableSummaryWrapper id='printable-summary-advice'>
            <PrintableSummaryContentDiv id='summary-advice'>
              <HTMLRender content={openingAdviceEmail(firstName)} />
              <hr />
              <SummaryHeader>ADVICE:</SummaryHeader>
              {Advice}
            </PrintableSummaryContentDiv>
          </PrintableSummaryWrapper>
        )}
      </TabPanel>
      <TabPanel currentIndex={currentTabIndex!} tabIndex={SummaryTabs.Summary}>
        <PrintableSummaryWrapper id='printable-summary'>
          <PrintableSummaryContentDiv id='summary-description'>
            <SummaryHeader>DESCRIPTION:</SummaryHeader>
            <HTMLRender content={description} />
            <hr />
          </PrintableSummaryContentDiv>

          {containsAdvice && hasSelectedModules && (
            <div id='summary-advice'>
              <SummaryHeader>ADVICE:</SummaryHeader>
              {Advice}
            </div>
          )}

          {hasSelectedModules && (
            <div id='summary-questions'>
              <hr />
              <SummaryHeader>QUESTIONS:</SummaryHeader>
              {Questions}
            </div>
          )}
        </PrintableSummaryWrapper>
      </TabPanel>
    </div>
  );
};

export default PrintableSummaryContent;

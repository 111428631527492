import { createContext } from 'react';
import { FeatureFlag } from './types';

export const FeatureFlagContext = createContext<{
  featureFlags: FeatureFlag[];
  isFeatureFlagOn: (name: string) => boolean;
  hasFetchedFlags: boolean;
  createFlag: (flag: Partial<FeatureFlag>) => Promise<{ success: boolean }>;
  deleteFlag: (name: string) => Promise<{ success: boolean }>;
  updateFlagStatus: ({
    env,
    name,
    active,
  }: {
    env: string;
    active: boolean;
    name: string;
  }) => Promise<{ success: boolean }>;
}>({
  featureFlags: [],
  isFeatureFlagOn: () => false,
  createFlag: async () => ({ success: false }),
  deleteFlag: async () => ({ success: false }),
  updateFlagStatus: async () => ({ success: false }),
  hasFetchedFlags: false,
});

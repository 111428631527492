import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectEntitySearchFilter,
  setActiveEntitySearchList,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../../styled';
import { EntityType, ModuleGroupId } from '@/store/types/basicTypes';
import { useEffect } from 'react';
import ModalContent from '../ModalContent';
import createModuleGroupThunk from '@/store/thunks/editor/createModuleGroup';
import filterModuleGroupsThunk from '@/store/thunks/editor/filterModuleGroups';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import resetEditorSessionOrArchive from '@/store/thunks/editor/resetEditorSessionOrArchive';

const ModuleGroupSearchModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { searchTerm, filterBy } = useAppSelector(selectEntitySearchFilter);

  const handleTermChange = (updatedSearchTerm: string) => {
    dispatch(
      filterModuleGroupsThunk({
        searchTerm: updatedSearchTerm,
      })
    );
  };

  const handleClose = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(refreshDisplayThunk());
  };

  const handleChangeFilterBy = (updatedFilterBy: EntityType) =>
    dispatch(
      filterModuleGroupsThunk({
        filterBy: updatedFilterBy,
      })
    );

  const handleCreateModuleGroup = () => {
    dispatch(createModuleGroupThunk());
  };

  const handleDeleteModuleGroup = (id: ModuleGroupId) => {
    dispatch(resetEditorSessionOrArchive(id, filterBy!));
    handleTermChange(searchTerm!);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(filterModuleGroupsThunk({ filterBy: EntityType.ModuleGroup }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <EditorDialog open={isOpen} maxWidth='lg' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Add or edit module groups
          </Typography>
          <Button onClick={handleClose} data-testid='btn-close-mgroup-modal'>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent>
        {isOpen && (
          <ModalContent
            onCreate={handleCreateModuleGroup}
            searchTerm={searchTerm!}
            onChangeTerm={handleTermChange}
            onChangeFilterBy={handleChangeFilterBy}
            onDelete={handleDeleteModuleGroup}
          />
        )}
      </DialogContent>
    </EditorDialog>
  );
};

export default ModuleGroupSearchModal;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import {
  CaseTypeId,
  AdviceId,
  EntityType,
  ModuleGroupId,
  QuestionId,
  TipId,
} from '../types/basicTypes';
import { RootState } from '../store';
import { EditorSession, FilterOptions } from '../types/editor';
import { createSelector } from '@reduxjs/toolkit';

export const editorSlice = createSlice({
  name: 'editor',
  initialState: initialState.editor,
  reducers: {
    setQuestionInEdit: (
      state,
      action: PayloadAction<{ id: QuestionId | null; isNew?: boolean }>
    ) => {
      state.questionInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew || false,
      };
    },
    setModuleInEdit: (
      state,
      action: PayloadAction<{ id: ModuleGroupId | null; isNew?: boolean }>
    ) => {
      state.moduleInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew || false,
      };
    },
    setModuleGroupInEdit: (
      state,
      action: PayloadAction<{ id: string | null; isNew?: boolean }>
    ) => {
      state.moduleGroupInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew || false,
      };
    },
    setTipInEdit: (
      state,
      action: PayloadAction<{ id: TipId | null; isNew?: boolean }>
    ) => {
      state.tipInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew || false,
      };
    },
    setCaseTypeInEdit: (
      state,
      action: PayloadAction<{ id: CaseTypeId | null; isNew?: boolean }>
    ) => {
      state.caseTypeInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew ?? false,
      };
    },
    setAdviceInEdit: (
      state,
      action: PayloadAction<{ id: AdviceId | null; isNew?: boolean }>
    ) => {
      state.adviceInEdit = {
        id: action.payload.id,
        isNew: action.payload.isNew || false,
      };
    },
    setActiveEntitySearchList: (
      state,
      action: PayloadAction<EntityType | null>
    ) => {
      state.activeEntitySearchList = action.payload;
    },
    setEntitySearchFilter: (state, action: PayloadAction<FilterOptions>) => {
      state.entitySearchFilter = {
        ...state.entitySearchFilter,
        ...action.payload,
      };
    },
    setActiveParentTreeView: (
      state,
      action: PayloadAction<{
        entityType: EntityType | null;
        entityId: string | null;
      }>
    ) => {
      state.activeParentTreeView = action.payload;
    },
    setIsSessionModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSessionModalOpen = action.payload;
    },
    setSessions: (
      state,
      action: PayloadAction<{
        entities: {
          [key: string]: EditorSession;
        };
        ids: string[];
      }>
    ) => {
      state.sessions.entities = action.payload.entities;
      state.sessions.ids = action.payload.ids;
    },
    deleteSession: (state, action: PayloadAction<string>) => {
      const { [action.payload]: _, ...rest } = state.sessions.entities;
      state.sessions.entities = rest;
      state.sessions.ids = state.sessions.ids.filter(
        (id) => id !== action.payload
      );
    },
    setIsFetchingSessions: (state, action: PayloadAction<boolean>) => {
      state.sessions.isFetching = action.payload;
    },
    setSessionInEdit: (state, action: PayloadAction<EditorSession | null>) => {
      state.sessionInEdit = action.payload;
    },
    setEntityModifications: (
      state,
      action: PayloadAction<{
        [key: string]: {
          [key: string]: string[];
        };
      }>
    ) => {
      state.entityModifications = action.payload;
    },
    setOutdatedEntities: (
      state,
      action: PayloadAction<{
        [key: string]: {
          [key: string]: string[];
        };
      }>
    ) => {
      state.outdatedEntities = action.payload;
    },
    removeEntityFromSession: (
      state,
      action: PayloadAction<{
        entityType: string;
        entityId: string;
      }>
    ) => {
      const { entityType, entityId } = action.payload;
      //@ts-ignore
      delete state.sessionInEdit.edited[entityType][entityId];
    },
    removeEntityModifications: (
      state,
      action: PayloadAction<{
        entityType: string;
        entityId: string;
      }>
    ) => {
      const { entityType, entityId } = action.payload;
      delete state.entityModifications[entityType][entityId];
    },
    removeOutdatedEntities: (
      state,
      action: PayloadAction<{
        entityType: string;
        entityId: string;
      }>
    ) => {
      const { entityType, entityId } = action.payload;
      delete state.outdatedEntities[entityType][entityId];
    },
    setIsConfrimUseEditorModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isConfrimUseEditorModalOpen = action.payload;
    },
    updateEntityModifications: (
      state,
      action: PayloadAction<{
        entityType: string;
        entityId: string;
        fields: string[];
      }>
    ) => {
      const { entityType, entityId, fields } = action.payload;
      state.entityModifications[entityType][entityId] = Array.from(
        new Set([
          ...(state.entityModifications[entityType][entityId] || []),
          ...fields,
        ])
      );
    },
    toggleResetAndSyncEntityModal: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        entityId?: string | null;
        entityType?: EntityType | null;
      }>
    ) => {
      state.resetAndSyncEntityModal = action.payload;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
  },
});

export const {
  setQuestionInEdit,
  setModuleInEdit,
  setModuleGroupInEdit,
  setTipInEdit,
  setCaseTypeInEdit,
  setAdviceInEdit,
  setActiveEntitySearchList,
  setEntitySearchFilter,
  setActiveParentTreeView,
  setIsSessionModalOpen,
  setSessions,
  setIsFetchingSessions,
  setSessionInEdit,
  deleteSession,
  setIsConfrimUseEditorModalOpen,
  removeEntityFromSession,
  removeEntityModifications,
  setEntityModifications,
  setOutdatedEntities,
  removeOutdatedEntities,
  updateEntityModifications,
  toggleResetAndSyncEntityModal,
  setIsDirty,
} = editorSlice.actions;

export const selectQuestionInEdit = (state: RootState) =>
  state.editor.questionInEdit;
export const selectModuleInEdit = (state: RootState) =>
  state.editor.moduleInEdit;
export const selectModuleGroupInEdit = (state: RootState) =>
  state.editor.moduleGroupInEdit;
export const selectTipInEdit = (state: RootState) => state.editor.tipInEdit;
export const selectCaseTypeInEdit = (state: RootState) =>
  state.editor.caseTypeInEdit;
export const selectAdviceInEdit = (state: RootState) =>
  state.editor.adviceInEdit;
export const selectActiveEntitySearchList = (state: RootState) =>
  state.editor.activeEntitySearchList;
export const selectEntitySearchFilter = (state: RootState) =>
  state.editor.entitySearchFilter;
export const selectActiveParentTreeView = (state: RootState) =>
  state.editor.activeParentTreeView;
export const selectIsSessionModalOpen = (state: RootState) =>
  state.editor.isSessionModalOpen;
export const selectIsFetchingSessions = (state: RootState) =>
  state.editor.sessions.isFetching;
export const selectSessionsList = (state: RootState) =>
  state.editor.sessions.ids.map((id) => state.editor.sessions.entities[id]);
export const selectSessionInEdit = (state: RootState) =>
  state.editor.sessionInEdit;
export const selectIsConfirmUseEditorModalOpen = (state: RootState) =>
  state.editor.isConfrimUseEditorModalOpen;

export const selectEntityModifications = (state: RootState) =>
  state.editor.entityModifications;

export const selectSingleEntityModifications = createSelector(
  [
    (state: RootState, entityType: string, entityId: string) => {
      return {
        entityType,
        entityId,
        entityModifications: state.editor.entityModifications,
      };
    },
  ],
  ({ entityType, entityId, entityModifications }) =>
    entityModifications?.[entityType]?.[entityId] || []
);

export const selectEntityTypeHasModifications = (
  state: RootState,
  entityType: string
) => {
  const entityIds = Object.keys(state.editor.entityModifications[entityType]);
  return entityIds.some((entityId) =>
    selectEntityModificationsCount(state, entityType, entityId)
  );
};
export const selectEntityModificationsCount = (
  state: RootState,
  entityType: string,
  entityId: string
) => {
  if (!state.editor.entityModifications[entityType][entityId]) {
    return 0;
  }

  return Object.values(
    state.editor.entityModifications[entityType][entityId] || []
  )?.filter((value) => value).length;
};

export const selectOutdatedEntities = (state: RootState) =>
  state.editor.outdatedEntities;

export const selectResetAndSyncEntityModal = (state: RootState) =>
  state.editor.resetAndSyncEntityModal;
export const selectIsEditorDirty = (state: RootState) => state.editor.isDirty;

export default editorSlice.reducer;

import client from '@/api/client';
//@ts-ignore
import { UpdateModule } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { ModuleId } from '@/store/types/basicTypes';
import { isEmptyTags } from '@/utils/helpers';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editModule = async (moduleId: ModuleId) => {
  const moduleEntity = store.getState().modules.entities[moduleId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: UpdateModule,
    variables: {
      moduleId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        label: moduleEntity?.label,
        description: isEmptyTags(moduleEntity?.description)
          ? ''
          : moduleEntity?.description,
        rootQuestionIds: moduleEntity?.questions,
        role: moduleEntity?.roles,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditModule,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editModule;

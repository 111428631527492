import { useState } from 'react';

export const useTabsHandlerAndIndex = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleOnTabIndexChange = (
    event: React.SyntheticEvent,
    newTabIndexValue: number
  ) => {
    setCurrentTabIndex(newTabIndexValue);
  };

  return {
    currentTabIndex,
    setCurrentTabIndex,
    handleOnTabIndexChange,
  };
};

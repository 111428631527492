import { Routes } from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectProjectId,
  selectIsQuestionnaireActive,
} from '@/store/slices/questionnaire';
import {
  setActivePath,
  setIsConfirmingFinishQuestionnaire,
  setPrintableSummaryStatus,
} from '@/store/slices/ui';
import { useRouter } from 'next/router';
import { Button } from '@s-rm/react-ui-lib';

const NavButton = () => {
  const router = useRouter();
  const projectId = useAppSelector(selectProjectId);
  const pathname = router.pathname.split('/')[1];
  const isCaseTypePage = pathname.includes(Routes.CaseType);
  const isSpecificsPage = pathname.includes(Routes.Specifics);
  const isSummaryPage = pathname.includes(Routes.Summary);
  const isQuestionnaireActive = useAppSelector(selectIsQuestionnaireActive);

  const handleClickFinishQuestionnaire = () =>
    dispatch(setIsConfirmingFinishQuestionnaire(true));

  const dispatch = useAppDispatch();

  const getButtonText = () => {
    let buttonText = 'Proceed to specifics';

    if (isSummaryPage) {
      buttonText = 'Summary preview';
    }
    if (isSpecificsPage) {
      buttonText = 'Proceed to summary';
    }

    return buttonText;
  };

  const handleClick = () => {
    if (isCaseTypePage) {
      router.push(`/specifics/${projectId}`);
      dispatch(setActivePath('specifics'));
    }
    if (isSpecificsPage) {
      router.push(`/summary/${projectId}`);
      dispatch(setActivePath('summary'));
    }
    if (isSummaryPage) {
      dispatch(setPrintableSummaryStatus({ isOpen: true, isAutoCopy: false }));
    }
  };

  return (
    <>
      {isSummaryPage ? (
        <>
          <Button
            variant='outlined'
            sx={{ mr: 1 }}
            data-testid={`nav-button-${getButtonText()
              .toLowerCase()
              .replace(/\s/g, '-')}`}
            onClick={handleClick}
          >
            {getButtonText()}
          </Button>
          <Button
            variant='contained'
            data-testid={`nav-button-finish-intake`}
            onClick={handleClickFinishQuestionnaire}
            disabled={!isQuestionnaireActive}
          >
            {isQuestionnaireActive ? 'Finish intake' : 'Intake finished'}
          </Button>
        </>
      ) : (
        <Button
          variant='contained'
          data-testid={`nav-button-${getButtonText()
            .toLowerCase()
            .replace(/\s/g, '-')}`}
          onClick={handleClick}
        >
          {getButtonText()}
        </Button>
      )}
    </>
  );
};

export default NavButton;

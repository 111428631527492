import DialogContent from '@mui/material/DialogContent';
import { Button, LoadingButton } from '@s-rm/react-ui-lib';

import {
  DialogTitle,
  Typography,
  Dialog,
  DialogActions,
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogueTitleContainer } from '../styled';

const ConfirmMergeSessionModal = ({
  isOpen,
  onConfirm,
  onClose,
  isPublishing,
}: {
  isOpen: boolean;
  isPublishing: boolean;
  onConfirm: () => void;
  onClose: () => void;
}) => {
  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            {isPublishing ? 'Publishing...' : 'Publishing changes'}
          </Typography>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent>
        <Alert severity='warning'>
          <AlertTitle>
            <Typography fontWeight={700}>Warning</Typography>
          </AlertTitle>
          <Typography>
            By continuing with this action all changes made in this draft will
            be published to the live version.
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Box py={2} px={2}>
          <LoadingButton
            loading={isPublishing}
            variant='contained'
            onClick={onConfirm}
          >
            Yes, publish
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMergeSessionModal;

import saveAnswer, { AnswerSaveData } from '../mutations/saveAnswer';
import updateQuestionnaire from '../mutations/updateQuestionnaire';
import SaveDataTypes from '../types/saveDataTypes';

export type SaveData =
  | { type: SaveDataTypes.Case; data?: void }
  | { type: SaveDataTypes.Answer; data: AnswerSaveData };

const saveData = ({ type, data }: SaveData) => {
  if (type === SaveDataTypes.Answer) {
    saveAnswer(data);
  }
  if (type === SaveDataTypes.Case) {
    updateQuestionnaire();
  }
};

export default saveData;

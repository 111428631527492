import { Alert, IconButton, Menu, MenuItem, styled } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from '@s-rm/react-ui-lib';

export const HeaderWrapper = styled('div')<{ isEditMode: boolean }>`
  background-color: ${({ theme, isEditMode }) =>
    isEditMode ? '#FBBA94' : theme.palette.common.black};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 7px;
`;

export const HeaderFlexContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 8px;

  & > img {
    max-width: 150px;
    height: auto;
  }
`;

export const AvatarWrapper = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const NavBarWrapper = styled('div')`
  max-width: 600px;
  flex: 2;
`;

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    overflow: visible;
    padding: 16px 16px 8px 16px;
    background: #f9f9f9;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
    & > .MuiMenu-list {
      padding: 0;
      & > li {
        font-size: 14px;
        padding: 8px 16px;
        &:not(:last-child):hover {
          background: none;
        }
      }
    }
    .MuiAvatar-root {
      width: 55px;
      height: 55px;
    },
  }
`;

export const InfoText = styled('span')`
  font-size: 14px;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  display: block;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const AvatarInnerProfile = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)};
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  & > .MuiAlert-message {
    display: inline-flex;
  }
`;

export const ToggleEditorButtonStyled = styled(Button)`
  && {
    border-color: white;
    color: white;
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const ClearQuestionnaireButton = styled(Button)<{ isEditMode: boolean }>`
  ${({ isEditMode, theme }) =>
    !isEditMode &&
    `
    && {
      border-color: white;
      color: white;
      &:hover {
        color: ${theme.palette.primary.main};
      }
    }
  `}
`;

export const MenuItemStyled = styled(MenuItem)`
  padding: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
`;

export const StyledIconButton = styled(IconButton)<{ isEditMode: boolean }>`
  background-color: ${({ theme, isEditMode }) =>
    isEditMode ? theme.palette.primary.light : '#737373CE'};
`;

export const StyledSettingsIcon = styled(SettingsIcon)<{ isEditMode: boolean }>`
  color: ${({ isEditMode }) => (isEditMode ? '#3A3D40' : '#FFF')};
  font-size: 22px;
`;

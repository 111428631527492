import { Card, CardContent } from '@mui/material';
import { ReactNode } from 'react';

const OverlayCard = ({ children }: { children: ReactNode }) => (
  <Card>
    <CardContent style={{ textAlign: 'center' }}>{children}</CardContent>
  </Card>
);

export default OverlayCard;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { ListActions } from '../styled';
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectEntityModificationsCount,
  selectOutdatedEntities,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setModuleInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { EntityType, ModuleId } from '@/store/types/basicTypes';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@s-rm/react-ui-lib';
import { selectModule } from '@/store/slices/modules';
import { SecondaryBadge } from '../../styled';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

const ModuleListItem = ({
  id,
  onDelete,
}: {
  id: ModuleId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { label } = useAppSelector((state) => selectModule(state, id)) || {
    label: '',
  };
  const entityModifications = useAppSelector((state) =>
    selectEntityModificationsCount(state, 'modules', id)
  );
  const isOutdated = !!useAppSelector(selectOutdatedEntities).modules[id];

  const handleClickModule = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setModuleInEdit({ id }));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType: EntityType.Module,
      })
    );
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button
            square
            onClick={handleClickParentView}
            data-testid='btn-module-parent-tree'
          >
            <AccountTreeOutlinedIcon />
          </Button>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-module-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickModule}
      >
        <Box position='absolute' top='-10px'>
          <Tooltip title='Current draft contains changes to this module'>
            <Badge color='secondary' badgeContent={entityModifications} />
          </Tooltip>
          <Tooltip title='This module is out of date with the latest published draft'>
            <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
          </Tooltip>
        </Box>
        <ListItemText>
          <Typography noWrap>{removeAllTags(label)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default ModuleListItem;

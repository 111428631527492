import client from '@/api/client';
//@ts-ignore
import { ClearCase } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';

export const clearQuestionnaire = async (questionnaireId?: string) => {
  const { data, errors } = await client.mutate({
    mutation: ClearCase,
    variables: {
      projectId: store.getState().questionnaire.projectId,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.UpdateCase,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { clearCase: data?.clearCase ?? null };
};

export default clearQuestionnaire;

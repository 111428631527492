import { AppDispatch, RootState } from '../store';
import { ModuleId } from '../types/basicTypes';
import {
  ComponentReferenceObject,
  QuestionTrigger,
  QuestionTriggerActions,
  QuestionTriggerComponents,
} from '../types/question';
import getIsDuplicateTrigger from '../utils/getIsDuplicateTrigger';
import validateTriggers from '../utils/validateTriggers';
import addTriggeredModulesThunk from './addTriggeredModules';
import removeTriggeredModulesThunk from './removeTriggeredModules';

/**
 * The triggering of modules is a recursive process, and should be run AFTER
 * the questions have been updated. This is because we want a finalised view
 * on the state of the questions before we trigger any modules.
 *
 * The process is as follows:
 *
 * If the question is not visible, skip it - A non visible question should have
 *    no impact on any modules or any questions preceding it.
 * If the question is visible, check if it has any triggers  - check if it does
 * then check if it's activated (valid).
 *
 * All activated triggers should show / hide their respective modules.
 *
 * All inactive triggers (triggers associated with question but not active)
 * should do the oposite of their associated action (i.e. hide if show, show if hide).
 *
 * The reason for this is i.e: If we have a Yes / No question, and there is a trigger
 * related to the value Yes to show a module - user selects Yes, module shows.
 * User selects No - module needs to hide (the opposite of the actual triggers' action).
 * If we didn't have this logic, the module would remain visible.
 */
const triggerModules = ({
  selectedModules,
  dispatch,
  getState,
}: {
  selectedModules: ModuleId[];
  dispatch: AppDispatch;
  getState: () => RootState;
}) => {
  const manuallySelectedModules = getState().modules.manuallySelected;

  selectedModules.forEach((selectedModuleId: string) => {
    // If the module was manually selected, keep it agnostic
    // to any triggers. It operates in isolation.
    if (manuallySelectedModules.includes(selectedModuleId)) {
      return;
    }

    const moduleQuestionDisplayOrder =
      getState().questionDisplayOrder[selectedModuleId];
    const questionsVisibility = getState().questions.questionsVisibility;

    moduleQuestionDisplayOrder.forEach(
      (questionRef: ComponentReferenceObject) => {
        // Do nothing if the question is not visible
        if (!questionsVisibility[questionRef.id]) return;

        const value = getState().questions.values[questionRef.id];

        // Collect all Module triggers for this question
        const triggers =
          getState().questions.entities[questionRef.id]?.triggers.filter(
            (t) => t.componentType === QuestionTriggerComponents.Module
          ) || [];

        // Find triggers that pass validation and should be used.
        const activatedTriggers = validateTriggers({ triggers, value }).map(
          (componentId) =>
            triggers.find(
              (t) => t.componentId === componentId
            ) as QuestionTrigger
        );

        // Any triggers that did not pass validation should be used
        // as inactive triggers.
        const inactiveTriggers = triggers.filter(
          (t) => !activatedTriggers.includes(t)
        );

        // Do as the tiggers action says for all activated triggers
        activatedTriggers.forEach((trigger) => {
          const isShow = trigger.action === QuestionTriggerActions.Show;
          const payload = {
            moduleIdToTrigger: trigger.componentId,
            triggeringModuleId: selectedModuleId,
          };

          dispatch(
            isShow
              ? addTriggeredModulesThunk(payload)
              : removeTriggeredModulesThunk(payload)
          );
        });

        // Do the opposite of the triggers action for all inactive triggers
        // Reasoning is in the function description.
        inactiveTriggers.forEach((trigger) => {
          // We might have multiple options which yeild the same trigger action and module.
          // In this case DON'T want to do the opposite of the action,
          // as it could undo what is done by the activated trigger on the same module
          const isDuplicateOfActiveTrigger = getIsDuplicateTrigger({
            triggersToCompare: activatedTriggers,
            trigger,
          });
          if (isDuplicateOfActiveTrigger) {
            return;
          }

          const isShow = trigger.action === QuestionTriggerActions.Show;
          const payload = {
            moduleIdToTrigger: trigger.componentId,
            triggeringModuleId: selectedModuleId,
          };

          dispatch(
            isShow
              ? removeTriggeredModulesThunk(payload)
              : addTriggeredModulesThunk(payload)
          );
        });
      }
    );
  });
};

export default triggerModules;

import {
  AdminSlice,
  Contributor,
  defaultFiltersObject,
} from '@/store/types/admin';
import {
  UpdateFilterPayload,
  updateFilters,
} from '@/store/utils/updateFilters';
import { PayloadAction } from '@reduxjs/toolkit';

const contributorsReducers = {
  setContributors: (
    state: AdminSlice,
    action: PayloadAction<Contributor[]>
  ) => {
    state.contributors.entities = action.payload.reduce((acc, contributor) => {
      acc[contributor.id] = contributor;
      return acc;
    }, {} as Record<string, Contributor>);
    state.contributors.ids = action.payload.map((v) => v.id);
  },
  updateContributorFilters: (
    state: AdminSlice,
    action: PayloadAction<UpdateFilterPayload>
  ) => {
    state.contributors.filters = updateFilters(
      state.contributors.filters,
      action.payload
    );
  },
  setIsLoadingContributors: (
    state: AdminSlice,
    action: PayloadAction<boolean>
  ) => {
    state.contributors.isLoading = action.payload;
  },
  resetContributorsFilters: (state: AdminSlice) => {
    state.contributors.filters = defaultFiltersObject;
  },
};

export default contributorsReducers;

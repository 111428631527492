import React, { useState, useEffect, ReactNode } from 'react';
import { FeatureFlagContext } from './Context';
import getFlagStatusFromLocalStorage from './utils/getFlagStatusFromLocalStorage';
import { FeatureFlag } from './types';
import isFeatureFlagOn from './utils/isFeatureFlagOn';
import { createFlag, deleteFlag, getFlags, updateFlagStatus } from './api';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectHasAuthenticated,
  setFeatureFlags as setFeatureFlagsToStore,
} from '@/store/slices/config';

export const FeatureFlagProvider = ({
  children,
  endpoint,
  onFetchFlags,
}: {
  children: ReactNode;
  endpoint: string;
  onFetchFlags?: ({ success }: { success: boolean }) => void;
}) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);
  const [hasFetchedFlags, setHasFetchedFlags] = useState(false);
  const hasAuthenticated = useAppSelector(selectHasAuthenticated);
  const dispatch = useAppDispatch();
  const handleGetFlags = async () => {
    // @ts-ignore
    const { flags } = await getFlags(endpoint);
    if (Array.isArray(flags)) {
      setFeatureFlags(
        flags.map((flag) => ({
          ...flag,
          activeInSession: getFlagStatusFromLocalStorage(flag.name),
        }))
      );
      dispatch(
        setFeatureFlagsToStore(
          flags.reduce(
            (acc, flag) => ({ ...acc, [flag.name]: flag.active }),
            {}
          )
        )
      );
    } else {
      console.error('Error getting feature flags');
    }

    setHasFetchedFlags(true);

    onFetchFlags?.({ success: !!flags });
  };

  const handleCreateFlag = async (flag: FeatureFlag) => {
    setFeatureFlags((flags) => [flag!, ...flags]);
    return await createFlag(flag, endpoint);
  };

  const handleDeleteFlag = async (flagName: string) => {
    setFeatureFlags((flags) => flags.filter((flag) => flag.name !== flagName));
    return await deleteFlag(flagName, endpoint);
  };

  const handleUpdateFlagStatus = async ({
    env,
    active,
    name,
  }: {
    env: string;
    active: boolean;
    name: string;
  }) => {
    const isSessionFlag = env === 'session';
    if (isSessionFlag) {
      localStorage.setItem(name, JSON.stringify(active));
    }

    const updates = isSessionFlag ? { activeInSession: active } : { active };

    setFeatureFlags((flags) =>
      flags.map((flag) => (flag.name === name ? { ...flag, ...updates } : flag))
    );

    if (isSessionFlag) {
      return Promise.resolve({ success: true });
    }
    return await updateFlagStatus(name, active, endpoint);
  };

  useEffect(() => {
    if (hasAuthenticated) {
      handleGetFlags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAuthenticated]);

  const value = {
    featureFlags,
    hasFetchedFlags,
    isFeatureFlagOn: (name: string) => isFeatureFlagOn(featureFlags, name),
    createFlag: handleCreateFlag,
    deleteFlag: handleDeleteFlag,
    updateFlagStatus: handleUpdateFlagStatus,
  };

  return (
    // @ts-ignore
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagContext);
  return context;
};

import { ModuleId } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import { debounce } from 'lodash';
import { updateCaseType } from '@/store/slices/caseTypes';
import editCaseType from '@/api/mutations/editor/editCaseType';
import { CaseType } from '@/store/types/caseType';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';

const debouncedEditCaseType = debounce((id) => {
  editCaseType(id);
}, 1000);

const updateCaseTypeThunk =
  ({
    id,
    updates,
    shouldDebounce,
  }: {
    id: ModuleId;
    updates: Partial<CaseType>;
    shouldDebounce?: boolean;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setIsDirty(true));
    dispatch(updateCaseType({ id, updates }));
    dispatch(
      updateEntityModifications({
        entityType: 'caseTypes',
        entityId: id,
        // Don't include triggered modules because it is not a UI field
        fields: Object.keys(updates).filter(
          (key) => key !== 'triggeredModules'
        ),
      })
    );

    if (shouldDebounce) {
      debouncedEditCaseType(id);
    } else {
      editCaseType(id);
    }
  };

export default updateCaseTypeThunk;

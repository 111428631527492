import client from '@/api/client';
//@ts-ignore
import { CreateJiraIssue } from '@/api/gql/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { store } from '@/store';
import { createError } from '@/utils/errorHandler';

const makeJiraIssue = async () => {
  const { data, errors } = await client.mutate({
    mutation: CreateJiraIssue,
    variables: {
      projectId: store.getState().questionnaire.projectId,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.TriggerIntegration,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { createJiraIssue: data?.createJiraIssue ?? null };
};

export default makeJiraIssue;

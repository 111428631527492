export const isDevelopment =
  process.env.ENV === 'development' ||
  process.env.NEXT_PUBLIC_ENV === 'development';
export const isIntegration =
  process.env.ENV === 'integration' ||
  process.env.NEXT_PUBLIC_ENV === 'integration';
export const isStaging =
  process.env.ENV === 'staging' || process.env.NEXT_PUBLIC_ENV === 'staging';
export const isProduction =
  process.env.ENV === 'production' ||
  process.env.NEXT_PUBLIC_ENV === 'production';
export const isUsingFixtures =
  process.env.NEXT_PUBLIC_USE_FIXTURES === 'true' ||
  process.env.USE_FIXTURES === 'true';
export const isUsingNewApi =
  process.env.NEXT_PUBLIC_USE_NEW_API === 'true' ||
  process.env.USE_NEW_API === 'true';
export const apiUrl = process.env.API_URL || process.env.NEXT_PUBLIC_API_URL;
export const isPreviewEnv = (
  process.env.ENV || process.env.NEXT_PUBLIC_ENV
)?.includes('pr-');

export const envApiKey = process.env.API_KEY || process.env.NEXT_PUBLIC_API_KEY;
export const isApiKeyCompatibleEnv =
  isDevelopment || isPreviewEnv || isIntegration;

import { Button } from '@s-rm/react-ui-lib';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

const ResetButton = ({
  onClick,
  testId,
}: {
  onClick: () => void;
  testId?: string;
}) => {
  return (
    <Button
      data-testid={testId ? testId : 'reset-button'}
      sx={{ color: 'text.primary' }}
      onClick={onClick}
      endIcon={<SyncOutlinedIcon />}
    >
      Reset & Sync
    </Button>
  );
};

export default ResetButton;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { ListActions } from '../styled';
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectEntityModificationsCount,
  selectOutdatedEntities,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setTipInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { EntityType, ModuleId } from '@/store/types/basicTypes';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@s-rm/react-ui-lib';
import { selectTip } from '@/store/slices/tips';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { SecondaryBadge } from '../../styled';

const TipListItem = ({
  id,
  onDelete,
}: {
  id: ModuleId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { text } = useAppSelector((state) => selectTip(state, id)) || {
    text: '',
  };
  const entityModifications = useAppSelector((state) =>
    selectEntityModificationsCount(state, 'tips', id)
  );

  const isOutdated = !!useAppSelector(
    (state) => selectOutdatedEntities(state).tips[id]
  );

  const handleClickTip = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setTipInEdit({ id }));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType: EntityType.Question,
      })
    );
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button
            square
            onClick={handleClickParentView}
            data-testid='btn-parent-tree-tip'
          >
            <AccountTreeOutlinedIcon />
          </Button>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-tip-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickTip}
      >
        <Box position='absolute' top='-10px'>
          <Tooltip title='Current draft contains changes to this tip'>
            <Badge color='secondary' badgeContent={entityModifications} />
          </Tooltip>
          <Tooltip title='This tip is out of date with the latest published draft'>
            <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
          </Tooltip>
        </Box>
        <ListItemText>
          <Typography noWrap>{removeAllTags(text)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default TipListItem;

import { TabPanelProps } from './types';
import Box from '@mui/material/Box';

export const TabPanel = (props: TabPanelProps) => {
  const { children, currentIndex, tabIndex, ...rest } = props;
  return (
    <div
      role='tabpanel'
      hidden={currentIndex !== tabIndex}
      id={`tabpanel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
      {...rest}
    >
      {currentIndex === tabIndex && <Box>{children}</Box>}
    </div>
  );
};

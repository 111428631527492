import {
  deleteQuestion,
  deleteQuestionValues,
  deleteQuestionsVisibility,
  updateQuestion,
} from '@/store/slices/questions';
import { AppThunk } from '@/store/store';
import archiveQuestion from '@/api/mutations/editor/archiveQuestion';
import { updateModule } from '@/store/slices/modules';
import { removeEntitiesFromQuestionDisplayOrder } from '@/store/slices/questionDisplayOrder';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveQuestionThunk =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const modules = getState().modules.entities;
    const questions = getState().questions.entities;
    const questionFilter = getState().editor.entitySearchFilter;
    const isSessionOnly = getState().questions.entities[id].isSessionOnly;

    !isSessionOnly
      ? await archiveQuestion(id)
      : await resetEditorSessionChange(id);

    dispatch(setIsDirty(true));
    dispatch(deleteQuestionsVisibility([id]));
    dispatch(deleteQuestionValues([id]));
    dispatch(removeEntitiesFromQuestionDisplayOrder(id));
    dispatch(
      setEntitySearchFilter({
        ...questionFilter,
        entityIds: questionFilter.entityIds?.filter((qId) => qId !== id),
      })
    );

    Object.values(modules).forEach((m) => {
      if (m.questions.includes(id)) {
        dispatch(
          updateModule({
            id: m.id,
            updates: {
              questions: modules[m.id].questions.filter((qId) => qId !== id),
            },
          })
        );
      }
    });

    Object.values(questions).forEach((q) => {
      if (q.triggers.some((t) => t.componentId === id)) {
        dispatch(
          updateQuestion({
            id: q.id,
            updates: {
              triggers: questions[q.id].triggers.filter(
                (t) => t.componentId !== id
              ),
            },
          })
        );
      }
    });

    dispatch(deleteQuestion(id));
  };

export default archiveQuestionThunk;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { ListActions } from '../styled';
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectEntityModificationsCount,
  selectOutdatedEntities,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setAdviceInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { AdviceId, EntityType } from '@/store/types/basicTypes';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@s-rm/react-ui-lib';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { selectAdvice } from '@/store/slices/advice';
import { SecondaryBadge } from '../../styled';

const AdviceListItem = ({
  id,
  onDelete,
}: {
  id: AdviceId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { text } = useAppSelector((state) => selectAdvice(state, id)) || {
    text: '',
  };
  const entityModifications = useAppSelector((state) =>
    selectEntityModificationsCount(state, 'advice', id)
  );
  const isOutdated = !!useAppSelector(selectOutdatedEntities).advice[id];
  const handleClickTip = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setAdviceInEdit({ id }));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType: EntityType.Advice,
      })
    );
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button
            square
            onClick={handleClickParentView}
            data-testid='btn-parent-tree-advice'
          >
            <AccountTreeOutlinedIcon />
          </Button>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-advice-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickTip}
      >
        <Box position='absolute' top='-10px'>
          <Tooltip title='Current draft contains changes to this advice'>
            <Badge
              color='secondary'
              badgeContent={entityModifications}
              data-testid={`entity-modification-${entityModifications}`}
            />
          </Tooltip>
          <Tooltip title='This advice is out of date with the latest published draft'>
            <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
          </Tooltip>
        </Box>
        <ListItemText>
          <Typography noWrap>{removeAllTags(text)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default AdviceListItem;

import {
  envApiKey,
  isDevelopment,
  isIntegration,
  isPreviewEnv,
} from '@/constants/env';
import { getRootPathRequiresAuth } from '@/utils/router';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import { setHasAuthenticated } from '@/store/slices/config';
import { store } from '@/store';

const useAuthenticate = async () => {
  const router = useRouter();

  const handleAuthenticate = () => {
    const routeRequiresAuth = getRootPathRequiresAuth(router);
    const hasJWT = !!Cookie.get('jwt');
    const shouldUseApiKey =
      (!!router.query.useApiKey && isIntegration) ||
      isDevelopment ||
      isPreviewEnv;

    if (shouldUseApiKey) {
      localStorage.setItem('apiKey', envApiKey as string);
      store.dispatch(setHasAuthenticated(true));
      return;
    }
    if (hasJWT) {
      store.dispatch(setHasAuthenticated(true));
      return;
    }
    if (!hasJWT && routeRequiresAuth) {
      // We only want to redirect if the user is on a page that requires authentication
      // because we could be on a /auth page and redirecting would cause an infinite loop.
      router.push(`/auth/verify?redirect=${window.location.href}`);
    }
  };

  useEffect(() => {
    if (router.isReady) {
      handleAuthenticate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return null;
};

export default useAuthenticate;

import { CaseTypeId, ModuleId } from '@/store/types/basicTypes';
import { CaseType } from '@/store/types/caseType';

const getModulesByCaseTypesFromApi = ({
  caseTypes,
  selectedCaseTypes,
}: {
  caseTypes: CaseType[];
  selectedCaseTypes: CaseTypeId[];
}): ModuleId[] =>
  selectedCaseTypes.reduce(
    (acc, caseTypeId) => [
      ...acc,
      ...(caseTypes.find((c) => c.id === caseTypeId)?.modules || []),
    ],
    [] as string[]
  );

export default getModulesByCaseTypesFromApi;

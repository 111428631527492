import {
  removeSelectedCaseTypes,
  removeSuggestedCaseTypes,
} from '../slices/caseTypes';
import { AppThunk } from '../store';
import getCaseClosureCaseType from '../utils/getCaseClosureCaseType';

const removeCaseClosureThunk = (): AppThunk => (dispatch, getState) => {
  const caseTypes = getState().caseTypes.entities;
  const caseClosureId = getCaseClosureCaseType(caseTypes);

  dispatch(removeSelectedCaseTypes([caseClosureId]));
  dispatch(removeSuggestedCaseTypes([caseClosureId]));
};

export default removeCaseClosureThunk;

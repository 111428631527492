import { styled } from '@mui/material';

export const SelectedQuestion = styled('div')`
  padding: ${({ theme }) =>
    `${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(
      1
    )} ${theme.spacing(1.5)}`};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
`;

export const QuestionItemWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1.2)};
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

import { AppThunk } from '@/store/store';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import { deleteAdvice } from '@/store/slices/advice';
import { removeAdviceFromTips } from '@/store/slices/tips';
import archiveAdvice from '@/api/mutations/editor/archiveAdvice';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveAdviceThunk =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const searchFilter = getState().editor.entitySearchFilter;
    const isSessionOnly = getState().advice.entities[id].isSessionOnly;

    !isSessionOnly
      ? await archiveAdvice(id)
      : await resetEditorSessionChange(id);

    dispatch(setIsDirty(true));

    dispatch(
      setEntitySearchFilter({
        ...searchFilter,
        entityIds: searchFilter.entityIds?.filter(
          (adviceId) => adviceId !== id
        ),
      })
    );

    dispatch(removeAdviceFromTips({ adviceId: id }));
    dispatch(deleteAdvice(id));
  };

export default archiveAdviceThunk;

import { ContributorStatus, UserRole } from '@/store/types/admin';

export interface RawContributorApiData {
  id: string;
  name: string;
  email: string;
  roles: UserRole[];
  organization: string;
  status: ContributorStatus;
  completionRate: number;
}

const getFormattedContributors = (data: RawContributorApiData[]) => {
  return data.map((contributor) => {
    return {
      id: contributor.id,
      name: contributor.name,
      email: contributor.email,
      roles: contributor.roles,
      organization: contributor.organization,
      status: contributor.status,
      completionRate: contributor.completionRate,
    };
  });
};

export default getFormattedContributors;

import { ControlTypes } from '@/store/types/question';

export const questionTypeOptions = [
  { value: ControlTypes.Radio, label: 'Radio' },
  { value: ControlTypes.Textarea, label: 'Free text' },
  { value: ControlTypes.MultiSelect, label: 'Multi-select' },
  { value: ControlTypes.MultiSelectSearch, label: 'Multi-select search' },
  { value: ControlTypes.Numeric, label: 'Numeric' },
  { value: ControlTypes.Datepicker, label: 'Datepicker' },
  { value: ControlTypes.Daterange, label: 'Daterange' },
  { value: ControlTypes.Timepicker, label: 'Timepicker' },
  { value: ControlTypes.Currency, label: 'Currency' },
];

export default questionTypeOptions;

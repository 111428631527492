import { Box, ListItemText } from '@mui/material';
import {
  ListItem,
  ListItemButton,
  SelectionHeader,
  SelectionTitle,
} from './styled';
import { Button, Checkbox } from '@s-rm/react-ui-lib';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectQuestionEntitiesAsArray } from '@/store/slices/questions';
import SearchInput from '@/components/SearchInput';
import Popover from '@/components/Popover';
import { EntityType, StateEntities } from '@/store/types/basicTypes';
import { setActiveParentTreeView } from '@/store/slices/editor';
import { Question } from '@/store/types/question';
import {
  getEntityTypeAsText,
  pluraliseString,
  removeAllTags,
} from '@/utils/helpers';
import { selectAllModulesAsArray } from '@/store/slices/modules';
import { selectAllTipsAsArray } from '@/store/slices/tips';
import useSearch from '@/hooks/useSearch';
import { Module } from '@/store/types/module';
import { Tip } from '@/store/types/tip';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import { ChangeEvent, ReactNode } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { selectIndicatorEntitiesAsArray } from '@/store/slices/indicators';
import { Indicator } from '@/store/types/indicator';
import { selectAllAdviceAsArray } from '@/store/slices/advice';
interface QuestionTriggerMenuProps {
  selectedEntityIds: string[];
  componentType: EntityType;
  Trigger: ReactNode;
  onSelect: (id: string) => void;
  invalidIds?: string[];
}

const EntitySelectMenu = ({
  selectedEntityIds,
  componentType,
  Trigger,
  onSelect,
  invalidIds,
}: QuestionTriggerMenuProps) => {
  const questions = useAppSelector(selectQuestionEntitiesAsArray);
  const modules = useAppSelector(selectAllModulesAsArray);
  const tips = useAppSelector(selectAllTipsAsArray);
  const indicators = useAppSelector(selectIndicatorEntitiesAsArray);
  const advice = useAppSelector(selectAllAdviceAsArray);
  let entities = {};

  switch (componentType) {
    case EntityType.Module:
      entities = modules;
      break;
    case EntityType.Tip:
      entities = tips;
      break;
    case EntityType.Advice:
      entities = advice;
      break;
    case EntityType.Indicator:
      entities = indicators.map((item) => ({
        ...item,
        entityType: EntityType.Indicator,
      }));
      break;
    default:
      entities = questions;
      break;
  }

  const dispatch = useAppDispatch();

  const { searchResults, setSearchTerm, searchTerm } = useSearch<
    Question | Module | Tip | Indicator
  >({
    searchableEntities: (
      convertEntitiesToArray(entities) as (
        | Tip
        | Question
        | Module
        | Indicator
      )[]
    ).filter((e) => !invalidIds?.includes(e.id)),
    searchKeys: [
      componentType === EntityType.Tip || componentType === EntityType.Advice
        ? 'text'
        : 'label',
    ],
  });

  const handleClickParentView = ({
    id,
    entityType,
  }: {
    id: string;
    entityType: EntityType;
  }) => {
    dispatch(
      setActiveParentTreeView({
        entityId: id,
        entityType,
      })
    );
  };

  const handleClose = () => {
    setSearchTerm('');
  };

  return (
    <Popover onClose={handleClose} Trigger={Trigger} width={900}>
      <SelectionHeader>
        <SelectionTitle>
          {pluraliseString(getEntityTypeAsText(componentType))}
        </SelectionTitle>
        <SearchInput
          value={searchTerm}
          onChange={(value) => setSearchTerm(value as string)}
        />
      </SelectionHeader>
      <FixedSizeList
        height={400}
        width='100%'
        itemSize={50}
        itemCount={searchResults.length}
        overscanCount={10}
      >
        {({ index, style }: ListChildComponentProps) => {
          const { id, label, text } = searchResults[index] as StateEntities;
          const entityType = searchResults[index].entityType as EntityType;
          const isSelected = selectedEntityIds.includes(id);
          return (
            <ListItem
              style={style}
              data-testid={`select-${componentType.toLocaleLowerCase()}-${id}`}
              aria-selected={isSelected}
              key={id}
              secondaryAction={
                <Box display='flex' alignItems='center' gap={2}>
                  <Button
                    square
                    onClick={() => handleClickParentView({ id, entityType })}
                  >
                    <AccountTreeOutlinedIcon />
                  </Button>
                </Box>
              }
              disablePadding
            >
              <Box ml={2}>
                <Checkbox
                  role='checkbox'
                  data-testid={`${componentType.toLocaleLowerCase()}-checkbox`}
                  checked={isSelected}
                  onClick={() => onSelect(id)}
                />
              </Box>
              <ListItemButton
                onClick={() => onSelect(id)}
                style={{
                  marginRight: 90,
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  primary={removeAllTags(label || text!)}
                />
              </ListItemButton>
            </ListItem>
          );
        }}
      </FixedSizeList>
    </Popover>
  );
};

export default EntitySelectMenu;

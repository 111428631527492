import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { RootState } from '../store';
import { Client, Project } from '../types/questionnaire';

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: initialState.questionnaire,
  reducers: {
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setDescriptionAtTimeOfSuggestIndicators: (
      state,
      action: PayloadAction<string>
    ) => {
      state.descriptionPreSuggestedIndicators = action.payload;
    },
    setCaseId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
    },
    // Note:
    // This action will be depricated, this field is effectively being..
    // used to control the case closure active state.
    // This action will be replaced by setIsQuestionnaireActive.
    setIsCaseActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setIsQuestionnaireActive: (state, action: PayloadAction<boolean>) => {
      // Note:
      // This will replace "setIsCaseActive" once that action is depricated.
      // And will be used to control the active state of a questionnaire. (i.e state.isActive = action.payload).
      state.isIntakeActive = action.payload;
    },
    setQuestionnaireStartedAt: (
      state,
      action: PayloadAction<null | string>
    ) => {
      // Note:
      // This should become state.startedAt as intake is..
      // no longer applicable in questionnaire and will be a depricated field.
      state.intakeTime = action.payload;
    },
    setQuestionnaireCompletedAt: (
      state,
      action: PayloadAction<null | string>
    ) => {
      state.completedAt = action.payload;
    },
    setClosureTime: (state, action: PayloadAction<string | null>) => {
      state.closureTime = action.payload;
    },
    setTriggeredIntegrations: (state, action: PayloadAction<string[]>) => {
      state.triggeredIntegrations = action.payload;
    },
    setIsDiagnosisEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDiagnosisEnabled = action.payload;
    },
    setIsNotesEnabled: (state, action: PayloadAction<boolean>) => {
      state.isNotesEnabled = action.payload;
    },
    setIsAddModulesEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAddModulesEnabled = action.payload;
    },
    setConfigLabel: (state, action: PayloadAction<string>) => {
      state.configLabel = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string>) => {
      state.projectId = action.payload;
    },
    setQuestionnaireId: (state, action: PayloadAction<string>) => {
      state.questionnaireId = action.payload;
    },
  },
});

export const {
  setDescription,
  setCaseId,
  setProject,
  setClient,
  setIsCaseActive,
  setDescriptionAtTimeOfSuggestIndicators,
  setQuestionnaireStartedAt,
  setQuestionnaireCompletedAt,
  setClosureTime,
  setTriggeredIntegrations,
  setIsQuestionnaireActive,
  setIsDiagnosisEnabled,
  setIsNotesEnabled,
  setIsAddModulesEnabled,
  setConfigLabel,
  setProjectId,
  setQuestionnaireId,
} = questionnaireSlice.actions;

export const selectQuestionnaireDesription = (state: RootState) =>
  state.questionnaire.description;
export const selectDesriptionPreSuggestedIndicators = (state: RootState) =>
  state.questionnaire.descriptionPreSuggestedIndicators;
export const selectCaseId = (state: RootState) => state.questionnaire.id;
export const selectProjectId = (state: RootState) =>
  state.questionnaire.projectId;
export const selectQuestionnaireId = (state: RootState) =>
  state.questionnaire.questionnaireId;
export const selectProject = (state: RootState) => state.questionnaire.project;
export const selectClient = (state: RootState) => state.questionnaire.client;
// Responsible for toggling the active state for case closure.
// Down the road this will modified and become the active state for the questionnaire.
export const selectIsCaseActive = (state: RootState) =>
  state.questionnaire.isActive;
// ***
export const selectIsQuestionnaireActive = (state: RootState) =>
  // TODO: will become state.questionnaire.isActive
  state.questionnaire.isIntakeActive;
export const selectQuestionnaireTime = (state: RootState) =>
  // TODO: will become state.questionnaire.startedAt -> (selectQuestionnaireStartedAt)
  state.questionnaire.intakeTime;
export const selectClosureTime = (state: RootState) =>
  // TODO: will become state.questionnaire.completedAt -> (selectQuestionnaireCompletedAt)
  state.questionnaire.closureTime;
export const selectTriggeredIntegrations = (state: RootState) =>
  state.questionnaire.triggeredIntegrations;
export const selectIsDiagnosisEnabled = (state: RootState) =>
  state.questionnaire.isDiagnosisEnabled;
export const selectIsNotesEnabled = (state: RootState) =>
  state.questionnaire.isNotesEnabled;
export const selectIsAddModulesEnabled = (state: RootState) =>
  state.questionnaire.isAddModulesEnabled;

export default questionnaireSlice.reducer;

import { ErrorCodes, errors } from '@/constants/errors';
import { store } from '@/store';
import { setError } from '@/store/slices/config';
import { setRefreshDialogStatus } from '@/store/slices/ui';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';
import Bugsnag from '@bugsnag/js';

export const createError = ({
  code,
  error,
  displaySnackbar,
  displayRefreshDialog,
}: {
  code: ErrorCodes;
  error?: Error;
  displaySnackbar?: boolean;
  displayRefreshDialog?: boolean;
}) => {
  store.dispatch(setError({ code, error }));

  const message =
    errors[code as keyof typeof errors]?.message ||
    errors[ErrorCodes.Unknown].message;

  if (displaySnackbar) {
    store.dispatch(
      updateSnackbarNotification({ message, type: SnackbarTypes.Error })
    );
  }

  if (displayRefreshDialog) {
    store.dispatch(setRefreshDialogStatus({ isOpen: true }));
  }

  Bugsnag.notify(error ?? message);
};

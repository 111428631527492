import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@s-rm/react-ui-lib';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectAdviceInEdit,
  selectSingleEntityModifications,
  setActiveEntitySearchList,
  setActiveParentTreeView,
  setModuleInEdit,
  toggleResetAndSyncEntityModal,
  setTipInEdit,
  setAdviceInEdit,
} from '@/store/slices/editor';
import { DialogueTitleContainer } from '../../styled';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { EntityType } from '@/store/types/basicTypes';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import ModalContent from './ModalContent';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import { selectAdvice } from '@/store/slices/advice';
import ResetButton from '../ResetButton';

const EditAdviceModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();

  const { isNew, id: adviceIdInEdit } = useAppSelector(selectAdviceInEdit);
  const isSessionOnly = useAppSelector((state) =>
    selectAdvice(state, adviceIdInEdit!)
  )?.isSessionOnly;

  const modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'advice', adviceIdInEdit!)
  );

  const handleClose = () => {
    dispatch(setAdviceInEdit({ id: null, isNew: false }));
    dispatch(refreshDisplayThunk());
  };

  const handleBack = () => {
    dispatch(setActiveEntitySearchList(EntityType.Advice));
    dispatch(setTipInEdit({ id: null, isNew: false }));
  };

  const handleClickParentView = () => {
    dispatch(
      setActiveParentTreeView({
        entityId: adviceIdInEdit,
        entityType: EntityType.Advice,
      })
    );
  };

  const handleReset = () => {
    dispatch(
      toggleResetAndSyncEntityModal({
        isOpen: true,
        entityId: adviceIdInEdit,
        entityType: EntityType.Advice,
      })
    );
  };

  return (
    <EditorDialog open={isOpen} maxWidth='md' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Button
            onClick={handleBack}
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            data-testid='btn-edit-advice-back'
          >
            Back
          </Button>

          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            {`${isNew ? 'Add' : 'Edit'}`} Advice
          </Typography>
          <Box display='flex' gap={2}>
            {!isSessionOnly && !!modifications.length && (
              <ResetButton
                onClick={handleReset}
                testId='btn-reset-sync-advice'
              />
            )}
            <Button
              square
              onClick={handleClickParentView}
              data-testid='btn-open-parent-view'
            >
              <AccountTreeOutlinedIcon />
            </Button>
            <Button onClick={handleClose} data-testid='btn-close-edit-advice'>
              <CloseIcon />
            </Button>
          </Box>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent style={{ padding: 0 }}>
        {isOpen && <ModalContent />}
      </DialogContent>
    </EditorDialog>
  );
};

export default EditAdviceModal;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';
import { Question, QuestionAnswerValue } from '../types/question';
import { QuestionId } from '../types/basicTypes';
import { omit } from 'lodash';

export const questionSlice = createSlice({
  name: 'questions',
  initialState: initialState.questions,
  reducers: {
    setQuestions: (
      state,
      action: PayloadAction<{
        entities: { [key: string]: Question };
        ids: string[];
      }>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
      // set initial versions for each question
      action.payload.ids.forEach((id) => {
        state.versions[id] = action.payload.entities[id].version;
      });
    },
    addQuestion: (state, action: PayloadAction<Question>) => {
      state.entities[action.payload.id] = action.payload;
      state.ids.push(action.payload.id);
      state.versions[action.payload.id] = action.payload.version;
    },
    deleteQuestion: (state, action: PayloadAction<QuestionId>) => {
      state.ids = state.ids.filter((id) => id !== action.payload);
      delete state.entities[action.payload];
    },
    setQuestionValue: (
      state,
      action: PayloadAction<{ [key: string]: QuestionAnswerValue }>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        state.values[key] = action.payload[key];
      });
    },
    deleteQuestionValues: (state, action: PayloadAction<string[]>) => {
      state.values = omit(state.values, action.payload);
    },
    updateQuestionsVisibility: (
      state,
      action: PayloadAction<{ [key: string]: boolean }>
    ) => {
      Object.keys(action.payload).forEach((key) => {
        state.questionsVisibility[key] = action.payload[key];
      });
    },
    deleteQuestionsVisibility: (state, action: PayloadAction<string[]>) => {
      state.questionsVisibility = omit(
        state.questionsVisibility,
        action.payload
      );
    },
    updateQuestionVersion: (
      state,
      action: PayloadAction<{ id: QuestionId; version: number }>
    ) => {
      const { id, version } = action.payload;
      state.versions[id] = version;
    },
    updateQuestion: (
      state,
      action: PayloadAction<{ id: QuestionId; updates: Partial<Question> }>
    ) => {
      const { id, updates } = action.payload;
      state.entities[id] = {
        ...state.entities[id],
        ...updates,
      };
    },
    setSuggestedQuestionValues: (
      state,
      action: PayloadAction<{ [key: string]: QuestionAnswerValue }>
    ) => {
      state.suggestedValues = {
        ...state.suggestedValues,
        ...action.payload,
      };
    },
    removeSuggestedQuestionValues: (state, action: PayloadAction<string[]>) => {
      state.suggestedValues = omit(state.suggestedValues, action.payload);
    },
  },
});

export const {
  setQuestions,
  addQuestion,
  setQuestionValue,
  deleteQuestionValues,
  updateQuestionsVisibility,
  updateQuestion,
  setSuggestedQuestionValues,
  removeSuggestedQuestionValues,
  deleteQuestion,
  deleteQuestionsVisibility,
} = questionSlice.actions;

export const selectAllQuestionEntites = (state: RootState) =>
  state.questions.entities;
export const selectEntitiesAsArray = (state: RootState) =>
  Object.values(state.questions.entities);

export const selectQuestionEntitiesAsArray = createSelector(
  [selectAllQuestionEntites],
  (questionEntities) => {
    return Object.values(questionEntities);
  }
);

export const selectQuestionIds = (state: RootState) => state.questions.ids;

export const selectQuestion = (state: RootState, id: string) =>
  state.questions.entities[id];
export const selectQuestionDisplayOrder = (state: RootState) =>
  state.questionDisplayOrder;

export const selectQuestionValue = (state: RootState, id: QuestionId) =>
  state.questions.values[id];
export const selectQuestionValues = (state: RootState) =>
  state.questions.values;
export const selectQuestionsVisibility = (state: RootState) =>
  state.questions.questionsVisibility;

export const selectSuggestedQuestionValue = (
  state: RootState,
  id: QuestionId
) => state.questions.suggestedValues?.[id] || null;

export const selectSuggestedValues = (state: RootState) =>
  state.questions.suggestedValues;

export default questionSlice.reducer;

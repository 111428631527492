import {
  QuestionAnswerValue,
  QuestionValidation,
} from '@/store/types/question';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@s-rm/react-ui-lib';

interface TextAreaProps {
  value: QuestionAnswerValue;
  onChange: (val: string) => void;
  'data-testid': string;
  isNumeric?: boolean;
  suggestedValue: QuestionAnswerValue | null;
  appliedSuggestedValue?: boolean;
  questionId: string;
  validation: QuestionValidation;
  placeholder?: string;
}

const TextArea = ({
  value: origValue,
  onChange,
  'data-testid': testId,
  isNumeric,
  suggestedValue,
  appliedSuggestedValue = false,
  questionId,
  validation,
  ...rest
}: TextAreaProps) => {
  const [value, setValue] = useState(origValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(onChange, 1000), []);

  useEffect(() => {
    setValue(origValue);
  }, [origValue]);

  const handleChange = (userInput: string) => {
    setValue(userInput);
    debounceFn(userInput);
  };

  return (
    <TextField
      size='small'
      value={value}
      multiline={!isNumeric}
      onChange={(e) => handleChange(e.target.value)}
      testId={testId}
      sx={{ maxWidth: isNumeric ? 200 : 'auto' }}
      rows={validation?.longText ? 10 : undefined}
      maxRows={10}
      {...rest}
    />
  );
};

export default TextArea;

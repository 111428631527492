import DialogContent from '@mui/material/DialogContent';
import { Button, LoadingButton } from '@s-rm/react-ui-lib';
import {
  DialogTitle,
  Typography,
  Dialog,
  DialogActions,
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogueTitleContainer } from '../styled';
import resetQuestionThunk from '@/store/thunks/editor/resetQuestion';
import resetModuleThunk from '@/store/thunks/editor/resetModule';
import resetModuleGroupThunk from '@/store/thunks/editor/restModuleGroup';
import resetCaseTypeThunk from '@/store/thunks/editor/resetCaseType';
import resetTipThunk from '@/store/thunks/editor/restTip';
import resetAdviceThunk from '@/store/thunks/editor/resetAdvice';
import { useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { EntityType } from '@/store/types/basicTypes';
import { toggleResetAndSyncEntityModal } from '@/store/slices/editor';

interface IConfirmResetAndSyncSessionModalProps {
  isOpen: boolean;
  entityId: string | null;
  entityType: EntityType | null;
}

const ConfirmResetAndSyncSessionModal = ({
  isOpen,
  entityId,
  entityType,
}: IConfirmResetAndSyncSessionModalProps) => {
  const dispatch = useAppDispatch();
  const [isResetting, setIsResetting] = useState(false);

  const handleOnConfirm = () => {
    setIsResetting(true);
    switch (entityType) {
      case EntityType.Question:
        dispatch(resetQuestionThunk(entityId!));
        break;
      case EntityType.Module:
        dispatch(resetModuleThunk(entityId!));
        break;
      case EntityType.ModuleGroup:
        dispatch(resetModuleGroupThunk(entityId!));
        break;
      case EntityType.CaseType:
        dispatch(resetCaseTypeThunk(entityId!));
        break;
      case EntityType.Tip:
        dispatch(resetTipThunk(entityId!));
        break;
      case EntityType.Advice:
        dispatch(resetAdviceThunk(entityId!));
        break;
    }
    handleOnClose();
    setIsResetting(false);
  };

  const handleOnClose = () => {
    dispatch(
      toggleResetAndSyncEntityModal({
        isOpen: false,
        entityId: null,
        entityType: null,
      })
    );
  };
  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            RESET & SYNC
          </Typography>
          <Button>
            <CloseIcon onClick={handleOnClose} />
          </Button>
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent>
        <Alert severity='warning'>
          <AlertTitle>
            <Typography fontWeight={700}>Warning</Typography>
          </AlertTitle>
          <Typography>
            Are you sure you want to clear all the changes made to this object
            and sync to the latest version? Warning: All the changes you have
            made to this object will be lost, it will be replaced by the latest
            published version.
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Box py={2} px={2}>
          <LoadingButton
            loading={isResetting}
            variant='contained'
            onClick={handleOnConfirm}
          >
            Yes, reset and sync
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmResetAndSyncSessionModal;

import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';
import getModule from '@/api/queries/getModule';
import {
  removeEntityFromSession,
  removeEntityModifications,
  removeOutdatedEntities,
} from '@/store/slices/editor';
import { updateModule } from '@/store/slices/modules';
import { AppThunk } from '@/store/store';
import { ModuleId } from '@/store/types/basicTypes';

const resetModuleThunk =
  (moduleId: ModuleId): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await getModule({ moduleId });
      dispatch(updateModule({ id: moduleId, updates: data }));
      resetEditorSessionChange(moduleId);
      dispatch(
        removeEntityFromSession({
          entityType: 'modules',
          entityId: moduleId,
        })
      );
      dispatch(
        removeEntityModifications({
          entityType: 'modules',
          entityId: moduleId,
        })
      );
      dispatch(
        removeOutdatedEntities({
          entityType: 'modules',
          entityId: moduleId,
        })
      );
    } catch (e) {
      // createError({ error: e, code: ErrorCodes.ArchiveAdvice });
      return;
    }
  };

export default resetModuleThunk;

import { useEffect, useState } from 'react';

export const useNetworkStatus = (): {
  isOnline: boolean;
  wasOffline: boolean;
} => {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null;

    const handleOnline = () => {
      setIsOnline(true);
      if (wasOffline) {
        timer = setTimeout(() => {
          setWasOffline(false);
        }, 2500);
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      setWasOffline(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [wasOffline]);

  return { isOnline, wasOffline };
};

import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetCase } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { Case } from '@/store/types/questionnaire';

export const getCase = async ({
  projectId,
}: {
  projectId: string;
}): Promise<{ data: Case }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const {
    data: { getCase },
    errors,
  } = await client.query({
    query: GetCase,
    variables: {
      projectId,
    },
  });

  // @ts-ignore
  return { data: getFormattedEntity(getCase) as Case, errors };
};

export default getCase;

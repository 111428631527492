import { EntityType, QuestionId } from '@/store/types/basicTypes';
import { Module } from '@/store/types/module';
import { Question } from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import getQuestionParents from '../../utils/getQuestionParents';

interface getTypeSpecificData {
  triggerComponentType: EntityType;
  modules: Module[];
  tips: Tip[];
  questions: Question[];
  questionInEdit: QuestionId;
}

const getTypeSpecificData = ({
  triggerComponentType,
  modules,
  tips,
  questions,
  questionInEdit,
}: getTypeSpecificData): {
  triggerableEntities: Question[] | Module[] | Tip[];
  buttonText: string;
  titleText: string;
  searchKey: string;
} => {
  const { parentQuestionIds, parentModuleIds } = getQuestionParents({
    questions,
    modules,
    childId: questionInEdit,
  });
  switch (triggerComponentType) {
    case EntityType.Module:
      return {
        triggerableEntities: modules.filter(
          (m) => !parentModuleIds.includes(m.id)
        ),
        buttonText: 'Trigger Module',
        titleText: 'Trigger Modules',
        searchKey: 'label',
      };
    case EntityType.Tip:
      return {
        triggerableEntities: tips,
        buttonText: 'Trigger Tip',
        titleText: 'Trigger Tips',
        searchKey: 'text',
      };
    case EntityType.Question:
      return {
        triggerableEntities: questions.filter(
          (q) => !parentQuestionIds.includes(q.id) && q.id !== questionInEdit
        ),
        buttonText: 'Trigger Question',
        titleText: 'Trigger Questions',
        searchKey: 'label',
      };
    default:
      return {
        triggerableEntities: [],
        buttonText: '',
        titleText: '',
        searchKey: '',
      };
  }
};

export default getTypeSpecificData;

import { CircularProgress } from '@mui/material';
import OverlayCard from '../Overlay/OverlayCard';
import { Link } from '@s-rm/react-ui-lib';

const AuthNotification = () => (
  <OverlayCard>
    <CircularProgress />
    <h2>Authenticating...</h2>
    <p>
      If you are not redirected within a few seconds, click{' '}
      <Link href={'/'}>here</Link> .
    </p>
  </OverlayCard>
);

export default AuthNotification;

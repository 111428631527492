import saveData from '@/api/utils/saveData';
import { setQuestionValue } from '../slices/questions';
import { AppThunk } from '../store';
import { ModuleId, QuestionId } from '../types/basicTypes';
import { MultipleChoiceValue, QuestionAnswerValue } from '../types/question';
import runTriggersThunk from './runTriggers';
import SaveDataTypes from '@/api/types/saveDataTypes';
import {
  getIsMultipleChoiceQuestion,
  getIsSearchableMultiChoiceQuestion,
} from '@/utils/helpers';
import getUpdatedMultipleChoiceAnswer from '../utils/getUpdatedMultipleChoiceAnswer';

const updateQuestionValueThunk =
  ({
    questionId,
    value,
    moduleId,
  }: {
    questionId: QuestionId;
    value: QuestionAnswerValue;
    moduleId: ModuleId;
  }): AppThunk =>
  (dispatch, getState) => {
    const questionEntity = getState().questions.entities[questionId];
    const questionValues = getState().questions.values;
    const isEditMode = getState().ui.isEditMode;
    const isMultipleChoiceQuestion = getIsMultipleChoiceQuestion(
      questionEntity.control
    );
    const isSearchableMultiChoiceQuestion = getIsSearchableMultiChoiceQuestion(
      questionEntity.control
    );
    const isMultipleChoiceOrSearchable =
      isMultipleChoiceQuestion || isSearchableMultiChoiceQuestion;
    let questionValue: QuestionAnswerValue = value;

    if (isMultipleChoiceOrSearchable) {
      questionValue = getUpdatedMultipleChoiceAnswer({
        existingValues: questionValues[
          questionId
        ] as unknown as MultipleChoiceValue,
        newValue: value as string,
      }) as MultipleChoiceValue;
    }
    dispatch(setQuestionValue({ [questionId]: questionValue }));
    dispatch(runTriggersThunk([moduleId]));

    if (isEditMode) {
      return;
    }
    saveData({
      type: SaveDataTypes.Answer,
      data: {
        questionId,
        value: (isMultipleChoiceOrSearchable
          ? questionValue.toString()
          : questionValue) as string,
      },
    });
  };

export default updateQuestionValueThunk;

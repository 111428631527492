const getUpdatedMultipleChoiceAnswer = ({
  existingValues,
  newValue,
}: {
  existingValues: string[] | string;
  newValue: string;
}) => {
  const valuesArray =
    typeof existingValues === 'string'
      ? existingValues.split(',')
      : existingValues;

  if (valuesArray?.includes(newValue)) {
    return valuesArray.filter((value) => value !== newValue);
  }
  return [...(valuesArray || []), newValue].filter(Boolean);
};

export default getUpdatedMultipleChoiceAnswer;

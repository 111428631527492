import { AppThunk } from '@/store/store';
import { setIsRefreshingDisplay } from '@/store/slices/ui';
import initAppData from '../initAppData';
import { setIsDirty } from '@/store/slices/editor';
import { isUsingFixtures } from '@/constants/env';

const refreshDisplayThunk = (): AppThunk => async (dispatch, getState) => {
  if (isUsingFixtures) {
    return;
  }

  if (!getState().editor.isDirty) {
    return;
  }

  dispatch(setIsRefreshingDisplay(true));

  await dispatch(initAppData());

  dispatch(setIsRefreshingDisplay(false));
  dispatch(setIsDirty(false));
};

export default refreshDisplayThunk;

import { CaseType, ProccessStep } from '../types/caseType';

const getCaseClosureCaseType = (caseTypeEntities: {
  [key: string]: CaseType;
}) => {
  return Object.values(caseTypeEntities).find(
    (caseType) => caseType.processStep === ProccessStep.Closure
  )?.id as string;
};

export default getCaseClosureCaseType;

import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetModuleGroup } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import Module from 'module';

export const getModuleGroup = async ({
  moduleGroupId,
}: {
  moduleGroupId: string;
}): Promise<{ data: Module }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetModuleGroup,
    variables: {
      id: moduleGroupId,
    },
  });

  // @ts-ignore
  return { data: getFormattedEntity(data.getModuleGroup) as Module, errors };
};

export default getModuleGroup;

import { addQuestion } from '@/store/slices/questions';
import { AppThunk } from '@/store/store';
import createQuestion from '@/api/mutations/editor/createQuestion';
import {
  setActiveEntitySearchList,
  setIsDirty,
  setQuestionInEdit,
} from '@/store/slices/editor';

const createQuestionThunk = (): AppThunk => async (dispatch, getState) => {
  const { data: newQuestion } = await createQuestion();
  dispatch(setIsDirty(true));
  dispatch(addQuestion({ ...newQuestion, isSessionOnly: true }));
  dispatch(setQuestionInEdit({ id: newQuestion.id, isNew: true }));
  dispatch(setActiveEntitySearchList(null));
};

export default createQuestionThunk;

import { EntityType } from '@/store/types/basicTypes';

export const module1Mock = {
  id: 'module-1',
  label: 'Module 1',
  description: 'Module 1 description',
  questions: [
    'radio-question',
    'multi-select-question',
    'text-field-questions',
    'date-range-question',
    'date-picker-question',
  ],
  entityType: EntityType.Module,
  roles: ['DFIR'],
};

export const module2Mock = {
  id: 'module-2',
  label: 'Module 2',
  description: 'Module 2 description',
  questions: ['numerical-question'],
  entityType: EntityType.Module,
};

export const module3Mock = {
  id: 'module-3',
  label: 'Module 3',
  description: 'Module 3 description',
  questions: ['numerical-question'],
  entityType: EntityType.Module,
};

export const module4Mock = {
  id: 'module-4',
  label: 'Module 4',
  description: 'Module 4 description',
  questions: ['numerical-question'],
  entityType: EntityType.Module,
};

export const moduleGroup1Mock = {
  id: 'module-group-1',
  label: 'Module group 1',
  moduleIds: [module1Mock.id],
  isSessionOnly: false,
  priorityIndex: '1',
};

export const moduleGroup2Mock = {
  id: 'module-group-2',
  label: 'Module group 2',
  moduleIds: [module2Mock.id],
  isSessionOnly: false,
  priorityIndex: '2',
};

export const moduleGroup3Mock = {
  id: 'module-group-3',
  label: 'Module group 3',
  moduleIds: [module3Mock.id],
  isSessionOnly: false,
  priorityIndex: '3',
};

export const staticModuleIdsMock = [
  module1Mock.id,
  module2Mock.id,
  module3Mock.id,
];

export const modulesMock = {
  entities: {
    [module1Mock.id]: module1Mock,
    [module2Mock.id]: module2Mock,
  },
  ids: [module1Mock.id, module2Mock.id],
  groups: {
    entities: {
      [moduleGroup1Mock.id]: moduleGroup1Mock,
      [moduleGroup2Mock.id]: moduleGroup2Mock,
      [moduleGroup3Mock.id]: moduleGroup3Mock,
    },
    ids: [moduleGroup1Mock.id, moduleGroup2Mock.id, moduleGroup3Mock.id],
  },
  selected: [module1Mock.id, module2Mock.id],
  manuallySelected: [],
  selectedByTrigger: [],
  staticModuleIds: staticModuleIdsMock,
};

import {
  CaseTypeId,
  IndicatorId,
  ModuleGroupId,
  ModuleId,
} from '../../store/types/basicTypes';
import { CaseType } from '../../store/types/caseType';
import { ModuleGroup } from '../../store/types/module';

interface Relations {
  caseType: CaseTypeId;
  indicators: IndicatorId[];
  moduleGroup?: ModuleGroupId;
  module: ModuleId;
}

export const getCaseTypeRelations = ({
  caseTypes,
  moduleId,
  indicatorIds,
  moduleGroups,
}: {
  caseTypes: {
    [key: string]: CaseType;
  };
  moduleId?: ModuleId;
  indicatorIds: string[];
  moduleGroups?: {
    [key: string]: ModuleGroup;
  };
}) => {
  return Object.values(caseTypes).reduce((acc, caseType): Relations[] => {
    const caseTypeContainsModuleOrIndicator =
      caseType.modules.some((m) => m === moduleId) ||
      caseType.indicators.some((indicator) =>
        indicatorIds?.includes(indicator)
      );

    //@ts-ignore
    return caseTypeContainsModuleOrIndicator
      ? [
          ...acc,
          {
            caseType: caseType.id,
            moduleGroup: moduleGroups
              ? Object.values(moduleGroups).find((group) =>
                  group.moduleIds.includes(moduleId || '')
                )?.id
              : null,
            indicators: caseType.indicators,
            module: moduleId,
          },
        ]
      : acc;
  }, [] as Relations[]);
};

export default getCaseTypeRelations;

import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';
import getCaseType from '@/api/queries/getCaseType';
import { updateCaseType } from '@/store/slices/caseTypes';
import {
  removeEntityFromSession,
  removeEntityModifications,
  removeOutdatedEntities,
} from '@/store/slices/editor';
import { AppThunk } from '@/store/store';
import { CaseTypeId } from '@/store/types/basicTypes';

const resetCaseTypeThunk =
  (caseTypeId: CaseTypeId): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { data } = await getCaseType({ caseTypeId });
      dispatch(updateCaseType({ id: caseTypeId, updates: data }));
      resetEditorSessionChange(caseTypeId);
      dispatch(
        removeEntityFromSession({
          entityType: 'caseTypes',
          entityId: caseTypeId,
        })
      );
      dispatch(
        removeEntityModifications({
          entityType: 'caseTypes',
          entityId: caseTypeId,
        })
      );
      dispatch(
        removeOutdatedEntities({
          entityType: 'caseTypes',
          entityId: caseTypeId,
        })
      );
    } catch (e) {
      // createError({ error: e, code: ErrorCodes.ArchiveAdvice });
      return;
    }
  };

export default resetCaseTypeThunk;

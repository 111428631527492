import { useAppDispatch, useAppSelector } from '@/hooks';
import { selectSelectedCaseTypes } from '@/store/slices/caseTypes';
import { Box, Container } from '@mui/material';
import {
  FlexContainer,
  InfoBarContainer,
  InfoHeader,
  InfoSection,
  SelectedCaseType,
  Spacer,
  PlaceholderText,
} from './styled';
import NavButton from './NavButton';
import {
  selectQuestionnaireTime,
  selectClient,
  selectClosureTime,
  selectIsCaseActive,
  selectProject,
} from '@/store/slices/questionnaire';
import {
  selectIsEditMode,
  setIsClosureSummaryDialogOpen,
  setIsConfirmingCaseClosure,
} from '@/store/slices/ui';
import EditModeContent from './EditModeContent';
import GlobalQuestionCounter from '../RequiredQuestionsCounter/GlobalQuestionCounter';
import { Button } from '@s-rm/react-ui-lib';
import useCaseClosure from '@/hooks/useCaseClosure';
import { format, formatInTimeZone } from 'date-fns-tz';
import { isNullishTimestamp } from '@/utils/helpers';
import { isValid } from 'date-fns';
import { locales } from '../Question/questionControls/DatePicker/locales';

const InfoBar = () => {
  const userLocale = navigator.languages[0] || navigator.language;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dispatch = useAppDispatch();
  const selectedCaseTypes = useAppSelector(selectSelectedCaseTypes);
  const { name: projectName } = useAppSelector(selectProject);
  const { name: clientName } = useAppSelector(selectClient);
  const intakeTime = useAppSelector(selectQuestionnaireTime);
  const closureTime = useAppSelector(selectClosureTime);
  const isEditMode = useAppSelector(selectIsEditMode);
  const { isCaseClosureRoute } = useCaseClosure();
  const isCaseActive = useAppSelector(selectIsCaseActive);
  const locale =
    locales[userLocale?.toLowerCase() as keyof typeof locales] ||
    locales['en-gb'];

  const handleClickSubmitCase = () =>
    dispatch(setIsConfirmingCaseClosure(true));
  const handleClickClosurePreview = () =>
    dispatch(setIsClosureSummaryDialogOpen(true));

  const displayIntakeTime = () => {
    if (!isNullishTimestamp(intakeTime!)) {
      const localTime = formatInTimeZone(intakeTime!, userTimeZone, 'hh:mm a');
      const utcTime = formatInTimeZone(intakeTime!, 'UTC', 'hh:mm a');
      const formattedDate = format(new Date(intakeTime!), 'PP', { locale });
      return `${formattedDate}, ${localTime} (${utcTime} UTC)`;
    }
    return '-';
  };

  const displayClosureTime = () => {
    if (closureTime !== null && isValid(new Date(closureTime))) {
      const localTime = formatInTimeZone(closureTime, userTimeZone, 'hh:mm a');
      const utcTime = formatInTimeZone(closureTime, 'UTC', 'hh:mm a');
      const formattedDate = format(new Date(closureTime), 'PP', { locale });
      return `${formattedDate}, ${localTime} (${utcTime} UTC)`;
    }
    return '-';
  };

  return (
    <InfoBarContainer data-testid='info-bar'>
      <Container maxWidth='xl'>
        <FlexContainer>
          {!!isEditMode && <EditModeContent />}
          {!isEditMode && (
            <>
              <div data-testid='selected-case-types'>
                {isCaseClosureRoute && (
                  <SelectedCaseType>{'Case closure'}</SelectedCaseType>
                )}
                {!isCaseClosureRoute && (
                  <>
                    {selectedCaseTypes.length ? (
                      selectedCaseTypes.map((caseType, index) => {
                        const isLast = index === selectedCaseTypes.length - 1;

                        return (
                          <SelectedCaseType key={caseType.id}>
                            {caseType.label}
                            {!isLast && <Spacer>|</Spacer>}
                          </SelectedCaseType>
                        );
                      })
                    ) : (
                      <PlaceholderText>No case types selected</PlaceholderText>
                    )}
                  </>
                )}

                <div>
                  <InfoSection>
                    <InfoHeader>Client organisation name</InfoHeader>
                    <div data-testid='client-name'>{clientName || '-'}</div>
                  </InfoSection>
                  <InfoSection>
                    <InfoHeader>Project name</InfoHeader>
                    <div data-testid='project-name'>{projectName || '-'}</div>
                  </InfoSection>
                  <InfoSection>
                    <InfoHeader>Intake Time</InfoHeader>
                    <div data-testid='intake-time'>{displayIntakeTime()}</div>
                  </InfoSection>
                  <InfoSection>
                    <InfoHeader>Closure Time</InfoHeader>
                    <div data-testid='closure-time'>{displayClosureTime()}</div>
                  </InfoSection>
                </div>
              </div>
              <Box ml='auto' mr={2}>
                <GlobalQuestionCounter />
              </Box>
              {isCaseClosureRoute ? (
                <Box display='flex' gap={2}>
                  <Button
                    variant='outlined'
                    onClick={handleClickClosurePreview}
                    data-testid='infobarpreview-btn'
                  >
                    Preview
                  </Button>
                  <Button
                    disabled={!isCaseActive}
                    variant='contained'
                    onClick={handleClickSubmitCase}
                    data-testid={
                      isCaseActive
                        ? 'infobar-close-case-btn'
                        : 'infobar-case-closed-btn'
                    }
                  >
                    {isCaseActive ? 'Submit Closure' : 'Closure Submitted'}
                  </Button>
                </Box>
              ) : (
                <NavButton />
              )}
            </>
          )}
        </FlexContainer>
      </Container>
    </InfoBarContainer>
  );
};

export default InfoBar;

import { ModuleId, QuestionId } from '@/store/types/basicTypes';
import { Module } from '@/store/types/module';
import { Question } from '@/store/types/question';

interface GetQuestionParents {
  questions: Question[];
  modules: Module[];
  childId: QuestionId;
}

const getQuestionParents = ({
  questions,
  modules,
  childId,
}: GetQuestionParents) => {
  const getParentQuestions = (): QuestionId[] => {
    const parentIds: QuestionId[] = [];

    const loopQuestions = (questionId: QuestionId) => {
      const triggeringParents = questions.filter((q) => {
        return q.triggers.some((trigger) => trigger.componentId === questionId);
      });

      triggeringParents.forEach(({ id: parentId }) => {
        parentIds.push(parentId);
        loopQuestions(parentId);
      });
    };
    loopQuestions(childId);
    return parentIds;
  };

  const getParentModules = (parentQuestionIds: QuestionId[]) => {
    return modules.reduce((acc, m) => {
      const containsQuestion = m.questions.some((q) =>
        [...parentQuestionIds, childId].includes(q)
      );

      return containsQuestion ? [...acc, m.id] : acc;
    }, [] as ModuleId[]);
  };

  const parentQuestionIds = getParentQuestions();

  return {
    parentQuestionIds,
    parentModuleIds: getParentModules(parentQuestionIds),
  };
};

export default getQuestionParents;

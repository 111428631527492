import { AppThunk } from '../store';
import updateSnackbarNotification from './updateSnackbar';
import { SnackbarTypes } from '../types/ui';
import makeJiraIssue from '@/api/mutations/createJiraIssue';

const triggerJiraIntegrationThunk = (): AppThunk => async (dispatch) => {
  const { createJiraIssue: jiraIssueCreatedSuccessfully } =
    await makeJiraIssue();

  if (jiraIssueCreatedSuccessfully) {
    dispatch(
      updateSnackbarNotification({
        message: 'The integration has been successfully created!',
        type: SnackbarTypes.Success,
      })
    );
  }
};

export default triggerJiraIntegrationThunk;

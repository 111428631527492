import { CaseTypeId, IndicatorId } from '../types/basicTypes';
import { IndicatorToCaseTypesMap } from '../types/indicator';

interface GetSuggestedCaseTypesToRemove {
  caseTypeIds: CaseTypeId[];
  selectedIndicators: IndicatorId[];
  indicatorCaseTypeMap: IndicatorToCaseTypesMap;
}

const getSuggestedCaseTypesToRemove = ({
  caseTypeIds,
  selectedIndicators,
  indicatorCaseTypeMap,
}: GetSuggestedCaseTypesToRemove) =>
  caseTypeIds.filter(
    (caseTypeId) =>
      !selectedIndicators.some((indicatorId) =>
        indicatorCaseTypeMap[indicatorId].includes(caseTypeId)
      )
  );

export default getSuggestedCaseTypesToRemove;

import {
  ApolloLink,
  Operation,
  FetchResult,
  NextLink,
} from '@apollo/client/link/core';
import { Observable, Observer } from '@apollo/client/utilities';

interface OperationQueueEntry {
  operation: Operation;
  forward: NextLink;
  observer: Observer<FetchResult>;
  subscription?: { unsubscribe: () => void };
}

// This class has already been tested and works as expected, no need to modify it as its part of the
// apollo-link-queue package however the open method has been overwritten.

export default class QueueLink extends ApolloLink {
  private opQueue: OperationQueueEntry[] = [];
  private isOpen = true;

  public async open() {
    this.isOpen = true;

    // This loop processes operations sequentially, the usage of await ensures that each iteration
    // waits for the completion of the asynchronous operation
    // before moving to the next request this way we avoid race conditions since order matters.
    try {
      for (const { operation, forward, observer } of this.opQueue) {
        await new Promise<void>((resolve, reject) => {
          forward(operation).subscribe({
            ...observer,
            complete: resolve,
            error: (error) => {
              reject(error);
            },
          });
        });
      }
    } catch (error) {
      console.error('Error executing operations:', error);
    } finally {
      this.opQueue = [];
    }
  }

  public close() {
    this.isOpen = false;
  }

  public request(operation: Operation, forward: NextLink) {
    if (this.isOpen || operation.getContext().skipQueue) {
      return forward(operation);
    }

    return new Observable<FetchResult>((observer: Observer<FetchResult>) => {
      const operationEntry = { operation, forward, observer };
      this.enqueue(operationEntry);
      return () => this.cancelOperation(operationEntry);
    });
  }

  private cancelOperation(entry: OperationQueueEntry) {
    this.opQueue = this.opQueue.filter((e) => e !== entry);
  }

  private enqueue(entry: OperationQueueEntry) {
    this.opQueue.push(entry);
  }
}

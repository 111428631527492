import { EntityType, ModuleId, QuestionId } from './basicTypes';

export enum ControlTypes {
  Radio = 'RADIO',
  Select = 'SELECT',
  Textarea = 'TEXTAREA',
  Numeric = 'NUMERIC',
  Checkbox = 'CHECKBOX',
  Datepicker = 'DATEPICKER',
  MultiSelect = 'MULTISELECT',
  Daterange = 'DATERANGE',
  Currency = 'CURRENCY',
  Timepicker = 'TIMEPICKER',
  MultiSelectSearch = 'MULTISELECTSEARCH',
}

export enum QuestionTriggerComponents {
  Question = 'QUESTION',
  Module = 'MODULE',
  Tip = 'TIP',
}

export enum QuestionTriggerActions {
  Show = 'SHOW',
  Hide = 'HIDE',
}

export interface QuestionValidation {
  pattern: string;
  isRequired: boolean;
  maxLength: number;
  minLength: number;
  maxValue: number;
  minValue: number;
  longText: boolean;
}

export interface QuestionOptions {
  label: string;
  value: string;
  id: string;
}

export type MultipleChoiceValue = string[];

export type QuestionAnswerValue = string | MultipleChoiceValue;

export interface QuestionTrigger {
  componentId: string;
  componentType: QuestionTriggerComponents | EntityType;
  value: QuestionAnswerValue;
  action: QuestionTriggerActions;
  id: string;
}

export interface Question {
  entityType: EntityType.Question;
  id: string;
  control: ControlTypes;
  placeholder: string;
  guidance: string;
  triggers: QuestionTrigger[];
  options: QuestionOptions[];
  validation: QuestionValidation;
  label: string;
  version: number;
  isSessionOnly: boolean;
  integrationTags: string[];
  text?: string;
}

export interface ComponentReferenceObject {
  id: string;
  componentType: EntityType;
  parentId: string;
}

export interface QuestionSlice {
  entities: {
    [key: QuestionId]: Question;
  };
  ids: QuestionId[];
  values: {
    [key: QuestionId]: QuestionAnswerValue;
  };
  questionDisplayOrder: {
    [key: ModuleId]: ComponentReferenceObject[];
  };
  questionsVisibility: {
    [key: QuestionId]: boolean;
  };
  versions: {
    [key: QuestionId]: number;
  };
  suggestedValues: {
    [key: QuestionId]: QuestionAnswerValue;
  };
}

import { EntityType } from '@/store/types/basicTypes';

export const editorMock = {
  isDirty: false,
  questionInEdit: {
    id: null,
    isNew: false,
  },
  moduleInEdit: {
    id: null,
    isNew: false,
  },
  tipInEdit: {
    id: null,
    isNew: false,
  },
  caseTypeInEdit: {
    id: null,
    isNew: false,
  },
  moduleGroupInEdit: {
    id: null,
    isNew: false,
  },
  adviceInEdit: {
    id: null,
    isNew: false,
  },
  activeEntitySearchList: null,
  activeParentTreeView: {
    entityType: null,
    entityId: null,
  },
  entitySearchFilter: {
    searchTerm: '',
    filterBy: EntityType.CaseType,
    entityIds: [],
  },
  isSessionModalOpen: false,
  isConfrimUseEditorModalOpen: false,
  sessions: {
    isFetching: true,
    entities: {},
    ids: [],
  },
  sessionInEdit: {
    id: '895794aa-ec0f-42d0-9903-183fe666e104',
    lastUpdatedAt: '29 Nov 2023, 13:24',
    createdAt: '29 Nov 2023, 13:16',
    version: 3,
    edited: {
      caseTypes: {},
      modules: {},
      questions: {},
      tips: {},
      advice: {},
      indicators: {},
      moduleGroups: {},
    },
  },
  entityModifications: {
    caseTypes: {},
    modules: {},
    questions: {},
    tips: {},
    advice: {},
    indicators: {},
    moduleGroups: {},
  },
  outdatedEntities: {
    caseTypes: {},
    modules: {},
    questions: {},
    tips: {},
    advice: {},
    indicators: {},
    moduleGroups: {},
  },
  resetAndSyncEntityModal: {
    isOpen: false,
    entityId: null,
    entityType: null,
  },
};

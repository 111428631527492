import client from '@/api/client';
//@ts-ignore
import { MakeTestCase } from '@/api/gql/mutations.gql';
import createCaseTypeTransform from '@/api/transforms/editor/createCaseTypeTransform';
import { store } from '@/store';
import { ProccessStep } from '@/store/types/caseType';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export interface CreateCaseTypeArgs {
  label: string;
  indicatorIds: string[];
  moduleIds: string[];
}

export const createCaseType = async () => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;
  const { data, errors } = await client.mutate({
    mutation: MakeTestCase,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      args: {
        label: '',
        indicatorIds: [],
        moduleIds: [],
        processStep: ProccessStep.Intake,
      } as CreateCaseTypeArgs,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateCaseType,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: createCaseTypeTransform(data) };
};

export default createCaseType;

import client from '@/api/client';
import {
  ArchiveModuleGroup,
  //@ts-ignore
} from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const archiveModuleGroup = async (id: string) => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;
  const { data, errors } = await client.mutate({
    mutation: ArchiveModuleGroup,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      id,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.ArchiveModuleGroup,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default archiveModuleGroup;

import { SnackbarTypes } from '@/store/types/ui';

export const uiMock = {
  sidebarSelections: {
    description: false,
    participantsList: false,
    history: false,
  },
  printableSummaryStatus: {
    isOpen: false,
    isAutoCopy: false,
  },
  snackbarNotification: {
    message: null,
    action: null,
    type: 'info',
    disableAutoHide: false,
  },
  refreshDialogStatus: {
    isOpen: false,
  },
  descriptionPopoutWindowStatus: {
    isOpen: false,
  },
  suggestedIndicatorsStatus: {
    isLoading: false,
  },
  isRefreshingDisplay: false,
  isEditMode: true,
  summaryStepScrollPositionY: 0,
  specificsStepScrollPositionY: 190,
  suggestedAnswersFetchingStatus: {},
  hasRequestedSuggestedAnswers: false,
  hasCaseBeenCleared: false,
};

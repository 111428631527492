import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetCaseType } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { CaseType } from '@/store/types/caseType';

export const getCaseType = async ({
  caseTypeId,
}: {
  caseTypeId: string;
}): Promise<{ data: CaseType }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetCaseType,
    variables: {
      id: caseTypeId,
    },
  });

  // @ts-ignore
  return { data: getFormattedEntity(data.getCaseType) as CaseType, errors };
};

export default getCaseType;

import { useEffect } from 'react';
import { DialogueTitleContainer } from '../../styled';
import { DialogContent, Typography } from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';
import CloseIcon from '@mui/icons-material/Close';
import ModalContent from '../ModalContent';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectEntitySearchFilter,
  setActiveEntitySearchList,
} from '@/store/slices/editor';
import refreshDisplayThunk from '@/store/thunks/editor/refreshDisplay';
import filterCaseTypesThunk from '@/store/thunks/editor/filterCaseTypes';
import { CaseTypeId, EntityType } from '@/store/types/basicTypes';
import createCaseTypeThunk from '@/store/thunks/editor/createCaseTypeThunk';
import EditorDialog, {
  EditorDialogTitle,
} from '../../EditorDialog/EditorDialog';
import resetEditorSessionOrArchive from '@/store/thunks/editor/resetEditorSessionOrArchive';

export const CaseTypeSearchModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();

  const { searchTerm, filterBy } = useAppSelector(selectEntitySearchFilter);

  const handleTermChange = (updatedSearchTerm: string) => {
    dispatch(
      filterCaseTypesThunk({
        searchTerm: updatedSearchTerm,
      })
    );
  };

  const handleChangeFilterBy = (updatedFilterBy: EntityType) =>
    dispatch(
      filterCaseTypesThunk({
        filterBy: updatedFilterBy,
      })
    );

  const handleClose = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(refreshDisplayThunk());
  };

  const handleDeleteCaseType = (id: CaseTypeId) =>
    dispatch(resetEditorSessionOrArchive(id, filterBy!));

  const handleOnCreate = () => {
    dispatch(createCaseTypeThunk());
  };

  useEffect(() => {
    dispatch(filterCaseTypesThunk({ filterBy: EntityType.CaseType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <EditorDialog open={isOpen} maxWidth='lg' fullWidth>
      <EditorDialogTitle>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Add or edit case types
          </Typography>
          <Button onClick={handleClose} data-testid='btn-close-ctype-modal'>
            <CloseIcon />
          </Button>
        </DialogueTitleContainer>
      </EditorDialogTitle>
      <DialogContent>
        {isOpen && (
          <ModalContent
            onCreate={handleOnCreate}
            searchTerm={searchTerm!}
            onChangeTerm={handleTermChange}
            onChangeFilterBy={handleChangeFilterBy}
            onDelete={handleDeleteCaseType}
          />
        )}
      </DialogContent>
    </EditorDialog>
  );
};

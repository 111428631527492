import { Alert, AlertTitle, Typography } from '@mui/material';
import ConfirmationModal from '../ConfirmationModal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectIsReopeningCase,
  setIsConfirmingReopenCase,
} from '@/store/slices/ui';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import { SnackbarTypes } from '@/store/types/ui';

const ReopenCaseClosureModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsReopeningCase);

  const handleClose = () => {
    dispatch(setIsConfirmingReopenCase(false));
  };

  const handleReopenQuestionnaire = () => {
    dispatch(setIsConfirmingReopenCase(false));
    dispatch(userActionInterceptor({ actions: [], shouldContinue: true }));
    dispatch(
      updateSnackbarNotification({
        message: 'The case has been re-opened',
        type: SnackbarTypes.Success,
      })
    );
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title='Re-open case'
      confirmText='Yes, continue'
      onConfirm={handleReopenQuestionnaire}
      onClose={handleClose}
      testId='reopen-case-confirmation-modal'
    >
      <Alert severity='warning' data-testid='reopoen-case-warning'>
        <AlertTitle>Warning</AlertTitle>
        <Typography>You are about to re-open the case</Typography>
      </Alert>
    </ConfirmationModal>
  );
};

export default ReopenCaseClosureModal;

import {
  Box,
  Container,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectOutdatedEntities,
  selectSingleEntityModifications,
  selectTipInEdit,
  setActiveParentTreeView,
} from '@/store/slices/editor';
import TextEditor from '@/components/TextEditor';
import { Label, LabelBadge, LabelContainer } from '../../styled';
import { AdviceId, EntityType } from '@/store/types/basicTypes';
import { selectTip } from '@/store/slices/tips';
import updateTipThunk from '@/store/thunks/editor/updateTip';
import { selectAllAdviceEntities } from '@/store/slices/advice';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { QuestionItemWrapper } from './styled';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { reorderList } from '../../utils/helpers';
import EntitySelectMenu from '../../EntitySelectMenu';
import { Button } from '@s-rm/react-ui-lib';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  DragHandle,
  SortableItem,
  SortableList,
} from '@/components/DragDropList/DragDropList';
import { removeAllTags } from '@/utils/helpers';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id: tipInEditId, isNew } = useAppSelector(selectTipInEdit);
  const tipEntity = useAppSelector((state) => selectTip(state, tipInEditId!));
  const adviceEntities = useAppSelector(selectAllAdviceEntities);
  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'tips', tipInEditId!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).tips?.[tipInEditId!] || [];

  const handleUpdateText = (val: string) => {
    /* istanbul ignore next */
    dispatch(
      updateTipThunk({
        id: tipEntity.id,
        updates: { text: val },
        shouldDebounce: true,
      })
    );
  };

  const handleClickParentView = (entityId: string) => {
    dispatch(
      setActiveParentTreeView({
        entityId,
        entityType: EntityType.Advice,
      })
    );
  };

  const handleSelectAdvice = (adviceId: AdviceId) => {
    dispatch(
      updateTipThunk({
        id: tipEntity.id,
        updates: {
          advice: tipEntity.advice.includes(adviceId)
            ? tipEntity.advice.filter((id) => id !== adviceId)
            : [...tipEntity.advice, adviceId],
        },
      })
    );
  };

  const handleDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;

    dispatch(
      updateTipThunk({
        id: tipEntity.id,
        updates: {
          advice: reorderList(tipEntity.advice, oldIndex, newIndex),
        },
      })
    );
  };

  return (
    <Box minHeight={500} mb={5}>
      <Container>
        <Box mt={2} mb={12} height={100}>
          <LabelContainer>
            <Label>Tip Text</Label>
            {modifications.includes('text') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields.includes('text') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextEditor onChange={handleUpdateText} value={tipEntity.text} />
        </Box>
        <Box
          mb={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center' gap={2}>
            <Typography>Advice</Typography>
            {modifications.includes('advice') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields.includes('advice') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </Box>
          <EntitySelectMenu
            componentType={EntityType.Advice}
            onSelect={handleSelectAdvice}
            Trigger={
              <Button startIcon={<AddOutlinedIcon />}>Add advice</Button>
            }
            selectedEntityIds={tipEntity.advice}
          />
        </Box>
        <Divider />
        <Stack direction='column' gap={2} mt={2}>
          {!tipEntity.advice.length && (
            <Typography color='grey.500'>
              No selected advice added yet
            </Typography>
          )}
          <SortableList onSortEnd={handleDragEnd} useDragHandle>
            {tipEntity.advice.map((adviceId: AdviceId, index) => {
              const advice = adviceEntities[adviceId];
              return (
                <SortableItem key={adviceId} index={index}>
                  <Box py={1} display='flex' alignItems='center' gap={1}>
                    <DragHandle>
                      <Box style={{ cursor: 'move' }}>
                        <DragIndicatorIcon />
                      </Box>
                    </DragHandle>
                    <Box width='100%'>
                      <QuestionItemWrapper key={adviceId}>
                        <Typography noWrap width={700} mr={1}>
                          {removeAllTags(advice.text)}
                        </Typography>
                        <IconButton
                          onClick={() => handleClickParentView(adviceId)}
                        >
                          <AccountTreeOutlinedIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSelectAdvice(adviceId)}
                          size='small'
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </QuestionItemWrapper>
                    </Box>
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
        </Stack>
      </Container>
    </Box>
  );
};

export default ModalContent;

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectActiveEntitySearchList,
  selectActiveParentTreeView,
  selectCaseTypeInEdit,
  selectAdviceInEdit,
  selectIsSessionModalOpen,
  selectModuleGroupInEdit,
  selectModuleInEdit,
  selectQuestionInEdit,
  selectSessionInEdit,
  selectTipInEdit,
  setIsSessionModalOpen,
  selectResetAndSyncEntityModal,
} from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import EditQuestionModal from '../editModals/EditQuestionModal';
import { selectIsRefreshingDisplay } from '@/store/slices/ui';
import ParentTreeViewModal from '../ParentTreeViewModal/ParentTreeViewModal';
import SessionModal from '../SessionModal';
import { selectHasInitialised } from '@/store/slices/config';
import QuestionSearchModal from '../searchModals/QuestionSearchModal/QuestionSearchModal';
import ModuleSearchModal from '../searchModals/ModuleSearchModal/ModuleSearchModal';
import EditModuleModal from '../editModals/EditModuleModal/EditModuleModal';
import ModuleGroupSearchModal from '../searchModals/ModuleGroupSearchModal/ModuleGroupSearchModal';
import EditModuleGroupModal from '../editModals/EditModuleGroupModal/EditModuleGroupModal';
import { CaseTypeSearchModal } from '../searchModals/CaseTypeSearchModal/CaseTypeSearchModal';
import EditCaseTypeModal from '../editModals/EditCaseTypeModal/EditCaseTypeModal';
import TipSearchModal from '../searchModals/TipSearchModal/TipSearchModal';
import EditTipModal from '../editModals/EditTipModal/EditTipModal';
import AdviceSearchModal from '../searchModals/AdviceSearchModal/AdviceSearchModal';
import EditAdviceModal from '../editModals/EditAdviceModal/EditAdviceModal';
import ConfirmResetAndSyncSessionModal from '../ConfirmResetAndSyncSessionModal';

const EditorModalManager = () => {
  const dispatch = useAppDispatch();

  const hasAppInitialised = useAppSelector(selectHasInitialised);
  const activeEntitySearchList = useAppSelector(selectActiveEntitySearchList);
  const { id: questionIdInEdit } = useAppSelector(selectQuestionInEdit);
  const { id: moduleIdInEdit } = useAppSelector(selectModuleInEdit);
  const { id: moduleGroupIdInEdit } = useAppSelector(selectModuleGroupInEdit);
  const { id: caseTypeInEdit } = useAppSelector(selectCaseTypeInEdit);
  const { id: tipIdInEdit } = useAppSelector(selectTipInEdit);
  const { id: adviceIdInEdit } = useAppSelector(selectAdviceInEdit);

  const sessionInEdit = useAppSelector(selectSessionInEdit);
  const isRefreshingDisplay = useAppSelector(selectIsRefreshingDisplay);
  const activeParentTreeView = useAppSelector(selectActiveParentTreeView);
  const isSessionModalOpen = useAppSelector(selectIsSessionModalOpen);

  const {
    isOpen: isResetAndSyncModalOpen,
    entityId,
    entityType,
  } = useAppSelector(selectResetAndSyncEntityModal);

  useEffect(() => {
    if (hasAppInitialised && !sessionInEdit?.id) {
      dispatch(setIsSessionModalOpen(true));
    }
  }, [sessionInEdit, hasAppInitialised, dispatch]);

  return (
    <>
      <ConfirmResetAndSyncSessionModal
        isOpen={isResetAndSyncModalOpen}
        entityId={entityId!}
        entityType={entityType!}
      />
      <CaseTypeSearchModal
        isOpen={activeEntitySearchList === EntityType.CaseType}
      />
      <QuestionSearchModal
        isOpen={activeEntitySearchList === EntityType.Question}
      />
      <ModuleSearchModal
        isOpen={activeEntitySearchList === EntityType.Module}
      />
      <ModuleGroupSearchModal
        isOpen={activeEntitySearchList === EntityType.ModuleGroup}
      />
      <TipSearchModal isOpen={activeEntitySearchList === EntityType.Tip} />
      <AdviceSearchModal
        isOpen={activeEntitySearchList === EntityType.Advice}
      />
      <EditQuestionModal isOpen={!!questionIdInEdit} />
      <EditModuleModal isOpen={!!moduleIdInEdit} />
      <EditCaseTypeModal isOpen={!!caseTypeInEdit} />
      <EditModuleGroupModal isOpen={!!moduleGroupIdInEdit} />
      <EditTipModal isOpen={!!tipIdInEdit} />
      <EditAdviceModal isOpen={!!adviceIdInEdit} />
      <ParentTreeViewModal isOpen={!!activeParentTreeView.entityId} />
      <SessionModal isOpen={isSessionModalOpen} />
    </>
  );
};

export default EditorModalManager;

import { updateQuestionsVisibility } from '../slices/questions';
import { AppDispatch, RootState } from '../store';
import { ModuleId } from '../types/basicTypes';
import { ComponentReferenceObject } from '../types/question';
import validateTriggers from '../utils/validateTriggers';

const triggerQuestions = ({
  selectedModules,
  dispatch,
  getState,
}: {
  selectedModules: ModuleId[];
  dispatch: AppDispatch;
  getState: () => RootState;
}) => {
  const moduleIds = getState().modules.ids;

  selectedModules.forEach((selectedModuleId: string) => {
    const moduleQuestionDisplayOrder =
      getState().questionDisplayOrder[selectedModuleId];
    const rootQuestions =
      getState().modules.entities[selectedModuleId].questions;

    // convert all children to false except root so we can re-evaluate from scratch
    let updatedVisibility: { [key: string]: boolean } =
      moduleQuestionDisplayOrder.reduce(
        (acc, { id }) => ({
          ...acc,
          [id]: rootQuestions.includes(id),
        }),
        {}
      );

    moduleQuestionDisplayOrder.forEach(
      (componentRef: ComponentReferenceObject) => {
        const isQuestion = componentRef.componentType === 'QUESTION';

        if (!isQuestion) return;
        const value = getState().questions.values[componentRef.id];
        const triggers =
          getState().questions.entities[componentRef.id]?.triggers || [];
        const validChildren = validateTriggers({ triggers, value });
        const questionIsVisible = updatedVisibility[componentRef.id];
        const validQuestions = validChildren.filter(
          (childId) => !moduleIds.includes(childId)
        );
        // Only display children if the current question is visible (valid)
        // otherwise leave the children's visibilities as false
        if (questionIsVisible) {
          validQuestions.forEach((childId) => {
            updatedVisibility[childId] = true;
          });
        }
      }
    );

    dispatch(updateQuestionsVisibility(updatedVisibility));
  });
};

export default triggerQuestions;

import { useAppSelector } from '@/hooks';
import { selectActiveParentTreeView } from '@/store/slices/editor';
import { Box, Typography } from '@mui/material';
import EmptyItemsPlaceholder from '@/components/EmptyItemsPlaceholder';
import { selectIndicatorEntities } from '@/store/slices/indicators';
import { selectCaseTypeEntities } from '@/store/slices/caseTypes';
import { getCaseTypeRelations } from '@/components/utils/getCaseTypeRelations';

const IndicatorTreeViewContent = () => {
  const { entityId } = useAppSelector(selectActiveParentTreeView);
  const caseTypes = useAppSelector(selectCaseTypeEntities);
  const indicatorEntities = useAppSelector(selectIndicatorEntities);

  const caseTypesIndicatorDisplayStructure = getCaseTypeRelations({
    caseTypes,
    indicatorIds: [entityId as string],
  });

  if (caseTypesIndicatorDisplayStructure?.length === 0) {
    return (
      <Box height='200px'>
        <EmptyItemsPlaceholder text='No parent items found' />
      </Box>
    );
  }

  return (
    <>
      {caseTypesIndicatorDisplayStructure?.map((caseTypeRef) => {
        const caseTypeEntity = caseTypes[caseTypeRef.caseType];
        return (
          <div key={caseTypeEntity.id}>
            <Typography mb={0.5} color='grey.600' fontSize='.8rem' variant='h5'>
              Case type
            </Typography>
            <Typography mb={2}>{caseTypeEntity.label}</Typography>
            <Box ml={2}>
              <div
                style={{ marginLeft: '20px' }}
                key={indicatorEntities[entityId as string]?.id}
              >
                <Typography mb={0.5} color='grey.600' fontSize='.8rem'>
                  Indicator
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                  mb={2}
                >
                  {indicatorEntities[entityId as string]?.label}
                </Typography>
              </div>
            </Box>
          </div>
        );
      })}
    </>
  );
};

export default IndicatorTreeViewContent;

import {
  addSelectedCaseTypes,
  addSuggestedCaseTypes,
} from '../slices/caseTypes';
import {
  addSelectedModules,
  removeManuallySelectedModules,
} from '../slices/modules';
import { AppThunk } from '../store';
import convertEntitiesToArray from '../utils/convertEntitiesToArray';
import getModulesByCaseTypes from '../utils/getModulesByCaseTypes';
import initModuleOnQuestionDisplayOrderThunk from './initModuleOnQuestionDisplayOrder';
import runTriggersThunk from './runTriggers';
import saveData from '@/api/utils/saveData';
import SaveDataTypes from '@/api/types/saveDataTypes';

const addSelectedCaseTypesThunk =
  ({
    caseTypeIds,
    disableUpdateAPI,
  }: {
    caseTypeIds: string[];
    // disableUpdateAPI - currently only used in loadCaseClosureThunk because we don't want to save data to the API
    // we want to run client only selection of case types. Especially true on page load.
    disableUpdateAPI?: boolean;
  }): AppThunk =>
  (dispatch, getState) => {
    const caseTypes = convertEntitiesToArray(getState().caseTypes.entities);

    const defaultModules = getModulesByCaseTypes({
      caseTypes,
      selectedCaseTypes: caseTypeIds,
    });

    dispatch(removeManuallySelectedModules(defaultModules));
    dispatch(addSuggestedCaseTypes(caseTypeIds));
    dispatch(addSelectedCaseTypes(caseTypeIds));
    dispatch(addSelectedModules({ moduleIds: defaultModules }));
    dispatch(initModuleOnQuestionDisplayOrderThunk(defaultModules));
    dispatch(runTriggersThunk(defaultModules));

    !disableUpdateAPI &&
      saveData({
        type: SaveDataTypes.Case,
      });
  };

export default addSelectedCaseTypesThunk;

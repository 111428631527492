import { addSelectedModules, addTriggeredModules } from '../slices/modules';
import { AppThunk } from '../store';
import initModuleOnQuestionDisplayOrderThunk from './initModuleOnQuestionDisplayOrder';
import runTriggersThunk from './runTriggers';
import { ModuleId } from '../types/basicTypes';
import getModuleRelatedCaseTypes from '../utils/getModuleRelatedCaseTypes';

const addTriggeredModulesThunk =
  ({
    moduleIdToTrigger,
    triggeringModuleId,
  }: {
    moduleIdToTrigger: ModuleId;
    triggeringModuleId?: string;
  }): AppThunk =>
  (dispatch, getState) => {
    const selectedModules = getState().modules.selected;
    const caseTypes = getState().caseTypes.entities;
    const isModuleAlreadyVisible = selectedModules.includes(moduleIdToTrigger);

    if (isModuleAlreadyVisible) {
      return;
    }

    const relatedCaseTypeIds = getModuleRelatedCaseTypes({
      caseTypes,
      moduleId: triggeringModuleId as string,
    });
    const triggeredModulesPayload = relatedCaseTypeIds.map((caseTypeId) => ({
      relatedCaseTypeId: caseTypeId,
      moduleId: moduleIdToTrigger,
    }));

    dispatch(addTriggeredModules(triggeredModulesPayload));
    dispatch(addSelectedModules({ moduleIds: [moduleIdToTrigger] }));
    dispatch(initModuleOnQuestionDisplayOrderThunk([moduleIdToTrigger]));
    dispatch(runTriggersThunk([moduleIdToTrigger]));
  };

export default addTriggeredModulesThunk;

import client from '@/api/client';
//@ts-ignore
import { UpdateTip } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { TipId } from '@/store/types/basicTypes';
import { isEmptyTags } from '@/utils/helpers';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editTip = async (tipId: TipId) => {
  const tipEntity = store.getState().tips.entities[tipId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: UpdateTip,
    variables: {
      tipId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        text: isEmptyTags(tipEntity.text) ? '' : tipEntity.text,
        linkedAdviceIds: tipEntity.advice,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditTip,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editTip;

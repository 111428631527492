import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';
import { Tip } from '../types/tip';
import { AdviceId, TipId } from '../types/basicTypes';

export const tipSlice = createSlice({
  name: 'tips',
  initialState: initialState.tips,
  reducers: {
    setTips: (state, action: PayloadAction<TransformedInitialData['tips']>) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
    deleteTip: (state, action: PayloadAction<string>) => {
      delete state.entities[action.payload];
      state.ids = state.ids.filter((id) => id !== action.payload);
    },
    addTip: (state, action: PayloadAction<Tip>) => {
      state.entities[action.payload.id] = action.payload;
      state.ids.push(action.payload.id);
    },
    updateTip: (
      state,
      action: PayloadAction<{ id: TipId; updates: Partial<Tip> }>
    ) => {
      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        ...action.payload.updates,
      };
    },
    removeAdviceFromTips: (
      state,
      action: PayloadAction<{ adviceId: AdviceId }>
    ) => {
      state.ids.forEach((tipId) => {
        const tip = state.entities[tipId];
        if (tip.advice.includes(action.payload.adviceId)) {
          tip.advice = tip.advice.filter(
            (adviceId: AdviceId) => adviceId !== action.payload.adviceId
          );
        }
      });
    },
    setTipAliasMap: (
      state,
      action: PayloadAction<{ [aliasId: TipId]: TipId }>
    ) => {
      state.aliasMap = action.payload;
    },
  },
});

export const {
  setTips,
  deleteTip,
  addTip,
  updateTip,
  removeAdviceFromTips,
  setTipAliasMap,
} = tipSlice.actions;

export const selectTip = (state: RootState, id: string) => {
  return (
    state.tips.entities[id] ?? state.tips.entities[state.tips.aliasMap[id]]
  );
};
export const selectAllTipEntities = (state: RootState) => state.tips.entities;
export const selectAllTipsAsArray = (state: RootState) =>
  state.tips.ids.map((id) => state.tips.entities[id]);
export const selectTipAliasMap = (state: RootState) => state.tips.aliasMap;

export default tipSlice.reducer;

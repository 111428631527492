export enum ActionNames {
  UpdateQuestionValue = 'updateQuestionValueThunk',
  UpdateDescription = 'updateDescriptionThunk',
  AddSelectedCaseTypes = 'addSelectedCaseTypesThunk',
  RemoveSelectedCaseTypes = 'removeSelectedCaseTypesThunk',
  AddSelectedModules = 'addSelectedModulesThunk',
  AddManuallySelectedModules = 'addManuallySelectedModules',
  RemoveManuallySelectedModules = 'removeManuallySelectedModules',
  RemoveSelectedModules = 'removeSelectedModules',
  ChangeIndicatorState = 'changeIndicatorStateThunk',
}

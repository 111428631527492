import { Button } from '@s-rm/react-ui-lib';

import { Typography, Box, CircularProgress } from '@mui/material';
import { useAppSelector } from '@/hooks';
import {
  selectActiveEntitySearchList,
  selectEntitySearchFilter,
} from '@/store/slices/editor';
import SearchInput from '@/components/SearchInput';
import { ListContainer, LoadingOverlay } from './styled';
import EmptyItemsPlaceholder from '@/components/EmptyItemsPlaceholder';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { getEntityTypeAsText, pluraliseString } from '@/utils/helpers';
import { EntityType } from '@/store/types/basicTypes';
import QuestionListItem from './QuestionSearchModal/QuestionListItem';
import ModuleListItem from './ModuleSearchModal/ModuleListItem';
import ModuleGroupListItem from './ModuleGroupSearchModal/ModuleGroupListItem';
import { getFilterOptions } from './utils/getFilterOptions';
import { useEffect, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import CaseTypeListItem from './CaseTypeSearchModal/CaseTypeListItem';
import TipListItem from './TipSearchModal/TipListItem';
import AdviceListItem from './AdviceSearchModal/AdviceListItem';

export interface EntitySearchModalContent {
  onCreate: () => void;
  searchTerm: string;
  onChangeTerm: (term: string) => void;
  onChangeFilterBy: (entityType: EntityType) => void;
  onDelete: (id: string) => void;
}

const ModalContent = ({
  onCreate,
  searchTerm,
  onChangeTerm,
  onChangeFilterBy,
  onDelete,
}: EntitySearchModalContent) => {
  const activeEntitySearchType = useAppSelector(selectActiveEntitySearchList);
  const { entityIds, filterBy } = useAppSelector(selectEntitySearchFilter);
  const entityAsString = getEntityTypeAsText(activeEntitySearchType!);
  const pluralisedEntityString = pluraliseString(entityAsString);
  const filterOptions = getFilterOptions(activeEntitySearchType!)!;
  const currentFilterLabel = (
    filterOptions as Array<{ label: string; value: EntityType }>
  )?.find((o) => o.value === filterBy)?.label;

  const [isLoading, setIsLoading] = useState(true);

  const getListItem = () => {
    switch (activeEntitySearchType!) {
      case EntityType.Question:
        return QuestionListItem;
      case EntityType.Module:
        return ModuleListItem;
      case EntityType.ModuleGroup:
        return ModuleGroupListItem;
      case EntityType.CaseType:
        return CaseTypeListItem;
      case EntityType.Tip:
        return TipListItem;
      case EntityType.Advice:
        return AdviceListItem;
    }
  };

  const ListItem = getListItem() as React.FC<{
    id: string;
    onDelete: (id: string) => void;
  }>;

  const handleDebounce = ({ isDebouncing }: { isDebouncing: boolean }) => {
    setIsLoading(isDebouncing);
  };

  useEffect(() => {
    // The rendering of lots of list items can delay the opening of the MUI modal.
    // To avoid this, we add a small delay prior to rendering of unfiltered initial content
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        mb={2}
      >
        <Typography mt={2} mb={2} textTransform='uppercase' fontWeight={700}>
          List of {pluralisedEntityString}
        </Typography>
        <Button
          onClick={onCreate}
          variant='outlined'
          startIcon={<AddOutlinedIcon />}
          data-testid={`btn-add-${activeEntitySearchType?.toLowerCase()}`}
        >
          Add {entityAsString}
        </Button>
      </Box>
      <SearchInput
        value={searchTerm}
        shouldDebounce
        // @ts-ignore
        onChange={onChangeTerm}
        // @ts-ignore
        onChangeFilterOption={onChangeFilterBy}
        placeholder={`Filter by ${pluraliseString(
          getEntityTypeAsText(filterBy as EntityType)
        )}`}
        selectedFilterOption={currentFilterLabel}
        filterOptions={filterOptions}
        onDebounce={handleDebounce}
      />

      <Typography
        mt={3}
        mb={2}
        fontWeight={700}
        color='grey.500'
        data-testid={`entity-total-counter`}
      >
        {pluralisedEntityString} ({entityIds?.length})
      </Typography>

      <ListContainer
        data-testid={`${pluralisedEntityString.toLowerCase()}-entity-list-container`}
      >
        {isLoading && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}
        {!entityIds?.length && !isLoading && (
          <EmptyItemsPlaceholder height={400} text='No items found' />
        )}
        <FixedSizeList
          height={500}
          width='100%'
          itemSize={60}
          itemCount={entityIds?.length || 0}
          overscanCount={10}
        >
          {({ index, style }: ListChildComponentProps) => {
            const id = entityIds![index];
            return (
              <div key={id} style={{ ...style, paddingTop: '10px' }}>
                <ListItem id={id} onDelete={() => onDelete(id)} />
              </div>
            );
          }}
        </FixedSizeList>
      </ListContainer>
    </>
  );
};

export default ModalContent;

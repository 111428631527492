import { ModuleId } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import editModule from '@/api/mutations/editor/editModule';
import { debounce } from 'lodash';
import { Module } from '@/store/types/module';
import { updateModule } from '@/store/slices/modules';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';

const debouncedEditModule = debounce((id) => {
  editModule(id);
}, 1000);

const updateModuleThunk =
  ({
    id,
    updates,
    shouldDebounce,
  }: {
    id: ModuleId;
    updates: Partial<Module>;
    shouldDebounce?: boolean;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setIsDirty(true));
    dispatch(updateModule({ id, updates }));
    dispatch(
      updateEntityModifications({
        entityType: 'modules',
        entityId: id,
        fields: Object.keys(updates),
      })
    );
    if (shouldDebounce) {
      debouncedEditModule(id);
    } else {
      editModule(id);
    }
  };

export default updateModuleThunk;

import { QuestionTrigger } from '../types/question';

const getIsDuplicateTrigger = ({
  triggersToCompare,
  trigger,
}: {
  triggersToCompare: QuestionTrigger[];
  trigger: QuestionTrigger;
}) => {
  const duplicate = triggersToCompare.find(
    (t) => t.componentId === trigger.componentId
  );
  return duplicate?.action === trigger.action;
};

export default getIsDuplicateTrigger;

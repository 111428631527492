import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';
import getQuestion from '@/api/queries/getQuestion';
import {
  removeEntityFromSession,
  removeEntityModifications,
  removeOutdatedEntities,
} from '@/store/slices/editor';
import { updateQuestion } from '@/store/slices/questions';
import { AppThunk } from '@/store/store';
import { QuestionId } from '@/store/types/basicTypes';

const resetQuestionThunk =
  (questionId: QuestionId): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await getQuestion({ questionId });
      dispatch(updateQuestion({ id: questionId, updates: data }));
      resetEditorSessionChange(questionId);
      dispatch(
        removeEntityFromSession({
          entityType: 'questions',
          entityId: questionId,
        })
      );
      dispatch(
        removeEntityModifications({
          entityType: 'questions',
          entityId: questionId,
        })
      );
      dispatch(
        removeOutdatedEntities({
          entityType: 'questions',
          entityId: questionId,
        })
      );
    } catch (e) {
      // createError({ error: e, code: ErrorCodes.ArchiveAdvice });
      return;
    }
  };

export default resetQuestionThunk;

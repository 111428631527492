import { AppThunk } from '../store';
import { ModuleId } from '../types/basicTypes';

import triggerModules from './triggerModules';
import triggerQuestions from './triggerQuestions';

/**
 * Run through all questions for each provided module by using the display order
 * validating their stored values against their associated triggers.
 *
 * Set the visibility of the sub questions revelead by trigger validation.
 */
export const runTriggersThunk =
  (selectedModules: ModuleId[]): AppThunk =>
  (dispatch, getState) => {
    // @ts-ignore - Issue with ThunkDispatch vs AppDispatch - just typing problem.
    triggerQuestions({ selectedModules, dispatch, getState });
    // @ts-ignore Issue with ThunkDispatch vs AppDispatch - just typing problem.
    triggerModules({ selectedModules, dispatch, getState });
  };

export default runTriggersThunk;

import {
  de,
  enGB,
  zhCN,
  enUS,
  nl,
  ptBR,
  zhHK,
  enZA,
  enCA,
} from 'date-fns/locale';

export const locales = {
  'en-us': enUS,
  'en-gb': enGB,
  'en-ca': enCA,
  'en-za': enZA,
  'zh-cn': zhCN,
  'zh-hk': zhHK,
  'pt-br': ptBR,
  de: de,
  nl: nl,
  en: enUS,
};

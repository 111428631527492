import { defaultFiltersObject } from '@/store/types/admin';

export const adminMock = {
  questionnaires: {
    entities: {},
    ids: [],
  },
  incidents: {
    ids: [],
    entities: {},
    filters: defaultFiltersObject,
    isLoading: false,
  },
  contributors: {
    entities: {},
    ids: [],
    filters: defaultFiltersObject,
    isLoading: false,
  },
};

import saveData from '@/api/utils/saveData';
import { addSuggestedCaseTypes } from '../slices/caseTypes';
import {
  addDismissedIndicators,
  addSelectedIndicators,
  removeDismissedIndicators,
  removeSelectedIndicators,
} from '../slices/indicators';
import { AppThunk } from '../store';
import { IndicatorId } from '../types/basicTypes';
import { IndicatorActions } from '../types/indicator';
import SaveDataTypes from '@/api/types/saveDataTypes';

const changeIndicatorStateThunk =
  ({
    indicatorId,
    action,
  }: {
    indicatorId: IndicatorId;
    action: IndicatorActions;
  }): AppThunk =>
  (dispatch, getState) => {
    const suggestedCaseTypes =
      getState().indicators.indicatorCaseTypesMap[indicatorId] || [];

    switch (action) {
      case IndicatorActions.Select:
        dispatch(addSelectedIndicators([indicatorId]));
        !!suggestedCaseTypes.length &&
          dispatch(addSuggestedCaseTypes(suggestedCaseTypes));
        break;
      case IndicatorActions.Deselect:
        dispatch(removeSelectedIndicators([indicatorId]));
        break;
      case IndicatorActions.Dismiss:
        dispatch(addDismissedIndicators([indicatorId]));
        break;
      case IndicatorActions.Revive:
        dispatch(removeDismissedIndicators([indicatorId]));
        break;
      default:
        break;
    }

    saveData({
      type: SaveDataTypes.Case,
    });
  };

export default changeIndicatorStateThunk;

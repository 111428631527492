import client from '@/api/client';
//@ts-ignore
import { UpdateAdvice } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { AdviceId } from '@/store/types/basicTypes';
import { isEmptyTags } from '@/utils/helpers';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editAdvice = async (adviceId: AdviceId) => {
  const adviceEntity = store.getState().advice.entities[adviceId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: UpdateAdvice,
    variables: {
      adviceId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        text: isEmptyTags(adviceEntity.text) ? '' : adviceEntity.text,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditAdvice,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editAdvice;

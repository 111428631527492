import { EditedEntity, EditorSession } from '@/store/types/editor';
import { getformattedTimestamp } from '@/utils/helpers';
import { getFormattedEntity } from '../initialData/adaptApiDataEntities';
import { SessionData } from '@/api/types/initialData';

interface Entity {
  id: string;
  __typename: string;
}

interface GetSessionTransformParams {
  fetchEditorSession: {
    id: string;
    lastUpdatedAt: string;
    lastUpdatedBy: {
      Name: string;
    };
    createdAt: string;
    createdBy: {
      Name: string;
    };
    version: number;
    edited: {
      caseTypes: EditedEntity[];
      modules: EditedEntity[];
      questions: EditedEntity[];
      tips: EditedEntity[];
      advice: EditedEntity[];
      indicators: EditedEntity[];
      moduleGroups: EditedEntity[];
    };
  };
}

export const getFormattedEditedEntities = (
  entities: {
    to: Entity;
    from: Entity;
    id: string;
    originalVersion: number;
    isOutdated: boolean;
  }[]
) => {
  return entities.reduce(
    // @ts-ignore
    (acc, { from, to, id, originalVersion, isOutdated }) => ({
      ...acc,
      [id]: {
        id,
        from: getFormattedEntity(from),
        to: getFormattedEntity(to),
        originalVersion,
        isOutdated,
      },
    }),
    {} as {
      [key: string]: {
        id: string;
        from: Partial<Entity>;
        to: Partial<Entity>;
        originalVersion: number;
        isOutdated: number;
      };
    }
  );
};

export const getSessionTransform = (
  data: GetSessionTransformParams
): SessionData => {
  const session = data.fetchEditorSession;
  return {
    id: session.id,
    lastUpdatedAt: getformattedTimestamp(session.lastUpdatedAt),
    createdAt: getformattedTimestamp(session.createdAt),
    createdBy: session.createdBy?.Name,
    lastUpdatedBy: session.lastUpdatedBy?.Name,
    version: session.version,
    edited: {
      caseTypes: getFormattedEditedEntities(
        session.edited.caseTypes || []
      ) as unknown as EditorSession['edited']['caseTypes'],
      modules: getFormattedEditedEntities(
        session.edited.modules || []
      ) as unknown as EditorSession['edited']['modules'],
      questions: getFormattedEditedEntities(
        session.edited.questions || []
      ) as unknown as EditorSession['edited']['questions'],
      tips: getFormattedEditedEntities(
        session.edited.tips || []
      ) as unknown as EditorSession['edited']['tips'],
      advice: getFormattedEditedEntities(
        session.edited.advice || []
      ) as unknown as EditorSession['edited']['advice'],
      indicators: getFormattedEditedEntities(
        session.edited.indicators || []
      ) as unknown as EditorSession['edited']['indicators'],
      moduleGroups: getFormattedEditedEntities(
        session.edited.moduleGroups || []
      ) as unknown as EditorSession['edited']['moduleGroups'],
    },
  };
};

export default getSessionTransform;

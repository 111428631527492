import { apiUrl } from '@/constants/env';
import { getTokenFromCookie } from '@/utils/auth';
import { User } from '../types/user';

export const fetchUser = async () => {
  const URL = `${apiUrl}/auth/getuser`;
  const token = getTokenFromCookie();

  if (token) {
    const response = await fetch(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const user = (await response.json()) as User;
    return user;
  } else {
    return {};
  }
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { initialState } from '@/store/initialState';
import { TransformedInitialData } from '@/api/types/initialData';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: initialState.integrations,
  reducers: {
    setIntegrations: (
      state,
      action: PayloadAction<TransformedInitialData['integrations']>
    ) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.ids;
    },
  },
});

export const { setIntegrations } = integrationsSlice.actions;

export const selectAllIntegrationEntities = (state: RootState) =>
  state.integrations.entities;
export const selectIntegrationEntitiesAsArray = (state: RootState) =>
  state.integrations.ids.map((id) => state.integrations.entities[id]);
export const selectIntegrationIds = (state: RootState) =>
  state.integrations.ids;

export default integrationsSlice.reducer;

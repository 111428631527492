import client from '@/api/client';
//@ts-ignore
import { CreateQuestion } from '@/api/gql/mutations.gql';
import { ControlTypes } from '@/store/types/question';
import createQuestionTransform from '../../transforms/editor/createQuestionTransform';
import { getuuid } from '@/utils/helpers';
import { store } from '@/store';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const createQuestion = async () => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;
  const { data, errors } = await client.mutate({
    mutation: CreateQuestion,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        control: ControlTypes.Radio,
        label: '',
        guidance: '',
        validation: {
          required: false,
        },
        options: [
          { label: '', value: getuuid() },
          { label: '', value: getuuid() },
        ],
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateQuestion,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: createQuestionTransform(data) };
};

export default createQuestion;

import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import { setIsCaseActive } from '../slices/questionnaire';
import { AppThunk } from '../store';

const activateCaseThunk = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsCaseActive(true));
  await updateQuestionnaire();
};

export default activateCaseThunk;

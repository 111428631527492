import { AppThunk } from '@/store/store';
import { deleteModuleGroup } from '@/store/slices/modules';
import { setEntitySearchFilter, setIsDirty } from '@/store/slices/editor';
import archiveModuleGroup from '@/api/mutations/editor/archiveModuleGroup';
import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';

const archiveModuleGroupThunk =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    const searchFilter = getState().editor.entitySearchFilter;
    const isSessionOnly = getState().modules.groups.entities[id].isSessionOnly;

    !isSessionOnly
      ? await archiveModuleGroup(id)
      : await resetEditorSessionChange(id);

    dispatch(setIsDirty(true));
    dispatch(
      setEntitySearchFilter({
        ...searchFilter,
        entityIds: searchFilter.entityIds?.filter((qId) => qId !== id),
      })
    );
    dispatch(deleteModuleGroup(id));
  };

export default archiveModuleGroupThunk;

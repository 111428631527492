export interface Integration {
  id: string;
  name: string;
  description: string;
}

export interface IntegrationSlice {
  entities: {
    [key: string]: Integration;
  };
  ids: string[];
}

export type IntegrationNames = 'Jira' | 'SentinelOne';
export enum IntegrationName {
  JIRA = 'Jira',
  S1 = 'SentinelOne',
}

import client from '@/api/client';
//@ts-ignore
import { ResetEditorSessionChange } from '@/api/gql/editor/mutations.gql';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';
import { store } from '@/store';

export const resetEditorSessionChange = async (
  docId: string
): Promise<{
  data: any;
  errors: any;
}> => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;
  // @ts-ignore
  const { errors } = await client.mutate({
    mutation: ResetEditorSessionChange,
    variables: {
      editorSessionId,
      docId,
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.ResetEditorSessionChange,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: { success: !errors }, errors };
};

export default resetEditorSessionChange;

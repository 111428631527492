import {
  mockContributor1,
  mockContributor10,
  mockContributor2,
  mockContributor3,
  mockContributor4,
  mockContributor5,
  mockContributor6,
  mockContributor7,
  mockContributor8,
  mockContributor9,
} from '@/__mocks__/testState/admin.ts/contributorsMock';
import { Filters } from '@/store/types/admin';

const mockData = [
  mockContributor1,
  mockContributor2,
  mockContributor3,
  mockContributor4,
  mockContributor5,
  mockContributor6,
  mockContributor7,
  mockContributor8,
  mockContributor9,
  mockContributor10,
];

export const mockApiFetchContributors = async (
  filters: Filters
): Promise<{
  data: any;
  total: number;
  limit: number;
  next: boolean;
  previous: boolean;
}> => {
  let filteredData = mockData;

  filters.filters.forEach((filter) => {
    filteredData = filteredData.filter((project) => {
      return filter.values.includes(
        project[filter.column as keyof (typeof mockData)[0]] as string
      );
    });
  });

  if (filters.sort.column) {
    filteredData.sort((a, b) => {
      const valueA = a[filters.sort.column as keyof (typeof mockData)[0]];
      const valueB = b[filters.sort.column as keyof (typeof mockData)[0]];
      //@ts-ignore
      if (valueA < valueB) return filters.sort.direction === 'asc' ? -1 : 1;
      //@ts-ignore
      if (valueA > valueB) return filters.sort.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }

  const offset = filters.pagination.offset;
  const limit = filters.pagination.limit;
  const paginatedData = filteredData.slice(offset, offset + limit);
  const total = filteredData.length;
  const next = offset + limit < total;
  const previous = offset > 0;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: paginatedData,
        total,
        limit,
        next,
        previous,
      });
    }, 2000);
  });
};

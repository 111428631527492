import { AppThunk } from '@/store/store';
import {
  setActiveEntitySearchList,
  setAdviceInEdit,
  setIsDirty,
  setTipInEdit,
} from '@/store/slices/editor';

import { addAdvice } from '@/store/slices/advice';
import createAdvice from '@/api/mutations/editor/createAdvice';

const createAdviceThunk = (): AppThunk => async (dispatch) => {
  const { data: newAdvice } = await createAdvice();
  dispatch(setIsDirty(true));
  dispatch(addAdvice({ ...newAdvice, isSessionOnly: true }));
  dispatch(setAdviceInEdit({ id: newAdvice.id, isNew: true }));
  dispatch(setActiveEntitySearchList(null));
};

export default createAdviceThunk;

import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import { setIsQuestionnaireActive } from '../slices/questionnaire';
import { AppThunk } from '../store';

const activateQuestionnaire = (): AppThunk => async (dispatch) => {
  dispatch(setIsQuestionnaireActive(true));
  await updateQuestionnaire();
};

export default activateQuestionnaire;

import { CaseTypeId, ModuleId } from '../types/basicTypes';
import { CaseType } from '../types/caseType';

const getModuleRelatedCaseTypes = ({
  caseTypes,
  moduleId,
}: {
  caseTypes: {
    [key: string]: CaseType;
  };
  moduleId: ModuleId;
}) => {
  const parentCaseTypes = Object.entries(caseTypes).reduce(
    (acc, [caseTypeId, caseType]) =>
      !!caseType.modules.includes(moduleId) ? [...acc, caseTypeId] : acc,
    [] as CaseTypeId[]
  );

  return parentCaseTypes;
};

export default getModuleRelatedCaseTypes;

import {
  setIsLoadingContributors,
  selectContributorsFilters,
  updateContributorFilters,
  setContributors,
} from '@/store/slices/admin/admin';
import { AppThunk } from '../../store';

import getFormattedContributors from '@/api/transforms/admin/getFormattedContributors';
import { mockApiFetchContributors } from '@/components/admin/ContributorsTable/utils/mockAPI';

const fetchContributorsThunk = (): AppThunk => async (dispatch, getState) => {
  dispatch(setIsLoadingContributors(true));
  try {
    const filters = selectContributorsFilters(getState());

    const { data, total, limit, next, previous } =
      await mockApiFetchContributors(filters);

    dispatch(setContributors(getFormattedContributors(data)));

    dispatch(
      updateContributorFilters({
        pagination: {
          offset: filters.pagination?.offset || 0,
          limit,
          total,
          hasNext: !!next,
          hasPrev: !!previous,
        },
      })
    );
  } catch (error) {
  } finally {
    dispatch(setIsLoadingContributors(false));
  }
};

export default fetchContributorsThunk;

import { BackdropProps } from '@mui/material';
import { Spinner, StyledOverlay } from './styled';

const Overlay = (props: BackdropProps) => {
  return (
    <StyledOverlay data-testid='overlay' sx={{ zIndex: 9999 }} {...props}>
      {props.children || <Spinner />}
    </StyledOverlay>
  );
};

export default Overlay;

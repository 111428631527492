import {
  Box,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectCaseTypeInEdit,
  selectOutdatedEntities,
  selectSingleEntityModifications,
} from '@/store/slices/editor';
import { Label, LabelBadge, LabelContainer } from '../../styled';
import { EntityType, IndicatorId, ModuleId } from '@/store/types/basicTypes';
import { selectModuleEntities } from '@/store/slices/modules';
import { selectCaseType } from '@/store/slices/caseTypes';
import EntitySelectMenu from '../../EntitySelectMenu';
import { Button, TextField } from '@s-rm/react-ui-lib';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { StyledChip } from './styled';
import { filterEntitiesByIds } from '../../utils/helpers';
import {
  removeIndicatorFromCaseTypesMap,
  selectIndicatorEntities,
} from '@/store/slices/indicators';
import updateCaseTypeThunk from '@/store/thunks/editor/updateCaseType';
import { IndicatorActions } from '@/store/types/indicator';
import changeIndicatorStateThunk from '@/store/thunks/changeIndicatorStateThunk';
import useCaseClosure from '@/hooks/useCaseClosure';
import CancelIcon from '@mui/icons-material/Cancel';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const { id: caseTypeInEdit, isNew } = useAppSelector(selectCaseTypeInEdit);
  const { caseClosureId } = useCaseClosure();
  const isCaseClosure = caseTypeInEdit === caseClosureId;
  const caseTypeEntity = useAppSelector((state) =>
    selectCaseType(state, caseTypeInEdit!)
  );

  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'caseTypes', caseTypeInEdit!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).caseTypes?.[caseTypeInEdit!] || [];
  const indicators = useAppSelector(selectIndicatorEntities);
  const modules = useAppSelector(selectModuleEntities);

  const handleUpdateLabel = (val: string) => {
    dispatch(
      updateCaseTypeThunk({
        id: caseTypeEntity?.id,
        updates: { label: val },
        shouldDebounce: true,
      })
    );
  };

  const handleSelectionIndicator = (indicatorId: IndicatorId) => {
    dispatch(
      updateCaseTypeThunk({
        id: caseTypeEntity.id,
        updates: { indicators: [...caseTypeEntity.indicators, indicatorId] },
      })
    );
  };

  const handleSelectModule = (moduleId: ModuleId) => {
    dispatch(
      updateCaseTypeThunk({
        id: caseTypeEntity?.id,
        updates: { modules: [...caseTypeEntity?.modules, moduleId] },
        shouldDebounce: true,
      })
    );
  };

  const handleDeleteIndicator = async (indicatorId: string) => {
    dispatch(
      updateCaseTypeThunk({
        id: caseTypeEntity.id,
        updates: {
          indicators: caseTypeEntity.indicators.filter(
            (iId) => iId !== indicatorId
          ),
        },
      })
    );
    dispatch(
      removeIndicatorFromCaseTypesMap({
        indicatorId,
        caseTypeId: caseTypeEntity.id,
      })
    );
    dispatch(
      changeIndicatorStateThunk({
        indicatorId,
        action: IndicatorActions.Deselect,
      })
    );
  };

  const handleDeleteModule = async (moduleId: string) => {
    const filteredModules = caseTypeEntity.modules.filter(
      (mId) => mId !== moduleId
    );
    dispatch(
      updateCaseTypeThunk({
        id: caseTypeEntity.id,
        updates: {
          modules: filteredModules,
          triggeredModules: filteredModules,
        },
      })
    );
  };

  return (
    <Box minHeight={500} mb={5}>
      <Container>
        {!isCaseClosure && (
          <Box mt={2} mb={3}>
            <LabelContainer>
              <Label htmlFor='case-type-label'>
                Case type label <span style={{ color: 'red' }}>*</span>
              </Label>
              {modifications.includes('label') && (
                <Tooltip title='This field contains changes'>
                  <LabelBadge variant='modified'>Modified</LabelBadge>
                </Tooltip>
              )}
              {outDatedFields?.includes('label') && (
                <Tooltip title='This field is out of date with the latest published draft'>
                  <LabelBadge variant='outdated'>Outdated</LabelBadge>
                </Tooltip>
              )}
            </LabelContainer>

            <TextField
              id='case-type-label'
              size='small'
              placeholder={caseTypeEntity?.label ?? 'Enter case type label'}
              value={caseTypeEntity?.label}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => handleUpdateLabel(event.target.value)}
            />
          </Box>
        )}
        {!isCaseClosure && (
          <>
            <Box
              mb={2}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Box display='flex' alignItems='center' gap={2}>
                <Typography>Indicators</Typography>
                {modifications.includes('indicators') && (
                  <Tooltip title='This field contains changes'>
                    <LabelBadge variant='modified'>Modified</LabelBadge>
                  </Tooltip>
                )}
                {outDatedFields?.includes('indicators') && (
                  <Tooltip title='This field is out of date with the latest published draft'>
                    <LabelBadge variant='modified'>Outdated</LabelBadge>
                  </Tooltip>
                )}
              </Box>
              <EntitySelectMenu
                componentType={EntityType.Indicator}
                onSelect={handleSelectionIndicator}
                Trigger={
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    data-testid='btn-add-indicators-to-ctype'
                  >
                    ADD INDICATORS
                  </Button>
                }
                selectedEntityIds={caseTypeEntity?.indicators ?? []}
              />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            {caseTypeEntity?.indicators?.length > 0 ? (
              <Stack
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
              >
                {filterEntitiesByIds(
                  indicators,
                  caseTypeEntity?.indicators
                ).map((indicator) => (
                  <StyledChip
                    key={indicator.id}
                    label={indicator.label}
                    onDelete={() => handleDeleteIndicator(indicator.id)}
                    deleteIcon={<CancelIcon />}
                  />
                ))}
              </Stack>
            ) : (
              <Typography>
                <b>No indicators selected</b>
              </Typography>
            )}
          </>
        )}
        <Box
          mt={4}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' alignItems='center' gap={2}>
            <Typography>Modules</Typography>
            {modifications.includes('modules') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('modules') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='modified'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </Box>
          <EntitySelectMenu
            componentType={EntityType.Module}
            onSelect={handleSelectModule}
            Trigger={
              <Button
                data-testid='btn-add-modules-to-ctype'
                startIcon={<AddOutlinedIcon />}
              >
                ADD MODULES
              </Button>
            }
            selectedEntityIds={caseTypeEntity?.modules ?? []}
          />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        {caseTypeEntity?.modules?.length > 0 ? (
          <Stack
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            spacing={2}
          >
            {filterEntitiesByIds(modules, caseTypeEntity?.modules).map(
              (module) => (
                <StyledChip
                  key={module.id}
                  label={module.label}
                  onDelete={() => handleDeleteModule(module.id)}
                  data-testid={`delete-module-chip-${module.id}`}
                />
              )
            )}
          </Stack>
        ) : (
          <Typography>
            <b>No modules selected</b>
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default ModalContent;

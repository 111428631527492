import { FilterOptions } from '@/store/types/editor';
import { setEntitySearchFilter } from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import {
  getQuestionsRelatedToModules,
  getQuestionsRelatedToTips,
} from './utils/helpers';
import fuzzySearch from '@/store/utils/fuzzySearch';

const filterQuestionsThunk =
  (filterOptions: FilterOptions): AppThunk =>
  (dispatch, getState) => {
    dispatch(setEntitySearchFilter(filterOptions));
    const questionDisplayOrder = getState().questionDisplayOrder;
    const modules = getState().modules.entities;
    const tips = getState().tips.entities;
    const questions = getState().questions.entities;
    const { searchTerm, filterBy } = getState().editor.entitySearchFilter;

    if (!searchTerm) {
      dispatch(
        setEntitySearchFilter({
          entityIds: convertEntitiesToArray(questions).map((q) => q.id),
        })
      );
      return;
    }

    if (filterBy === EntityType.Module) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(modules),
        searchTerm,
      });

      dispatch(
        setEntitySearchFilter({
          entityIds: getQuestionsRelatedToModules(
            searchResults,
            questionDisplayOrder
          ),
        })
      );
    }

    if (filterBy === EntityType.Question) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(questions),
        searchTerm,
      });

      dispatch(
        setEntitySearchFilter({
          entityIds: searchResults,
        })
      );
    }

    if (filterBy === EntityType.Tip) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(tips),
        searchTerm,
      });

      dispatch(
        setEntitySearchFilter({
          entityIds: getQuestionsRelatedToTips(searchResults, questions),
        })
      );
    }

    return;
  };

export default filterQuestionsThunk;

import {
  Box,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/hooks';
import {
  selectModuleGroupInEdit,
  selectOutdatedEntities,
  selectSingleEntityModifications,
} from '@/store/slices/editor';

import TextArea from '@/components/Question/questionControls/TextArea/TextArea';
import { Label, LabelBadge, LabelContainer } from '../../styled';
import { EntityType, ModuleId } from '@/store/types/basicTypes';
import {
  selectModuleEntities,
  selectModuleGroup,
  selectModuleGroupEntities,
} from '@/store/slices/modules';
import EntitySelectMenu from '../../EntitySelectMenu';
import { Button, TextField } from '@s-rm/react-ui-lib';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ModuleItemWrapper } from './styled';
import updateModuleGroupThunk from '@/store/thunks/editor/updateModuleGroup';
import { useState } from 'react';
import { isNumberString } from '@/utils/helpers';
import getNonOrphanedModules from './utils/getNonOrphanedModules';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { reorderList } from '../../utils/helpers';
import {
  DragHandle,
  SortableItem,
  SortableList,
} from '@/components/DragDropList/DragDropList';

const ModalContent = () => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  const { id: moduleGroupInEditId, isNew } = useAppSelector(
    selectModuleGroupInEdit
  );
  const moduleGroupEntity = useAppSelector((state) =>
    selectModuleGroup(state, moduleGroupInEditId!)
  );
  const moduleGroupEntities = useAppSelector(selectModuleGroupEntities);
  const moduleEntities = useAppSelector(selectModuleEntities);
  let modifications = useAppSelector((state) =>
    selectSingleEntityModifications(state, 'moduleGroups', moduleGroupInEditId!)
  );
  if (isNew) {
    modifications = [];
  }
  const outDatedFields =
    useAppSelector(selectOutdatedEntities).moduleGroups?.[
      moduleGroupInEditId!
    ] || [];
  // Only allow modules that are not already in another module group or are in this one
  const invalidIds = getNonOrphanedModules({
    moduleGroups: moduleGroupEntities,
    modules: moduleEntities,
  }).filter((id) => !moduleGroupEntity.moduleIds.includes(id));

  const handleUpdateLabel = (val: string) => {
    dispatch(
      updateModuleGroupThunk({
        id: moduleGroupEntity.id,
        updates: { label: val },
      })
    );
  };

  const handleUpdatePriorityIndex = (val: string) => {
    if (!isNumberString(val) || !val) {
      setErrors({ priorityIndex: 'Priority index must be a number' });
      return;
    }

    setErrors({ priorityIndex: null });

    dispatch(
      updateModuleGroupThunk({
        id: moduleGroupEntity.id,
        updates: { priorityIndex: val },
      })
    );
  };

  const handleSelectModule = (moduleId: ModuleId) => {
    dispatch(
      updateModuleGroupThunk({
        id: moduleGroupEntity.id,
        updates: {
          moduleIds: moduleGroupEntity.moduleIds.includes(moduleId)
            ? moduleGroupEntity.moduleIds.filter((id) => id !== moduleId)
            : [...moduleGroupEntity.moduleIds, moduleId],
        },
      })
    );
  };

  const handleDragEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex === newIndex) return;
    dispatch(
      updateModuleGroupThunk({
        id: moduleGroupEntity.id,
        updates: {
          moduleIds: reorderList(
            moduleGroupEntity.moduleIds,
            oldIndex,
            newIndex
          ),
        },
      })
    );
  };

  return (
    <Box minHeight={500} mb={5}>
      <Container>
        <Box mt={2} mb={3}>
          <LabelContainer>
            <Label>Module group Text</Label>
            {modifications.includes('label') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('label') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextField
            size='small'
            placeholder={'Enter module group text'}
            value={moduleGroupEntity.label}
            onChange={(event) => handleUpdateLabel(event.target.value)}
            inputProps={{
              'data-testid': 'module-group-label-textfield',
            }}
          />
        </Box>
        <Box mt={2} mb={3}>
          <LabelContainer>
            <Label>Priority index</Label>
            {modifications.includes('priorityIndex') && (
              <Tooltip title='This field contains changes'>
                <LabelBadge variant='modified'>Modified</LabelBadge>
              </Tooltip>
            )}
            {outDatedFields?.includes('priorityIndex') && (
              <Tooltip title='This field is out of date with the latest published draft'>
                <LabelBadge variant='outdated'>Outdated</LabelBadge>
              </Tooltip>
            )}
          </LabelContainer>
          <TextArea
            placeholder='Enter a number'
            value={moduleGroupEntity.priorityIndex}
            onChange={handleUpdatePriorityIndex}
            // @ts-ignore
            error={!!errors.priorityIndex}
            helperText={errors.priorityIndex}
            inputProps={{
              'data-testid': 'module-group-priority-index-textfield',
            }}
          />
        </Box>
        <Box
          mb={2}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box display='flex' gap={2}>
            <Typography>Modules</Typography>
            <LabelContainer>
              {modifications.includes('moduleIds') && (
                <Tooltip title='This field contains changes'>
                  <LabelBadge variant='modified'>Modified</LabelBadge>
                </Tooltip>
              )}
              {outDatedFields?.includes('moduleIds') && (
                <Tooltip title='This field is out of date with the latest published draft'>
                  <LabelBadge variant='outdated'>Outdated</LabelBadge>
                </Tooltip>
              )}
            </LabelContainer>
          </Box>
          <EntitySelectMenu
            invalidIds={invalidIds}
            componentType={EntityType.Module}
            onSelect={handleSelectModule}
            Trigger={
              <Button startIcon={<AddOutlinedIcon />}>
                Add modules to group
              </Button>
            }
            selectedEntityIds={moduleGroupEntity.moduleIds}
          />
        </Box>
        <Divider />
        <Box mt={3}>
          {!moduleGroupEntity.moduleIds.length && (
            <Typography color='grey.500'>No selected modules yet</Typography>
          )}
          <SortableList onSortEnd={handleDragEnd} useDragHandle>
            {moduleGroupEntity.moduleIds.map((moduleId: ModuleId, index) => {
              const moduleEntity = moduleEntities[moduleId];
              return (
                <SortableItem key={moduleId} index={index}>
                  <Box py={1} display='flex' alignItems='center' gap={1}>
                    <DragHandle>
                      <Box display='flex' style={{ cursor: 'move' }}>
                        <DragIndicatorIcon />
                      </Box>
                    </DragHandle>
                    <Box width='100%'>
                      <ModuleItemWrapper key={moduleId}>
                        <Box mr={1}>{moduleEntity.label}</Box>
                        <IconButton
                          onClick={() => handleSelectModule(moduleId)}
                          size='small'
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </ModuleItemWrapper>
                    </Box>
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
        </Box>
      </Container>
    </Box>
  );
};

export default ModalContent;

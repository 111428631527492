import client from '@/api/client';
import fetchEditorSessionFixture from '@/api/fixtures/fetchEditorSessionFixture';
//@ts-ignore
import { GetModule } from '@/api/gql/queries.gql';
import { isUsingFixtures } from '@/constants/env';
import { getFormattedEntity } from '../transforms/initialData/adaptApiDataEntities';
import { Module } from '@/store/types/module';

export const getModule = async ({
  moduleId,
}: {
  moduleId: string;
}): Promise<{ data: Module }> => {
  if (isUsingFixtures) {
    // @ts-ignore
    return { data: fetchEditorSessionFixture };
  }

  // @ts-ignore
  const { data, errors } = await client.query({
    query: GetModule,
    variables: {
      id: moduleId,
    },
  });

  // @ts-ignore
  return { data: getFormattedEntity(data.getModule) as Module, errors };
};

export default getModule;

import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';

interface UseSearchProps<T> {
  searchableEntities: T[];
  initialSearchTerm?: string;
  searchKeys: string[];
  onSearchEnd?: () => void;
}

const useSearch = <T>({
  searchableEntities,
  initialSearchTerm,
  searchKeys,
  onSearchEnd,
}: UseSearchProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm || '');
  const [searchResults, setSearchResults] = useState<T[]>(searchableEntities);

  const handleSearch = () => {
    const fuse = new Fuse(searchableEntities, {
      keys: searchKeys,
      threshold: 0.2,
    });
    const result = fuse.search(searchTerm).map((match) => match.item);

    setSearchResults(!!searchTerm ? result : searchableEntities);
    onSearchEnd?.();
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return { searchResults, setSearchTerm, handleSearch, searchTerm };
};
export default useSearch;

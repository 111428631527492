import resetEditorSessionChange from '@/api/mutations/editor/resetEditorSessionChange';
import getModuleGroup from '@/api/queries/getModuleGroup';
import {
  removeEntityFromSession,
  removeEntityModifications,
  removeOutdatedEntities,
} from '@/store/slices/editor';
import { updateModuleGroup } from '@/store/slices/modules';
import { AppThunk } from '@/store/store';
import { ModuleId } from '@/store/types/basicTypes';

const resetModuleGroupThunk =
  (moduleGroupId: ModuleId): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await getModuleGroup({ moduleGroupId });
      dispatch(updateModuleGroup({ id: moduleGroupId, updates: data }));
      resetEditorSessionChange(moduleGroupId);
      dispatch(
        removeEntityFromSession({
          entityType: 'moduleGroups',
          entityId: moduleGroupId,
        })
      );
      dispatch(
        removeEntityModifications({
          entityType: 'moduleGroups',
          entityId: moduleGroupId,
        })
      );
      dispatch(
        removeOutdatedEntities({
          entityType: 'moduleGroups',
          entityId: moduleGroupId,
        })
      );
    } catch (e) {
      // createError({ error: e, code: ErrorCodes.ArchiveAdvice });
      return;
    }
  };

export default resetModuleGroupThunk;

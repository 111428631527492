import { EntityType } from '@/store/types/basicTypes';

export const advice1Mock = {
  id: 'advice-1',
  label: '',
  text: 'Advice 1',
  entityType: EntityType.Advice,
  isSessionOnly: true,
};

export const advice2Mock = {
  id: 'advice-2',
  label: '',
  text: 'Advice 2',
  entityType: EntityType.Advice,
  isSessionOnly: true,
};

export const adviceMock = {
  entities: {
    [advice1Mock.id]: advice1Mock,
  },
  ids: [advice1Mock.id],
};

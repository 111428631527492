import { FilterOptions } from '@/store/types/editor';
import { setEntitySearchFilter } from '@/store/slices/editor';
import { EntityType } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import convertEntitiesToArray from '@/store/utils/convertEntitiesToArray';
import { getModuleGroupsByModuleIds } from './utils/helpers';
import fuzzySearch from '@/store/utils/fuzzySearch';

const filterModuleGroupsThunk =
  (filterOptions: FilterOptions): AppThunk =>
  (dispatch, getState) => {
    dispatch(setEntitySearchFilter(filterOptions));
    const modules = getState().modules.entities;
    const moduleGroups = getState().modules.groups.entities;
    const { searchTerm, filterBy } = getState().editor.entitySearchFilter;

    if (!searchTerm) {
      dispatch(
        setEntitySearchFilter({
          entityIds: convertEntitiesToArray(moduleGroups).map((q) => q.id),
        })
      );
      return;
    }
    if (filterBy === EntityType.ModuleGroup) {
      dispatch(
        setEntitySearchFilter({
          entityIds: fuzzySearch({
            keys: ['label'],
            entities: convertEntitiesToArray(moduleGroups),
            searchTerm,
          }),
        })
      );
      return;
    }

    if (filterBy === EntityType.Module) {
      const searchResults = fuzzySearch({
        keys: ['label'],
        entities: convertEntitiesToArray(modules),
        searchTerm,
      });

      dispatch(
        setEntitySearchFilter({
          entityIds: getModuleGroupsByModuleIds({
            moduleIds: searchResults,
            moduleGroups,
          }),
        })
      );
      return;
    }
  };

export default filterModuleGroupsThunk;

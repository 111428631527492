import { EntityType } from '@/store/types/basicTypes';
import { Module } from '@/store/types/module';

const createModuleTransform = (data: any): Module => {
  const {
    createModule: { id },
  } = data;
  return {
    entityType: EntityType.Module,
    id: id,
    description: '',
    label: '',
    questions: [],
    isSessionOnly: true,
    roles: [],
  };
};

export default createModuleTransform;

import { CaseTypeId, ModuleId } from '@/store/types/basicTypes';
import { CaseType } from '@/store/types/caseType';
import { ModuleGroup, ModuleSelectedByTrigger } from '@/store/types/module';
import getModuleRelatedCaseTypes from '@/store/utils/getModuleRelatedCaseTypes';
import { ModuleDisplayStructure } from '../types';

export const getCaseTypesInGroup = (
  modulesInGroup: ModuleId[],
  caseTypes: { [key: string]: CaseType }
) =>
  modulesInGroup.reduce(
    (acc, moduleId) =>
      Array.from(
        new Set([
          ...acc,
          ...getModuleRelatedCaseTypes({
            caseTypes,
            moduleId,
          }),
        ])
      ),
    [] as CaseTypeId[]
  );

export const getSelectedModulesInGroup = (
  selectedModuleIds: ModuleId[],
  group: ModuleGroup
) => group.moduleIds.filter((moduleId) => selectedModuleIds.includes(moduleId));

// Preserve the order of the selected case types
export const getSelectedCaseTypesInGroup = (
  caseTypesInGroup: CaseTypeId[],
  selectedCaseTypeIds: CaseTypeId[]
) => selectedCaseTypeIds.filter((id) => caseTypesInGroup.includes(id));

export const getTriggeredModulesInGroup = (
  triggeredModules: ModuleSelectedByTrigger[],
  selectedModulesInGroup: ModuleId[]
) =>
  triggeredModules.filter((triggeredModule) =>
    selectedModulesInGroup.includes(triggeredModule.moduleId)
  );

export const getTriggerRelatedCaseTypes = (
  triggeredModulesInGroup: ModuleSelectedByTrigger[]
) =>
  triggeredModulesInGroup.reduce((acc, triggeredModule) => {
    return Array.from(new Set([...acc, triggeredModule.relatedCaseTypeId]));
  }, [] as CaseTypeId[]);

export const getCaseTypesWithModules = ({
  selectedCaseTypesInGroup,
  caseTypes,
  selectedModulesInGroup,
  triggeredModulesInGroup,
}: {
  selectedCaseTypesInGroup: CaseTypeId[];
  caseTypes: { [key: string]: CaseType };
  selectedModulesInGroup: ModuleId[];
  triggeredModulesInGroup: ModuleSelectedByTrigger[];
}) =>
  selectedCaseTypesInGroup.map((caseTypeId) => ({
    caseTypeId,
    relatedModules: sortSelectedModulesToGroupOrder({
      selectedModules: Array.from(
        // Must be unique, caseTypes modules may contain same ids as triggeredModulesInGroup
        // if it's not a unique array, module will appear multiple times in the ui.
        new Set([
          // Any modules related to the case type that are also in the group
          ...caseTypes[caseTypeId].modules.filter((moduleId) =>
            selectedModulesInGroup.includes(moduleId)
          ),
          // caseTypes.entity.modules[] does not contain triggered modules, so we need to add any here.
          ...triggeredModulesInGroup.reduce(
            (acc, m) => [
              ...acc,
              ...(m.relatedCaseTypeId === caseTypeId ? [m.moduleId] : []),
            ],
            [] as ModuleId[]
          ),
        ])
      ),
      order: selectedModulesInGroup,
    }),
  }));

export const sortSelectedModulesToGroupOrder = ({
  selectedModules,
  order,
}: {
  selectedModules: ModuleId[];
  order: ModuleId[];
}) => {
  const sortedModules = [...selectedModules];
  sortedModules.sort((a, b) => {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);
    if (indexA < indexB) {
      return -1;
    } else if (indexA > indexB) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedModules;
};

type CaseTypeWithModules = {
  caseTypeId: string;
  relatedModules: string[];
};

type ResultType = {
  caseTypeIds: string[];
  relatedModules: string[];
};

/**
 * Transforms an array of objects, where each object has a 'caseTypeId' and 'relatedModules' fields, into a new structure.
 * In the new structure, 'caseTypeId' becomes 'caseTypeIds' - an array of IDs. This change is useful when multiple case types share
 * related modules. As such, instead of duplicating related modules, the function groups case types into a single entry
 * with an identical 'relatedModules' array. Thus, redundancies are avoided, showing a clearer relation between case types and modules.
 *
 * @example
 * Input:
 *  [
 *      {"caseTypeId": "CT1", "relatedModules": ["MOD1"]},
 *      {"caseTypeId": "CT2", "relatedModules": ["MOD2"]},
 *      {"caseTypeId": "CT-3", "relatedModules": ["MOD2"]}
 *  ]
 *
 * Output:
 *  [
 *      {"caseTypeIds": ["CT1"], "relatedModules": ["MOD1"]},
 *      {"caseTypeIds": ["CT2", "CT3"], "relatedModules": ["MOD2"]}
 *  ]
 */
export const getCombinedCaseTypesWithModules = (
  caseTypesWithModules: CaseTypeWithModules[]
): ResultType[] => {
  const moduleCaseTypeMap = caseTypesWithModules.reduce(
    (map, { relatedModules, caseTypeId }) => {
      relatedModules.forEach((moduleId) => {
        const caseTypeIds = map.get(moduleId) || [];
        map.set(moduleId, [...caseTypeIds, caseTypeId]);
      });
      return map;
    },
    new Map<string, string[]>()
  );

  return Array.from(moduleCaseTypeMap.entries()).reduce(
    (result, [moduleId, caseTypeIds]) => {
      // @ts-ignore
      const sortedUniqueCaseTypeIds = [...new Set(caseTypeIds)].sort((a, b) => {
        const indexA = caseTypeIds.indexOf(a);
        const indexB = caseTypeIds.indexOf(b);
        if (indexA < indexB) {
          return -1;
        } else if (indexA > indexB) {
          return 1;
        } else {
          return 0;
        }
      });
      const existingEntry = result.find(
        (entry) =>
          JSON.stringify(entry.caseTypeIds) ===
          JSON.stringify(sortedUniqueCaseTypeIds)
      );

      existingEntry
        ? existingEntry.relatedModules.push(moduleId)
        : result.push({
            caseTypeIds: sortedUniqueCaseTypeIds,
            relatedModules: [moduleId],
          });

      return result;
    },
    [] as ResultType[]
  );
};
/**
 * We want to render module groups, in which we render case types, in which we render
 * modules. eg:
 *
 * - Module Group 1
 *   - Case Type 1
 *    - Module 1
 *    - Module 2
 * - Module Group 2
 *   - Case Type 2
 *     - Module 3
 *
 * Here we construct an array of module group objects. Each object contains the case types
 * related to the group's modules, and each case type object then contains the modules related
 * to the case type.
 */
export const getDiagnosisModuleDisplayStructure = ({
  moduleGroups,
  caseTypes,
  selectedCaseTypeIds,
  selectedModuleIds,
  triggeredModules,
}: {
  moduleGroups: {
    entities: { [key: string]: ModuleGroup };
    ids: ModuleId[];
  };
  caseTypes: { [key: string]: CaseType };
  selectedModuleIds: ModuleId[];
  selectedCaseTypeIds: CaseTypeId[];
  triggeredModules: ModuleSelectedByTrigger[];
}): ModuleDisplayStructure[] => {
  const moduleDisplayStructure = moduleGroups.ids.map((groupId) => {
    const group = moduleGroups.entities[groupId];
    // Find whichever modules in the group are selected
    const selectedModulesInGroup = getSelectedModulesInGroup(
      selectedModuleIds,
      group
    );
    const triggeredModulesInGroup = getTriggeredModulesInGroup(
      triggeredModules,
      selectedModulesInGroup
    );

    // Find the case types related to those selected modules
    const caseTypesInGroup = [
      ...getCaseTypesInGroup(selectedModulesInGroup, caseTypes),
      ...getTriggerRelatedCaseTypes(triggeredModulesInGroup),
    ];

    // Filter case types to only those selected
    const selectedCaseTypesInGroup = getSelectedCaseTypesInGroup(
      caseTypesInGroup,
      selectedCaseTypeIds
    );

    // Now that we have a list of case types and modules relating to them which are in
    // the current group - We can construct the case type objects which also contain their related modules.
    const caseTypesInGroupWithModules = getCaseTypesWithModules({
      selectedCaseTypesInGroup,
      caseTypes,
      selectedModulesInGroup,
      triggeredModulesInGroup,
    });

    // We only want to return a group if it has case types associated with it
    return selectedCaseTypesInGroup.length
      ? {
          groupLabel: group.label,
          groupId: group.id,
          caseTypes: getCombinedCaseTypesWithModules(
            caseTypesInGroupWithModules
          ),
        }
      : null;
  });

  return moduleDisplayStructure.filter(Boolean) as ModuleDisplayStructure[];
};

export default getDiagnosisModuleDisplayStructure;

import {
  AdaptedEntities,
  FormattedCase,
  FormattedQuestionnaire,
} from '@/api/types/initialData';
import { Advice } from '@/store/types/advice';
import { CaseType } from '@/store/types/caseType';
import { Indicator } from '@/store/types/indicator';
import { Module, ModuleGroup } from '@/store/types/module';
import { Question } from '@/store/types/question';
import { Tip } from '@/store/types/tip';
import { omitBy } from 'lodash';

export const filterInvalidModuleIdsFromCaseType = ({
  caseTypes,
  modules,
}: {
  caseTypes: CaseType[];
  modules: Module[];
}) => {
  const moduleIds = modules.map(({ id }) => id);
  return caseTypes.map((caseType) => {
    const filteredModules =
      caseType.modules?.filter((moduleId) => moduleIds.includes(moduleId)) ||
      [];
    return {
      ...caseType,
      modules: filteredModules,
    };
  });
};

export const filterInvalidRootQuestionsFromModules = ({
  questions,
  modules,
}: {
  questions: Question[];
  modules: Module[];
}) => {
  const questionIds = questions.map(({ id }) => id);
  return modules.map((m) => {
    const filteredQuestions =
      m.questions?.filter((qId) => questionIds.includes(qId)) || [];
    return {
      ...m,
      questions: filteredQuestions,
    };
  });
};

export const filterInvalidIndicatorIdsFromCaseType = ({
  caseTypes,
  indicators,
}: {
  caseTypes: CaseType[];
  indicators: Indicator[];
}) => {
  const indicatorIds = indicators.map(({ id }) => id);
  return caseTypes.map((caseType) => {
    const filteredIndicators =
      caseType.indicators?.filter((indicatorId) =>
        indicatorIds.includes(indicatorId)
      ) || [];
    return {
      ...caseType,
      indicators: filteredIndicators,
    };
  });
};

export const filterInvalidAdviceIdsFromTips = ({
  tips,
  advice,
}: {
  tips: Tip[];
  advice: Advice[];
}) => {
  const adviceIds = advice.map(({ id }) => id);
  return tips.map((tip) => {
    const filteredAdvice =
      tip.advice?.filter((adviceId) => adviceIds.includes(adviceId)) || [];
    return {
      ...tip,
      advice: filteredAdvice,
    };
  });
};

export const filterInvalidTriggersFromQuestions = ({
  questions,
  tips,
  modules,
}: {
  questions: Question[];
  tips: Tip[];
  modules: Module[];
}) => {
  const validIds = [
    ...questions.map((q) => q.id),
    ...tips.map((t) => t.id),
    ...modules.map((m) => m.id),
  ];

  return questions.map((question) => {
    const filteredTriggers = question.triggers.filter((trigger) =>
      validIds.includes(trigger.componentId)
    );
    return {
      ...question,
      triggers: filteredTriggers,
    };
  });
};

export const filterInvalidIdsFromCase = ({
  caseEntity,
  indicators,
  caseTypes,
  questions,
  modules,
}: {
  caseEntity: FormattedCase;
  indicators: Indicator[];
  caseTypes: CaseType[];
  questions: Question[];
  modules: Module[];
}) => {
  const indicatorIds = indicators.map(({ id }) => id);
  const caseTypeIds = caseTypes.map(({ id }) => id);
  const questionIds = questions.map(({ id }) => id);

  const filteredSelectedIndicators = caseEntity.selectedIndicators.filter(
    (indicatorId) => indicatorIds.includes(indicatorId)
  );
  const filteredDismissedIndicators = caseEntity.dismissedIndicators.filter(
    (indicatorId) => indicatorIds.includes(indicatorId)
  );
  const filteredSelectedCaseTypes = caseEntity.selectedCaseTypes.filter(
    (caseTypeId) => caseTypeIds.includes(caseTypeId)
  );
  const filteredAnswers = omitBy(caseEntity.questionValues, (value, key) => {
    return !questionIds.includes(key);
  });
  const filteredSelectedModules = caseEntity.manuallySelectedModules?.filter(
    (moduleId) => modules.map(({ id }) => id).includes(moduleId)
  );

  return {
    ...caseEntity,
    selectedIndicators: filteredSelectedIndicators || [],
    selectedCaseTypes: filteredSelectedCaseTypes || [],
    dismissedIndicators: filteredDismissedIndicators || [],
    manuallySelectedModules: filteredSelectedModules || [],
    questionValues: filteredAnswers,
  };
};

export const filterInvalidIdsFromQuestionnaire = ({
  questionnaireEntity,
  indicators,
  caseTypes,
  questions,
  modules,
}: {
  questionnaireEntity: FormattedQuestionnaire;
  indicators: Indicator[];
  caseTypes: CaseType[];
  questions: Question[];
  modules: Module[];
}) => {
  const indicatorIds = indicators.map(({ id }) => id);
  const caseTypeIds = caseTypes.map(({ id }) => id);
  const questionIds = questions.map(({ id }) => id);

  const filteredSelectedIndicators =
    questionnaireEntity.selectedIndicators.filter((indicatorId) =>
      indicatorIds.includes(indicatorId)
    );
  const filteredDismissedIndicators =
    questionnaireEntity.dismissedIndicators.filter((indicatorId) =>
      indicatorIds.includes(indicatorId)
    );
  const filteredSelectedCaseTypes =
    questionnaireEntity.selectedCaseTypes.filter((caseTypeId) =>
      caseTypeIds.includes(caseTypeId)
    );
  const filteredAnswers = omitBy(
    questionnaireEntity.questionValues,
    (value, key) => {
      return !questionIds.includes(key);
    }
  );
  const filteredSelectedModules =
    questionnaireEntity.manuallySelectedModules?.filter((moduleId) =>
      modules.map(({ id }) => id).includes(moduleId)
    );

  return {
    ...questionnaireEntity,
    selectedIndicators: filteredSelectedIndicators || [],
    selectedCaseTypes: filteredSelectedCaseTypes || [],
    dismissedIndicators: filteredDismissedIndicators || [],
    manuallySelectedModules: filteredSelectedModules || [],
    questionValues: filteredAnswers,
  };
};

export const filterInvalidModuleIdsFromGroups = ({
  moduleGroups,
  modules,
}: {
  moduleGroups: ModuleGroup[];
  modules: Module[];
}) => {
  const moduleIds = modules.map(({ id }) => id);
  return moduleGroups.map((group) => {
    const filteredModules =
      group.moduleIds?.filter((moduleId) => moduleIds.includes(moduleId)) || [];
    return {
      ...group,
      moduleIds: filteredModules,
    };
  });
};

export const filterInvalidEntityIdLinks = (
  entities: AdaptedEntities
): AdaptedEntities => {
  const {
    questions,
    tips,
    modules,
    moduleGroups,
    caseTypes,
    indicators,
    advice,
    caseEntity,
    description,
    user,
    integrations,
    questionnaireEntity,
  } = entities;
  return {
    caseTypes: filterInvalidModuleIdsFromCaseType({
      caseTypes: filterInvalidIndicatorIdsFromCaseType({
        caseTypes,
        indicators,
      }),
      modules,
    }),
    tips: filterInvalidAdviceIdsFromTips({ tips, advice }),
    questions: filterInvalidTriggersFromQuestions({
      questions: questions,
      tips,
      modules: modules,
    }),
    modules: filterInvalidRootQuestionsFromModules({
      questions,
      modules,
    }),
    moduleGroups: filterInvalidModuleIdsFromGroups({
      moduleGroups: moduleGroups,
      modules,
    }),
    caseEntity: filterInvalidIdsFromCase({
      caseEntity,
      indicators,
      caseTypes,
      questions,
      modules,
    }),
    questionnaireEntity: filterInvalidIdsFromQuestionnaire({
      questionnaireEntity,
      indicators,
      caseTypes,
      questions,
      modules,
    }),
    advice,
    indicators,
    description,
    user,
    integrations,
  };
};

export default filterInvalidEntityIdLinks;

import { CaseTypeId, ModuleId } from '../types/basicTypes';
import { CaseType } from '../types/caseType';

const getModulesByCaseTypes = ({
  caseTypes,
  selectedCaseTypes,
}: {
  caseTypes: CaseType[];
  selectedCaseTypes: CaseTypeId[];
}): ModuleId[] =>
  selectedCaseTypes.reduce(
    (acc, caseTypeId) => [
      ...acc,
      ...(caseTypes.find((c) => c.id === caseTypeId)?.modules || []),
    ],
    [] as string[]
  );

export default getModulesByCaseTypes;

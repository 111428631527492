import client from '@/api/client';
//@ts-ignore
import { UpdateModuleGroup } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { ModuleGroupId } from '@/store/types/basicTypes';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editModuleGroup = async (moduleGroupId: ModuleGroupId) => {
  const moduleGroupEntity =
    store.getState().modules.groups.entities[moduleGroupId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: UpdateModuleGroup,
    variables: {
      moduleGroupId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        label: moduleGroupEntity?.label,
        moduleIds: moduleGroupEntity?.moduleIds,
        priorityIndex: moduleGroupEntity?.priorityIndex,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditModuleGroup,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editModuleGroup;

interface Entity {
  id: string;
  archived: boolean;
  __typename: string;
}

const mergeSessionEntities = (
  originalEntities: Entity[],
  sessionEntities: Entity[]
) => {
  if (!sessionEntities) return originalEntities;

  const findEntity = (entities: Entity[], id: string) =>
    entities.find((entity) => entity.id === id);

  const mergedEntities = originalEntities.map((entity) => {
    return findEntity(sessionEntities, entity.id) || entity;
  });

  const addedEntities = sessionEntities
    .filter((entity) => !findEntity(originalEntities, entity.id))
    .map((e) => ({ ...e, isSessionOnly: true }));

  return [...mergedEntities, ...addedEntities].filter((e) => !e.archived);
};

export default mergeSessionEntities;

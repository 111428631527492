import {
  PlaceholderIconWrapper,
  PlaceholderText,
  PlaceholderWrapper,
} from './styled';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

interface EmptyItemsPlaceholderProps {
  icon?: React.ReactNode;
  text: React.ReactNode;
  height?: string | number;
}

const EmptyItemsPlaceholder = ({
  icon,
  text,
  height,
}: EmptyItemsPlaceholderProps) => (
  <PlaceholderWrapper style={{ height }}>
    <PlaceholderIconWrapper>
      {icon || <ManageSearchIcon />}
    </PlaceholderIconWrapper>
    <PlaceholderText>{text}</PlaceholderText>
  </PlaceholderWrapper>
);

export default EmptyItemsPlaceholder;

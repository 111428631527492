import { Button } from '@s-rm/react-ui-lib';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useEffect, useState } from 'react';

const CopyButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children?: React.ReactNode;
}) => {
  const [hasClicked, setHasClicked] = useState(false);

  const handleClick = () => {
    onClick();
    setHasClicked(true);
  };

  useEffect(() => {
    if (hasClicked) {
      setTimeout(() => {
        setHasClicked(false);
      }, 2000);
    }
  }, [hasClicked]);

  return (
    <Button
      startIcon={
        hasClicked ? (
          <DoneOutlinedIcon color='success' />
        ) : (
          <ContentCopyOutlinedIcon />
        )
      }
      variant='outlined'
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

export default CopyButton;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '@/store/initialState';
import { AppThunk, RootState } from '../store';
import { setQuestions, setQuestionValue } from './questions';
import { RawInitialData } from '@/api/types/initialData';
import {
  setModules,
  addSelectedModules,
  setModuleGroups,
  addManuallySelectedModules,
  setStaticModuleIds,
} from './modules';
import {
  addSelectedIndicators,
  setIndicatorToCaseTypesMap,
  setIndicators,
  setSuggestedIndicators,
} from './indicators';
import {
  addSelectedCaseTypes,
  addSuggestedCaseTypes,
  setCaseTypes,
} from './caseTypes';
import { setTipAliasMap, setTips } from './tips';
import { setAdvice } from './advice';
import initialDataTransform from '@/api/transforms/initialData/initialDataTransform';
import runTriggersThunk from '../thunks/runTriggers';
import initModuleOnQuestionDisplayOrderThunk from '../thunks/initModuleOnQuestionDisplayOrder';
import {
  setClient,
  setClosureTime,
  setDescription,
  setDescriptionAtTimeOfSuggestIndicators,
  setQuestionnaireStartedAt,
  setIsCaseActive,
  setIsQuestionnaireActive,
  setProject,
  setTriggeredIntegrations,
  setIsDiagnosisEnabled,
  setIsNotesEnabled,
  setIsAddModulesEnabled,
  setQuestionnaireCompletedAt,
  setConfigLabel,
  setQuestionnaireId,
} from './questionnaire';
import { ErrorCodes } from '@/constants/errors';
import {
  setEntityModifications,
  setOutdatedEntities,
  setSessionInEdit,
} from './editor';
import { User } from '@/api/types/user';
import { EditorSession } from '../types/editor';
import getModifiedEntities, {
  getOutdatedEntities,
} from '../utils/getModifiedFields';
import { setIntegrations } from './integrations';
import { getFeatureFlagFromStorage } from '@/components/FeatureFlags/utils/checkAndSetFeatureFlag';

/**
 * Transform the raw api data into usable state chunks and dispatch
 * to all required slices.
 *
 * If an existing case - convert state to how it was previously.
 */
export const hydrateStore =
  ({
    rawInitialData,
    sessionData,
  }: {
    rawInitialData: RawInitialData;
    sessionData: any;
  }): AppThunk =>
  (dispatch) => {
    const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
      'IS_USING_QUESTIONNAIRE_API'
    );
    const {
      questions,
      modules,
      moduleGroups,
      indicators,
      indicatorToCaseTypesMap,
      caseTypes,
      tips,
      tipAliasMap,
      advice,
      integrations,
      user,
      unMergedEntities,
      editorSession,
      caseId: caseIdFromApi,
      project,
      client,
      questionValues,
      selectedCaseTypes,
      selectedIndicators,
      selectedModules,
      suggestedIndicators,
      suggestedCaseTypes,
      manuallySelectedModules,
      caseDescription: notes, // To be renamed by "notes" (from questionnaire.notes).
      isIntakeActive = false, // To be replaced by "isActive".
      triggeredIntegrations,
      // Please note: The field "isActive" is used interchangeably ATM for closure and questionnaire.
      // In the new API we shall use it to control the active state of the questionnaire,
      // and for the legacy case API its used to control the case closure active state.
      isActive,
      startedAt,
      completedAt,
      isDiagnosisEnabled,
      isNotesEnabled,
      isAddModulesEnabled,
      staticModuleIds,
      configLabel,
      questionnaireId,
    } = initialDataTransform({ initialData: rawInitialData, sessionData });

    if (sessionData) {
      dispatch(setSessionInEdit(editorSession as EditorSession));
      dispatch(
        setEntityModifications(
          getModifiedEntities(editorSession as EditorSession)
        )
      );

      dispatch(
        setOutdatedEntities(
          getOutdatedEntities({
            // @ts-ignore
            edited: editorSession?.edited!,
            // @ts-ignore
            original: unMergedEntities,
          })
        )
      );
    }

    if (IS_USING_QUESTIONNAIRE_API) {
      dispatch(setQuestionnaireId(questionnaireId!));
      dispatch(setConfigLabel(configLabel!));
      dispatch(setIsDiagnosisEnabled(isDiagnosisEnabled!));
      dispatch(setIsNotesEnabled(isNotesEnabled!));
      dispatch(setIsAddModulesEnabled(isAddModulesEnabled!));
      dispatch(setStaticModuleIds(staticModuleIds!));
      dispatch(setIsQuestionnaireActive(isActive!));
      dispatch(setQuestionnaireStartedAt(startedAt!));
      dispatch(setQuestionnaireCompletedAt(completedAt!));
    } else {
      dispatch(setIsQuestionnaireActive(isIntakeActive));
      dispatch(setQuestionnaireStartedAt(startedAt!));
      dispatch(setClosureTime(completedAt!));
      dispatch(setIsCaseActive(isActive));
    }

    dispatch(setIsCaseValid(!!caseIdFromApi));
    dispatch(setQuestions(questions));
    dispatch(setIntegrations(integrations));
    dispatch(setTriggeredIntegrations(triggeredIntegrations));
    dispatch(setTipAliasMap(tipAliasMap));
    dispatch(setCurrentUser(user));
    dispatch(setProject(project));
    dispatch(setClient(client));
    dispatch(setIndicators(indicators));
    dispatch(addSelectedIndicators(selectedIndicators));
    dispatch(setSuggestedIndicators(suggestedIndicators));
    dispatch(setCaseTypes(caseTypes));
    dispatch(setIndicatorToCaseTypesMap(indicatorToCaseTypesMap));
    dispatch(addSelectedCaseTypes(selectedCaseTypes));

    // Selected case types need to also be suggested in order to be displayed.
    // This might change.
    dispatch(addSuggestedCaseTypes(suggestedCaseTypes));

    dispatch(setModules(modules));
    dispatch(setModuleGroups(moduleGroups));
    dispatch(addSelectedModules({ moduleIds: selectedModules }));
    dispatch(addManuallySelectedModules(manuallySelectedModules));
    dispatch(setTips(tips));
    dispatch(setAdvice(advice));
    dispatch(setQuestionValue(questionValues));
    dispatch(
      initModuleOnQuestionDisplayOrderThunk(
        staticModuleIds?.length ? staticModuleIds : selectedModules
      )
    );
    dispatch(setDescription(notes));
    // Ensure the version we use to diff check is up to date from the start.
    dispatch(setDescriptionAtTimeOfSuggestIndicators(notes));

    // If we selected modules, this means we have presaved answers in store that
    // we can run the triggers for each root question which will recursively validate answers.
    // This ensures the relevent questions are initially displayed
    !!selectedModules ||
      (!!staticModuleIds && dispatch(runTriggersThunk(selectedModules)));
  };

export const configSlice = createSlice({
  name: 'config',
  initialState: initialState.config,
  reducers: {
    setHasInitialised: (state, action: PayloadAction<boolean>) => {
      state.hasInitialised = action.payload;
    },
    setHasAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.hasAuthenticated = action.payload;
    },
    setIsCaseValid: (state, action: PayloadAction<boolean>) => {
      state.isCaseValid = action.payload;
    },
    addError: (
      state,
      action: PayloadAction<{ code: ErrorCodes; error?: Error }>
    ) => {
      state.errors.push(action.payload);
    },
    setError: (
      state,
      action: PayloadAction<{ code: ErrorCodes; error?: Error }>
    ) => {
      state.errors = [action.payload];
    },
    removeError: (state, action: PayloadAction<ErrorCodes>) => {
      state.errors = state.errors.filter(
        (error) => error.code !== action.payload
      );
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setFeatureFlags: (
      state,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.featureFlags = action.payload;
    },
  },
});

export const selectHasInitialised = (state: RootState) =>
  state.config.hasInitialised;
export const selectHasAuthenticated = (state: RootState) =>
  state.config.hasAuthenticated;
export const selectIsCaseValid = (state: RootState) => state.config.isCaseValid;
export const selectErrors = (state: RootState) => state.config.errors;
export const selectLatestError = (state: RootState) => state.config.errors[0];
export const selectCurrentUser = (state: RootState) => state.config?.user ?? {};
export const selectFeatureFlags = (state: RootState) =>
  state.config.featureFlags;
export const {
  setHasInitialised,
  setHasAuthenticated,
  setIsCaseValid,
  addError,
  removeError,
  setCurrentUser,
  setFeatureFlags,
  setError,
} = configSlice.actions;

export default configSlice.reducer;

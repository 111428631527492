import { ModuleId } from '@/store/types/basicTypes';
import { AppThunk } from '@/store/store';
import { debounce } from 'lodash';
import { updateTip } from '@/store/slices/tips';
import { Tip } from '@/store/types/tip';
import editTip from '@/api/mutations/editor/editTip';
import { setIsDirty, updateEntityModifications } from '@/store/slices/editor';

const debouncedEditTip = debounce((id) => {
  editTip(id);
}, 1000);

const updateTipThunk =
  ({
    id,
    updates,
    shouldDebounce,
  }: {
    id: ModuleId;
    updates: Partial<Tip>;
    shouldDebounce?: boolean;
  }): AppThunk =>
  (dispatch) => {
    dispatch(setIsDirty(true));
    dispatch(updateTip({ id, updates }));
    dispatch(
      updateEntityModifications({
        entityType: 'tips',
        entityId: id,
        fields: Object.keys(updates),
      })
    );
    if (shouldDebounce) {
      debouncedEditTip(id);
    } else {
      editTip(id);
    }
  };

export default updateTipThunk;

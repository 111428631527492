import { StateEntities } from '../types/basicTypes';
import { EditorSession } from '../types/editor';

interface Entity {
  id: string;
  to: any;
  from: any;
  originalVersion: number;
  isOutdated: boolean;
}

const excludedFields = ['version', 'id'];

export const removeIdsFromObjectArray = (arr: unknown[]) => {
  if (Array.isArray(arr)) {
    return arr.map((item) => {
      if (typeof item === 'object') {
        const { id, ...rest } = item as { id: string };
        return rest;
      }
      return item;
    });
  }
  return arr;
};

export const getModifiedFields = ({ from, to }: Partial<Entity>) => {
  const modifiedFields = Object.keys(from || {})
    .filter((key) => !excludedFields.includes(key))
    .reduce((acc, key) => {
      const hasDiff =
        JSON.stringify(removeIdsFromObjectArray(from[key] || '')) !==
        JSON.stringify(removeIdsFromObjectArray(to[key] || ''));
      if (hasDiff) {
        return [...acc, key];
      }
      return acc;
    }, [] as string[]);

  return modifiedFields;
};

// Get a list of modified entities and their modified fields.
// Do this by comparing the from and to versions of the entity.
export const getModifiedEntities = ({ edited }: EditorSession) =>
  Object.keys(edited ?? {}).reduce(
    (acc, entityType) => ({
      ...acc,
      [entityType]: {
        ...Object.keys(edited[entityType as keyof typeof edited]).reduce(
          (acc, key) => {
            const entity = edited[entityType as keyof typeof edited][
              key as keyof (typeof edited)[keyof typeof edited]
            ] as Entity;
            // Since the validation object is nested, we need to track those modified fields also.
            // Do this by iterating over the validation object and comparing the fields.
            const validationFields = getModifiedFields({
              from: entity.from.validation,
              to: entity.to.validation,
            });
            return {
              ...acc,
              [entity.id]: [...getModifiedFields(entity), ...validationFields],
            };
          },
          {}
        ),
      },
    }),
    {}
  );

// Get a list of outdated entities and their modified fields.
// Do this by comparing the from and original version of the entity.
export const getOutdatedEntities = ({
  edited,
  original,
}: {
  edited: EditorSession;
  original: { [key: string]: StateEntities[] };
}) => {
  return Object.keys(edited ?? {}).reduce(
    (acc, entityType) => ({
      ...acc,
      [entityType]: {
        ...Object.keys(edited[entityType as keyof typeof edited]).reduce(
          (acc, key) => {
            const entity = edited[entityType as keyof typeof edited][
              key as keyof (typeof edited)[keyof typeof edited]
            ] as Entity;

            if (!entity.isOutdated) {
              return acc;
            }

            return {
              ...acc,
              [entity.id]: getModifiedFields({
                from: entity.from,
                to: original[entityType].find((e) => e.id === entity.from.id),
              }),
            };
          },
          {}
        ),
      },
    }),
    {}
  );
};

export default getModifiedEntities;

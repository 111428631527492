import { useAppSelector, useAppDispatch } from '@/hooks';
import {
  selectQuestionValue,
  removeSuggestedQuestionValues,
} from '@/store/slices/questions';
import {
  MultipleChoiceValue,
  QuestionAnswerValue,
  QuestionOptions,
} from '@/store/types/question';
import { QuestionId } from '@/store/types/basicTypes';
import {
  AiIndicator,
  FormControlLabelWrapper,
  RadioGroupWrapper,
} from './styled';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Checkbox } from '@s-rm/react-ui-lib';

interface RadioProps {
  options: QuestionOptions[];
  onChange: (val: string) => void;
  questionId: QuestionId;
  'data-testid': string;
  controlledValue?: string;
  onClear?: () => void;
  suggestedValue?: QuestionAnswerValue | null;
  appliedSuggestedValue?: boolean;
  hideSuggestedValue?: boolean;
}

const MultiSelect = ({
  options,
  onChange,
  questionId,
  'data-testid': testId,
  controlledValue,
  onClear,
  suggestedValue,
  appliedSuggestedValue,
  hideSuggestedValue = false,
}: RadioProps) => {
  const value = useAppSelector((state) =>
    selectQuestionValue(state, questionId)
  ) as MultipleChoiceValue;

  const valueToUse = controlledValue || value;

  // @ts-ignore
  const onClickOptionHandler = (e) => {
    onChange(e.target.value);
  };

  const shouldRenderAiIndicator = (option: QuestionOptions) =>
    !hideSuggestedValue &&
    !appliedSuggestedValue &&
    (option.value === suggestedValue || option.label === suggestedValue);

  return (
    <Stack>
      {options?.map((option) => (
        <FormControlLabelWrapper key={option.value}>
          <FormControlLabel
            checked={valueToUse?.includes(option.value)}
            value={option.value}
            control={<Checkbox />}
            label={option.label}
            onClick={onClickOptionHandler}
            data-testId={`question-option-${questionId}`}
          />
          {shouldRenderAiIndicator(option) && <AiIndicator>AI</AiIndicator>}
        </FormControlLabelWrapper>
      ))}
    </Stack>
  );
};

export default MultiSelect;

import { AppThunk } from '../store';
import updateSnackbarNotification from './updateSnackbar';
import { SnackbarTypes } from '../types/ui';
import createSentinelOneSite from '@/api/mutations/createSentinelOneSite';

const triggerS1IntegrationThunk = (): AppThunk => async (dispatch) => {
  const { sentinelOneSiteCreated } = await createSentinelOneSite();

  if (sentinelOneSiteCreated) {
    dispatch(
      updateSnackbarNotification({
        message: 'The integration has been successfully created!',
        type: SnackbarTypes.Success,
      })
    );
  }
};

export default triggerS1IntegrationThunk;

import updateQuestionnaire from '@/api/mutations/updateQuestionnaire';
import { setClosureTime, setIsCaseActive } from '../slices/questionnaire';
import { AppThunk } from '../store';

const submitCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsCaseActive(false));
  const {
    data: {
      updateCase: { closureTime },
    },
  } = (await updateQuestionnaire()) as { data: any };
  dispatch(setClosureTime(closureTime));
};

export default submitCase;

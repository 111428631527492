import client from '@/api/client';
//@ts-ignore
import { UpdateQuestion } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import { isEmptyTags } from '@/utils/helpers';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const editQuestion = async (questionId: string) => {
  const question = store.getState().questions.entities[questionId];
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: UpdateQuestion,
    variables: {
      questionId,
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        control: question.control,
        label: question.label,
        placeholder: question.placeholder,
        guidance: isEmptyTags(question.guidance) ? '' : question.guidance,
        options: question.options.map((o) => ({
          label: o.label,
          value: o.value,
        })),
        triggers: question.triggers.map((t) => ({
          componentType: t.componentType,
          componentId: t.componentId,
          triggeringValue: t.value,
          action: t.action,
        })),
        validation: {
          pattern: question.validation.pattern,
          maxLength: question.validation.maxLength,
          minLength: question.validation.minLength,
          maxValue: question.validation.maxValue,
          minValue: question.validation.minValue,
          required: question.validation.isRequired,
          longText: question.validation.longText,
        },
        integrationTags: question.integrationTags,
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.EditQuestion,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data };
};

export default editQuestion;

import { EntityType, StateEntities } from '../types/basicTypes';
import { ComponentReferenceObject, Question } from '../types/question';

const isQuestionEntity = (obj: StateEntities): obj is Question => {
  return !!(obj as Question).triggers;
};

/**
 * Create array of questions in the order that they will be rendered from first to last
 * by checking the order of the questions triggers, we can derive the children for each
 * question in the recursive function, and add them to the structure.
 */
export const buildStructure = (
  entities: StateEntities[],
  rootObj: ComponentReferenceObject
) => {
  let structure: ComponentReferenceObject[] = [];

  const updateStructure = (
    currentItem: Partial<ComponentReferenceObject>,
    parentId = ''
  ) => {
    if (currentItem.componentType === EntityType.Module) {
      return;
    }
    const isQuestionItem = currentItem.componentType === EntityType.Question;

    const currentEntity =
      isQuestionItem && entities.find((entity) => entity.id === currentItem.id);

    const triggeredChildren =
      currentEntity && isQuestionEntity(currentEntity)
        ? currentEntity.triggers?.map((t) => ({
            id: t.componentId,
            componentType: t.componentType,
          }))
        : [];

    structure.push({
      id: currentItem.id as string,
      componentType: currentItem.componentType || EntityType.Question,
      parentId,
    });
    triggeredChildren.forEach((child) => {
      updateStructure(child as ComponentReferenceObject, currentItem.id);
    });
  };

  updateStructure(rootObj);

  return structure;
};

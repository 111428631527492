import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export const useDebouncedInputChange = ({
  onDebounce,
  initialValue,
  cb,
}: {
  onDebounce?: (val: { isDebouncing: boolean }) => void;
  initialValue: string;
  cb: (val: string) => void;
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false);

  const handleDebounceEnd = (val: string) => {
    cb(val);
    setIsDebouncing(false);
    onDebounce?.({ isDebouncing: false });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(handleDebounceEnd, 800), []);

  const handleChange = (userInput: string) => {
    setIsDebouncing(true);
    onDebounce?.({ isDebouncing: true });
    setValue(userInput);
    debounceFn(userInput);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return {
    value,
    handleChange,
    isDebouncing,
  };
};

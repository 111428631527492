interface Entity {
  id: string;
}

export type FlattenedEntities<E> = {
  entities: { [key: string]: E };
  ids: string[];
};

export const getFlattenedEntity = <T extends Entity>(
  entities: T[]
): FlattenedEntities<T> => ({
  entities: entities.reduce(
    (acc, entity) => ({
      ...acc,
      [entity.id]: entity,
    }),
    {} as { [key: string]: T }
  ),
  ids: entities.map((entity) => entity.id),
});

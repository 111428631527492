import useRequiredQuestionsCounter from './hooks/useRequiredQuestionsCounter';
import { Counter } from './styled';
import { Box, Typography } from '@mui/material';
import useGetModulesWithGroups from '@/hooks/useGetModulesWithGroups';

const GlobalQuestionCounter = () => {
  const moduleIds = useGetModulesWithGroups();
  const { total, answered, isComplete } =
    useRequiredQuestionsCounter(moduleIds).combinedCounters;

  if (!total) {
    return null;
  }

  return (
    <Counter isComplete={isComplete}>
      <div>
        <Typography component='span' mr={1} color='pal'>
          Required questions
        </Typography>
        <span data-testid={`global-required-questions-answered`}>
          {answered}
        </span>
        <span>/</span>
        <span data-testid={`global-total-required-questions`}>{total}</span>
      </div>
      <Box
        fontSize='1.2rem'
        fontWeight={600}
        lineHeight={0}
        color={isComplete ? 'primary.main' : 'error.main'}
        ml={'-5px'}
      >
        *
      </Box>
    </Counter>
  );
};

export default GlobalQuestionCounter;

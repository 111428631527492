import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DialogWrapper } from './styled';
import { useAppDispatch } from '@/hooks';
import { clearQuestionnaireThunk } from '@/store/thunks/clearQuestionnaireThunk';
import { useRouter } from 'next/router';

export const ConfirmClearQuestionnaireDialog = ({
  isOpen = false,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const CONFIRMATION_STRING = 'CLEAR_CASE_CONFIRM';
  const [inputValue, setInputValue] = useState('');
  const [inputConfirmed, setInputConfirmed] = useState(false);

  const handleInputChange = (e: any) => setInputValue(e.target.value);

  useEffect(() => {
    inputValue === CONFIRMATION_STRING
      ? setInputConfirmed(true)
      : setInputConfirmed(false);
  }, [inputValue]);

  const handleOnCloseDialog = () => {
    handleClose();
    setInputValue('');
    setInputConfirmed(false);
  };

  const handleOnClearCaseData = async () => {
    await dispatch(clearQuestionnaireThunk());
    router.reload();
  };

  return (
    <DialogWrapper open={isOpen} data-testid='confirm-clear-case-dialog'>
      <DialogTitle sx={{ m: 0, p: 3, paddingBottom: 0 }}>
        CONFIRM CLEAR CASE
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleOnCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>
          To confirm, type <b>{CONFIRMATION_STRING}</b> in the box below
        </Typography>
        <TextField
          role='input'
          error={!inputConfirmed}
          id='confirm-input'
          onChange={handleInputChange}
          sx={{ marginTop: '16px' }}
          fullWidth={true}
          inputProps={{ 'data-testid': 'confirm-text-input' }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          autoFocus
          disabled={!inputConfirmed}
          onClick={handleOnClearCaseData}
          data-testid='confirm-clear-case-btn'
          sx={{ borderRadius: '0' }}
          style={
            !inputConfirmed
              ? { cursor: 'not-allowed', pointerEvents: 'auto' }
              : { cursor: 'pointer' }
          }
        >
          Clear case data
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};

import { CaseTypeId, ModuleId } from '../types/basicTypes';
import { CaseType, CaseTypeSlice } from '../types/caseType';
import { ModuleSelectedByTrigger } from '../types/module';

const getTriggeredModulesToRemove = ({
  caseTypesToRemove,
  allTriggeredModules,
}: {
  caseTypesToRemove: CaseTypeId[];
  allTriggeredModules: ModuleSelectedByTrigger[];
}): ModuleSelectedByTrigger[] => {
  return caseTypesToRemove.reduce(
    (acc, caseTypeId) => [
      ...acc,
      ...allTriggeredModules.filter((m) => m.relatedCaseTypeId === caseTypeId),
    ],
    [] as ModuleSelectedByTrigger[]
  );
};

export default getTriggeredModulesToRemove;

import client from '@/api/client';
//@ts-ignore
import { CreateModule } from '@/api/gql/mutations.gql';
import { store } from '@/store';
import createModuleTransform from '@/api/transforms/editor/createModuleTransform';
import { ErrorCodes } from '@/constants/errors';
import { createError } from '@/utils/errorHandler';

export const createModule = async () => {
  const editorSessionId = store.getState().editor.sessionInEdit?.id;

  const { data, errors } = await client.mutate({
    mutation: CreateModule,
    variables: {
      ...(editorSessionId ? { editorSessionId } : {}),
      content: {
        label: '',
        description: '',
        rootQuestionIds: [],
      },
    },
  });

  if (errors) {
    createError({
      code: ErrorCodes.CreateModule,
      error: new Error(JSON.stringify(errors)),
      displaySnackbar: true,
    });
  }

  return { data: createModuleTransform(data) };
};

export default createModule;

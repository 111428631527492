import { ModuleId } from '@/store/types/basicTypes';
import { ModuleDisplayStructure } from '../types';

export const getModuleIdsFromDisplayStructure = (
  moduleDisplayStructure: ModuleDisplayStructure[]
): ModuleId[] =>
  Array.from(
    new Set(
      moduleDisplayStructure.reduce(
        (acc, group) => [
          ...acc,
          ...group.caseTypes!.reduce(
            (acc, caseType) => [
              ...acc,
              ...caseType.relatedModules.map((moduleId) => moduleId),
            ],
            [] as ModuleId[]
          ),
        ],
        [] as ModuleId[]
      )
    )
  );

export default getModuleIdsFromDisplayStructure;

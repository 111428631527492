import { ComponentReferenceObject } from '@/store/types/question';
import getEntityParents from '../../utils/getEntityParents';

interface getDisplayStructure {
  moduleIds: string[];
  structure: { [key: string]: ComponentReferenceObject[] };
  childId: string;
}

const getDisplayStructure = ({
  moduleIds,
  structure,
  childId,
}: getDisplayStructure): {
  id: string;
  children: ComponentReferenceObject[];
}[] =>
  moduleIds.reduce((acc, moduleId) => {
    return [
      ...acc,
      {
        id: moduleId,
        children: [
          ...getEntityParents(structure[moduleId], childId as string),
        ].reverse(),
      },
    ];
  }, [] as { id: string; children: ComponentReferenceObject[] }[]);

export default getDisplayStructure;

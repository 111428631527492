import { ModuleId } from '@/store/types/basicTypes';
import { ComponentReferenceObject } from '@/store/types/question';

const getModulesContainingChild = (
  structure: { [key: string]: ComponentReferenceObject[] },
  childId: string
): ModuleId[] => {
  const isDuplicated = Object.keys(structure).some(
    (key) =>
      structure[key].some(
        (item) => item.id === childId && key !== 'placeholder-module'
      ) && structure['placeholder-module']?.some((item) => item.id === childId)
  );

  return Object.keys(structure).filter((key) => {
    return structure[key].some(
      (item) =>
        item.id === childId && !(isDuplicated && key === 'placeholder-module')
    );
  });
};

export default getModulesContainingChild;

import { Routes, routeData } from '@/constants/routes';
import CategoryIcon from '@mui/icons-material/Category';
import TuneIcon from '@mui/icons-material/Tune';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const navPaths = [
  {
    label: routeData[Routes.CaseType].label,
    value: routeData[Routes.CaseType].path as string,
    icon: <CategoryIcon fontSize='small' />,
  },
  {
    label: routeData[Routes.Specifics].label,
    value: routeData[Routes.Specifics].path as string,
    icon: <TuneIcon fontSize='small' />,
  },
  {
    label: routeData[Routes.Summary].label,
    value: routeData[Routes.Summary].path as string,
    icon: <GroupWorkIcon fontSize='small' />,
  },
];

export const closureRoute = {
  label: routeData[Routes.Closure].label,
  value: routeData[Routes.Closure].path as string,
  icon: <CheckCircleIcon fontSize='small' />,
};

export default navPaths;

import { useAppDispatch, useAppSelector } from '@/hooks';
import { ListActions } from '../styled';
import {
  Badge,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  selectEntityModificationsCount,
  selectOutdatedEntities,
  setActiveEntitySearchList,
  setModuleGroupInEdit,
} from '@/store/slices/editor';
import { removeAllTags } from '@/utils/helpers';
import { ModuleId } from '@/store/types/basicTypes';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button } from '@s-rm/react-ui-lib';
import { selectModuleGroup } from '@/store/slices/modules';
import { SecondaryBadge } from '../../styled';

const ModuleGroupListItem = ({
  id,
  onDelete,
}: {
  id: ModuleId;
  onDelete: () => void;
}) => {
  const dispatch = useAppDispatch();

  const { label } = useAppSelector((state) => selectModuleGroup(state, id)) || {
    label: '',
  };
  const entityModifications = useAppSelector((state) =>
    selectEntityModificationsCount(state, 'moduleGroups', id)
  );
  const isOutdated = !!useAppSelector(selectOutdatedEntities).moduleGroups[id];

  const handleClickModuleGroup = () => {
    dispatch(setActiveEntitySearchList(null));
    dispatch(setModuleGroupInEdit({ id }));
  };

  return (
    <ListItem
      sx={{ background: 'white' }}
      secondaryAction={
        <ListActions>
          <Button square>
            <DeleteOutlineOutlinedIcon
              onClick={onDelete}
              data-testid='btn-delete-mgroup-item'
            />
          </Button>
        </ListActions>
      }
      disablePadding
    >
      <ListItemButton
        style={{ paddingRight: '100px' }}
        data-testid={id}
        onClick={handleClickModuleGroup}
      >
        <ListItemText>
          <Box position='absolute' top='-10px'>
            <Tooltip title='Current draft contains changes to this module group'>
              <Badge color='secondary' badgeContent={entityModifications} />
            </Tooltip>
            <Tooltip title='This module group is out of date with the latest published draft'>
              <SecondaryBadge badgeContent={isOutdated ? 'outdated' : null} />
            </Tooltip>
          </Box>
          <Typography noWrap>{removeAllTags(label)}</Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export default ModuleGroupListItem;

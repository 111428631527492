import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Button } from '@s-rm/react-ui-lib';
import CloseIcon from '@mui/icons-material/Close';
import CopyButton from '../PrintableSummary/CopyButton';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { DialogueTitleContainer } from '../ConfirmationModal/styled';
import SummaryQuestions from '../SummaryContent/SummaryQuestions';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useReactToPrint } from 'react-to-print';
import copyToClipboard from '../PrintableSummary/utils/copyToClipboard';
import updateSnackbarNotification from '@/store/thunks/updateSnackbar';
import {
  selectIsClosureSummaryDialogOpen,
  setIsClosureSummaryDialogOpen,
} from '@/store/slices/ui';
import useOrderedModules from '@/hooks/useOrderedModules';

const PrintableCaseClosureDialog = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsClosureSummaryDialogOpen);
  const onClose = () => dispatch(setIsClosureSummaryDialogOpen(false));
  const orderedModules = useOrderedModules();
  const handlePrint = useReactToPrint({
    content: () => document?.querySelector?.('#printable-content'),
  });

  const handleCopy = () => {
    copyToClipboard('printable-content');
    dispatch(updateSnackbarNotification({ message: 'Copied to clipboard' }));
  };

  return (
    <Dialog
      data-testid='case closure summary dialog'
      open={isOpen}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle sx={{ textAlign: 'right' }}>
        <DialogueTitleContainer>
          <Typography variant='h6' textTransform='uppercase' fontWeight={700}>
            Closure Preview
          </Typography>
          <IconButton
            aria-label='close'
            onClick={onClose}
            data-testid={`case-closure-summary-close-btn`}
          >
            <CloseIcon />
          </IconButton>
        </DialogueTitleContainer>
      </DialogTitle>
      <DialogContent id='printable-content'>
        <Box>
          {orderedModules.map((selectedModule) => (
            <SummaryQuestions
              key={selectedModule}
              moduleId={selectedModule}
              getAsPrintView
              hideUnanswered
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box py={2} px={2} display='flex' gap={2}>
          <>
            <CopyButton onClick={handleCopy}>Copy summary</CopyButton>
            <Button
              startIcon={<LocalPrintshopOutlinedIcon />}
              variant='outlined'
              onClick={handlePrint}
            >
              Print
            </Button>
          </>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PrintableCaseClosureDialog;

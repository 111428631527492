import { caseType1Mock, caseType2Mock } from './caseTypesMock';

export const indicator1Mock = {
  id: 'indicator-1',
  label: 'Indicator 1',
};

export const indicator2Mock = {
  id: 'indicator-2',
  label: 'Indicator 2',
};

export const indicatorsMock = {
  entities: {
    [indicator1Mock.id]: indicator1Mock,
    [indicator2Mock.id]: indicator2Mock,
  },
  ids: [indicator1Mock.id, indicator2Mock.id],
  selected: [],
  dismissed: [],
  indicatorCaseTypesMap: {
    [indicator1Mock.id]: [caseType1Mock.id],
    [indicator2Mock.id]: [caseType2Mock.id],
  },
  suggested: [],
};

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import uiReducer from './slices/ui';
import tipsReducer from './slices/tips';
import questionsReducer from './slices/questions';
import modulesReducer from './slices/modules';
import indicatorsReducer from './slices/indicators';
import caseTypesReducer from './slices/caseTypes';
import adviceReducer from './slices/advice';
import configReducer from './slices/config';
import questionDisplayOrderReducer from './slices/questionDisplayOrder';
import questionnaireReducer from './slices/questionnaire';
import editorReducer from './slices/editor';
import integrationsReducer from './slices/integrations';
import adminReducer from './slices/admin/admin';

export const rootReducer = {
  config: configReducer,
  ui: uiReducer,
  questionnaire: questionnaireReducer,
  tips: tipsReducer,
  questions: questionsReducer,
  modules: modulesReducer,
  indicators: indicatorsReducer,
  caseTypes: caseTypesReducer,
  advice: adviceReducer,
  questionDisplayOrder: questionDisplayOrderReducer,
  editor: editorReducer,
  integrations: integrationsReducer,
  admin: adminReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

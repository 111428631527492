import { IndicatorToCaseTypesMap } from '../types/indicator';

const getSugestedCaseTypeFromIndicators = ({
  indicators,
  indicatorCaseTypesMap,
}: {
  indicators: string[];
  indicatorCaseTypesMap: IndicatorToCaseTypesMap;
}) => {
  const caseTypeIds = indicators.reduce(
    (acc: string[], indicatorId: string) => {
      const caseTypeIds = indicatorCaseTypesMap[indicatorId];
      return caseTypeIds ? [...acc, ...caseTypeIds] : acc;
    },
    []
  );

  return caseTypeIds;
};

export default getSugestedCaseTypeFromIndicators;
